import React,{Component,Fragment} from 'react';
import scanlogo from '../assets/images/scanlogo.png';
import {
  Page,
  LoginScreenTitle,
  List,
  ListButton,
  Button,
  Popup,
  Row,
  Col,
  Icon
} from 'framework7-react';

export default class extends Component {
    constructor(props) {
      super(props);
  
      this.state = {

        type:'',
        addedcontact:this.props.f7route.params.addedcontact,
        oldcontact:this.props.f7route.params.oldcontact,
        totalcontact:this.props.f7route.params.totalcontact,
        buttontext:'',

      };

    }

    componentDidMount(){

        this.setState(()=>{
            return{
                type:this.props.f7route.params.type
            }
        },()=>{

            const{type} = this.state;

            let addmorebtntext  = "";

            if(type === "bulkaddsuccess" || type==='bulkqueuesuccess')
            {
                addmorebtntext  = "Add More Bulk Contact";
            }
            else if(type === "addcampaignsuccess"|| type === "resendcampaignsuccess")
            {
                addmorebtntext  = "Add More Campaign";
            }
            else if(type === "addlistsuccess")
            {
                addmorebtntext  = "Add More List";
            }
            else if(type === "addsupportsuccess")
            {
                addmorebtntext  = "Add More Support";
            }
            else if(type === "addcampaigngroupsuccess")
            {
                addmorebtntext  = "Add More Group";
            }
            else if(type === "addstaffsuccess")
            {
                addmorebtntext  = "Add More Staff";
            }
            else if(type === "addleadcatsuccess")
            {
                addmorebtntext  = "Add More Category";
            }
            else if(type === "addleadtagsuccess")
            {
                addmorebtntext  = "Add More Tag";
            }
            else if(type === "addsmscreditsuccess")
            {
                addmorebtntext  = "Add More SMS Credit";
            }
            else if(type === "addleadcreditsuccess")
            {
                addmorebtntext  = "Add More Lead Credit";
            }
            else if(type === "addcontactsuccess")
            {
                addmorebtntext  = "Add More Contact";
            }
            else if(type === "addpagesuccess")
            {
                addmorebtntext  = "Add More Page";
            }

            this.setState({
                buttontext:addmorebtntext
            });
        })

    }
      
    render() {
        
        const{type, addedcontact, oldcontact, totalcontact, buttontext} = this.state;

      return (
        <Popup>
            <Page noToolbar noNavbar noSwipeback loginScreen>
                <Row>
                    <Col>
                        <img className="loginlogo" src={scanlogo} alt="logo" />
                    </Col>
                </Row>
                <LoginScreenTitle>Success</LoginScreenTitle>
                <Row noGap>
                    <Col width="100" style={{textAlign:'center'}}>
                        <Icon f7="checkmark_alt_circle_fill" style={{color:'green',fontSize:'3rem'}}></Icon>
                    </Col>
                </Row>
                {(()=>{
                    if(type === "bulkaddsuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80">
                                    <br />
                                    {
                                        Number(oldcontact) ? (<p style={{textAlign:'center'}}>
                                            {addedcontact} contact added, <span style={{color:'#ff0000'}}>{oldcontact}</span> already exists out of {totalcontact} contact(s)
                                        </p>):(<p style={{textAlign:'center'}}>
                                            {addedcontact} contact added out of {totalcontact} contact(s)
                                        </p>)
                                    }
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                    else if(type === "bulkqueuesuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80">
                                    <br />
                                    Your request has been added to the queue. Contact(s) will be addded shortly.
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addcampaignsuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80" style={{textAlign:'center'}}>
                                    <br />
                                    Campaign added successfully.
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addlistsuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80" style={{textAlign:'center'}}>
                                    <br />
                                    List added successfully.
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addsupportsuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80" style={{textAlign:'center'}}>
                                    <br />
                                    Support added successfully.
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addcampaigngroupsuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80" style={{textAlign:'center'}}>
                                    <br />
                                    Campaign group added successfully.
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addstaffsuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80" style={{textAlign:'center'}}>
                                    <br />
                                    Staff added successfully.
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addleadcatsuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80" style={{textAlign:'center'}}>
                                    <br />
                                    Category added successfully.
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addleadtagsuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80" style={{textAlign:'center'}}>
                                    <br />
                                    Tag added successfully.
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addsmscreditsuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80" style={{textAlign:'center'}}>
                                    <br />
                                    SMS credit request sent successfully.
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addleadcreditsuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80" style={{textAlign:'center'}}>
                                    <br />
                                    Lead credit request sent successfully.
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addcontactsuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80" style={{textAlign:'center'}}>
                                    <br />
                                    Contact added successfully.
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                    else if(type === "resendcampaignsuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80" style={{textAlign:'center'}}>
                                    <br />
                                    Campaign resend successfully.
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addpagesuccess")
                    {
                        return(
                            <Row noGap>
                                <Col width="10"></Col>
                                <Col width="80" style={{textAlign:'center'}}>
                                    <br />
                                    Page added successfully.
                                </Col>
                                <Col width="10"></Col>
                            </Row>
                        )
                    }
                })()}
                <br />
                <Row>
                    <Col width="20"></Col>
                    <Col width="60"><Button fill color="blue" popupClose>{buttontext}</Button></Col>
                    <Col width="20"></Col>
                </Row>
                <br /><br />
                {(()=>{
                    if(type === "bulkaddsuccess" || type === "addcontactsuccess")
                    {
                        return(
                            <Row>
                                <Col width="20"></Col>
                                <Col width="60">
                                    <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                        <ListButton
                                            onClick={()=>{
                                               this.$f7.views.main.router.back({
                                                url:`/managecontacts/`,
                                                options:{
                                                    force:true
                                                }
                                            });
                                            this.$f7.views.main.router.navigate(`/managecontacts/`);
                                            }}
                                            style={{color:'green !important'}}>Go to Manage Contacts <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                    </List>
                                </Col>
                                <Col width="20"></Col>
                            </Row>
                        )
                    }
                    if(type === "bulkqueuesuccess")
                    {
                        return(
                            <Row>
                                <Col width="20"></Col>
                                <Col width="60">
                                    <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                        <ListButton
                                            onClick={()=>{
                                               this.$f7.views.main.router.back({
                                                url:`/bulkaddrequest/`,
                                                options:{
                                                    force:true
                                                }
                                            });
                                            this.$f7.views.main.router.navigate(`/bulkaddrequest/`);
                                            }}
                                            style={{color:'green !important'}}>Go to Pending Request <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                    </List>
                                </Col>
                                <Col width="20"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addcampaignsuccess" || type === "resendcampaignsuccess")
                    {
                        return(
                            <Row>
                                <Col width="20"></Col>
                                <Col width="60">
                                    <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                        <ListButton
                                            onClick={()=>{
                                                this.$f7.views.main.router.back({
                                                    url:`/campaign/`,
                                                    options:{
                                                        force:true
                                                    }
                                                });
                                            this.$f7.views.main.router.navigate(`/campaign/`);
                                            }}
                                            style={{color:'green !important'}}>Go to Manage Campaigns <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                    </List>
                                </Col>
                                <Col width="20"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addcampaigngroupsuccess")
                    {
                        return(
                            <Row>
                                <Col width="20"></Col>
                                <Col width="60">
                                    <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">

                                        <ListButton
                                            onClick={()=>{
                                                this.$f7.views.main.router.back({
                                                    url:`/campaigngroup/`,
                                                    options:{
                                                        force:true
                                                    }
                                                });
                                            this.$f7.views.main.router.navigate(`/campaigngroup/`);
                                            }}
                                            style={{color:'green !important'}}>Go to Manage Group <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                    </List>
                                </Col>
                                <Col width="20"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addstaffsuccess")
                    {
                        return(
                            <Row>
                                <Col width="20"></Col>
                                <Col width="60">
                                    <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                        <ListButton
                                            onClick={()=>{
                                               this.$f7.views.main.router.back({
                                                    url:`/staff/`,
                                                    options:{
                                                        force:true
                                                    }
                                                });
                                            this.$f7.views.main.router.navigate(`/staff/`);
                                            }}
                                            style={{color:'green !important'}}>Go to Manage Staff <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                    </List>
                                </Col>
                                <Col width="20"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addleadcatsuccess")
                    {
                        return(
                            <Row>
                                <Col width="20"></Col>
                                <Col width="60">
                                    <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                        <ListButton
                                            onClick={()=>{
                                                this.$f7.views.main.router.back({
                                                    url:`/leadcategory/`,
                                                    options:{
                                                        force:true
                                                    }
                                                });
                                            this.$f7.views.main.router.navigate(`/leadcategory/`);
                                            }}
                                            style={{color:'green !important'}}>Go to Manage Category <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                    </List>
                                </Col>
                                <Col width="20"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addsupportsuccess")
                    {
                        return(
                            <Row>
                                <Col width="20"></Col>
                                <Col width="60">
                                    <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                        <ListButton
                                            onClick={()=>{
                                                this.$f7.views.main.router.back({
                                                    url:`/support/`,
                                                    options:{
                                                        force:true
                                                    }
                                                });
                                            this.$f7.views.main.router.navigate(`/support/`);
                                            }}
                                            style={{color:'green !important'}}>Go to Manage Support <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                    </List>
                                </Col>
                                <Col width="20"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addleadtagsuccess")
                    {
                        return(
                            <Row>
                                <Col width="20"></Col>
                                <Col width="60">
                                    <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                        <ListButton
                                            onClick={()=>{
                                                this.$f7.views.main.router.back({
                                                    url:`/leadtag/`,
                                                    options:{
                                                        force:true
                                                    }
                                                });
                                            this.$f7.views.main.router.navigate(`/leadtag/`);
                                            }}
                                            style={{color:'green !important'}}>Go to Manage Tag <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                    </List>
                                </Col>
                                <Col width="20"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addsmscreditsuccess")
                    {
                        return(
                            <Row>
                                <Col width="20"></Col>
                                <Col width="60">
                                    <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                        <ListButton
                                            onClick={()=>{
                                                this.$f7.views.main.router.back({
                                                    url:`/smscredits/`,
                                                    options:{
                                                        force:true
                                                    }
                                                });
                                            this.$f7.views.main.router.navigate(`/smscredits/`);
                                            }}
                                            style={{color:'green !important'}}>Go to SMS Credit <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                    </List>
                                </Col>
                                <Col width="20"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addleadcreditsuccess")
                    {
                        return(
                            <Row>
                                <Col width="20"></Col>
                                <Col width="60">
                                    <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                        <ListButton
                                            onClick={()=>{
                                                this.$f7.views.main.router.back({
                                                    url:`/smscredits/leadcredit/`,
                                                    options:{
                                                        force:true
                                                    }
                                                });
                                                this.$f7.views.main.router.navigate(`/smscredits/leadcredit/`);
                                            }}
                                            style={{color:'green !important'}}>Go to Lead Credit <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                    </List>
                                </Col>
                                <Col width="20"></Col>
                            </Row>
                        )
                    }
                    else if(type === "addpagesuccess")
                    {
                        return(
                            <Row>
                                <Col width="20"></Col>
                                <Col width="60">
                                    <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                        <ListButton
                                            onClick={()=>{
                                                this.$f7.views.main.router.back({
                                                    url:`/pages/`,
                                                    options:{
                                                        force:true
                                                    }
                                                });
                                                this.$f7.views.main.router.navigate(`/pages/`);
                                            }}
                                            style={{color:'green !important'}}>Go to Manage Pages <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                    </List>
                                </Col>
                                <Col width="20"></Col>
                            </Row>
                        )
                    }
                })()}
                <Row>
                    <Col width="20"></Col>
                    <Col width="60">
                        <List style={{marginTop:'0px',marginBottom:'0px'}}>
                            <ListButton
                                onClick={()=>{
                                    this.$f7.views.main.router.back({
                                        url:`/dashboard/`,
                                        options:{
                                            force:true
                                        }
                                    });
                                    this.$f7.views.main.router.navigate(`/dashboard/`);
                                }}
                                style={{color:'green !important'}}>Go to dashboard <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                        </List>
                    </Col>
                    <Col width="20"></Col>
                </Row>
            </Page>
        </Popup>
      )
    }
  }