import React,{Component,Fragment} from 'react';
import { CommonConsumer } from '../context/CommonContext';
import { CampaignConsumer } from '../context/CampaignContext';

import {
    Page,
    Popup,
    Navbar,
    NavRight,
    NavTitle,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Link,
    Toggle,
  } from 'framework7-react';

  export default class LeadFilter extends Component {
    
    constructor(props) {
      super(props);

      this.state = {

        clientdetail:[],
        campiganlist:[],
        phonenumberlist:[],
        interestedfilterlist:[{id:"1",name:"Yes"},{id:"2",name:"No"},{id:"0",name:"Not Answered"}],
        leadstatuslist:[],
        stafflist:[],
        leadcategorylist:[],
        statelist:[],
        citylist:[],
        pincodelist:[],
        city:'',
        pincode:'',
        leadstategory:'',
        leadcategoryid:'',
        filterstaffid:'',
        staff:'',
        statename:'',
        campid:'',
        campname:'',
        phonenumber:'',
        isinterested:-1,
        isreferred:false,
        selectedleadcampaign:[],
        selectedleadstatus:['0','2','3','5','7'],
        selectedleadcommonfilter:[],
        isfilterloaded:false,
        isautocompleteload:false,
        effect:'blink',
        defaulttags:[],
        leadtags:[],
        grouplist:[],
        groupid:'',
        groupname:'',
        istagrequestdone:false

      };

      this.handleUserInput = this.handleUserInput.bind(this);

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      
      if(clientdetail !== undefined && clientdetail !== null)
      {
        this.setState(()=>{
          return{

            clientdetail:clientdetail

          }
        },()=>{

          Promise.all([
            
            localStorage.getItem('selectedleadcampaign') && JSON.parse(localStorage.getItem('selectedleadcampaign')).length > 0 && this.setState(()=>{
              return{
                selectedleadcampaign:JSON.parse(localStorage.getItem('selectedleadcampaign'))
              }
            }),
            localStorage.getItem('selectedleadstatus') && JSON.parse(localStorage.getItem('selectedleadstatus')).length > 0 && this.setState(()=>{
              return{
                selectedleadstatus:JSON.parse(localStorage.getItem('selectedleadstatus'))
              }
            }),
            localStorage.getItem('selectedleadtag') && JSON.parse(localStorage.getItem('selectedleadtag')).length > 0 && this.setState(()=>{
              return{
                defaulttags:JSON.parse(localStorage.getItem('selectedleadtag'))
              }
            }),
            localStorage.getItem('selectedleadcommonfilter') && this.setState(()=>{
              return{
                selectedleadcommonfilter:JSON.parse(localStorage.getItem('selectedleadcommonfilter'))
              }
            },()=>{

              let phonenumber = this.state.selectedleadcommonfilter.phonenumber;

              this.setState({
                phonenumber:phonenumber,
                leadcategoryid:this.state.selectedleadcommonfilter.leadcategoryid,
                leadcategory:this.state.selectedleadcommonfilter.leadcategory,
                filterstaffid:this.state.selectedleadcommonfilter.filterstaffid,
                staff:this.state.selectedleadcommonfilter.staff,
                statename:this.state.selectedleadcommonfilter.statename,
                city:this.state.selectedleadcommonfilter.city,
                pincode:this.state.selectedleadcommonfilter.pincode,
                isreferred:this.state.selectedleadcommonfilter.isreferred,
                isintrestedname:this.state.selectedleadcommonfilter.isintrestedname,
              });
              
            })

          ]).then(()=>{

            this.getCampaignFilter();
            this.getLeadStatusFilter();
            this.getLeadTagFilter();

          })

        });
      }
    }

    UNSAFE_componentWillUpdate(nextProps, nextState){
      /*localStorage.setItem("leadcampaignfilter",JSON.stringify(nextState.campid));*/

      if(nextState.campiganlist.length > 0 && nextState.leadstatuslist.length > 0 && this.state.isfilterloaded === false)
      {
        this.setState({
          isfilterloaded:true
        },()=>{

          if(this.state.isautocompleteload === false)
          {
            this.setState({isautocompleteload:true},()=>{
              /*this.onPageInit();*/
            })
          }
          
        })
      }

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'isinterested')
      {
        this.setState({isinterested:!this.state.isinterested});
      }
      else if(name === 'isreferred')
      {
        this.setState({isreferred:!this.state.isreferred});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: !value
          }
        });
      }
    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteCampaignPopup.destroy();
      self.autocompletePhoneNumberPopup.destroy();
      self.autocompleteStaffPopup.destroy();
      self.autocompleteLeadCategoryPopup.destroy();
      self.autocompleteStatePopup.destroy();
      self.autocompleteCityPopup.destroy();
      self.autocompletePincodePopup.destroy();
      self.autocompleteGroupPopup.destroy();

    }

    onPageInit(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }

      self.autocompletePhoneNumberPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-phone-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectphone/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          let bodyFormData ='';
          if(Number(self.state.clientdetail.staffid) > 0)
          {
            bodyFormData = {
              Mode:'GetLeadPhoneFilter',
              clientid:self.state.clientdetail.id,
              staffid:Number(self.state.clientdetail.staffid),
            }
          }
          else
          {
            bodyFormData = {
              Mode:'GetLeadPhoneFilter',
              clientid:self.state.clientdetail.id
            }  
          }

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/campaign.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    phonenumberlist:res.recordlist,
                  }
                },()=>{

                  const searchstatuslist  = self.state.phonenumberlist;

                  for (let i = 0; i < searchstatuslist.length; i += 1) {
                    if (searchstatuslist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchstatuslist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-phone-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
                phonenumber:name
            });

          },
        },
      });

      self.autocompleteStatePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-state-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectstate/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          let bodyFormData  = "";
          if(Number(self.state.clientdetail.staffid) > 0)
          {
            bodyFormData = {
              Mode:'GetLeadStateFilter',
              clientid:self.state.clientdetail.id,
              staffid:Number(self.state.clientdetail.staffid),
            }
          }
          else
          {
            bodyFormData = {
              Mode:'GetLeadStateFilter',
              clientid:self.state.clientdetail.id
            }  
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/campaign.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    statelist:res.recordlist,
                  }
                },()=>{

                  const searchstatelist  = self.state.statelist;

                  for (let i = 0; i < searchstatelist.length; i += 1) {
                    if (searchstatelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchstatelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-state-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
                statename:name
            });

          },
        },
      });

      self.autocompleteCityPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-city-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectcity/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          let bodyFormData ='';
          if(Number(self.state.clientdetail.staffid) > 0)
          {
            bodyFormData = {
              Mode:'GetLeadCityFilter',
              clientid:self.state.clientdetail.id,
              staffid:Number(self.state.clientdetail.staffid),
            }
          }
          else
          {
            bodyFormData = {
              Mode:'GetLeadCityFilter',
              clientid:self.state.clientdetail.id
            }  
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/campaign.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    citylist:res.recordlist,
                  }
                },()=>{

                  const searchcitylist  = self.state.citylist;

                  for (let i = 0; i < searchcitylist.length; i += 1) {
                    if (searchcitylist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchcitylist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-city-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
                city:name
            });

          },
        },
      });

      self.autocompletePincodePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-pincode-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectpincode/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          let bodyFormData ='';
          if(Number(self.state.clientdetail.staffid) > 0)
          {
            bodyFormData = {
              Mode:'GetLeadPincodeFilter',
              clientid:self.state.clientdetail.id,
              staffid:Number(self.state.clientdetail.staffid),
            }
          }
          else
          {
            bodyFormData = {
              Mode:'GetLeadPincodeFilter',
              clientid:self.state.clientdetail.id
            }  
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/campaign.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    pincodelist:res.recordlist,
                  }
                },()=>{

                  const searchpincodelist  = self.state.pincodelist;

                  for (let i = 0; i < searchpincodelist.length; i += 1) {
                    if (searchpincodelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchpincodelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-pincode-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
                pincode:name
            });

          },
        },
      });

      self.autocompleteStaffPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-staff-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectstaff/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/staff.php?t=${Date.now()}`,
            data: {
              Mode:'GetStaffList',
              clientid:self.state.clientdetail.id
            },
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    stafflist:res.recordlist,
                  }
                },()=>{

                  const searchstafflist  = self.state.stafflist;

                  for (let i = 0; i < searchstafflist.length; i += 1) {
                    if (searchstafflist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchstafflist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-staff-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
              filterstaffid:id,
              staff:name,
            });

          },
        },
      });

      self.autocompleteLeadCategoryPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-leadcategory-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectleadcategory/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          let bodyFormData ='';
          if(Number(self.state.clientdetail.staffid) > 0)
          {
            bodyFormData = {
              Mode:'GetLeadCategoryFilter',
              clientid:self.state.clientdetail.id,
              staffid:Number(self.state.clientdetail.staffid),
            }
          }
          else
          {
            bodyFormData = {
              Mode:'GetLeadCategoryFilter',
              clientid:self.state.clientdetail.id
            }  
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/leadcategory.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    leadcategorylist:res.recordlist,
                  }
                },()=>{

                  const searchleadcategorylist  = self.state.leadcategorylist;

                  for (let i = 0; i < searchleadcategorylist.length; i += 1) {
                    if (searchleadcategorylist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchleadcategorylist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-leadcategory-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
              leadcategory:name,
              leadcategoryid:id,
            });

          },
        },
      });
     
      const{interestedfilterlist} = this.state;

      self.autocompleteIntrestedPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-interested-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectinterested/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < interestedfilterlist.length; i += 1) {
            if (interestedfilterlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(interestedfilterlist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            $('#autocomplete-interested-popup').find('.item-after').text(value[0].name);
            
            self.setState({

              isinterested:value[0].id,
              isinterestedname:value[0].name
              
            });

          },
        },
      });

      self.autocompleteGroupPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-group-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectgroup/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/campaign.php?t=${Date.now()}`,
            data: {
              Mode:'GetGroup',
              clientid:self.state.clientdetail.id
            },
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    grouplist:res.recordlist,
                  }
                },()=>{

                  const searchgrouplist  = self.state.grouplist;

                  for (let i = 0; i < searchgrouplist.length; i += 1) {
                    if (searchgrouplist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchgrouplist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-group-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
              groupid:id,
              groupname:name,
            });

          },
        },
      });
    }

    getCampaignFilter(){

      const self      = this;
      const app       = self.$f7;

      let bodyFormData = {};

      if(Number(self.state.clientdetail.isstaff) > 0)
      {
        bodyFormData = {
            Mode:'GetCampaignFilter',
            clientid:self.state.clientdetail.id,
            staffid:self.state.clientdetail.staffid,
        };
      }
      else
      {
        bodyFormData = {
            Mode:'GetCampaignFilter',
            clientid:self.state.clientdetail.id,
        };
      }

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }

      const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          // Hide Pull To Refresh
          app.ptr.done();

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
              this.setState(()=>{
                  return{
                    campiganlist:resdata.recordlist,
                    isfilterloaded:true,
                  }
              });
          }
          else
          {
              this.setState(()=>{
                  return{
                    campiganlist:[],
                    isfilterloaded:true,
                  }
              });
          }

      }).catch(e => {
          console.log(e);
      });
    }

    getLeadStatusFilter(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetLeadStatus',
          clientid:self.state.clientdetail.id,
      };

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }

      const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          // Hide Pull To Refresh
          app.ptr.done();

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
              this.setState(()=>{
                  return{
                    leadstatuslist:resdata.recordlist,
                  }
              });
          }

      }).catch(e => {
          console.log(e);
      });
    }

    getLeadTagFilter(){

      const self      = this;
      const app       = self.$f7;

      let bodyFormData = {
          Mode:'GetLeadTag',
          clientid:self.state.clientdetail.id,
      };

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }

      const accesstoken  = localStorage.getItem('prachar_accesstoken');
      
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      setTimeout(()=>{
        app.request.promise.post(`${serverurl}api/leadtag.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
          // Hide Pull To Refresh
          app.ptr.done();
  
          const resdata = JSON.parse(res.data);
  
          if(resdata.success === true)
          {
            this.setState(()=>{
              return{
                leadtags:resdata.recordlist,
                istagrequestdone:true,
              }
            },()=>{
              this.onPageInit();
            });
          }
          else
          {
            this.setState(()=>{
              return{
                leadtags:[],
                istagrequestdone:true
              }
            },()=>{
              this.onPageInit();
            });
          }
  
        }).catch(e => {
            console.log(e);
        });
      },500)
    }

    render(){
      const {staffid, isstaff} = this.state.clientdetail;
      
      const{campid, campname, effect, phonenumber, isinterested, isreferred, campiganlist, leadstatuslist, selectedleadcampaign, selectedleadstatus, isintrestedname, isfilterloaded,filterstaffid,staff,leadcategoryid,leadcategory,statename,city, pincode, leadtags, defaulttags, istagrequestdone, groupid, groupname} = this.state;

      return(

        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <CampaignConsumer>{(value)=>{

                const{setCampaignLeadFilterData} = value;

                  return(
                    <Popup className="demo-popup">
                      <Navbar sliding={true}>
                          <NavTitle>Filter</NavTitle>
                          <NavRight>
                            <Link popupClose>Close</Link>
                          </NavRight>
                      </Navbar>
                        <Page className="page" name="LeadFilter">
                            <Block>
                            {
                              isfilterloaded && istagrequestdone ? (
                                <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>  
                                    <ListItem
                                        title="Choose Campaign"
                                        smartSelect
                                        smartSelectParams={{openIn:'popup',searchbar: true, searchbarPlaceholder: 'Search List',routableModals:true}}
                                        name='campaignids'
                                        id='campaignids'
                                        className="smartselectlist smartselectcampaign"
                                        after = 'Select'
                                        PlaceHolder='Select'
                                        >
                                        <select name="campaign" multiple onChange={this.handleUserInput} defaultValue={selectedleadcampaign}>
                                        {
                                          campiganlist.map((campigan)=>{
                                            return(
                                                    <option value={campigan.id} key={campigan.id}>{campigan.name}</option>
                                                );
                                            })
                                        }
                                        </select>
                                    </ListItem>
                                    <ListItem
                                        title="Choose Lead Status"
                                        smartSelect
                                        smartSelectParams={{openIn:'popup',searchbar: true, searchbarPlaceholder: 'Search List',routableModals:true}}
                                        name='statusids'
                                        id='statusids'
                                        className="smartselectlist smartselectstatus"
                                        after = 'Select'
                                        PlaceHolder='Select'
                                        >
                                        <select name="leadstatus" multiple onChange={this.handleUserInput} defaultValue={selectedleadstatus}>
                                        {
                                          leadstatuslist.map((status)=>{
                                            return(
                                                <option value={status.id} key={status.id}>{status.name}</option>
                                              );
                                            })
                                        }
                                        </select>
                                    </ListItem>
                                    <ListItem
                                        title="Choose Tag"
                                        smartSelect
                                        smartSelectParams={{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Search Tag'}}
                                        name='ListTags'
                                        id='ListTags'
                                        className="smartselectlist smartselecttags"
                                        after = 'Select'
                                        PlaceHolder='Select'
                                        >
                                        <select name="Tag" multiple onChange={this.handleUserInput} defaultValue={defaulttags}>
                                        {
                                            leadtags.map((tag)=>{
                                            return(
                                                    <option value={tag.id} key={tag.id}>{tag.name}</option>
                                                );
                                            })
                                        }
                                        </select>
                                    </ListItem>
                                    {isstaff ? null:(<ListItem link="#" id="autocomplete-staff-popup" title="Staff" after={staff}></ListItem>)}
                                    <ListItem link="#" id="autocomplete-leadcategory-popup" title="Lead Category" after={leadcategory}></ListItem>
                                    <ListItem link="#" id="autocomplete-state-popup" title="State" after={statename}></ListItem>
                                    <ListItem link="#" id="autocomplete-city-popup" title="City" after={city}></ListItem>
                                    <ListItem link="#" id="autocomplete-pincode-popup" title="Pincode" after={pincode}></ListItem>
                                    <ListItem link="#" id="autocomplete-phone-popup" title="Phone" after={phonenumber}></ListItem>
                                    <ListItem link="#" id="autocomplete-group-popup" title="Campaign Group" after={groupname}></ListItem>
                                    <ListItem link="#" id="autocomplete-interested-popup" title="Is Interested?" after={isintrestedname}></ListItem>
                                    <ListItem title="Is Referred ?">
                                      <Toggle slot="after" name="isreferred" checked={Number(this.state.isreferred) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                                    </ListItem>
                                </List>
                              ):(<Fragment>
                                  <List mediaList className={`skeleton-text skeleton-effect-${effect}`}>
                                    <ListItem
                                        title="Title Title Title Title Title Title Title Title Title"
                                    >
                                    </ListItem>
                                    <ListItem
                                        title="Title Title Title Title Title Title Title Title Title"
                                    >
                                    </ListItem>
                                    <ListItem
                                        title="Title Title Title Title Title Title Title Title Title"
                                    >
                                    </ListItem>                              
                                    <ListItem
                                        title="Title Title Title Title Title Title Title Title Title"
                                    >
                                    </ListItem>
                                    <ListItem
                                        title="Title Title Title Title Title Title Title Title Title"
                                    >
                                    </ListItem>
                                    <ListItem
                                        title="Title Title Title Title Title Title Title Title Title"
                                    >
                                    </ListItem>
                                    <ListItem
                                        title="Title Title Title Title Title Title Title Title Title"
                                    >
                                    </ListItem>
                                    <ListItem
                                        title="Title Title Title Title Title Title Title Title Title"
                                    >
                                    </ListItem>
                                    <ListItem
                                        title="Title Title Title Title Title Title Title Title Title"
                                    >
                                    </ListItem>
                                  </List>
                                </Fragment>
                              )
                            }
                            </Block>
                            <br />
                            <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                                
                                let selectedlist = this.$f7.smartSelect.get('.smartselectcampaign .smart-select').$selectEl.val();

                                const selectedcamp  = [];

                                selectedlist.forEach(campid => {
                                  const tempcamp = this.state.campiganlist.find(campigan => campigan.id === campid);

                                  if(selectedcamp.indexOf(tempcamp.name) === -1)
                                  {
                                    selectedcamp.push(tempcamp.name);
                                  }
                                });

                                const campids   = selectedlist.join(',');
                                const campnames = selectedcamp.join(', ');

                                let selectedstatus = this.$f7.smartSelect.get('.smartselectstatus .smart-select').$selectEl.val();

                                const selectedleadstatus  = [];

                                selectedstatus.forEach(statusid => {
                                  const templeadstatus = this.state.leadstatuslist.find(status => Number(status.id) === Number(statusid));

                                  if(selectedleadstatus.indexOf(templeadstatus.name) === -1)
                                  {
                                    selectedleadstatus.push(templeadstatus.name);
                                  }
                                });

                                let selectedtag = this.$f7.smartSelect.get('.smartselecttags .smart-select').$selectEl.val();

                                const selectedleadtagname  = [];

                                selectedtag.forEach(tagid => {
                                  const templeadtag = this.state.leadtags.find(tag => Number(tag.id) === Number(tagid));

                                  if(selectedleadtagname.indexOf(templeadtag.name) === -1)
                                  {
                                    selectedleadtagname.push(templeadtag.name);
                                  }
                                });

                                const statusids   = selectedstatus.join(',');
                                const statusnames = selectedleadstatus.join(', ');

                                const tagids   = selectedtag.join(',');
                                const tagnames = selectedleadtagname.join(', ');

                                if(internetstatus === 'online')
                                {
                                  const self      = this;
                                  const app       = self.$f7;
                                  if((campids === "" || campids === undefined) && (phonenumber == "" || phonenumber === undefined) && (isinterested === "" || isinterested === undefined) && (isreferred === "" || isreferred === undefined) && (statusids === "" || statusids === undefined) && (tagids === "" || tagids === undefined) && (leadcategory === "" || leadcategory === undefined) && (staff === "" || staff === undefined) && (statename === "" || statename === undefined) && (city === "" || city === undefined) && (pincode === "" || pincode === undefined))
                                  {
                                    const toastWithButton = app.toast.create({
                                        text: "Please select atleast one filter.",
                                        closeButton: true,
                                        closeTimeout: 3000,
                                    });
                                      
                                    toastWithButton.open();
                                  }
                                  else{
                                    Promise.all([

                                      setTimeout(()=> {

                                        let filterisinteresedtext = '-';
                                        let filterisinteresedabb = '-';

                                        if(isinterested === 2)
                                        {
                                          filterisinteresedtext ='No';
                                          filterisinteresedabb = 'N';
                                        }
                                        else if( isinterested === 1)
                                        {
                                          filterisinteresedtext ='Yes';
                                          filterisinteresedabb = 'Y';
                                        }
                                        else if( isinterested === -1)
                                        {
                                          filterisinteresedtext ='';
                                          filterisinteresedabb = '';
                                        }

                                        localStorage.setItem("selectedleadcampaign",JSON.stringify(selectedlist));
                                        localStorage.setItem("selectedleadcampaignname",JSON.stringify(selectedcamp));

                                        localStorage.setItem("selectedleadstatus",JSON.stringify(selectedstatus));
                                        localStorage.setItem("selectedleadstatusname",JSON.stringify(selectedleadstatus));

                                        localStorage.setItem("selectedleadtag",JSON.stringify(selectedtag));
                                        localStorage.setItem("selectedleadtagname",JSON.stringify(selectedleadtagname));

                                        let selectedleadcommonfilter = {phonenumber:phonenumber,isinterested:isinterested,isintrestedname:filterisinteresedtext,isreferred:Number(isreferred),leadcategoryid:leadcategoryid,leadcategory:leadcategory,filterstaffid:filterstaffid,staff:staff,statename:statename,city:city,pincode:pincode};

                                        localStorage.setItem("selectedleadcommonfilter",JSON.stringify(selectedleadcommonfilter));

                                        setCampaignLeadFilterData(campids, campnames, phonenumber, isinterested, Number(isreferred), filterisinteresedtext, filterisinteresedabb, statusids, statusnames, leadcategoryid, leadcategory, filterstaffid, staff, statename, city, pincode, tagids, tagnames, groupid, groupname);
                                        
                                      }, 500)

                                    ])
                                    .then(() => {

                                        this.$$('.popup-close').trigger('click');

                                    })
                                  }
                                };
                            }}>
                            <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                            </Fab>
                        </Page>
                    </Popup>
                  );
              }}
              </CampaignConsumer>            
          );
        }}
        </CommonConsumer>        

      );
    }
}