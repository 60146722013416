import React,{Component} from 'react';
import AppNavbar from './appnavbar';

import {
    Page,
    Block,
    List,
    ListInput,
    Row,
    Col,
    Button,
    Toolbar,
    Icon,
  } from 'framework7-react';

  export default class Dashboard extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          password: '',
          newpassword: '',
          confirmpassword:'',
          clientdetail:'',
          isloaded:false,
          internetstatus:navigator.onLine ? "online" : "offline",
        };

        this.handleUserInput = this.handleUserInput.bind(this);
        this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
        
    }

    componentDidMount(){
    
        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

        this.setState(()=>{
            return{
            clientdetail:clientdetail
            }
        },()=>{
            this.hideSplashScreen();
        });

	    this.updateOnlineStatus();

        window.addEventListener('online',  this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
        document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus() {

        if(this.state.isloaded  === true)
        {
            let intstatus	= "offline";

            intstatus		= navigator.onLine ? "online" : "offline";

            this.setState(()=>{
                return{
                    internetstatus: intstatus,
                };
            },()=>{

                const self      = this;
                const app       = self.$f7;

                if(this.state.internetstatus === "offline")
                {
                    const toastWithButton = app.toast.create({
                        text: "No internet connection",
                    });
                    
                    toastWithButton.open();
                }
                else
                {
                    const toastWithButton = app.toast.create({
                        text: "Internet connected",
                        closeButton: true,
                        closeTimeout: 3000,
                    });
                    
                    toastWithButton.open();
                }
            });
        }
        else
        {
            this.setState({
                isloaded:true
            });
        }
    }

    handleUserInput(e){
        const name  = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value});
    }

    changeDefaultPassword(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(this.state.internetstatus === "offline")
        {
             const toastWithButton = app.toast.create({
                 text: "No internet connection",
             });
               
             toastWithButton.open();
             
             return;
        }

        let errorstr    = "";

        app.input.validateInputs('.adddata');

        if(self.state.clientdetail.ispasswordupdate === 1)
        {
            if(self.state.password === '' || self.state.newpassword === '' || self.state.confirmpassword === '')
            {
                errorstr    = "Please enter all required field.";
            }
        }
        else
        {
            if(self.state.newpassword === '' || self.state.confirmpassword === '')
            {
                errorstr    = "Please enter all required field.";
            }
        }

        if(errorstr === "")
        {
            if(self.state.newpassword.length < 6)
            {
                errorstr    = "Password must have at least 6 characters.";
            }
            if((self.state.newpassword !== self.state.confirmpassword) && errorstr === "")
            {
                errorstr    = "Passwords do not match.";                
            }
        }

        if(errorstr !== "")
        {
            const toastWithButton = app.toast.create({
                text: errorstr,
                closeButton: true,
                closeTimeout: 3000,
            });
    
            toastWithButton.open();
            /*router.back();*/
            return false;
        }

        app.preloader.show();

        let bodyFormData = "";

        if(Number(self.state.clientdetail.isstaff) === 1)
        {
            bodyFormData = {
                Mode:'ChangeStaffPassword',
                password:self.state.password,
                newpassword:self.state.newpassword,
                confirmpassword:self.state.confirmpassword,
                recordid:Number(self.state.clientdetail.staffid)
            };
        }
        else
        {
            bodyFormData = {
                Mode:'ChangeDefaultPassword',
                password:self.state.password,
                newpassword:self.state.newpassword,
                confirmpassword:self.state.confirmpassword,
                recordid:self.state.clientdetail.id,
                ispasswordupdate:self.state.clientdetail.ispasswordupdate
            };
        }

        // Simulate Ajax Request
        setTimeout(()=> {
            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            let serverurl = "/";

            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

            const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${serverurl}api/changepassword.php?t=${Date.now()}`,bodyFormData)
            .then(function (res) {

                app.preloader.hide();

                const resdata = JSON.parse(res.data);

                const toastWithButton = app.toast.create({
                    text: resdata.msg,
                    closeButton: true,
                    closeTimeout: 3000,
                });

                toastWithButton.open();

                if(resdata.success === true)
                {
                    if(self.state.clientdetail.islineman !== 1)
                    {
                        localStorage.setItem("prachar_clientdetail",JSON.stringify(resdata.clientdetail));
                    }

                    router.navigate('/dashboard/');
                }

                }).catch(e => {
                console.log(e);
            });

        }, 1000);           

    }

    render(){
      return(
            <Page name="ChangePassword">
                <AppNavbar title="Change Password"/>
                <Block strong>
                    {
                        this.state.clientdetail.ispasswordupdate ?null:(<p><b>Note</b> : For security reasons, Please change your password. Password can not be same as existing password.</p>)
                    }
                    <List noHairlinesMd>
                    {
                        this.state.clientdetail.ispasswordupdate ?(
                        <ListInput
                            name="password"
                            className="adddata"
                            label="Existing Password"
                            floatingLabel
                            type="password"
                            onInput={this.handleUserInput}
                            placeholder="Existing Password"
                            clearButton required validate
                        >
                        </ListInput>
                        ):null
                    }
                        <ListInput
                            name="newpassword"
                            className="adddata"
                            label="New Password"
                            floatingLabel
                            type="password"
                            onInput={this.handleUserInput}
                            placeholder="New Password"
                            clearButton required validate
                        >
                        </ListInput>
                        <ListInput
                            name="confirmpassword"
                            className="adddata"
                            label="Confirm Password"
                            floatingLabel
                            type="password"
                            onInput={this.handleUserInput}
                            placeholder="Retype Password"
                            clearButton required validate
                        >
                        </ListInput>
                    </List>
                </Block>
                <Toolbar position="bottom">
                    <Row style={{width:'100%'}}>
                        <Col width="60"></Col>
                        <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                        onClick={()=>{
                        if(this.state.internetstatus === "online")
                        {
                            this.changeDefaultPassword();
                        }
                        }}
                        ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                    </Row>
                </Toolbar>
            </Page>
      );
    }
}