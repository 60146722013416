import React,{Component,Fragment} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import logo404 from '../assets/images/orlo404.png';

import {
    Page,
    PageContent,
    BlockTitle,
    Block,
    List,
    Link,
    Row,
    Col,
    ListItem,
    Fab,
    Icon,
  } from 'framework7-react';

  export default class SMSCreditPending extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;
      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let date = today.getFullYear()+'-'+currmonth+'-'+today.getDate();
     
      this.state = {
        smsloglist:[],
        clientdetail:'',
        /*subscriptiondate:date,*/
        isptr:false,
        isloaded:false,
        internetstatus:navigator.onLine ? "online" : "offline",
        ismount:false,
        isdatafetched:false,
        effect:'blink',
        hasdataavailable:false,
        totalavailable:0,
        totalpending:0,
        totalpurchase:0,
        totalused:0,
        totalbalance:0,
        logtype:1,
        deleterecordid:'',
      };

      this.handleUserInput    = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.getSMSCreditLog    = this.getSMSCreditLog.bind(this);
      this.deleteRecordAction = this.deleteRecordAction.bind(this);

    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getSMSCreditLog();
        this.hideSplashScreen();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus(){

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }
    
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getSMSCreditLog(){

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
            Mode:'GetPendingCreditLog',
            clientid:self.state.clientdetail.id,
        };

        this.setState(()=>{

          return{
            isdatafetched:false
          }

        },()=>{

          setTimeout(()=> {
  
            let serverurl = "/";
  
            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

            const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
    
            app.request.promise.post(`${serverurl}api/smscredit.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {
  
                // Hide Pull To Refresh
                app.ptr.done();
  
                const resdata = JSON.parse(res.data);
      
                if(resdata.success === true)
                {
                    this.setState(()=>{
                        return{
                            smsloglist:resdata.recordlist,
                            totalavailable:resdata.availablecredit,
                            totalused:resdata.totalused,
                            totalpending:resdata.requestedcredit,
                            totalpurchase:resdata.totalpurchase,
                            totalbalance:resdata.totalbalance,
                            isptr:false,
                            isdatafetched:true,
                            hasdataavailable:true
                        }
                    })
                }
                else
                {
                    this.setState(()=>{
                        return{
                            isptr:false,
                            isdatafetched:true,
                            hasdataavailable:false,
                            totalpurchase:0,
                            totalused:0,
                            totalbalance:0
                        }
                    });
                }
      
            }).catch(e => {
                console.log(e);
            });
  
          },500);

        });
    }

    deleteRecordAction(recordid){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                this.setState(()=>{
                  return{
                    deleterecordid:recordid
                  }
                },()=>{

                  this.deleteSingleRecord();

                })
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    deleteSingleRecord(){

      const self	= this;
      const app	= self.$f7;

        const bodyFormData = {
            Mode:'DeletePendingCreditRequest',
            clientid:this.state.clientdetail.id,
            recordid:this.state.deleterecordid,
        };

        app.preloader.show();

        // Simulate Ajax Request
        setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${serverurl}api/smscredit.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();

              const resdata = JSON.parse(res.data);

              const toastWithButton = app.toast.create({
                text: resdata.msg,
                closeButton: true,
                closeTimeout: 3000,
              });
    
              toastWithButton.open();

              if(resdata.success === true)
              {
                this.setState(
                  ()=>{
                    return {
                      deleterecordid:'',
                    }
                  },()=>{
                    this.getSMSCreditLog();
                  }
                );
                
              }
    
          }).catch(e => {
              console.log(e);
          });

        }, 500);	
    }    

    render(){
        
      const{smsloglist, isdatafetched, hasdataavailable, totalavailable, totalused, totalpending, totalpurchase, totalbalance,logtype} = this.state;

      return(
        <Page className="page" name="SMSCreditPending" pageContent={false}>
        <AppNavbar title="Pending SMS Credit" showrefresh={true} init={this.getSMSCreditLog}/>
        <PageContent>
          <CommonConsumer>{(value)=>{

            const{navigateToLink} = value;

            const{internetstatus} = value;

            return(
                    <Block>
                            {
                              isdatafetched ? (
                                <Fragment>
                                {
                                  hasdataavailable ? (
                                              <Fragment>
                                                <Row noGap className="listtype">
                                                    <Col width="20">
                                                        <p style={{marginLeft:'10px',marginTop:'1.2em',marginBottom:'1.2em'}}><b>Type</b></p>
                                                    </Col>
                                                    <Col width="40">
                                                        <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                            <ListItem radio value="1" name="logtype" checked={Number(logtype) === 1} title="SMS Credits"
                                                            onChange={this.handleUserInput}
                                                            ></ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col width="40">
                                                        <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                            <ListItem radio value="0" name="logtype" checked={Number(logtype) === 0} title="Lead Credits"
                                                            onChange={this.handleUserInput}
                                                            ></ListItem>
                                                        </List>
                                                    </Col>
                                                </Row>
                                                <Row noGap className='rowwithbottomborder'>
                                                    <Col width='30' className="tableheader">
                                                        Date
                                                    </Col>
                                                    <Col width='20' className="tableheader">
                                                        Item
                                                    </Col>
                                                    <Col width='20' className="tableheader">
                                                        Cost
                                                    </Col>
                                                    <Col width='15' className="tableheader">
                                                        Credit
                                                    </Col>
                                                    <Col width='15' className="tableheader">
                                                        Status
                                                    </Col>
                                                </Row>
                                                {
                                                smsloglist.map((smslog)=>{
                                                      
                                                      let statusclassname = 'redcolortxt';
                                                      if(Number(smslog.smsstatus) > 0)
                                                      {
                                                        statusclassname = 'greencolortxt';
                                                      }
                                                      return(<Fragment key={smslog.serial}>
                                                        {
                                                        (()=>{
                                                            if(Number(logtype) === Number(smslog.credittype))
                                                            {
                                                              return(
                                                                    <Row noGap className='rowwithbottomborder'>
                                                                    <Col width='30' className="ledgercolumn textalignleft">
                                                                        {smslog.createdon}
                                                                    </Col>
                                                                    <Col width='20' className="ledgercolumn">
                                                                        {smslog.packagename}
                                                                    </Col>
                                                                    <Col width='20' className="ledgercolumn">
                                                                        {smslog.packagecost}
                                                                    </Col>
                                                                    <Col width='15' className={`ledgercolumn`}>
                                                                        {smslog.smscredits}
                                                                    </Col>
                                                                    <Col width='15' className={`ledgercolumn ${statusclassname}`}>
                                                                        {smslog.statustxt}
                                                                        {smslog.paylink && Number(smslog.packagecost) ? (<Fragment><Link  className='greencolortxt' onClick={()=>{
                                                                          if(internetstatus === "online")
                                                                          {
                                                                            window.open(smslog.paylink,'_blank');
                                                                            this.$f7.views.main.router.navigate(`payments/${smslog.paymentid}/pending/`);
                                                                          }
                                                                        }}>(Retry)</Link></Fragment>):null}<Link  className='redcolortxt' onClick={()=>{
                                                                          if(internetstatus === "online")
                                                                          {
                                                                            this.deleteRecordAction(smslog.id);
                                                                          }
                                                                      }}>(Cancel)</Link>
                                                                    </Col>
                                                                </Row>
                                                          )
                                                        }
                                                      })()
                                                    }</Fragment>)
                                                }
                                                )}
                                    </Fragment>                                
                                  ):(
                                      <Block>
                                          <br />
                                          <Row>
                                          <Col>
                                              <img src={logo404} className="norecimg" />
                                              <BlockTitle className="errmsg">No record found.</BlockTitle>
                                          </Col>
                                          </Row>
                                      </Block>                                
                                  )
                                }
                                </Fragment>
                              ):(
                                <Fragment>
                                <List mediaList className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>                              
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>                              
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                </List>
                              </Fragment>
                              )
                            }
                        <br /><br /><br />
                    </Block>
            );

            }}
          </CommonConsumer>
        <br /><br />
        </PageContent>
         
        <Fab position="center-bottom" slot="fixed" text="Add Credits" onClick={()=>{
                if(this.state.internetstatus === "online")
                {
                  this.$f7router.navigate('/addsmscredit/');
                }
              }}>
                  <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
              </Fab>
        </Page>

      );
    }
}