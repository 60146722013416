import React,{Component} from 'react';
import { CommonConsumer } from '../context/CommonContext';
import { ContactConsumer } from '../context/ContactContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Link,
  } from 'framework7-react';

  export default class ContactFilter extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        listid:'',
        filterlist:'',
        listrecords:[],
        clientdetail:'',
        isloaded:false,
        internetstatus:navigator.onLine ? "online" : "offline",
        hassmartlist:false,
        isbulkcontact:"",
        bulkcontactname:"",
        datatypefilterlist:[{id:"1",name:"Bulk Added Contacts"},{id:"0",name:"Manually Added Contacts"}],
      };

      this.updateOnlineStatus        = this.updateOnlineStatus.bind(this);
      this.listAutocompletePopup     = this.listAutocompletePopup.bind(this);
      this.applyFilter               = this.applyFilter.bind(this);

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.listAutocompletePopup();
        this.onPageInit();
        
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    listAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }
      self.autocompleteStandalonePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-listrecords-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectlist/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          let bodyFormData = {};

          if(Number(self.state.clientdetail.isstaff) > 0)
          {
            bodyFormData = {
                Mode:'GetList',
                type:'contactfilter',
                Type2:'Assign',
                clientid:self.state.clientdetail.id,
                staffid:self.state.clientdetail.staffid,
            };
          }
          else
          {
            bodyFormData = {
                Mode:'GetList',
                type:'contactfilter',
                Type2:'Assign',
                clientid:self.state.clientdetail.id,
            };
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/list.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    listrecords:res.recordlist,
                  }
                },()=>{
                  
                  const searchlistrecords  = self.state.listrecords;

                  for (let i = 0; i < searchlistrecords.length; i += 1) {
                    if (searchlistrecords[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlistrecords[i]);
                  }
                  
                })
              }
              //results = [{id: "9", name: "again testing"}]
              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

           // $('#autocomplete-listrecords-popup').find('.item-after').text(value[0].name);
            self.Dom7('#autocomplete-listrecords-name').text(value[0].name);
            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              listid:id,
              filterlist:name
            });

          },
        },
      });
    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteDatatypePopup.destroy();

    }

    onPageInit(){
      
      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;

      const{datatypefilterlist} = this.state;

      self.autocompleteDatatypePopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-datatype-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectdatatype/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < datatypefilterlist.length; i += 1) {
            if (datatypefilterlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(datatypefilterlist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            $('#autocomplete-datatype-popup').find('.item-after').text(value[0].name);
            
            self.setState({

              isbulkcontact:value[0].id,
              bulkcontactname:value[0].name
              
            });

          },
        },
      });
    }

    applyFilter(){

      if(this.state.internetstatus === "offline")
      {
        const toastWithButton = this.$f7.toast.create({
          text: "No internet connection",
        });
    
        toastWithButton.open();
        
        return;
      }

      this.props.setNewFilterData(this.state.listid, this.state.filterlist);
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });

                this.hideSplashScreen();

                if(this.state.listrecords.length < 1)
                {
                  /*this.getList();*/
                }
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    getList(){

        const self      = this;
        const app       = self.$f7;
  
        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
  
        const bodyFormData = {
            Mode:'GetList',
            type:'contactfilter',
            clientid:clientdetail.id,
            areaid:self.state.areaid,
        };
  
        setTimeout(()=> {
  
          let serverurl = "/";
  
          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${serverurl}api/list.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {
  
              app.preloader.hide();
  
              app.ptr.done();            
  
              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({

                  listrecords:resdata.recordlist,
                  isptr:false

                },()=>{

                  if(this.state.hassmartline === false)
                  {
                    this.setState({hassmartline:true});
                    this.lineAutocompletePopup();
                  }

                });
              }
              else
              {
                this.setState({

                  listrecords:[],
                  isptr:false

                },()=>{

                  if(this.state.hassmartline === false)
                  {
                    this.setState({hassmartline:true});
                    this.lineAutocompletePopup();
                  }

                });
              }
    
          }).catch(e => {

              console.log(e);

          });
  
        }, 500);
  
    }

    render(){

      const{listid, filterlist, clientdetail, isbulkcontact, bulkcontactname} = this.state;

      return(

        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <ContactConsumer>{(value)=>{

                const{setNewFilterData} = value;

                  return(
                    <Popup className="demo-popup">
                        <Navbar sliding={true}>
                            <NavTitle>Filter</NavTitle>
                            <NavRight>
                              <Link popupClose>Close</Link>
                            </NavRight>
                        </Navbar>
                        <Page className="page" name="ContactFilter">
                          <Block>
                            <List noHairlinesMd>
                              <ListItem link="#" id="autocomplete-listrecords-popup" title="List">
                                <div slot="after" id="autocomplete-listrecords-name">Select</div>
                              </ListItem>
                              <ListItem link="#" id="autocomplete-datatype-popup" title="Data Type" after="Select"></ListItem>
                            </List>
                          </Block>
                          <br />
                          <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                              if(internetstatus === 'online')
                              {
                                Promise.all([
                                  setTimeout(()=> {
                    
                                    setNewFilterData(listid, filterlist, isbulkcontact, bulkcontactname);
                        
                                  }, 500)
                                  
                                ])
                                .then(() => {
                    
                                  setTimeout(()=> {
                    
                                    this.$$('.popup-close').trigger('click');
                        
                                  }, 500)
                                  
                                })

                              };
                            }}>
                            <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                          </Fab>
                        </Page>
                    </Popup>
                  );
              }}
              </ContactConsumer>            
          );
        }}
        </CommonConsumer>        

      );
    }
}