import React,{Component} from 'react';
import { CommonConsumer } from '../context/CommonContext';
import { CampaignConsumer } from '../context/CampaignContext';

import {
    Panel,
    View,
    Page,
    List,
    ListItem,
    Link,
    Icon,
    Button,
    Row,
    Col
  } from 'framework7-react';

  export default class AppMenu extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          clientdetail:[],
          iscreditloaded:false,
        };
    }

    componentDidMount(){

        localStorage.getItem('prachar_clientdetail') && JSON.parse(localStorage.getItem('prachar_clientdetail')).length > 0 && this.setState(()=>{
          return{
            clientdetail:JSON.parse(localStorage.getItem('prachar_clientdetail'))
          }
        });
    }

    appLogOut(useconfirm){

      const self      = this;
      const router    = self.$f7router;

      if(useconfirm)
      {
        this.$f7.actions.create({
          buttons: [
          // First group
            [
              {
                text: 'Are you sure? You want to logout',
                label: true
              },
              {
                text: 'Yes',
                bold: true,
                color:'blue',
                onClick:()=>{

                  localStorage.setItem("prachar_accesstoken",'');
                  localStorage.setItem("prachar_clientdetail",JSON.stringify([]));
                  localStorage.setItem("prachar_isadminlogin",false);
                  localStorage.setItem("prachar_adminid","");
                  localStorage.setItem("prachar_isadminverified",false);
                  localStorage.setItem("selectedleadstatus",JSON.stringify([]));
                  localStorage.setItem("selectedleadcommonfilter",JSON.stringify([]));
                  localStorage.setItem("selectedleadcampaignname",JSON.stringify([]));
                  localStorage.setItem("selectedleadcampaign",JSON.stringify([]));
                  localStorage.setItem("selectedleadstatusname",JSON.stringify([]));
                  localStorage.setItem("selectedleadtagname",JSON.stringify([]));
                  localStorage.setItem("selectedleadtag",JSON.stringify([]));

                  this.$f7.views.main.router.navigate('/login/');
                }
              }
            ],
            // Second group
            [
              {
                text: 'No',
                color: 'red'
              }
            ]
          ]
        }).open();
      }
      else
      {
        /*const toastWithButton = this.$f7.toast.create({
          text: 'Invalid access, we are logging off you',
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();*/

        localStorage.setItem("prachar_accesstoken",'');
        localStorage.setItem("prachar_clientdetail",JSON.stringify([]));
        localStorage.setItem("prachar_isadminlogin",false);
        localStorage.setItem("prachar_adminid","");
        localStorage.setItem("prachar_isadminverified",false);
        localStorage.setItem("selectedleadstatus",JSON.stringify([]));
        localStorage.setItem("selectedleadcommonfilter",JSON.stringify([]));
        localStorage.setItem("selectedleadcampaignname",JSON.stringify([]));
        localStorage.setItem("selectedleadcampaign",JSON.stringify([]));
        localStorage.setItem("selectedleadstatusname",JSON.stringify([]));
        localStorage.setItem("selectedleadtagname",JSON.stringify([]));
        localStorage.setItem("selectedleadtag",JSON.stringify([]));

        this.$f7.views.main.router.navigate('/login/');
      }
    }

    render(){

      const{iscreditloaded} = this.state;

        return(

          <CommonConsumer>{(value)=>{

              const{navigateToLink} = value;

              return(

                <CampaignConsumer>{(value)=>{

                  const{getSMSCredit} = value;

                  const{hascredit, credits, clientname, clientphone, clientdetail, fourcelogout, adminid} = value;

                  setTimeout(() => {
                    if(iscreditloaded === false)
                    {
                      this.setState(()=>{
                        return{
                          iscreditloaded:true,
                        }
                      },()=>{

                        getSMSCredit();
                        
                      })
                      
                    }
                  }, 100);

                  if(fourcelogout === true && (localStorage.getItem('prachar_accesstoken') === undefined || localStorage.getItem('prachar_accesstoken') === null))
                  {
                    this.appLogOut(false);
                  }

                  return(
                      <Panel left resizable>
                        <View>
                        {(()=>{
                            if(clientdetail !== undefined && clientdetail !== null)
                            {
                              if(Number(clientdetail.isstaff) === 1)
                              {
                                return(
                                  <Page>
                                    <div style={{textAlign:'center',backgroundColor:'#0D2348',color:'#ffffff',padding:'25px 0',position:'relative'}} onClick={()=>{this.$f7.panel.close('left',true);navigateToLink('changepassword');}}>
                                      <Icon f7="pencil" style={{position:'absolute',top:'5px',right:'5px'}}></Icon>
                                      <Icon f7="person_alt_circle_fill"></Icon>
                                      <br />
                                      {clientdetail.staffname}
                                      <br />
                                      {clientdetail.staffphone}
                                      <Link external href={`tel:${clientdetail.staffphone}`} style={{color:'#ffffff'}} target="_system">
                                      <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                      </Link>
                                    </div>
                                    {
                                      Number(clientdetail.cansmscredits) ? (<div style={{textAlign:'center',backgroundColor:'#D32D41',color:'#ffffff',padding:'10px 0',margin:'auto',fontSize:'18px'}}>
                                      <Icon f7="rectangle_stack_fill"></Icon>&nbsp;&nbsp;<span style={{verticalAlign:'middle'}}>{credits}</span>&nbsp;&nbsp;
                                      <Button outline round panelClose color="white" style={{width:'100px',display:'inline-block',height:'30px',lineHeight:'25px'}} onClick={()=>{navigateToLink('addsmscredit')}}>Recharge</Button>
                                    </div>):null  
                                    }
                                   
                                    <List style={{marginTop:'5px'}}>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('dashboard')}} title="Dashboard" ignoreCache={true} className="menulink"><Icon slot="media" f7="rectangle_split_3x3_fill" className="menu_dashboard"></Icon></ListItem>
                                      
                                      { Number(clientdetail.canlist) ?( <ListItem link="#" panelClose onClick={()=>{navigateToLink('lists')}} title="Lists" ignoreCache={true} className="menulink"><Icon slot="media" f7="list_bullet" className="menu_list"></Icon></ListItem>):null}

                                      { Number(clientdetail.cancontacts) ?(<ListItem link="#" panelClose onClick={()=>{navigateToLink('contactdashboard')}} title="Contacts" ignoreCache={true} className="menulink"><Icon slot="media" f7="person_2_fill" className="menu_contacts"></Icon></ListItem>):null}

                                      { Number(clientdetail.canpages) ?(<ListItem link="#" panelClose onClick={()=>{navigateToLink('pages')}} title="Pages" ignoreCache={true} className="menulink"><Icon slot="media" f7="doc_on_doc_fill" className="menu_pages"></Icon></ListItem>):null}

                                      { Number(clientdetail.canlist) ?( <ListItem link="#" panelClose onClick={()=>{navigateToLink('campaigngroup')}} title="Campaign Groups" ignoreCache={true} className="menulink"><Icon slot="media" f7="list_bullet" className="menu_list"></Icon></ListItem>):null}
                                      
                                      { Number(clientdetail.cancampaign) ?(<ListItem link="#" panelClose onClick={()=>{navigateToLink('campaigndashboard')}} title="Campaign" ignoreCache={true} className="menulink"><Icon slot="media" f7="envelope_badge_fill" className="menu_messages"></Icon></ListItem>):null}

                                      { Number(clientdetail.canbulkadd) ?(<ListItem link="#" panelClose onClick={()=>{navigateToLink('bulkaddrequest')}} title="Bulk Add" ignoreCache={true} className="menulink"><Icon slot="media" f7="person_2_square_stack_fill" className="menu_bulkadd"></Icon></ListItem>):null}
                                     
                                      { Number(clientdetail.canleads) ?(<ListItem link="#" panelClose onClick={()=>{navigateToLink('leaddashboard')}} title="Leads" ignoreCache={true} className="menulink"><Icon slot="media" f7="layers_alt_fill" className="menu_leads"></Icon></ListItem>):null}

                                      { Number(clientdetail.canleadcategory) ?(<ListItem link="#" panelClose onClick={()=>{navigateToLink('leadcategory')}} title="Lead Category" ignoreCache={true} className="menulink"><Icon slot="media" f7="list_bullet" className="menu_list"></Icon></ListItem>):null}

                                      { Number(clientdetail.canleadcategory) ?(<ListItem link="#" panelClose onClick={()=>{navigateToLink('leadtag')}} title="Lead Tag" ignoreCache={true} className="menulink"><Icon slot="media" f7="tag_circle_fill" className="menu_tags"></Icon></ListItem>):null}

                                      { Number(clientdetail.canstaff) ?(<ListItem link="#" panelClose onClick={()=>{navigateToLink('staff')}} title="Staff" ignoreCache={true} className="menulink"><Icon slot="media" f7="person_2_fill" className="menu_contacts"></Icon></ListItem>):null}

                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('settings')}} title="Settings" ignoreCache={true} className="menulink"><Icon slot="media" f7="wrench_fill" className="menu_pages"></Icon></ListItem>

                                      { Number(adminid) ?(<ListItem link="#" panelClose onClick={()=>{navigateToLink('changepassword')}} title="Change Password" ignoreCache={true} className="menulink"><Icon slot="media" f7="lock_circle" className="menu_changepassword"></Icon></ListItem>):null}
                                      
                                      <ListItem link="#" panelClose onClick={()=>{this.appLogOut(true)}} title="Logout" className="menulink"><Icon slot="media" f7="lock_rotation" className="menu_logout"></Icon></ListItem>
                                    </List>
                                  </Page>
                                );
                              }
                              else
                              {
                                return(
                                  <Page>
                                    <div style={{textAlign:'center',backgroundColor:'#0D2348',color:'#ffffff',padding:'25px 0',position:'relative'}} onClick={()=>{this.$f7.panel.close('left',true);navigateToLink('profile');}}>
                                      <Icon f7="pencil" style={{position:'absolute',top:'5px',right:'5px'}}></Icon>
                                      <Icon f7="person_alt_circle_fill"></Icon>
                                      <br />
                                      {clientdetail.clientname}
                                      <br />
                                      {clientdetail.clientphone}
                                      <Link external href={`tel:${clientdetail.clientphone}`} style={{color:'#ffffff'}} target="_system">
                                      <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                      </Link>
                                    </div>
                                    <div style={{textAlign:'center',backgroundColor:'#D32D41',color:'#ffffff',padding:'10px 0',margin:'auto',fontSize:'18px'}}>
                                      <Icon f7="rectangle_stack_fill"></Icon>&nbsp;&nbsp;<span style={{verticalAlign:'middle'}}>{credits}</span>&nbsp;&nbsp;
                                      <Button outline round panelClose color="white" style={{width:'100px',display:'inline-block',height:'30px',lineHeight:'25px'}} onClick={()=>{navigateToLink('addsmscredit')}}>Recharge</Button>
                                    </div>
                                    <List style={{marginTop:'5px'}}>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('dashboard')}} title="Dashboard" ignoreCache={true} className="menulink"><Icon slot="media" f7="rectangle_split_3x3_fill" className="menu_dashboard"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('smscredits')}} title="SMS Credit" ignoreCache={true} className="menulink"><Icon slot="media" f7="rectangle_stack_fill" className="menu_sms_credit"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('lists')}} title="Lists" ignoreCache={true} className="menulink"><Icon slot="media" f7="list_bullet" className="menu_list"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('contactdashboard')}} title="Contacts" ignoreCache={true} className="menulink"><Icon slot="media" f7="person_2_fill" className="menu_contacts"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('pages')}} title="Pages" ignoreCache={true} className="menulink"><Icon slot="media" f7="doc_on_doc_fill" className="menu_pages"></Icon></ListItem>                                      
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('campaigngroup')}} title="Campaign Groups" ignoreCache={true} className="menulink"><Icon slot="media" f7="list_bullet" className="menu_list"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('campaigndashboard')}} title="Campaign" ignoreCache={true} className="menulink"><Icon slot="media" f7="envelope_badge_fill" className="menu_messages"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('bulkaddrequest')}} title="Bulk Add" ignoreCache={true} className="menulink"><Icon slot="media" f7="person_2_square_stack_fill" className="menu_bulkadd"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('leaddashboard')}} title="Leads" ignoreCache={true} className="menulink"><Icon slot="media" f7="layers_alt_fill" className="menu_leads"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('leadcategory')}} title="Lead Category" ignoreCache={true} className="menulink"><Icon slot="media" f7="list_bullet" className="menu_list"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('leadtag')}} title="Lead Tag" ignoreCache={true} className="menulink"><Icon slot="media" f7="tag_circle_fill" className="menu_tags"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('staff')}} title="Staff" ignoreCache={true} className="menulink"><Icon slot="media" f7="person_2_fill" className="menu_contacts"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('profile')}} title="Manage Profile" ignoreCache={true} className="menulink"><Icon slot="media" f7="person_alt_circle_fill" className="menu_profile"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('changepassword')}} title="Change Password" ignoreCache={true} className="menulink"><Icon slot="media" f7="lock_circle" className="menu_changepassword"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('configuration')}} title="Configuration" ignoreCache={true} className="menulink"><Icon slot="media" f7="wrench_fill" className="menu_pages"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{navigateToLink('support')}} title="Support" ignoreCache={true} className="menulink"><Icon slot="media" f7="headphones" className="menu_sms_credit"></Icon></ListItem>
                                      <ListItem link="#" panelClose onClick={()=>{this.appLogOut(true)}} title="Logout" className="menulink"><Icon slot="media" f7="lock_rotation" className="menu_logout"></Icon></ListItem>
                                    </List>
                                  </Page>
                                );
                              }
                            }
                          })()}
                        </View>
                      </Panel>
                  );
                }}</CampaignConsumer>

              )
          }}
          </CommonConsumer>

        );
    }
}