import React,{Component} from 'react';
import AppNavbar from './appnavbar';

import {
    Page,
    Icon,
    PageContent,
    List,
    ListItem,
    Block,
    Row,
    Col,
    ListInput,
    Button,
    Toolbar,
  } from 'framework7-react';

  export default class Profile extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientname:'',
        contactname:'',
        contactemail:'',
        phone1:'',
        iswhatsapp1:0,
        phone2:'',
        pincode:'',
        stateid:'',
        cityid:'',
        address1:'',
        address2:'',
        profiledetail:[],
        statelist:[],
        citylist:[],
        clientdetail:'',
        isloaded:false,
        internetstatus:navigator.onLine ? "online" : "offline",
      };
      this.getProfileDetail = this.getProfileDetail.bind(this);
      this.handleUserInput = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.getProfileDetail();
        this.hideSplashScreen();

      })

      const app = this.$f7;
      const $$  = this.Dom7;
      const ptrContent = $$('.ptr-content');

      // Add 'refresh' listener on it
      ptrContent.on('ptr:refresh', (e)=> {
        // Emulate 2s loading

        setTimeout(()=> {
      
          this.getProfileDetail();
      
          app.ptr.done(); // or e.detail();
        }, 2000);

      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    handleUserInput(e){
      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        if(name === 'stateid')
        {
          this.getStateCity();
        }

      });

    }

    check_email(elementValue){
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(elementValue);
    }

    getProfileDetail(){

      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      const bodyFormData = {
          Mode:'GetProfileDetail',
          recordid:self.state.clientdetail.id,
      };

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }

      const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${serverurl}api/profile.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
            this.setState({
              clientname:resdata.profiledetail.clientname,
              contactname:resdata.profiledetail.contactname,
              contactemail:resdata.profiledetail.contactemail,
              phone1:resdata.profiledetail.phone1,
              phone2:resdata.profiledetail.phone2,
              pincode:resdata.profiledetail.pincode,
              stateid:resdata.profiledetail.stateid,
              cityid:resdata.profiledetail.cityid,
              address1:resdata.profiledetail.address1,
              address2:resdata.profiledetail.address2,
              profiledetail:resdata.profiledetail,
              statelist:resdata.statelist,
              citylist:resdata.citylist,
              iswhatsapp1:resdata.profiledetail.iswhatsapp1,
              isloaded:true,
            });
          }

      }).catch(e => {
          console.log(e);
      });
    }

    saveProfileDetail(){
      
      const self      = this;
      const app       = self.$f7;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      const toastWithButton = app.toast.create({
        text: 'Please enter all required field.',
        closeButton: true,
        closeTimeout: 3000,
      });

      app.input.validateInputs('.adddata');

      if(this.state.contactname === "" || this.state.contactemail === "" || this.state.phone1 === "" || this.state.pincode === "" || this.state.stateid === "" || this.state.cityid === "" || this.state.address1 === "")
      {
        toastWithButton.open();
        return false;
      }

      if(this.state.contactemail != "" && !this.check_email(this.state.contactemail))
      {
        const toastWithButton = app.toast.create({
          text: 'Please enter a valid email.',
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();

        return false;
      }

      const bodyFormData = {
          Mode:'SaveProfileDetail',
          recordid:self.state.clientdetail.id,
          clientname:self.state.clientname,
          contactname:self.state.contactname,
          contactemail:self.state.contactemail,
          phone1:self.state.phone1,
          phone2:self.state.phone2,
          pincode:self.state.pincode,
          stateid:self.state.stateid,
          cityid:self.state.cityid,
          address1:self.state.address1,
          address2:self.state.address2,
          iswhatsapp1:self.state.iswhatsapp1,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/profile.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
            const resdata = JSON.parse(res.data);

            const toastWithButton2 = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton2.open();

            if(resdata.success === true)
            {
              /*app.dialog.alert(resdata.msg);*/
              localStorage.setItem("prachar_clientdetail",JSON.stringify(resdata.clientdetail));
              localStorage.setItem("prachar_accesstoken",resdata.accesstoken);
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    getStateCity(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetStateCity',
          stateid:this.state.stateid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/stateandcitylist.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                citylist:resdata.citylist
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){
       
      return(
            <Page className="page" name="Profile" pageContent={false}>
                <AppNavbar title="Profile"/>
                <PageContent className="ptr-content">
                  <div className="ptr-preloader">
                    <div className="preloader"></div>
                    <div className="ptr-arrow"></div>
                  </div>
                  <Block>
                  <List noHairlinesMd>
                  <ListInput
                      name="clientname"
                      label="Company Name"
                      floatingLabel
                      type="text"
                      onInput={this.handleUserInput}
                      placeholder="Company Name"
                      value={this.state.clientname}
                      maxlength="200"
                      required validate
                  >
                  </ListInput>

                  <ListInput
                      name="contactname"
                      label="Contact Name"
                      floatingLabel
                      type="text"
                      onInput={this.handleUserInput}
                      placeholder="Contact Name"
                      value={this.state.contactname}
                      maxlength="200"
                      clearButton required validate
                  >
                  </ListInput>

                  <ListInput
                      name="contactemail"
                      label="Contact Email"
                      floatingLabel
                      type="email"
                      onInput={this.handleUserInput}
                      placeholder="Contact Email"
                      value={this.state.contactemail}
                      clearButton required validate
                  >
                  </ListInput>

                  <ListInput
                      name="phone1"
                      label="Phone Number"
                      floatingLabel
                      type="tel"
                      onInput={this.handleUserInput}
                      placeholder="Phone number"
                      value={this.state.phone1}
                      maxlength="200"
                      clearButton required validate pattern="[0-9]*"
                  >
                  </ListInput>

                  <ListItem checkbox title="Is Whatsapp Enabled ?" 
                  name="iswhatsapp1"
                  onChange={()=>{
                    this.setState({iswhatsapp1:!this.state.iswhatsapp1})
                  }} 
                  checked={Number(this.state.iswhatsapp1) === 1}
                  value="1"
                  >
                  </ListItem>

                  <ListInput
                      name="phone2"
                      label="Alternate Phone"
                      floatingLabel
                      type="tel"
                      onInput={this.handleUserInput}
                      placeholder="Alternate Phone"
                      value={this.state.phone2}
                      maxlength="200"
                      clearButton validate pattern="[0-9]*"
                  >
                  </ListInput>

                  <ListInput
                      name="pincode"
                      label="Pin Code"
                      floatingLabel
                      type="number"
                      onInput={this.handleUserInput}
                      placeholder="Pin Code"
                      value={this.state.pincode}
                      clearButton required validate
                  >
                  </ListInput>

                  <ListInput
                      name="stateid"
                      label="State"
                      type="select"
                      onChange={this.handleUserInput}
                      value={this.state.stateid}                            
                      placeholder="Please choose..."
                      >
                      <option value="">-Select-</option>
                      {
                          this.state.statelist.map((state)=>{
                          return(<option key={state.id} value={state.id}>{state.name}</option>);
                          })
                      }
                  </ListInput>

                  <ListInput
                      name="cityid"
                      label="City"
                      type="select"
                      onChange={this.handleUserInput}
                      value={this.state.cityid}
                      placeholder="Please choose..."
                      >
                      <option value="">-Select-</option>
                      {
                          this.state.citylist.map((city)=>{
                          return(<option key={city.id} value={city.id}>{city.name}</option>);
                          })
                      }
                  </ListInput>

                  <ListInput
                      name="address1"
                      label="Address Line 1"
                      floatingLabel
                      type="text"
                      onInput={this.handleUserInput}
                      placeholder="Address Line 1"
                      value={this.state.address1}
                      clearButton required validate
                  >
                  </ListInput>

                  <ListInput
                      name="address2"
                      label="Address Line 2"
                      floatingLabel
                      type="text"
                      onInput={this.handleUserInput}
                      placeholder="Address Line 2"
                      value={this.state.address2}
                      clearButton validate
                  >
                  </ListInput>
                  </List>
                  </Block>
                  <br /><br />
              </PageContent>
                <Toolbar position="bottom">
                    <Row style={{width:'100%'}}>
                        <Col width="60"></Col>
                        <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                        onClick={()=>{
                        if(this.state.internetstatus === "online")
                        {
                            this.saveProfileDetail();
                        }
                        }}
                        ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                    </Row>
                </Toolbar>
            </Page>
      );
    }
}