import React,{Component, Fragment} from 'react';
import { CommonConsumer } from '../context/CommonContext';
import whatsappicon  from '../assets/images/whatsapp-icon.png';
import { CampaignConsumer } from '../context/CampaignContext';

import {
    Page,
    Popup,
    Navbar,
    NavRight,
    NavTitle,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    ListInput,
    Link,
    Toggle,
    Row,
    Col,
    ListButton
  } from 'framework7-react';

  export default class LeadRemark extends Component {
    
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;
      let currdate = today.getDate();
      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      if(currdate < 10)
      {
        currdate = "0"+currdate;
      }
      let date = today.getFullYear()+'-'+currmonth+'-'+currdate;
      let currtimehours = today.getHours();   
      let currtimeminutes = today.getMinutes();   
      if(currtimehours < 10)
      {
        currtimehours = '0'+currtimehours;
      }
      if(currtimeminutes < 10)
      {
        currtimeminutes = '0'+currtimeminutes;
      }
      let tempscheduletime = currtimehours+':'+currtimeminutes;

      this.state = {

        clientdetail:[],
        leadstatuslist:[],
        leadcategorylist:[],
        recordid:'',
        remark:'',
        phonenumber:'',
        statusid:'',
        catid:'',
        isinterested:'',
        statusname:'Select',
        catname:'Select',
        issuccess:false,
        remark_org:'',
        statusid_org:'',
        catid_org:'',
        isinterested_org:'',
        name:'',
        pincode:'',
        cityid:'',
        stateid:'',
        statelist:[],
        citylist:[],
        scheduleddate:date,
        scheduledtime:tempscheduletime,
        defaulttags:[],
        leadtags:[],
        isrequestdone:false,
        effect:'blink',
      };

      this.handleUserInput  = this.handleUserInput.bind(this);
      this.getState         = this.getState.bind(this);
      this.fetchCityInfo    = this.fetchCityInfo.bind(this);
      this.getLeadTag       = this.getLeadTag.bind(this);
      this.sendToWhatapp    = this.sendToWhatapp.bind(this);

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      
      if(clientdetail !== undefined && clientdetail !== null)
      {
          this.setState(()=>{
            return{

              clientdetail:clientdetail,
              recordid:this.props.f7route.params.id

            }

          },()=>{
            this.getDetail();
          });
      }

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteLeadStatusPopup.destroy();
      self.autocompleteLeadCategoryPopup.destroy();

    }

    onPageInit(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }

      self.autocompleteLeadStatusPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-leadstatus-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectleadstatus/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/campaign.php?t=${Date.now()}`,
            data: {
              Mode:'GetLeadStatus',
              clientid:self.state.clientdetail.id,
            },
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    leadstatuslist:res.recordlist,
                  }
                },()=>{

                  const searchstatuslist  = self.state.leadstatuslist;

                  for (let i = 0; i < searchstatuslist.length; i += 1) {
                    if (searchstatuslist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchstatuslist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*$('#autocomplete-leadstatus-popup').find('.item-after').text(value[0].name);*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            self.setState({
                statusid:id,
                statusname:name
            });

          },
        },
      });

      self.autocompleteLeadCategoryPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-leadcategory-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectleadcategory/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/leadcategory.php?t=${Date.now()}`,
            data: {
              Mode:'GetLeadCategory',
              clientid:self.state.clientdetail.id,
            },
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    leadcategorylist:res.recordlist,
                  }
                },()=>{

                  const searchstatuslist  = self.state.leadcategorylist;

                  for (let i = 0; i < searchstatuslist.length; i += 1) {
                    if (searchstatuslist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchstatuslist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*$('#autocomplete-leadstatus-popup').find('.item-after').text(value[0].name);*/

            let id              = Number(value[0].id);
            let name            = value[0].name;
            let templateremark  = value[0].templateremark;

            self.setState({
                catid:id,
                catname:name,
                remark:templateremark
            });

          },
        },
      });
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        if(name === 'stateid')
        {
          this.getStateCity();
        }
        else
        {
          if(Number(this.state.isinterested) > 0)
          {
            this.onPageBeforeRemove();
            this.onPageInit();
          }
        }
      });
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(this.state.internetstatus === "offline")
        {
          const toastWithButton = app.toast.create({
              text: "No internet connection",
          });
            
          toastWithButton.open();
          
          return;
        }

        let bodyFormData  = "";

        if(Number(self.state.clientdetail.staffid) > 0)
        {
          bodyFormData = {
            Mode:'GetLeadRemark',
            clientid:self.state.clientdetail.id,
            recordid:self.state.recordid,
            staffid:self.state.clientdetail.staffid,
          };
        }
        else
        {
          bodyFormData = {
              Mode:'GetLeadRemark',
              clientid:self.state.clientdetail.id,
              recordid:self.state.recordid,
          };
        }

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
          serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
  
                remark:resdata.detail.remark,
                phonenumber:resdata.detail.phonenumber,
                statusid:resdata.detail.statusid,
                catid:resdata.detail.catid,
                isinterested:resdata.detail.isinterested,
                statusname:resdata.detail.statusname,
                catname:resdata.detail.catname,
                remark_org:resdata.detail.remark,
                statusid_org:resdata.detail.statusid,
                catid_org:resdata.detail.catid,
                isinterested_org:resdata.detail.isinterested,
                name:resdata.detail.name,
                stateid:resdata.detail.stateid,
                cityid:resdata.detail.cityid,
                pincode:resdata.detail.pincode,
                scheduleddate:resdata.detail.scheduleddate,
                scheduledtime:resdata.detail.scheduledtime,
                defaulttags:resdata.detail.defaulttags,

              },()=>{
                /*if(Number(this.state.isinterested) === 1)
                {
                  this.onPageBeforeRemove();
                  this.onPageInit();
                  this.getState();
                }*/ /*Commented By VK to run all functions for all status*/
                
                /*this.onPageBeforeRemove();*/
                this.getLeadTag();
              });
            }
            else
            {
              this.setState({
                remark:'',
                phonenumber:resdata.detail.phonenumber,
                statusname:'Select',
                catname:'Select',
                remark_org:'',
                statusid_org:'',
                catid_org:'',
                remark_org:'',
                name:'',
                stateid:'',
                cityid:'',
                pincode:'',
              },()=>{
                this.getState();
              });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    getState(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetState',
          stateid:this.state.stateid,
      };

      //app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        app.request.promise.post(`${serverurl}api/stateandcitylist.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                statelist:resdata.statelist
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    getStateCity(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetStateCity',
          stateid:this.state.stateid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/stateandcitylist.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                citylist:resdata.citylist
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    fetchCityInfo(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetStateByPinCode',
          pincode:this.state.pincode,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/stateandcitylist.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                stateid:resdata.state,
                cityid:resdata.city
              },()=>{

                this.getStateCity();

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    getLeadTag(){

      const self      = this;
      const app       = self.$f7;

      let bodyFormData = {
          Mode:'GetLeadTag',
          clientid:self.state.clientdetail.id,
      };

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }

      const accesstoken  = localStorage.getItem('prachar_accesstoken');
      
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      setTimeout(()=>{
        app.request.promise.post(`${serverurl}api/leadtag.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
          // Hide Pull To Refresh
          app.ptr.done();
  
          const resdata = JSON.parse(res.data);
  
          if(resdata.success === true)
          {
            this.setState(()=>{
              return{
                leadtags:resdata.recordlist,
                isrequestdone:true,
              }
            },()=>{
              this.getState();
              this.onPageInit();

              if(Number(this.state.stateid) > 0)
              {
                this.getStateCity();
              }
            });
          }
          else
          {
            this.setState(()=>{
              return{
                leadtags:[],
                isrequestdone:true
              }
            },()=>{
              this.getState();
              this.onPageInit();

              if(Number(this.state.stateid) > 0)
              {
                this.getStateCity();
              }
            });
          }
  
        }).catch(e => {
            console.log(e);
        });
      },500)
    }

    saveDetail(){
      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      let selectedtag = "";

      selectedtag     = app.smartSelect.get('.smart-select').$selectEl.val();

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });

           toastWithButton.open();
           
           return;
      }
      let errmsg    = "";
      
      app.input.validateInputs('.adddata');

      if((this.state.statusid === "" || this.state.statusid === undefined) && errmsg === "")
      {
        errmsg  = "Please select a lead status";
      }

      if(this.state.statusid === "" || this.state.statusid === undefined)
      {
          this.$$('.autocomplete-leadstatus-popup').addClass('error');
      }
      else
      {
          this.$$('.autocomplete-leadstatus-popup').removeClass('error');
      }

      let isremarkrequired  = true;

      if(Number(this.state.statusid) === 4 || Number(this.state.statusid) === 6)
      {
        isremarkrequired  = false;
      }

      if(isremarkrequired === true && this.state.remark === "" && errmsg === "")
      {
        errmsg = "Please enter remark";
      }

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

        let bodyFormData  = "";

        if(Number(self.state.clientdetail.staffid) > 0)
        {
          bodyFormData = {
              Mode:'SaveRemark',
              clientid:self.state.clientdetail.id,
              recordid:self.state.recordid,
              remark:self.state.remark,
              statusid:self.state.statusid,
              catid:self.state.catid,
              statusname:self.state.statusname,
              catname:self.state.catname,
              isinterested:Number(self.state.isinterested),
              staffid:self.state.clientdetail.staffid,
              phonenumber:self.state.phonenumber,
              name:self.state.name,
              pincode:self.state.pincode,
              stateid:self.state.stateid,
              cityid:self.state.cityid,
              scheduleddate:self.state.scheduleddate,
              scheduledtime:self.state.scheduledtime,
              selectedtags:selectedtag
          };
        }
        else
        {
          bodyFormData = {
              Mode:'SaveRemark',
              clientid:self.state.clientdetail.id,
              recordid:self.state.recordid,
              remark:self.state.remark,
              statusid:self.state.statusid,
              catid:self.state.catid,
              statusname:self.state.statusname,
              catname:self.state.catname,
              isinterested:Number(self.state.isinterested),
              phonenumber:self.state.phonenumber,
              name:self.state.name,
              pincode:self.state.pincode,
              stateid:self.state.stateid,
              cityid:self.state.cityid,
              scheduleddate:self.state.scheduleddate,
              scheduledtime:self.state.scheduledtime,
              selectedtags:selectedtag
          };
        }      

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();
  
            if(resdata.success === true)
            {
              this.setState({issuccess:true});
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    sendToWhatapp(){
      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });

           toastWithButton.open();
           
           return;
      }
      let errmsg    = "";
      
      app.input.validateInputs('.adddata');

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

        let bodyFormData  = "";

        bodyFormData = {
          Mode:'SendToWhatsApp',
          clientid:self.state.clientdetail.id,
          recordid:self.state.recordid,
          remark:self.state.remark,
          statusname:self.state.statusname,
          catname:self.state.catname,
          phonenumber:self.state.phonenumber,
          name:self.state.name,
          pincode:self.state.pincode,
          stateid:self.state.stateid,
          cityid:self.state.cityid
      };    

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();
  
            if(resdata.success === true)
            {
              window.open("http://api.whatsapp.com/send?text="+encodeURIComponent(resdata.txtmessage));
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){

      const {remark, phonenumber, issuccess, statusname, statusid, statusid_org, catname, catid, catid_org, remark_org, isinterested, isinterested_org, name, stateid, cityid, pincode, scheduleddate, scheduledtime, leadtags, isrequestdone, effect, defaulttags} = this.state;

      return(

        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <CampaignConsumer>{(value)=>{

                const{getAllLead} = value;

                if(issuccess)
                {
                  Promise.all([
                    setTimeout(()=> {

                      this.setState(()=>{

                        return{
                          issuccess:false
                        }
                        
                      },()=>{

                        getAllLead();

                      })

                    }, 500)
                    
                  ])
                  .then(() => {
      
                    setTimeout(()=> {

                      this.$f7.popup.close();
          
                    }, 1200)
                    
                  })
                }

                  return(
                    <Popup className="demo-popup">
                      <Navbar sliding={true}>
                          <NavTitle>Remark</NavTitle>
                          <NavRight>
                            <Link className="popup-close-custom" onClick={()=>{
                              if((remark !== remark_org) || (statusid !== statusid_org) || (catid !== catid_org) || (isinterested !== isinterested_org))
                              {
                                if(internetstatus === 'online')
                                {
                                  this.$f7.actions.create({
                                    buttons: [
                                    // First group
                                    [
                                      {
                                        text: 'Select an action to proceed',
                                        label: true
                                      },
                                      {
                                        text: 'Save',
                                        bold: true,
                                        color:'blue',
                                        onClick:()=>{
                                          this.saveDetail();
                                        }
                                      },
                                      {
                                        text: `Don't Save`,
                                        bold: true,
                                        onClick:()=>{
                                          this.$f7.popup.close();
                                        }
                                      }
                                    ],
                                    // Second group
                                    [
                                      {
                                        text: 'Cancel',
                                        color: 'red'
                                      }
                                    ]
                                    ]
                                  }).open();

                                };
                              }
                              else
                              {
                                this.$f7.popup.close();
                              }
                            }}>Close</Link>
                          </NavRight>
                      </Navbar>
                        <Page className="page" name="LeadRemark">
                        {
                          isrequestdone ? (
                            <Block>
                                <Row noGap className="listtype list" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <Col width="25">
                                        <div style={{marginLeft:'8px',marginTop:'1em',marginBottom:'1.2em'}} className="item-title">Interested?</div>
                                    </Col>
                                    <Col width="20">
                                        <List noHairlines className="logintype remarktype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                            <ListItem radio value="1" name="isinterested" checked={Number(isinterested) === 1} title="Yes"
                                            onChange={this.handleUserInput}
                                            ></ListItem>
                                        </List>
                                    </Col>
                                    <Col width="20">
                                        <List noHairlines className="logintype remarktype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                            <ListItem radio value="2" name="isinterested" checked={Number(isinterested) === 2} title="No"
                                            onChange={this.handleUserInput}
                                            ></ListItem>
                                        </List>
                                    </Col>
                                    <Col width="35">
                                        <List noHairlines className="logintype remarktype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                            <ListItem radio value="0" name="isinterested" checked={Number(isinterested) === 0} title="No Answer"
                                            onChange={this.handleUserInput}
                                            ></ListItem>
                                        </List>
                                    </Col>
                                </Row>

                                {(()=>{
                                  if(Number(isinterested) === 1)
                                  {
                                    return(
                                      <List noHairlines style={{marginTop:'5px',marginBottom:'5px'}}>
                                        <ListItem link="#" id="autocomplete-leadcategory-popup" className="autocomplete-leadcategory-popup" title="Category" after={catname}></ListItem>
                                      </List>
                                    );
                                  }
                                })()}

                                <Fragment>
                                  <List noHairlines style={{marginTop:'5px',marginBottom:'5px'}}>
                                    <ListItem
                                        title="Choose Tag"
                                        smartSelect
                                        smartSelectParams={{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Search Tag'}}
                                        name='ListTags'
                                        id='ListTags'
                                        className="smartselecttags"
                                        after = 'Select'
                                        PlaceHolder='Select'
                                        >
                                        <select name="Tag" multiple onChange={this.handleUserInput} defaultValue={defaulttags}>
                                        {
                                            leadtags.map((tag)=>{
                                            return(
                                                    <option value={tag.id} key={tag.id}>{tag.name}</option>
                                                );
                                            })
                                        }
                                        </select>
                                    </ListItem>
                                  </List>
                                </Fragment>

                                <List noHairlines style={{marginTop:'5px',marginBottom:'5px'}}>

                                  <ListInput
                                      name="name"
                                      className="adddata"
                                      label="Name"
                                      type="text"
                                      placeholder="Name"
                                      maxlength="200"
                                      value={name}
                                      onInput={this.handleUserInput}
                                      clearButton validate
                                  >
                                  </ListInput>

                                  <ListInput
                                      name="phonenumber"
                                      className="adddata"
                                      label="Phone Number"
                                      type="tel"
                                      placeholder="Phone Number"
                                      floatingLabel
                                      value={phonenumber}
                                      onInput={this.handleUserInput}
                                      clearButton required validate pattern="[0-9]*"
                                  >
                                  </ListInput>

                                  <ListInput
                                      name="pincode"
                                      label="Pin Code"
                                      floatingLabel
                                      type="tel"
                                      onInput={this.handleUserInput}
                                      placeholder="Pin Code"
                                      value={pincode}
                                      pattern="[0-9]{6}" maxlength={6}
                                      clearButton required validate
                                  >
                                  </ListInput>

                                </List>

                                <Block style={{marginTop:'5px',marginBottom:'5px'}}>

                                  <Row>
                                      <Col width="30">
                                          <Link onClick={()=>{
                                            this.fetchCityInfo();
                                          }}>
                                          Fetch City
                                          </Link>
                                      </Col>
                                      <Col width="40">
                                          <Link href="#" onClick={()=>{
                                            window.open(`https://pincode.net.in/${pincode}`,'_blank');
                                          }}>
                                          Pin Code Info
                                          </Link>
                                      </Col>
                                      <Col width="30">
                                      </Col>
                                  </Row>

                                </Block>

                                <List noHairlines style={{marginTop:'5px',marginBottom:'5px'}}>

                                    <ListInput
                                        name="stateid"
                                        label="State"
                                        type="select"
                                        onChange={this.handleUserInput}
                                        value={this.state.stateid}                            
                                        placeholder="Please choose..."
                                        >
                                        <option value="">-Select-</option>
                                        {
                                            this.state.statelist.map((state)=>{
                                            return(<option key={state.id} value={state.id}>{state.name}</option>);
                                            })
                                        }
                                    </ListInput>

                                    <ListInput
                                        name="cityid"
                                        label="City"
                                        type="select"
                                        onChange={this.handleUserInput}
                                        value={this.state.cityid}
                                        placeholder="Please choose..."
                                        >
                                        <option value="">-Select-</option>
                                        {
                                            this.state.citylist.map((city)=>{
                                            return(<option key={city.id} value={city.id}>{city.name}</option>);
                                            })
                                        }
                                    </ListInput>
                                </List>
                                <List noHairlines style={{marginTop:'5px',marginBottom:'5px'}}>
                                  <ListInput
                                      name="remark"
                                      inputId="messagearea"
                                      className="adddata resizable"
                                      label="Remark"
                                      type="textarea"
                                      resizable
                                      placeholder="Remark"
                                      onInput={this.handleUserInput}
                                      value={remark}
                                      multiple={true}
                                      clearButton required validate
                                  >
                                  </ListInput>
                                  <ListItem link="#" id="autocomplete-leadstatus-popup" className="autocomplete-leadstatus-popup" title="Status" after={statusname}></ListItem>
                                </List>
                                {(()=>{
                                  if(Number(statusid) === 7)
                                  {
                                    return(
                                      <List noHairlines style={{marginTop:'5px',marginBottom:'5px'}}>
                                          <ListInput
                                          name="scheduleddate"
                                          id="scheduleddate"
                                          label="Select Date"
                                          placeholder="Select Date"
                                          type="date"
                                          value={scheduleddate}
                                          onChange={this.handleUserInput}
                                          calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                                          >
                                          </ListInput>
                                          <Row noGap>
                                              <Col style={{marginLeft:'15px',marginRight:'15px'}}>
                                                <div className="item-title item-label">Time</div>
                                                <input type="time" id="scheduledtime" name="scheduledtime" min="09:00" max="18:00" onChange={this.handleUserInput} placeholder='select a time' value={scheduledtime} required style={{borderBottom:'1px solid #f1f1f1'}}/>
                                              </Col>
                                          </Row>
                                      </List>
                                    );
                                  }
                                })()}
                            </Block>
                          ):(
                              <List mediaList className={`skeleton-text skeleton-effect-${effect}`}>
                                  <ListItem
                                      title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                      title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                      title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>                              
                                  <ListItem
                                      title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                      title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                      title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                      title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                      title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                      title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                              </List>
                          )
                        }
                            <br /><br />
                                <Fab position="center-bottom" slot="fixed" text="Save Now" onClick={()=>{
                                    if(internetstatus === 'online')
                                    {
                                        this.saveDetail();
                                    };
                                }}>
                                <Icon f7="floppy_disk"></Icon>
                                </Fab>
                                <Fab position="right-bottom" slot="fixed" color="white" onClick={()=>{
                                    if(internetstatus === 'online')
                                    {
                                        this.sendToWhatapp();
                                    };
                                }}>
                                <img src={whatsappicon} alt='send to whatsapp' />
                                </Fab>
                             
                        </Page>
                    </Popup>
                  );
              }}
              </CampaignConsumer>            
          );
        }}
        </CommonConsumer>
      );
    }
}