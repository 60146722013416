import React,{Component, Fragment} from 'react';
import { CommonConsumer } from '../context/CommonContext';
import logo404 from '../assets/images/orlo404.png';

import {
    BlockTitle,
    Link,
    Block,
    List,
    Row,
    Col,
    ListItem,
    Chip,
    Button,
    Fab,
    Icon,
    Page,
  } from 'framework7-react';

  export default class SMSCreditTab extends Component {
    constructor(props) {
      super(props);

      this.state = {
        smsloglist:[],
        clientdetail:'',
        isptr:false,
        isloaded:false,
        internetstatus:navigator.onLine ? "online" : "offline",
        ismount:false,
        isdatafetched:false,
        effect:'blink',
        hasdataavailable:false,
        totalavailable:0,
        totalpending:0,
        totalpurchase:0,
        totalrefunds:0,
        totalused:0,
        totalbalance:0,
        hascredit:false,
        haspendingcredit:false,
      };

      this.handleUserInput    = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.getSMSCreditLog    = this.getSMSCreditLog.bind(this);

    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getSMSCreditLog();
        this.hideSplashScreen();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus(){

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getSMSCreditLog(){

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
            Mode:'GetSMSCreditLog',
            clientid:self.state.clientdetail.id,
        };

        this.setState(()=>{

          return{
            isdatafetched:false
          }

        },()=>{

          setTimeout(()=> {
  
            let serverurl = "/";
  
            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

            const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
    
            app.request.promise.post(`${serverurl}api/smscredit.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {
  
                // Hide Pull To Refresh
                app.ptr.done();
  
                const resdata = JSON.parse(res.data);
      
                if(resdata.success === true)
                {
                    this.setState(()=>{
                        return{
                            smsloglist:resdata.recordlist,
                            totalavailable:resdata.availablecredit,
                            totalused:resdata.totalused,
                            totalpending:resdata.totalpending,
                            totalpurchase:resdata.totalpurchase,
                            totalrefunds:resdata.totalrefunds,
                            totalbalance:resdata.totalbalance,
                            isptr:false,
                            isdatafetched:true,
                            hasdataavailable:true,
                            hascredit:resdata.hascredit,
                            haspendingcredit:resdata.haspendingcredit
                        }
                    })
                }
                else
                {
                    this.setState(()=>{
                        return{
                            isptr:false,
                            isdatafetched:true,
                            hasdataavailable:false,
                            totalpurchase:0,
                            totalrefunds:0,
                            totalused:0,
                            totalbalance:0
                        }
                    });
                }
      
            }).catch(e => {
                console.log(e);
            });
  
          },500);

        });
    }

    render(){

      const{smsloglist, isdatafetched, hasdataavailable, totalused, totalpending, totalpurchase,totalrefunds, totalbalance, hascredit, totalavailable} = this.state;

      return(
        <CommonConsumer>{(value)=>{
          
          const{navigateToLink, internetstatus} = value;
       
          return(
              <Page>
              {
                isdatafetched ? (
                  <Fragment>
                  {
                    hasdataavailable ? (
                        <Fragment>
                        {
                          hascredit ? (<Fragment>
                            <Block style={{textAlign:'center',fontWeight:'bold'}}>
                              <Chip text={`Total Purchase : ${totalpurchase}`} mediaBgColor="pink" media="P"/>
                              <br />
                              <Chip text={`Total Used : ${totalused}`} mediaBgColor="orange" media="U"/>
                              <br />
                              <Chip text={`Total Refund : ${totalrefunds}`} mediaBgColor="green" media="R"/>
                              <br />
                              <Chip text={`Total Balance : ${totalbalance}`} mediaBgColor="blue" media="B"/>
                              {
                                Number(totalpending) ? (<Fragment><br/>
                                  <Link onClick={()=>{navigateToLink('smscreditpending')}} ignoreCache={true}>
                                    <Chip text={`Pending Recharge: ${totalpending}`} mediaBgColor="red" media="P"/>
                                    &nbsp;<span style={{color:'#ff0000'}}>View</span>
                                  </Link></Fragment>
                                ):null
                              }
                              {
                                totalavailable ? null:(<Fragment>
                                  <br/><br/>
                                  <p style={{textAlign:'center',color:'#ff0000',fontSize:'1.2rem'}}>You don't have credit in your account</p>
                                  <br/>
                                  <Row>
                                      <Col width="20"></Col>
                                      <Col width="60"><Button fill color="blue" onClick={()=>{
                                        if(internetstatus === "online")
                                        {
                                          navigateToLink('addsmscredit');
                                        }
                                      }}>Recharge Now</Button></Col>
                                      <Col width="20"></Col>
                                  </Row>
                                  <br/>
                                </Fragment>)
                              }
                            </Block>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='20' className="tableheader">Date</Col>
                              <Col width='20' className="tableheader">Item</Col>
                              <Col width='15' className="tableheader">Pkg.</Col>
                              <Col width='15' className="tableheader">Cr.</Col>
                              <Col width='15' className="tableheader">Dr.</Col>
                              <Col width='15' className="tableheader">Bal.</Col>
                            </Row>
                            {
                              smsloglist.map((smslog)=>{

                                let statusclassname = 'redcolortxt';
                                if(Number(smslog.smsstatus) > 0)
                                {
                                  statusclassname = 'greencolortxt';
                                }
                                return(
                                  <Row key={smslog.serial} noGap className='rowwithbottomborder'>
                                    <Col width='20' className="ledgercolumn textalignleft">{smslog.date}<br/>{smslog.time}</Col>
                                    <Col width='20' className={`ledgercolumn ${statusclassname}`}>{smslog.item}</Col>
                                    <Col width='15' className={`ledgercolumn`}>{smslog.packagetype}</Col>
                                    <Col width='15' className={`ledgercolumn`}>{smslog.smscredit}</Col>
                                    <Col width='15' className={`ledgercolumn`}>{smslog.smsdebit}</Col>
                                    <Col width='15' className={`ledgercolumn`}>{smslog.smsbalance}</Col>
                                  </Row>
                                );
                              })
                            }
                          </Fragment>):(<Fragment>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='30' className="tableheader">Date</Col>
                              <Col width='20' className="tableheader">Item</Col>
                              <Col width='20' className="tableheader">Cost</Col>
                              <Col width='15' className="tableheader">Credit</Col>
                              <Col width='15' className="tableheader">Status</Col>
                            </Row>
                            {
                              smsloglist.map((smslog)=>{
                                  
                                  let statusclassname = 'redcolortxt';
                                  if(Number(smslog.smsstatus) > 0)
                                  {
                                    statusclassname = 'greencolortxt';
                                  }

                                  return(
                                    <Row key={smslog.serial} noGap className='rowwithbottomborder'>
                                        <Col width='30' className="ledgercolumn textalignleft">{smslog.date}<br/>{smslog.time}</Col>
                                        <Col width='20' className="ledgercolumn">{smslog.packagename}</Col>
                                        <Col width='20' className="ledgercolumn">{smslog.packagecost}</Col>
                                        <Col width='15' className={`ledgercolumn`}>{smslog.smscredits}</Col>
                                        <Col width='15' className={`ledgercolumn ${statusclassname}`}>{smslog.statustxt}</Col>
                                    </Row>
                                  );

                                })
                            }
                          </Fragment>)
                        }
                        </Fragment>
                    ):(
                        <Block>
                          <br/><br/>
                          <p style={{textAlign:'center',color:'#ff0000',fontSize:'1.2rem'}}>You don't have credit in your account</p>
                          <br/>
                          <Row>
                              <Col width="20"></Col>
                              <Col width="60"><Button fill color="blue" onClick={()=>{
                                if(internetstatus === "online")
                                {
                                  navigateToLink('addsmscredit');
                                }
                              }}>Recharge Now</Button></Col>
                              <Col width="20"></Col>
                          </Row>
                          <br />
                          <Row>
                            <Col>
                                <img src={logo404} className="norecimg" />
                                <BlockTitle className="errmsg">No record found.</BlockTitle>
                            </Col>
                          </Row>
                        </Block>                                
                      )
                  }
                  </Fragment>
                ):(
                  <Fragment>
                  <List mediaList className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>                              
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>                              
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                  </List>
                </Fragment>
                )
              }
              {
                totalavailable ? (
                  <Fab position="center-bottom" slot="fixed" text="Recharge" onClick={()=>{
                      if(internetstatus === "online")
                      {
                        navigateToLink('addsmscredit');
                      }
                    }}>
                    <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
                  </Fab>
                ):null
              }
              <br /><br /><br />
              </Page>
          );

        }}</CommonConsumer>

      )
    }
}