import React,{Component} from 'react';

const ListContext = React.createContext();
//Provider
//Consumer

class ListProvider extends Component{

	constructor(props){
		super(props);

        this.state = {
            isavailable:false,
            loaded:false,
            clientdetail:'',
            contactlist:[],
            actionGridOpened: false,
            oneGroupOpened: false,
            deleterecordid:'',
            hascontact:'',
            contactcount:'',
            isptr:false,
            isloaded:false,
            effect:'blink',
            internetstatus:navigator.onLine ? "online" : "offline",
          };

          this.init               = this.init.bind(this);
          this.getAllList         = this.getAllList.bind(this);
          this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
          this.deleteSingleRecord = this.deleteSingleRecord.bind(this);
          this.deleteFilterAction = this.deleteFilterAction.bind(this);
          this.deleteRecordAction = this.deleteRecordAction.bind(this);
          this.manageListAction   = this.manageListAction.bind(this);
	}

  componentDidMount(){

      setTimeout(()=> {

        this.Dom7('.waiting').addClass('animate-bottom');

      }, 50);

      this.hideSplashScreen();
    }

    hideSplashScreen(){

      document.getElementById("splash-screen").style.visibility = "hidden";
      
    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        this.Paging('select');

      });

    }

    Paging(mode){

      let pageerror = "";

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = this.$f7.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      let temppage    = '';

      const currpage  = this.state.page;

      if(mode === 'previous')
      {
        temppage  = Number(this.state.page) - 1;
      }
      if(mode === 'next')
      {
        temppage  = Number(this.state.page) + 1;
      }
      if(mode === 'select')
      {
        temppage  = Number(this.state.page);
      }

      if(temppage < 1)
      {
        pageerror = "You are on first page";
      }

      if(temppage > Number(this.state.totalpages))
      {
        pageerror = "You are on last page";
      }

      if(pageerror !== "")
      {
           const toastWithButton = this.$f7.toast.create({
               text: pageerror,
               closeButton: true,
               closeTimeout: 3000,
           });
             
           toastWithButton.open();
           
           return;
      }

      if(temppage <= 0)
      {
        temppage  = 1;
      }

      if(temppage >= Number(this.state.totalpages))
      {
        temppage  = Number(this.state.totalpages);
      }

      this.setState(()=>{

        return{
          page:temppage,
          loaded:false
        }

      },()=>{

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          if(mode === 'select')
          {
            this.getAllList();
          }
          else
          {
            if(Number(this.state.page) !== Number(currpage))
            {
              this.getAllList();
            }
          }

        })

      })

    }

    getAllList(){

        const self      = this;
        const app       = self.$f7;

        if(this.state.internetstatus === "offline")
        {
             const toastWithButton = app.toast.create({
                 text: "No internet connection",
             });
               
             toastWithButton.open();
             
             return;
        }

        let bodyFormData = "";

        if(Number(this.state.clientdetail.isstaff) > 0)
        {
          bodyFormData = {
              Mode:'GetAllList',
              clientid:this.state.clientdetail.id,
              staffid:this.state.clientdetail.staffid,
          };
        }
        else
        {
          bodyFormData = {
              Mode:'GetAllList',
              clientid:this.state.clientdetail.id,
          };
        }

        /*if(this.state.isptr !== true)
        {
          app.preloader.show();
        }*/

        this.setState(()=>{
          return{
            loaded:false
          }
        },()=>{

          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');
  
          }, 50);

          // Simulate Ajax Request
          setTimeout(()=> {

            let serverurl = "/";

            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

            const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${serverurl}api/list.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request
        
                // Hide Preloader
                app.preloader.hide();
                
                // Hide Pull To Refresh
                app.ptr.done();
      
                const resdata = JSON.parse(res.data);

                if(resdata.success === true)
                {
                  this.setState({
                    loaded:true,
                    isavailable:true,
                    contactlist:resdata.recordlist,
                    isptr:false,
                  });
                }
                else{
                  this.setState({
                    loaded:true,
                    isavailable:false,
                    isptr:false,
                  });
                }
      
            }).catch(e => {
                console.log(e);
            });
    
          }, 500);

        })
    }

    deleteSingleRecord(){

      const self	= this;
      const app	= self.$f7;

        const bodyFormData = {
            Mode:'DeleteList',
            clientid:this.state.clientdetail.id,
            recordid:this.state.deleterecordid,
        };

        app.preloader.show();

        // Simulate Ajax Request
        setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${serverurl}api/list.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();

              const resdata = JSON.parse(res.data);

              const toastWithButton = app.toast.create({
                text: resdata.msg,
                closeButton: true,
                closeTimeout: 3000,
              });
    
              toastWithButton.open();

              if(resdata.success === true)
              {
                this.setState(
                  ()=>{
                    return {
                      deleterecordid:'',
                    }
                  },()=>{
                    this.getAllList();
                  }
                );
              }
    
          }).catch(e => {
              console.log(e);
          });

        }, 500);	
    }

    deleteRecordAction(recordid, hascontact, contactcount){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

	    app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                  this.setState(()=>{
                      return{
                        deleterecordid:recordid,
                        hascontact:hascontact,
                        contactcount:contactcount
                      }
                  },()=>{
                    if(this.state.hascontact)
                    {
                        app.dialog.confirm(`Are you sure, you want to delete? There are ${this.state.contactcount} contact(s) in list.`,'Delete List Confirmation',() => {
    
                        this.deleteSingleRecord();
    
                        },()=>{
    
                          this.setState({
                              deleterecordid:'',
                              hascontact:false,
                              contactcount:''
                          })
    
                        });
                    }
                    else
                    {
                        this.deleteSingleRecord();
                    }
                  })
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    deleteFilterAction(filtername){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                if(filtername === "status")
                {
                  this.setState(()=>{
                    return{
                      statusid:'',
                      status:'',
                      hasfilter:true,
                      hasallrecord:true,
                      isavailable:false,
                      loaded:false,
                    }
                  },()=>{
  
                    this.getAllList();
  
                  })
                }
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    manageListAction(recordid, name){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Select an action to proceed',
            label: true
          },
          {
            text: 'Edit',
            bold: true,
            color:'blue',
            onClick:()=>{

                if(this.state.internetstatus === "online")
                {
                  this.$f7.views.main.router.navigate(`/editlist/${recordid}/`);
                }
            }
          },
          {
            text: 'View Contacts',
            bold: true,
            color:'blue',
            onClick:()=>{

                if(this.state.internetstatus === "online")
                {
                  this.$f7.views.main.router.navigate(`/managecontacts/list/${recordid}/${name}/`);
                }
            }
          },
          {
            text: 'Add New Contact',
            bold: true,
            color:'blue',
            onClick:()=>{

                if(this.state.internetstatus === "online")
                {
                  this.$f7.views.main.router.navigate(`/addcontact/${recordid}/${name}/`);
                }
            }
          }
        ],
        // Second group
        [
          {
            text: 'Cancel',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    init(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      
      if(clientdetail !== undefined && clientdetail !== null)
      {
          this.setState(()=>{
            return{
  
              clientdetail:clientdetail,
  
            }
          },()=>{
  
            this.getAllList();
  
          });
      }
    }

    setNewFilterData(statusid, status){

      if(this.state.internetstatus === "offline")
      {
        const toastWithButton = this.$f7.toast.create({
          text: "No internet connection",
        });
    
        toastWithButton.open();
        
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            statusid:statusid,
            status:status,
            loaded:false,
            isavailable:false,
            applyfilter:false,
            page:1,
          }
  
        },()=>{
  
          if(Number(this.state.statusid) > 0)
          {
            this.setState({hasfilter:true,hasallrecord:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallrecord:true});
          }
  
          this.getAllList();
  
        });
      })
    }

    openFilterPopup(){

      if(this.state.internetstatus === "online")
      {
        this.$f7.views.main.router.navigate('filterpopup/');
      }
    }

	render(){
		return (
			<ListContext.Provider value={{
			  ...this.state,
				init:this.init,
				getAllList:this.getAllList,
				setNewFilterData:this.setNewFilterData,
				deleteRecordAction:this.deleteRecordAction,
				deleteFilterAction:this.deleteFilterAction,
				handleUserInput:this.handleUserInput,
				Paging:this.Paging,
				openFilterPopup:this.openFilterPopup,
				deleteSingleRecord:this.deleteSingleRecord,
				manageListAction:this.manageListAction,
			}}
			>
			{this.props.children}
			</ListContext.Provider>
		);
	}
}

const ListConsumer = ListContext.Consumer;

export {ListProvider, ListConsumer, ListContext};