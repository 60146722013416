import React,{Component,Fragment} from 'react';
import AppNavbar from './appnavbar';
import { CustomerConsumer } from '../context/CustomerContext';

import {
    Page,
    Toolbar,
    Link,
    Block,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Button,
    Icon,
    Input,
    Toggle,
  } from 'framework7-react';

  export default class BulkAdd extends Component {
    constructor(props) {
      super(props);

      this.state = {
        listrecords:[],
        defaultlist:[],
        clientdetail:'',
        isloaded:false,
        type:true,
        phonelist:'',
        hasphonelist:false,
        totaltextboxphone:'',
        ismount:false,
        isrequestdone:true,
        recordinfo:[],
        internetstatus:navigator.onLine ? "online" : "offline",
        effect:'blink',
        fromposition:'',
        toposition:'',
        partialupload:0
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.updateOnlineStatus   = this.updateOnlineStatus.bind(this);
      this.addNewList           = this.addNewList.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

            this.setState({ismount:true});
  
          }, 1200);

          this.hideSplashScreen();
          this.getMessageingDetail();

      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus(){

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    addNewList(listid, listname){

        const templistrecordid  = [...this.state.listrecords];

        const tempSingleList        = templistrecordid.find(list => Number(list.id) === Number(listid));

        if(!tempSingleList)
        {
            const newlistdetail = {id:listid, name:listname};

            const templistrecordid2    = [...templistrecordid, newlistdetail];
    
            this.setState(()=>{
              
              return{
                defaultlist: [...this.state.defaultlist, listid],
              }
    
            },()=>{
                setTimeout(() => {
                    this.getMessageingDetail();
                }, 500);
            });
        }
    }
    
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      
      if(name === 'isscheduled')
      {
        this.setState({isscheduled:!this.state.isscheduled});
      }
      else if(name === 'partialupload')
      {
        this.setState({partialupload:!this.state.partialupload});
      }
      else
      {
        let totalnumber = this.state.totaltextboxphone;
        let hasphone    = this.state.hasphonelist;

        if(name === 'phonelist')
        {
            let lines = value.split(/\r\n|\r|\n/);

            let filtered = lines.filter(value => Object.keys(value).length !== 0);

            if(filtered.length > 0)
            {
                totalnumber = filtered.length;
                hasphone    = true;
            }
        }
        else if(name === 'type')
        {
            if(value === 1)
            {
                totalnumber = '';
                hasphone    = false;                
            }
        }
        
        this.setState(()=>{
            return{
                [name]: value,
                totaltextboxphone: totalnumber,
                hasphonelist: hasphone,
            }
        },()=>{
         });
     }
    }

    getMessageingDetail(){

        const self      = this;
        const app       = self.$f7;

        let bodyFormData = {};

        if(Number(self.state.clientdetail.isstaff) > 0)
        {
          bodyFormData = {
            Mode:'GetList',
            Type:'Assign',
            clientid:self.state.clientdetail.id,
            staffid:self.state.clientdetail.staffid,
          };
        }
        else
        {
            bodyFormData = {
                Mode:'GetList',
                Type:'Assign',
                clientid:self.state.clientdetail.id,
            };
        }
    
          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${serverurl}api/list.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
              console.log(resdata)
              if(resdata.success === true)
              {
                  this.setState(()=>{
                      return{
                          listrecords:resdata.recordlist,
                          //isrequestdone:true,
                      }
                  });
              }
              else
              {
                  this.setState(()=>{
                      return{
                          //isrequestdone:false
                      }
                  });
              }
    
          }).catch(e => {
              console.log(e);
          });
    }

    saveDetail(){

        const self    = this;
        const app     = self.$f7;
        const $$      = self.Dom7;
        const router  = self.$f7router;

        let errmsg    = "";

        if(this.state.internetstatus === "offline")
        {
            const toastWithButton = app.toast.create({
                text: "No internet connection",
            });
                
            toastWithButton.open();
            
            return;
        }

        let selectedlist = app.smartSelect.get('.smart-select').$selectEl.val();

        let haslist = false;
        
        let filedate    = "";

        if(Number(this.state.type) === 1)
        {
            filedate = document.getElementById('csvfile').files[0];
        }

        if(Number(selectedlist.length) > 0)
        {
            haslist  = true;
            this.$$('.smartselectlist').removeClass('error');
        }
        else
        {
            this.$$('.smartselectlist').addClass('error');
        }

        if((haslist !== true) && errmsg === "")
        {
            errmsg  = "Please select atleast one list to add contact list.";
        }

        if(((filedate === "" || filedate === undefined) && Number(this.state.type) === 1) && errmsg === "")
        {
            errmsg  = "You must upload a file with .txt or .csv extension.";
        }

        if((this.state.phonelist === "" && Number(this.state.type) === 0) && errmsg === "")
        {
            errmsg  = "You must enter atleast one phone number.";
        }

        if(Number(this.state.partialupload) === 1 && errmsg === "")
        {
            if(this.state.fromposition === "" || this.state.toposition === "")
            {
                errmsg  = "From and To position can not be blank.";
            }
        }
        
        app.input.validateInputs('.adddata');

        if(errmsg !== "")
        {
            const toastWithButton = app.toast.create({
                text: errmsg,
                closeButton: true,
                closeTimeout: 3000,
            });

            toastWithButton.open();
    
            return false;
        }

        app.preloader.show();

        // Simulate Ajax Request
        setTimeout(()=> {

            let serverurl = "/";

            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

            const formData = new FormData();

            formData.append('Mode','BulkAddContact');
            formData.append('contactlistfile',filedate);
            formData.append('clientid',Number(self.state.clientdetail.id));
            formData.append('staffid',Number(self.state.clientdetail.staffid));
            formData.append('selectedlist',selectedlist);
            formData.append('type',Number(self.state.type));
            formData.append('ispartialupload',Number(self.state.partialupload));
            formData.append('fromposition',Number(self.state.fromposition));
            formData.append('toposition',Number(self.state.toposition));
            formData.append('phonelist',self.state.phonelist);

            if(Number(self.state.clientdetail.isstaff) > 0)
            {
                formData.append('staffid',Number(self.state.clientdetail.staffid));
            }
            else
            {
                formData.append('staffid',0);
            }

            const accesstoken  = localStorage.getItem('prachar_accesstoken');

            app.request({
                url: `${serverurl}api/bulkadd.php?t=${Date.now()}`,
                method: 'POST', 
                data: formData,
                crossDomain: true,
                cache: false, 
                dataType: 'application/json',
                contentType: 'multipart/form-data',
                processData: true,
                headers: {
                    Authorization: `${accesstoken}`
                },
                success: (data)=>{

                  const resdata = JSON.parse(data);

                  app.preloader.hide();
                  
                  if(resdata.success == true)
                  {
                  
                    if(Number(this.state.type) === 1)
                    {
                        document.getElementById('csvfile').value='';
                    }
                        Promise.all([
                            this.setState(()=>{
                                return{
                                    phonelist:'',
                                }
                            })
                        ])
                        .then(() => {
            
                            $$('.item-content').removeClass('item-input-with-error-message');
                            $$('.item-content').removeClass('item-input-invalid');
            
                        })
                        if(Number(this.state.type) === 1)
                        {
                            this.$f7.views.main.router.navigate(`bulkthanksqueue/bulkqueuesuccess/`);
                        }
                        else
                        {
                            this.$f7.views.main.router.navigate(`bulkthanks/bulkaddsuccess/${resdata.recordinfo.addedcontact}/${resdata.recordinfo.oldcontact}/${resdata.recordinfo.totalcontact}/`);
                        }
                    }
                    else
                    {
                        const toastWithButton2 = app.toast.create({
                            text: resdata.msg,
                            closeButton: true,
                            closeTimeout: 3000,
                        });
                        toastWithButton2.open();
                    } 
                }
            });

        }, 200);
    }

    downloadLog(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'DownloadBulkSample',
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${serverurl}api/bulkadd.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);
              if(resdata.success === true)
              {
                  window.open(resdata.reportfilepath);
              }
              else
              {
                  const toastWithButton = app.toast.create({
                      text: resdata.msg,
                      closeButton: true,
                      closeTimeout: 3000,
                  });
                    
                  toastWithButton.open();    
              }
          }).catch(e => {
              console.log(e);
          });

      },500);
    }

    render(){
        
        const{listrecords, type, isrequestdone, phonelist, hasphonelist, totaltextboxphone, ismount, defaultlist, effect, fromposition, toposition, partialupload} = this.state;

        return(
            <CustomerConsumer>{(value)=>{

                const{setListData}  = value;
                const{singlelist}   = value;

                if(ismount === true)
                {                    
                    if(singlelist.isnewlist === true && Number(singlelist.id) > 0)
                    {
                        setTimeout(() => {
                            this.addNewList(''+singlelist.id+'', singlelist.name);
                            setListData('','',false);

                        }, 1200);
                    }
                }

                return(
                    <Page className="page" name="BulkAdd">
                        <AppNavbar title="Bulk Add"/>
                        <Block>
                        {
                            isrequestdone ? (<Fragment><br /><Row noGap>
                                    <Col width='85'>
                                        <List noHairlines className="zeromargin">
                                            <ListItem
                                                title="Choose List(s)"
                                                smartSelect
                                                smartSelectParams={{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Search List'}}
                                                name='ListIds'
                                                id='ListIds'
                                                className="smartselectlist"
                                                after = 'Select'
                                                PlaceHolder='Select'
                                                >
                                                <select name="List" multiple onChange={this.handleUserInput}
                                                defaultValue={defaultlist}>
                                                {
                                                    listrecords.map((list)=>{
                                                    return( 
                                                            <option value={list.id} key={list.id}>{list.name}</option>
                                                        );
                                                    })
                                                }
                                                </select>
                                            </ListItem>
                                        </List>
                                    </Col>
                                    <Col width="15" style={{marginTop:'8px',marginRight:'0px'}}>
                                        <Link onClick={()=>{
                                            if(this.state.internetstatus === "online")
                                            {
                                                this.$f7router.navigate('addlistpopup/')
                                            }
                                        }}>
                                            <Icon ios="f7:plus" aurora="f7:plus" md="material:add_circle" style={{fontSize:'30px'}}></Icon>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row noGap className="listtype">
                                    <Col width="30">
                                        <p style={{marginLeft:'10px',marginTop:'1.2em',marginBottom:'1.2em'}}><b>Entry Type</b></p>
                                    </Col>
                                    <Col width="40">
                                        <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                            <ListItem radio value="1" name="type" checked={Number(type) === 1} title="CSV Upload"
                                            onChange={this.handleUserInput}
                                            ></ListItem>
                                        </List>
                                    </Col>
                                    <Col width="30">
                                        <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                            <ListItem radio value="0" name="type" checked={Number(type) === 0} title="Manual"
                                            onChange={this.handleUserInput}
                                            ></ListItem>
                                        </List>
                                    </Col>
                                </Row>
                                {
                                    Number(type) ? (<Fragment>
                                        <br />
                                        <List noHairlines style={{marginTop:'5px'}}>
                                            <Input type="file" name="csvfile" inputId="csvfile" />
                                            <br />
                                            <div style={{color:'#ff0000'}}>Required Headers : "Mobile", "Name"</div>
                                            <div style={{color:'#ff0000'}}>Note : Name is an optional field</div>
                                            <div style={{color:'#ff0000',textDecoration:'underline',marginTop:'10px',textAlign:'center'}} onClick={()=>{this.downloadLog()}}>Download Sample</div>
                                            <ListItem title="Partial Upload">
                                                <Toggle slot="after" name="partialupload" checked={Number(partialupload) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                                            </ListItem>
                                        </List>
                                        {
                                            partialupload ? (
                                                <Row noGap>
                                                    <Col width="50">
                                                        <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                            <ListInput
                                                                name="fromposition"
                                                                className="adddata resizable"
                                                                label="From Position"
                                                                type="number"
                                                                resizable
                                                                placeholder="From Position"
                                                                onInput={this.handleUserInput}
                                                                value={fromposition}
                                                                multiple={true}
                                                                clearButton required validate
                                                            >
                                                            </ListInput>
                                                        </List>
                                                    </Col>
                                                    <Col width="50">
                                                        <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                            <ListInput
                                                                name="toposition"
                                                                className="adddata resizable"
                                                                label="To Position"
                                                                type="number"
                                                                resizable
                                                                placeholder="To Position"
                                                                onInput={this.handleUserInput}
                                                                value={toposition}
                                                                multiple={true}
                                                                clearButton required validate
                                                            >
                                                            </ListInput>
                                                        </List>
                                                    </Col>
                                                </Row>
                                            ):null
                                        }
                                    </Fragment>):(<Fragment>
                                        <br />
                                        <List noHairlines style={{marginTop:'5px'}}>
                                            <ListInput
                                                name="phonelist"
                                                className="adddata resizable"
                                                label="Phone List"
                                                type="textarea"
                                                resizable
                                                placeholder="Phone List"
                                                onInput={this.handleUserInput}
                                                value={phonelist}
                                                multiple={true}
                                                clearButton required validate
                                            >
                                            </ListInput>
                                        </List>
                                        {
                                            hasphonelist ? (
                                                <p style={{marginLeft:'15px',marginTop:'0'}}><i style={{color:'#ff0000'}}>{totaltextboxphone} Number(s)</i></p>
                                            ):null
                                        }
                                        <p style={{marginLeft:'15px',marginTop:'5px'}}><i style={{color:'#ff0000'}}>Example : 2222222222</i><br />
                                        <i><b>One Phone Number per line</b></i>
                                        </p>
                                    </Fragment>)
                                }
                            </Fragment>):(
                            <List mediaList className={`skeleton-text skeleton-effect-${effect}`}>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>                              
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                            </List>
                            )
                        }
                        <br /><br />
                        </Block>
                        {
                            isrequestdone ? (
                                <Toolbar position="bottom">
                                    <Row style={{width:'100%'}}>
                                        <Col width="60"></Col>
                                        <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                                        onClick={()=>{
                                            if(this.state.internetstatus === "online")
                                            {
                                            this.saveDetail();
                                            }
                                        }}
                                        ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                                    </Row>
                                </Toolbar>
                            ):null
                        }
                    </Page>
                );
        }}</CustomerConsumer>
      );
    }
}