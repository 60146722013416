import React,{Component} from 'react';

const CommonContext = React.createContext();
//Provider
//Consumer

class CommonProvider extends Component{
	constructor(props){
		super(props);

		this.state = {
		  isloaded:false,
		  internetstatus:navigator.onLine ? "online" : "offline",
      singlecampaigngroup:[]
		};

		this.updateOnlineStatus	= this.updateOnlineStatus.bind(this);
    this.navigateToLink		= this.navigateToLink.bind(this);
    this.setCampaignGroupData       = this.setCampaignGroupData.bind(this);
	}

	componentDidMount(){

		this.hideSplashScreen();
		this.updateOnlineStatus();
		window.addEventListener('online',  this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);

	}

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus(){

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self	= this;
            const app	= self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });

                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });

                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

	navigateToLink(link){

		if(this.state.internetstatus === "online")
		{
			this.$f7.views.main.router.navigate(`/${link}/`);
		}
  }
  
  setCampaignGroupData(id, name, isnewlist){

    let dataset	= [...this.state.singlecampaigngroup];

    dataset.id			= id;
    dataset.name		= name;
    dataset.isnewlist	= isnewlist;

    this.setState({

      singlecampaigngroup:dataset

    });

  }  

	render(){
		return (
			<CommonContext.Provider value={{
			...this.state,
				navigateToLink:this.navigateToLink,
				setCampaignGroupData:this.setCampaignGroupData
			}}
			>
			{this.props.children}
			</CommonContext.Provider>
		);
	}
}

const CommonConsumer = CommonContext.Consumer;

export {CommonProvider, CommonConsumer, CommonContext};