import React,{Component,Fragment} from 'react';
import scanlogo from '../assets/images/scanlogo.png';
import {
  Page,
  LoginScreenTitle,
  ListInput,
  BlockFooter,
  List,
  ListButton,
  Button,
  Row,
  Col
} from 'framework7-react';

export default class Signup extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        phone: '',
        internetstatus:navigator.onLine ? "online" : "offline",
        issuccess:false,
      };

      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.signUp             = this.signUp.bind(this);
      this.handleUserInput    = this.handleUserInput.bind(this);
    }

    componentDidMount(){

        this.hideSplashScreen();

        this.updateOnlineStatus();
        
        window.addEventListener('online',  this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);

    }
    
	updateOnlineStatus() {

        if(this.state.isloaded  === true)
        {
            let intstatus	= "offline";

            intstatus		= navigator.onLine ? "online" : "offline";
    
            this.setState(()=>{
                return{
                    internetstatus: intstatus,
                };
            },()=>{
    
                const self      = this;
                const app       = self.$f7;
    
                if(this.state.internetstatus === "offline")
                {
                    const toastWithButton = app.toast.create({
                        text: "No internet connection",
                    });
                      
                    toastWithButton.open();
                }
                else
                {
                    const toastWithButton = app.toast.create({
                        text: "Internet connected",
                        closeButton: true,
                        closeTimeout: 3000,
                    });
                      
                    toastWithButton.open();
                }
            });
        }
        else
        {
            this.setState({
                isloaded:true
            });
        }
	}

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

        this.setState(()=>{
            return{
                [name]: value
            }
        });
    }

    signUp(){
        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(this.state.internetstatus === "offline")
        {
             const toastWithButton = app.toast.create({
                 text: "No internet connection",
             });
               
             toastWithButton.open();
             
             return;
        }

        let errmsg  = "";

        if(self.state.phone === '')
        {
            errmsg  = "Phone can not be blank";
        }

        if(errmsg !== "")
        {
            const toastWithButton = app.toast.create({
                text: errmsg,
                closeButton: true,
                closeTimeout: 3000,
            });
              
            toastWithButton.open();

            app.input.validateInputs('.logindata');
        }
        else
        {
            app.preloader.show();

           const bodyFormData = {
              Mode:'CheckRegistration',
              phone:self.state.phone,
           };

            // Simulate Ajax Request
            setTimeout(()=> {
                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                let serverurl = "/";

                if(process.env.NODE_ENV === "development")
                {
                    serverurl = "http://orloprachar/app/";
                }

                app.request.promise.post(`${serverurl}api/register.php?t=${Date.now()}`,bodyFormData)
                .then((res)=> {

                    const resdata = JSON.parse(res.data);

                    if(resdata.success === true)
                    {
                        localStorage.setItem("prachar_reguserid",resdata.recordid);
                        router.navigate('verifyregistration/');
                    }
                    else
                    {
                        const toastWithButton = app.toast.create({
                            text: resdata.msg,
                            closeButton: true,
                            closeTimeout: 3000,
                        });
                          
                        toastWithButton.open();            
                    }

                }).catch(e => {
                    console.log(e);
                });                

            }, 1000);

        }
    }
  
    render() {
        
        const{issuccess, resdata} = this.state;
               
        return (
            <Page noToolbar noNavbar noSwipeback loginScreen>
                <Row>
                    <Col>
                        <img className="loginlogo" src={scanlogo} alt="logo" />
                    </Col>
                </Row>
                <LoginScreenTitle>Prachar Sign Up</LoginScreenTitle>
                <Row noGap>
                    <Col width="10"></Col>
                    <Col width="80">
                    </Col>
                    <Col width="10"></Col>
                </Row>
                <Row noGap>
                    <Col width="10"></Col>
                    <Col width="80">
                    <List form style={{marginBottom:'5px',marginTop:'5px'}}>
                    <ListInput
                    className="logindata"
                    label="Phone"
                    floatingLabel
                    type="tel"
                    placeholder="Phone"
                    value={this.state.phone}
                    onInput={(e) => {
                        this.setState({ phone: e.target.value});
                    }}
                    clearButton required validate pattern="[0-9]*"
                    />
                    </List>
                    </Col>
                    <Col width="10"></Col>
                </Row>
                <Row>
                    <Col width="20"></Col>
                    <Col width="60"><Button fill color="blue" onClick={()=>{
                        this.signUp();
                    }}>Register</Button></Col>
                    <Col width="20"></Col>
                </Row>
                <br />
                <BlockFooter>&copy; 2020 OrloPay.com</BlockFooter>
            </Page>
        )
    }
}