import React,{Component,Fragment} from 'react';
import { CommonConsumer } from '../context/CommonContext';

import {
    Link,
    Icon,
    Row,
    Col,    
  } from 'framework7-react';

  export default class Agentdashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    componentDidMount(){

    }
    
    render(){

        const{clientdetail} = this.props;
        const isstaff = clientdetail.isstaff;
        return(
            <CommonConsumer>{(value)=>{

                const{navigateToLink} = value;

                return(
                        <Row>
                        {
                           <Fragment>
                                {Number(clientdetail.cansmscredits) ?
                                 (<Col>
                                    <Link className="dashboardlink" onClick={()=>{navigateToLink('smscredits')}} ignoreCache={true}>
                                        <div className="elevation-demo elevation-3 dashboardlink elevation-hover-5 elevation-pressed-5">
                                            <Icon f7="rectangle_stack_fill" className="dashboard_sms_credit"></Icon>
                                            <div>SMS Credit</div>
                                        </div>
                                    </Link>
                                </Col>):null
                                }
                                {Number(clientdetail.canlist) ?
                                 (<Col>
                                    <Link className="dashboardlink" onClick={()=>{navigateToLink('lists')}} ignoreCache={true}>
                                        <div className="elevation-demo elevation-3 dashboardlink elevation-hover-5 elevation-pressed-5">
                                            <Icon f7="list_bullet" className="dashboard_list"></Icon>
                                            <div>Lists</div>
                                        </div>
                                    </Link>
                                </Col>):null
                                }
                                {Number(clientdetail.cancontacts) ?
                                 (
                                <Col>
                                    <Link className="dashboardlink" onClick={()=>{navigateToLink('contactdashboard')}} ignoreCache={true}>
                                        <div className="elevation-demo elevation-3 dashboardlink elevation-hover-5 elevation-pressed-5">
                                            <Icon f7="person_2_fill" className="dashboard_contacts"></Icon>
                                            <div>Contacts</div>
                                        </div>
                                    </Link>
                                </Col>):null
                                }
                                {Number(clientdetail.canpages) ?
                                 (
                                <Col>
                                    <Link className="dashboardlink" onClick={()=>{navigateToLink('pages')}} ignoreCache={true}>
                                        <div className="elevation-demo elevation-3 dashboardlink elevation-hover-5 elevation-pressed-5">
                                            <Icon f7="doc_on_doc_fill" className="dashboard_pages"></Icon>
                                            <div>Pages</div>
                                        </div>
                                    </Link>
                                </Col>
                                ):null
                            }
                            {Number(clientdetail.cancampaign) ?
                             (
                                <Col>
                                    <Link className="dashboardlink" onClick={()=>{navigateToLink('campaigndashboard')}} ignoreCache={true}>
                                        <div className="elevation-demo elevation-3 dashboardlink elevation-hover-5 elevation-pressed-5">
                                            <Icon f7="envelope_badge_fill" className="dashboard_messages"></Icon>
                                            <div>Campaign</div>
                                        </div>
                                    </Link>
                                </Col>):null
                                }
                                {Number(clientdetail.canbulkadd) ?
                                 (
                                <Col>
                                    <Link className="dashboardlink" onClick={()=>{navigateToLink('bulkadd')}} ignoreCache={true}>
                                        <div className="elevation-demo elevation-3 dashboardlink elevation-hover-5 elevation-pressed-5">
                                            <Icon f7="person_2_square_stack_fill" className="dashboard_invoices"></Icon>
                                            <div>Bulk Add</div>
                                        </div>
                                    </Link>
                                </Col>
                                ):null
                            }
                            {Number(clientdetail.canleads) ?
                             (
                                <Col>
                                    <Link className="dashboardlink" onClick={()=>{navigateToLink('leads')}} ignoreCache={true}>
                                        <div className="elevation-demo elevation-3 dashboardlink elevation-hover-5 elevation-pressed-5">
                                            <Icon f7="layers_alt_fill" className="dashboard_leads"></Icon>
                                            <div>Leads</div>
                                        </div>
                                    </Link>
                                </Col>
                                ):null
                            }
                            {Number(clientdetail.canleadcategory) ?
                             (
                                <Col>
                                    <Link className="dashboardlink" onClick={()=>{navigateToLink('leadcategory')}} ignoreCache={true}>
                                        <div className="elevation-demo elevation-3 dashboardlink elevation-hover-5 elevation-pressed-5">
                                            <Icon f7="list_bullet" className="dashboard_list"></Icon>
                                            <div>Lead Category</div>
                                        </div>
                                    </Link>
                                </Col>
                                ):null
                            }
                            {Number(clientdetail.canstaff) ?
                             (
                                <Col>
                                    <Link className="dashboardlink" onClick={()=>{navigateToLink('staff')}} ignoreCache={true}>
                                        <div className="elevation-demo elevation-3 dashboardlink elevation-hover-5 elevation-pressed-5">
                                            <Icon f7="person_2_fill" className="dashboard_contacts"></Icon>
                                            <div>Staff</div>
                                        </div>
                                    </Link>
                                </Col>
                            ):null
                             }
                            </Fragment>
                        }
                        <Col>
                            <Link className="dashboardlink" onClick={()=>{navigateToLink('configuration')}} ignoreCache={true}>
                                <div className="elevation-demo elevation-3 dashboardlink elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="wrench_fill" className="dashboard_pages"></Icon>
                                    <div>Configuration</div>
                                </div>
                            </Link>
                        </Col>
                       </Row>
                );
            }}
            </CommonConsumer>
        )
    }
}