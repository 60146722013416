import React,{Component} from 'react';
import { CommonConsumer } from '../context/CommonContext';
import { CampaignConsumer } from '../context/CampaignContext';

import {
    Page,
    Popup,
    Navbar,
    NavRight,
    NavTitle,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Link,
  } from 'framework7-react';

  export default class LeadFilter extends Component {
    
    constructor(props) {
      super(props);

      this.state = {

        clientdetail:[],
        campiganlist:[],
        grouplist:[],
        groupid:'',
        groupname:'',
        campaignstatuslist:[{id:"9999",name:"Archive"},{id:"0",name:"Pending"},{id:"1",name:"In Process"},{id:"2",name:"Completed"},{id:"3",name:"Paused"},{id:"4",name:"Incomplete"}],

      };

      this.handleUserInput = this.handleUserInput.bind(this);

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      
      if(clientdetail !== undefined && clientdetail !== null)
      {
        this.setState(()=>{
          return{

            clientdetail:clientdetail

          }
        },()=>{

          this.getCampaignFilter();

        });
      }
    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteGroupPopup.destroy();

    }

    onPageInit(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }
    
      self.autocompleteGroupPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-group-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectgroup/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/campaign.php?t=${Date.now()}`,
            data: {
              Mode:'GetGroup',
              clientid:self.state.clientdetail.id
            },
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    grouplist:res.recordlist,
                  }
                },()=>{

                  const searchgrouplist  = self.state.grouplist;

                  for (let i = 0; i < searchgrouplist.length; i += 1) {
                    if (searchgrouplist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchgrouplist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-group-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
              groupid:id,
              groupname:name,
            });

          },
        },
      });
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: !value
        }
      });

    }

    getCampaignFilter(){

      const self      = this;
      const app       = self.$f7;

      let bodyFormData = {};

      if(Number(self.state.clientdetail.isstaff) > 0)
      {
        bodyFormData = {
            Mode:'GetCampaignFilter',
            clientid:self.state.clientdetail.id,
            staffid:self.state.clientdetail.staffid,
        };
      }
      else
      {
        bodyFormData = {
            Mode:'GetCampaignFilter',
            clientid:self.state.clientdetail.id,
        };
      }

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
        serverurl = "http://orloprachar/app/";
      }

      const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          // Hide Pull To Refresh
          app.ptr.done();

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
              this.setState(()=>{
                  return{
                    campiganlist:resdata.recordlist,
                  }
              },()=>{
                this.onPageInit();
              });
          }
          else
          {
            this.onPageInit();
          }

      }).catch(e => {
          console.log(e);
      });
    }

    render(){

      const{campiganlist, campaignstatuslist, groupid, groupname} = this.state;

      return(

        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <CampaignConsumer>{(value)=>{

                const{setCampaignFilterData} = value;

                  return(
                    <Popup className="demo-popup">
                      <Navbar sliding={true}>
                          <NavTitle>Filter</NavTitle>
                          <NavRight>
                            <Link popupClose>Close</Link>
                          </NavRight>
                      </Navbar>
                        <Page className="page" name="LeadFilter">
                            <Block>
                                <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}> 
                                    <ListItem
                                        title="Choose Campaign"
                                        smartSelect
                                        smartSelectParams={{openIn:'popup',searchbar: true, searchbarPlaceholder: 'Search List',routableModals:true}}
                                        name='campaignids'
                                        id='campaignids'
                                        className="smartselectlist smartselectcampaign"
                                        after = 'Select'
                                        PlaceHolder='Select'
                                        >
                                        <select name="campaign" multiple onChange={this.handleUserInput}>
                                        {
                                          campiganlist.map((campigan)=>{
                                            return(
                                                    <option value={campigan.id} key={campigan.id}>{campigan.name}</option>
                                                );
                                            })
                                        }
                                        </select>
                                    </ListItem>
                                    <ListItem
                                        title="Choose Status"
                                        smartSelect
                                        smartSelectParams={{openIn:'popup',searchbar: true, searchbarPlaceholder: 'Search List',routableModals:true}}
                                        name='statusids'
                                        id='statusids'
                                        className="smartselectlist smartselectstatus"
                                        after = 'Select'
                                        PlaceHolder='Select'
                                        >
                                        <select name="leadstatus" multiple onChange={this.handleUserInput}>
                                        {
                                          campaignstatuslist.map((status)=>{
                                            return(
                                                    <option value={status.id} key={status.id}>{status.name}</option>
                                                );
                                            })
                                        }
                                        </select>
                                    </ListItem>
                                    <ListItem link="#" id="autocomplete-group-popup" title="Campaign Group" after={groupname}></ListItem>
                                </List>
                            </Block>
                            <br />
                            <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                                
                                let selectedlist = this.$f7.smartSelect.get('.smartselectcampaign .smart-select').$selectEl.val();

                                const selectedcamp  = [];

                                selectedlist.forEach(campid => {
                                  const tempcamp = this.state.campiganlist.find(campigan => campigan.id === campid);

                                  if(selectedcamp.indexOf(tempcamp.name) === -1)
                                  {
                                    selectedcamp.push(tempcamp.name);
                                  }
                                });

                                const campids   = selectedlist.join(',');
                                const campnames = selectedcamp.join(', ');


                                let selectedcampaignstatus = this.$f7.smartSelect.get('.smartselectstatus .smart-select').$selectEl.val();

                                const selectedstatusname  = [];

                                selectedcampaignstatus.forEach(statusid => {
                                  const tempcampaignstatus = this.state.campaignstatuslist.find(status => status.id === statusid);

                                  if(selectedstatusname.indexOf(tempcampaignstatus.name) === -1)
                                  {
                                    selectedstatusname.push(tempcampaignstatus.name);
                                  }
                                });

                                const statusids   = selectedcampaignstatus.join(',');
                                const statusnames = selectedstatusname.join(', ');

                                if(internetstatus === 'online')
                                {
                                  const self      = this;
                                  const app       = self.$f7;
                                  if((campids === "" || campids === undefined) && (statusids === "" || statusids === undefined) && (groupid === "" || groupid === undefined))
                                  {
                                    const toastWithButton = app.toast.create({
                                        text: "Please select atleast one filter.",
                                        closeButton: true,
                                        closeTimeout: 3000,
                                    });
                                      
                                    toastWithButton.open();
                                  }
                                  else{
                                    Promise.all([

                                      setTimeout(()=> {

                                        setCampaignFilterData(campids, campnames, statusids, statusnames, groupid, groupname);

                                      }, 500)
                                        
                                    ])
                                    .then(() => {

                                        this.$$('.popup-close').trigger('click');

                                    })
                                  }
                                };
                            }}>
                            <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                            </Fab>
                        </Page>
                    </Popup>
                  );
              }}
              </CampaignConsumer>            
          );
        }}
        </CommonConsumer>        

      );
    }
}