import React,{Component, Fragment} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import { CampaignConsumer } from '../context/CampaignContext';

import {
    Page,
    PageContent,
    List,
    Row,
    Col,
    Icon,
    Link,
    Block,
    ListItem,
    Fab,
    SkeletonBlock,
  } from 'framework7-react';

  export default class CampaignDashboard extends Component {

    constructor(props) {
        super(props);
    
        this.state = {

          issummaryloading:false

        };
    }

    componentDidMount(){
      setTimeout(()=> {
    
        this.Dom7('.campaigndashboardlink').addClass('animate-bottom');
  
      }, 100);
    }

    unmountPage(){
      console.log("I will unmount");
    }

    render(){

      return(
        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <CampaignConsumer>{(value)=>{

                const{getCampaignSummary} = value;

                const{isavailablesummary, issummaryloaded, campaignlistbystatus,isinsufficientcredit, totalschedulecampaign, hasinprocesscampaign, totalinprocesscampaign, inprocessmsg} = value;
                  setTimeout(() => {
                    if(this.state.issummaryloading === false)
                    {
                      this.setState(()=>{
                        return{
                          issummaryloading:true
                        }
                      },()=>{

                        getCampaignSummary();

                      })
                      
                    }
                  }, 100);

                  return(
                      <Page name="CampaignDashboard" className="ContactDashboard page dashboardpage" pageContent={false}>
                        <AppNavbar title="Campaign Dashboard" showrefresh={true} init={getCampaignSummary}/>
                        <PageContent>
                          {
                            issummaryloaded ? (
                              <Fragment>
                                <br /><br />
                                {Number(isinsufficientcredit) > 0 ? ( 
                                <Row noGap>
                                  <Col noGap style={{background:'red',color:'white',marginLeft:'10px',marginRight:'10px',width:'100%',padding:'3px'}} onClick={()=>{
                                              if(internetstatus === "online")
                                              {
                                                navigateToLink(`smscredits`);
                                              }
                                            }} >
                                    Campaigns are paused due to insufficient sms credit.
                                  </Col>
                                </Row>    
                                ):null}
                                {
                                  hasinprocesscampaign ? (
                                    <Row>
                                        <Col style={{width:'100%'}}>
                                          <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5 campaigndashboardelev" onClick={()=>{
                                              if(internetstatus === "online")
                                              {
                                                navigateToLink(`campaign/1/In Process`);
                                              }
                                            }} style={{color:'#2196f3'}}>
                                              <div style={{fontSize:'3rem',fontWeight:'bold',paddingTop:'0px'}}>{totalinprocesscampaign}</div>
                                              <div style={{fontSize:'1.5rem',marginTop:'5px'}}>In Process</div>
                                              <div style={{fontSize:'.8rem',marginTop:'0px',color:'#ff0000'}}>{inprocessmsg}</div>
                                          </div>
                                        </Col>
                                    </Row>
                                  ):null
                                }
                                {
                                  issummaryloaded ? (<Fragment>
                                      {
                                        isavailablesummary ? (
                                            <Block>
                                            {
                                              isavailablesummary ? (<Fragment>
                                                    <Row>
                                                    {
                                                      campaignlistbystatus.map((list)=>{
                                                          return(
                                                            <Col key={list.status}>
                                                                <Link className="dashboardlink" onClick={()=>{navigateToLink(`campaign/${list.status}/${list.name}`)}} ignoreCache={true}>
                                                                    <div className="elevation-demo elevation-3 campaigndashboardlink elevation-hover-5 elevation-pressed-5">
                                                                        <Icon f7={list.icon_classname} className={list.classname}></Icon>
                                                                        <div>{`${list.name} (${list.totalcampaign})`}</div>
                                                                    </div>
                                                                </Link>
                                                            </Col>
                                                          );
                                                        })
                                                    }
                                                    <Col>
                                                        <Link className="dashboardlink" onClick={()=>{navigateToLink(`campaign`)}} ignoreCache={true}>
                                                            <div className="elevation-demo elevation-3 campaigndashboardlink elevation-hover-5 elevation-pressed-5">
                                                                <Icon f7="envelope_badge_fill" className="dashboard_messages"></Icon>
                                                                <div>All ({totalschedulecampaign})</div>
                                                            </div>
                                                        </Link>
                                                    </Col>
                                                  </Row>
                                                </Fragment>):null
                                            }
                                          </Block>
                                        ):null
                                      }
                                    </Fragment>):null
                                }
                              </Fragment>
                            ):(
                              <Fragment>
                                <List noHairlinesMd className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                                    <ListItem title="Title Title Title Title Title Title Title Title Title"></ListItem>
                                </List>
                                <Row>
                                    <Col style={{width:'100%'}}>
                                      <div className={`elevation-demo elevation-hover-5 elevation-pressed-5`} style={{padding:'0'}}>
                                        <SkeletonBlock style={{ width: '100%', height: '95px', margin:'auto', borderRadius:'10px'}} slot="media" className={`skeleton-effect-${this.state.effect}`}/>
                                        </div>
                                    </Col>
                                </Row>
                                <List mediaList className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                </List>
                              </Fragment>
                            )
                          }
                      <br /><br />
                      </PageContent>
                      {
                        issummaryloaded ? (
                        <Fab position="center-bottom" slot="fixed" text="Add Campaign" onClick={()=>{
                              if(internetstatus === "online")
                              {
                                navigateToLink('dataselection');
                              }
                          }}>
                          <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
                        </Fab>
                        ):null
                      }
                      </Page>
                  );

              }}
              </CampaignConsumer>            
          );
        }}
        </CommonConsumer>
      );
    }
}