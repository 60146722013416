import React,{Component,Fragment} from 'react';
import AppNavbar from './appnavbar';
import Contactloading from './contactloading';
import logo404 from '../assets/images/orlo404.png';
import { CommonConsumer } from '../context/CommonContext';
import { ContactConsumer } from '../context/ContactContext';

import {
    Page,
    PageContent,
    Icon,
    Block,
    BlockTitle,
    Chip,
    Row,
    Col,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
    List,
    ListInput,
    Toolbar,
  } from 'framework7-react';

  export default class ManageContact extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        loaded:false,
        isavailable:true,
        allcontacts:[],
        contacts:[],
        internetstatus:navigator.onLine ? "online" : "offline",
        iscontactloaded:false,
      };
    }

    render(){

       return(
        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <ContactConsumer>{(value)=>{
                const{handleUserInput_contact, getAllContacts,  openFilterPopup, Paging, deleteFilterAction, deleteRecordAction, initContact, clearContactFilter} = value;

                const{isavailable_contact, loaded_contact, contacts, paginglist, showpages, totalrecord, hasfilter, filterlist, searchkeyword, hasallcontact, page, totalpages, isbulkcontact, bulkcontactname, recordrange, toolbarPosition} = value;

                  setTimeout(() => {
                    if(this.state.iscontactloaded === false)
                    {
                      this.setState(()=>{
                        return{
                          iscontactloaded:true,
                        }
                      },()=>{

                        initContact(this.props.f7route.params.listid, this.props.f7route.params.listname, this.props.f7route.params.searchkeyword);
                        
                      })
                      
                    }
                  }, 100);

                  return(
                      <Page className="page" name="Leads" pageContent={false}>
                      <AppNavbar title="Contacts" showcontactrefresh={true} handleUserInput={handleUserInput_contact} searchkeyword={searchkeyword} showrefresh={true} init={getAllContacts}/>

                        <PageContent>
                              {
                                loaded_contact ? (<Fragment>
                                  {
                                    isavailable_contact ? (<BlockTitle className="totalrec">Total Contacts : {totalrecord}</BlockTitle>):null
                                  }
                                  {
                                    recordrange ? (<p className="totalrec">Showing {recordrange}</p>):null
                                  }
                                  {
                                    hasfilter ? (
                                      <Block strong className="selectedfilter" style={{marginTop:0}}>
                                      {hasallcontact ? (<Chip text={`Contact : All`} mediaBgColor="blue" media="A"/>):null}
                                        {searchkeyword ? (<Chip text={`Keyword : ${searchkeyword}`} mediaBgColor="blue" media={searchkeyword.toUpperCase().substring(0, 1)} deleteable
                                        onClick={()=>{
                                                if(internetstatus === "online")
                                                {
                                                  deleteFilterAction('searchkeyword');
                                                }
                                              }}
                                        />):null}
                                        {filterlist ? (<Chip text={`List : ${filterlist}`} mediaBgColor="pink" media={filterlist.toUpperCase().substring(0, 1)} deleteable
                                        onClick={()=>{
                                                if(internetstatus === "online")
                                                {
                                                  deleteFilterAction('list');
                                                }
                                              }}
                                        />):null}
                                        {isbulkcontact ? (<Chip text={`List : ${bulkcontactname}`} mediaBgColor="green" media={bulkcontactname.toUpperCase().substring(0, 1)} deleteable
                                        onClick={()=>{
                                                if(internetstatus === "online")
                                                {
                                                  deleteFilterAction('datatype');
                                                }
                                              }}
                                        />):null}
                                        &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                                          openFilterPopup();
                                        }}/>
                                        &nbsp;
                                        <Chip text="Clear Filter" color="blue" onClick={()=>{
                                          clearContactFilter();
                                        }}/>
                                      </Block>
                                    ):null
                                  }
                                  {
                                    isavailable_contact ? (
                                      <div className="search-list searchbar-found">
                                        {
                                            contacts.map((person)=>{
                                              return(
                                                <div key={person.id}>
                                                <Card outline className="recordset">
                                                  <CardHeader
                                                  className="date"><div className="item-title">{person.name}</div>{/*<div className="item-title">{person.id}</div>*/}</CardHeader>
                                                  <CardContent>
                                                    {person.phone ? (<p><b>Phone : </b>{person.phone}
                                                      <Link external href={`tel:${person.phone}`} target="_system">
                                                      <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                                      </Link>
                                                    </p>):null}
                                                    {person.listname ? (<p><b>List : </b>{person.listname}</p>):null}
                                                  </CardContent>
                                                  <CardFooter>
                                                  <Link onClick={()=>{navigateToLink(`editcontact/${person.id}`)}} ignoreCache={true}>Edit</Link>
                                                  <Link onClick={() =>{
                                                    if(internetstatus === "online")
                                                    {
                                                      deleteRecordAction(person.id);
                                                    }
                                                  }}>Delete</Link>
                                                </CardFooter>
                                                </Card>
                                                </div>
                                              );
                                            })
                                        }
                                    </div>
                                    ):(<Block>
                                      <br />
                                      <Row>
                                        <Col>
                                          <img src={logo404} className="norecimg" />
                                          <BlockTitle className="errmsg">No contact record found.</BlockTitle>
                                        </Col>
                                      </Row>
                                      </Block>)
                                  }
                                </Fragment>):(<Contactloading />)
                              }
                          {/*</PageContent>*/}
                          <br /><br /><br /><br />
                        </PageContent>
                        {
                          isavailable_contact  && showpages ? (
                            <Toolbar position={toolbarPosition}>
                            {
                              (()=>{
                                if(page > 1)
                                {
                                  return(
                                    <Link className="paging" onClick={()=>{Paging('previous')}}>
                                    <Icon f7="arrow_left_square_fill" ></Icon>
                                    </Link>
                                  );
                                }
                                else
                                {
                                  return(
                                    <Link href="#"></Link>
                                  );
                                }
                              })()
                            }
                              <List className="droppage" noHairlines>
                                  <ListInput
                                      name="page"
                                      type="select"
                                      value={page}
                                      onChange={handleUserInput_contact}
                                      placeholder="Please choose..."
                                      >
                                      {
                                        paginglist.map((pages)=>{
                                        return(<option key={pages.index} value={pages.page}>{pages.name}</option>);
                                        })
                                      }
                                  </ListInput>
                              </List>
                              {
                                (()=>{
                                  if(page < totalpages)
                                  {
                                    return(
                                      <Link className="paging" onClick={()=>{Paging('next')}}>
                                      <Icon f7="arrow_right_square_fill"></Icon>
                                      </Link>
                                    );
                                  }
                                  else
                                  {
                                    return(
                                      <Link href="#"></Link>
                                    );
                                  }
                                })()
                              }
                            </Toolbar>
                          ):null
                        }
                      </Page>
                  );
              }}
              </ContactConsumer>            
          );
        }}
        </CommonConsumer>
      )
    }
}