import React,{Component} from 'react';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Icon,
    PageContent,
    List,
    ListItem,
    Block,
    Row,
    Col,
    ListInput,
    Button,
    Toolbar,
    Navbar,
    NavRight,
    NavLeft,
    NavTitle
  } from 'framework7-react';

  export default class CompleteRegistration extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientname:'',
        contactname:'',
        contactemail:'',
        username:'',
        phone1:'',
        iswhatsapp1:0,
        phone2:'',
        pincode:'',
        stateid:'',
        cityid:'',
        address1:'',
        address2:'',
        profiledetail:[],
        statelist:[],
        citylist:[],
        clientdetail:'',
        password:'',
        confirmpassword:'',
        refcode:'',
        isloaded:false,
        internetstatus:navigator.onLine ? "online" : "offline",
      };
      this.handleUserInput = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.getState();
        this.hideSplashScreen();

      })

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    handleUserInput(e){
      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        if(name === 'stateid')
        {
          this.getStateCity();
        }

      });

    }

    check_email(elementValue){
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(elementValue);
    }

    saveDetail(){
      
      const self      = this;
      const app       = self.$f7;
      const $$        = self.Dom7;
      const router    = self.$f7router;

      let errmsg      = "";

      app.input.validateInputs('.adddata');

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      const toastWithButton = app.toast.create({
        text: 'Please enter all required field.',
        closeButton: true,
        closeTimeout: 3000,
      });

      app.input.validateInputs('.adddata');

      if(this.state.contactname === "" && errmsg === "")
      {
          errmsg  = "Please enter your company name.";
      }

      if(this.state.contactemail === "" && errmsg === "")
      {
          errmsg  = "Please enter your contact email.";
      }
      else if(this.state.contactemail != "" && !this.check_email(this.state.contactemail)  && errmsg === "")
      {
        errmsg  = "Please enter a valid email.";
      }

      if(this.state.username === "" && errmsg === "")
      {
          errmsg  = "Please enter user name.";
      }
      if(this.state.password === "" && errmsg === "")
      {
          errmsg  = "Please enter your password.";
      }
      if(this.state.confirmpassword === "" && errmsg === "")
      {
          errmsg  = "Please enter your confirm password.";
      }
      if((this.state.password !== this.state.confirmpassword) && errmsg === "")
      {
          errmsg  = "Password does not match.";
      }
      
      /*if(this.state.phone1 === "" && errmsg === "")
      {
          errmsg  = "Please enter your phone.";
      }*/

      if(this.state.pincode === "" && errmsg === "")
      {
          errmsg  = "Please enter pin code.";
      }

      if(this.state.stateid === "" && errmsg === "")
      {
          errmsg  = "Please select a state.";
      }

      if(this.state.cityid === "" && errmsg === "")
      {
          errmsg  = "Please select a city.";
      }

      if(this.state.address1 === "" && errmsg === "")
      {
          errmsg  = "Please enter your address.";
      }

      if(errmsg !== "")
      {
          const toastWithButton = app.toast.create({
              text: errmsg,
              closeButton: true,
              closeTimeout: 3000,
          });

          toastWithButton.open();
  
          return false;
      }

      const bodyFormData = {
          Mode:'Register',
          clientname:self.state.clientname,
          contactname:self.state.contactname,
          contactemail:self.state.contactemail,
          username:self.state.username,
          phone2:self.state.phone2,
          pincode:self.state.pincode,
          stateid:self.state.stateid,
          cityid:self.state.cityid,
          address1:self.state.address1,
          address2:self.state.address2,
          iswhatsapp1:self.state.iswhatsapp1,
          password:self.state.password,
          refcode:self.state.refcode,
          reguserid:Number(localStorage.getItem('prachar_reguserid')),
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.promise.post(`${serverurl}api/register.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
            const resdata = JSON.parse(res.data);

            const toastWithButton2 = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton2.open();

            if(resdata.success === true)
            {
                Promise.all([
                    this.setState(()=>{
                        return{
                            clientname:'',
                            contactname:'',
                            contactemail:'',
                            username:'',
                            iswhatsapp1:0,
                            phone2:'',
                            pincode:'',
                            stateid:'',
                            cityid:'',
                            address1:'',
                            address2:'',
                            password:'',
                            refcode:'',
                        }
                    },()=>{
                        router.navigate('/login/');
                    })
                ])
                .then(() => {

                    $$('.item-content').removeClass('item-input-with-error-message');
                    $$('.item-content').removeClass('item-input-invalid');

                })
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    getState(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetStateList',
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/register.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                statelist:resdata.statelist
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    getStateCity(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetStateCity',
          stateid:this.state.stateid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/register.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                citylist:resdata.citylist
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){
       
      return(
            <Page className="page" name="Signup" pageContent={false}>
                <Navbar sliding={true}>
                    <NavLeft backLink="Back">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </NavLeft>
                    <NavTitle>Sign Up</NavTitle>
                    <NavRight className="right-with-menu">
                    </NavRight>
                </Navbar>
                <PageContent className="ptr-content">
                  <Block>
                    <List noHairlinesMd>
                      <ListInput
                          name="clientname"
                          className="adddata resizable"
                          label="Company Name"
                          floatingLabel
                          type="text"
                          onInput={this.handleUserInput}
                          placeholder="Company Name"
                          value={this.state.clientname}
                          maxlength="200"
                          clearButton required validate
                      >
                      </ListInput>

                      <ListInput
                          name="contactname"
                          className="adddata resizable"
                          label="Name"
                          floatingLabel
                          type="text"
                          onInput={this.handleUserInput}
                          placeholder="Name"
                          value={this.state.contactname}
                          maxlength="200"
                          clearButton required validate
                      >
                      </ListInput>

                      <ListInput
                          name="contactemail"
                          className="adddata resizable"
                          label="Email"
                          floatingLabel
                          type="email"
                          onInput={this.handleUserInput}
                          placeholder="Email"
                          value={this.state.contactemail}
                          clearButton required validate
                      >
                      </ListInput>

                      <ListInput
                          name="username"
                          className="adddata resizable"
                          label="User Name"
                          floatingLabel
                          type="text"
                          onInput={this.handleUserInput}
                          placeholder="User Name"
                          value={this.state.username}
                          clearButton required validate
                      >
                      </ListInput>

                      <ListInput
                          name="password"
                          className="adddata resizable"
                          label="Password"
                          floatingLabel
                          type="password"
                          onInput={this.handleUserInput}
                          placeholder="Password"
                          value={this.state.password}
                          clearButton required validate
                      >
                      </ListInput>
                      
                      <ListInput
                          name="confirmpassword"
                          className="adddata resizable"
                          label="Confirm Password"
                          floatingLabel
                          type="password"
                          onInput={this.handleUserInput}
                          placeholder="Confirm Password"
                          value={this.state.confirmpassword}
                          clearButton required validate
                      >
                      </ListInput>

                      {/*<ListInput
                          name="phone1"
                          className="adddata resizable"
                          label="Phone Number"
                          floatingLabel
                          type="tel"
                          onInput={this.handleUserInput}
                          placeholder="Phone number"
                          value={this.state.phone1}
                          maxlength="200"
                          clearButton required validate pattern="[0-9]*"
                      >
                      </ListInput>

                      <ListItem checkbox title="Is Whatsapp Enabled ?" 
                      name="iswhatsapp1"
                      onChange={()=>{
                        this.setState({iswhatsapp1:!this.state.iswhatsapp1})
                      }} 
                      checked={Number(this.state.iswhatsapp1) === 1}
                      value="1"
                      >
                      </ListItem>*/}

                      <ListInput
                          name="phone2"
                          label="Alternate Phone"
                          floatingLabel
                          type="tel"
                          onInput={this.handleUserInput}
                          placeholder="Alternate Phone"
                          value={this.state.phone2}
                          maxlength="200"
                          clearButton validate pattern="[0-9]*"
                      >
                      </ListInput>

                      <ListInput
                          name="pincode"
                          className="adddata resizable"
                          label="Pin Code"
                          floatingLabel
                          type="number"
                          onInput={this.handleUserInput}
                          placeholder="Pin Code"
                          value={this.state.pincode}
                          clearButton required validate
                      >
                      </ListInput>

                      <ListInput
                          name="stateid"
                          className="adddata resizable"
                          label="State"
                          type="select"
                          onChange={this.handleUserInput}
                          value={this.state.stateid}                            
                          placeholder="Please choose..."
                          >
                          <option value="">-Select-</option>
                          {
                              this.state.statelist.map((state)=>{
                              return(<option key={state.id} value={state.id}>{state.name}</option>);
                              })
                          }
                      </ListInput>

                      <ListInput
                          name="cityid"
                          className="adddata resizable"
                          label="City"
                          type="select"
                          onChange={this.handleUserInput}
                          value={this.state.cityid}
                          placeholder="Please choose..."
                          >
                          <option value="">-Select-</option>
                          {
                              this.state.citylist.map((city)=>{
                              return(<option key={city.id} value={city.id}>{city.name}</option>);
                              })
                          }
                      </ListInput>

                      <ListInput
                          name="address1"
                          className="adddata resizable"
                          label="Address Line 1"
                          floatingLabel
                          type="text"
                          onInput={this.handleUserInput}
                          placeholder="Address Line 1"
                          value={this.state.address1}
                          clearButton required validate
                      >
                      </ListInput>

                      <ListInput
                          name="address2"
                          label="Address Line 2"
                          floatingLabel
                          type="text"
                          onInput={this.handleUserInput}
                          placeholder="Address Line 2"
                          value={this.state.address2}
                          clearButton validate
                      >
                      </ListInput>
                      <ListInput
                          name="refcode"
                          className="adddata resizable"
                          label="Ref. Code (Optional)"
                          floatingLabel
                          type="text"
                          onInput={this.handleUserInput}
                          placeholder="Ref. Code"
                          value={this.state.refcode}
                          clearButton validate
                      >
                      </ListInput>
                    </List>
                  </Block>
                  <br /><br />
              </PageContent>
                <Toolbar position="bottom">
                    <Row style={{width:'100%'}}>
                        <Col width="40"></Col>
                        <Col width="60"><Button fill color="blue" style={{marginRight:'.4rem'}}
                        onClick={()=>{
                        if(this.state.internetstatus === "online")
                        {
                            this.saveDetail();
                        }
                        }}
                        ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Register Now</Button></Col>
                    </Row>
                </Toolbar>
            </Page>
      );
    }
}