import React,{Component} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import { SupportConsumer } from '../context/SupportContext';

import {
    Page,
    PageContent,
    Icon,
    Block,
    List,
    ListInput,
    ListItem,
    Toggle,
    Toolbar,
    Row,
    Col,
    Button,
    Link
  } from 'framework7-react';

  export default class AddSupport extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        phone:'',
        phone2:'',
        description:'',
        templateremark:'',
        status:1,
        isloaded:false,
        issuccess:false,
        internetstatus:navigator.onLine ? "online" : "offline",
      };
      this.handleUserInput = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.getClientDetail = this.getClientDetail.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.updateOnlineStatus();
        this.hideSplashScreen();
        this.getClientDetail();
      });

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    getClientDetail(){

      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      if(this.state.internetstatus === "offline")
      {
        const toastWithButton = app.toast.create({
            text: "No internet connection",
        });
            
        toastWithButton.open();
        
        return;
      }

      const bodyFormData = {
          Mode:'GetProfileDetail',
          recordid:self.state.clientdetail.id,
      };

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }

      const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${serverurl}api/profile.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
            this.setState({
              phone:resdata.profiledetail.phone1,
              phone2:resdata.profiledetail.phone2,
            });
          }

      }).catch(e => {
          console.log(e);
      });
    }

    saveDetail(){
      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      let errmsg    = "";
      
      app.input.validateInputs('.adddata');

      if(this.state.phone === "")
      {
        errmsg = "Please enter phone";
      }
     
      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const bodyFormData = {
          Mode:'AddSupport',
          clientid:self.state.clientdetail.id,
          phone:self.state.phone,
          phone2:self.state.phone2,
          status:Number(self.state.status),
          description:self.state.description,
          templateremark:self.state.templateremark,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
            serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${serverurl}api/support.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            if(resdata.success === true)
            {
              Promise.all([
                this.setState(()=>{
                  return{
                    phone:'',
                    phone2:'',
                    description:'',
                    templateremark:'',
                    status:1,
                    issuccess:true,
                  }
                })
              ])
              .then(() => {
  
                $$('.item-content').removeClass('item-input-with-error-message');
                $$('.item-content').removeClass('item-input-invalid');
  
              });

              this.$f7.views.main.router.navigate(`thanks/addsupportsuccess/`);

            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    render(){

      const{issuccess}  = this.state;
       
      return(
        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <SupportConsumer>{(value)=>{

                const{init} = value;

                  if(issuccess)
                  {
                      setTimeout(() => {

                          init();

                          this.setState(()=>{
                              return{
                                  issuccess:false
                              }
                          })
                          
                      }, 500);
                  }

                  return(
                      <Page className="page" name="AddSupport" pageContent={false}>
                        <AppNavbar title="Support Request"/>
                        <PageContent>
                          <Block>
                            <List noHairlinesMd>
            
                                <ListInput
                                    name="phone"
                                    className="adddata"
                                    label="Phone"
                                    floatingLabel
                                    type="tel"
                                    onInput={this.handleUserInput}
                                    placeholder="Phone"
                                    value={this.state.phone}
                                    clearButton required validate pattern="[0-9]*"
                                >
                                </ListInput>

                                <ListInput
                                    name="phone2"
                                    className="adddata"
                                    label="Alt. Phone"
                                    floatingLabel
                                    type="tel"
                                    onInput={this.handleUserInput}
                                    placeholder="Alt. Phone"
                                    value={this.state.phone2}
                                    clearButton required validate pattern="[0-9]*"
                                >
                                </ListInput>

                                <ListInput
                                    name="description"
                                    label="Remark"
                                    floatingLabel
                                    type="textarea"
                                    onInput={this.handleUserInput}
                                    value={this.state.description}
                                    resizable
                                    placeholder="Remark"
                                >
                                </ListInput>

                                {/*<ListItem title="Status">
                                  <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                                </ListItem>*/}
                            </List>
                        </Block>
                        </PageContent>
                        <Toolbar position="bottom">
                          <Row style={{width:'100%'}}>
                              <Col width="40"></Col>
                              <Col width="60"><Button fill color="blue" style={{marginRight:'.4rem'}}
                              onClick={()=>{
                                if(internetstatus === "online")
                                {
                                  this.saveDetail();
                                }
                              }}
                              ><Icon f7="headphones" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Call Me Back</Button></Col>
                          </Row>
                        </Toolbar>
                      </Page>
                  );
              }}
              </SupportConsumer>            
          );
        }}
        </CommonConsumer>
      );
    }
}