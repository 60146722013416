import React,{Component, Fragment} from 'react';

import {
    BlockTitle,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
  } from 'framework7-react';

  export default class Contactloading extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          effect:'blink',
        };

      }
    
    render(){
        
      return(<Fragment>
        <BlockTitle className={`totalrec recordset skeleton-text skeleton-effect-${this.state.effect}`} style={{width:'50%',margin:'12px auto'}}>xxxxxxxxxxxxxxxxxxxx</BlockTitle>
        <BlockTitle className={`totalrec recordset skeleton-text skeleton-effect-${this.state.effect}`} style={{width:'50%',margin:'12px auto'}}>xxxxxxxxxxxxxxxxxxxx</BlockTitle>
        <Card className={`waiting recordset skeleton-text skeleton-effect-${this.state.effect}`}>
          <CardHeader
          className="date"><div className="item-title">Customer Name</div><div className="item-title">xxxxx</div></CardHeader>
          <CardContent>
            <p><b>Phone : </b>xxx xxx xxxx</p>
            <p><b>Line : </b>Line Text</p>
          </CardContent>
          <CardFooter>
            <Link >Edit</Link>
            <Link >Delete</Link>
          </CardFooter>
        </Card>
        <Card className={`waiting recordset skeleton-text skeleton-effect-${this.state.effect}`}>
          <CardHeader
          className="date"><div className="item-title">Customer Name</div><div className="item-title">xxxxx</div></CardHeader>
          <CardContent>
            <p><b>Phone : </b>xxx xxx xxxx</p>
            <p><b>Line : </b>Line Text</p>
          </CardContent>
          <CardFooter>
            <Link >Edit</Link>
            <Link >Delete</Link>
          </CardFooter>
        </Card>
        <Card className={`waiting recordset skeleton-text skeleton-effect-${this.state.effect}`}>
          <CardHeader
          className="date"><div className="item-title">Customer Name</div><div className="item-title">xxxxx</div></CardHeader>
          <CardContent>
            <p><b>Phone : </b>xxx xxx xxxx</p>
            <p><b>Line : </b>Line Text</p>
          </CardContent>
          <CardFooter>
            <Link >Edit</Link>
            <Link >Delete</Link>
          </CardFooter>
        </Card>
        <Card className={`waiting recordset skeleton-text skeleton-effect-${this.state.effect}`}>
          <CardHeader
          className="date"><div className="item-title">Customer Name</div><div className="item-title">xxxxx</div></CardHeader>
          <CardContent>
            <p><b>Phone : </b>xxx xxx xxxx</p>
            <p><b>Line : </b>Line Text</p>
          </CardContent>
          <CardFooter>
            <Link >Edit</Link>
            <Link >Delete</Link>
          </CardFooter>
        </Card>
        <Card className={`waiting recordset skeleton-text skeleton-effect-${this.state.effect}`}>
          <CardHeader
          className="date"><div className="item-title">Customer Name</div><div className="item-title">xxxxx</div></CardHeader>
          <CardContent>
            <p><b>Phone : </b>xxx xxx xxxx</p>
            <p><b>Line : </b>Line Text</p>
          </CardContent>
          <CardFooter>
            <Link >Edit</Link>
            <Link >Delete</Link>
          </CardFooter>
        </Card>        
      </Fragment>);
    }
}