import React,{Component, Fragment} from 'react';
import AppNavbar from './appnavbar';
import logo404 from '../assets/images/orlo404.png';

import {
    Page,
    PageContent,
    Icon,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Toolbar,
    Button,
    SkeletonBlock
  } from 'framework7-react';

  export default class AddLeadCredit extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        name:'',
        phone:'',
        status:1,
        remarks:'',
        clientdetail:[],
        isloaded:false,
        packageid:'',
        smspackages:[],
        bankdetails:'',
        internetstatus:navigator.onLine ? "online" : "offline",
        ismount:false,
        filterpackage:'',
        smscredits:'',
        packagecost:'',
        packagename:'',
        hassmartpackages:false,
        packagetype:true,
        ispackageloaded:false,
        haspackage:false,
        effect:'blink',
        showpackagedetail:false,
        paymenttype:'online'
      };

      this.handleUserInput    = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.getLeadPackage     = this.getLeadPackage.bind(this);
      
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        
        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.hideSplashScreen();
        this.getLeadPackage();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else if(name === 'packagetype')
      {
        this.setState({packagetype:!this.state.packagetype,
          ispackageloaded:false,
          haspackage:false
        },()=>{
          setTimeout(()=>{

            this.getLeadPackage();

          },1000)
          
        });
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    getLeadPackage(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }

      let bodyFormData ='';
      if(Number(self.state.clientdetail.staffid) > 0)
      {
        bodyFormData = {
          Mode:'GetSMSPackages',
          clientid:self.state.clientdetail.id,
          credittype:0,
          /*packagetype:Number(self.state.packagetype),*/
          staffid:Number(self.state.clientdetail.staffid),
        }
      }
      else
      {
        bodyFormData = {
          Mode:'GetSMSPackages',
          credittype:0,
          /*packagetype:Number(self.state.packagetype),*/
          clientid:self.state.clientdetail.id
        }  
      }

      const accesstoken  = localStorage.getItem('prachar_accesstoken');

      app.request({
            
        method: 'POST',
        dataType: 'json',
        url: `${serverurl}api/smscredit.php?t=${Date.now()}`,
        data: bodyFormData,
        headers: {
            Authorization: `${accesstoken}`
        },
        success(res) {
        // Find matched items

          // Hide Preoloader
          /*autocomplete.preloaderHide();*/
          app.preloader.hide();

          if(res.success === true)
          {
            self.setState(()=>{
              return{
                smspackages:res.recordlist,
                ispackageloaded:true,
                haspackage:true,
              }
            })
          }
          else
          {
            self.setState(()=>{
              return{
                smspackages:[],
                ispackageloaded:true,
                haspackage:false,
              }
            })
          }
        },
      });
    }

    saveDetail(){
      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;

      let errmsg    = "";

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      app.input.validateInputs('.adddata');
      if((this.state.packageid === "" || this.state.packageid === undefined) && errmsg === "")
      {
        errmsg  = "Please select a package";
      }

      /*if(this.state.packageid === "" || this.state.packageid === undefined)
      {
        this.$$('.autocomplete-package-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-package-popup').removeClass('error');
      }*/

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const bodyFormData = {
          Mode:'AddLeadCredit',
          clientid:self.state.clientdetail.id,
          packageid:self.state.packageid,
          packagecost:self.state.packagecost,
          smscredits:self.state.smscredits,
          paymenttype:self.state.paymenttype,
          packagetype:Number(self.state.packagetype),
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

        app.request.promise.post(`${serverurl}api/smscredit.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();

            if(resdata.success === true)
            {
              Promise.all([
                this.setState(()=>{
                  return{
                    packageid:'',
                    smscredits:'',
                    packagecost:'',
                  }
                })
              ])
              .then(() => {
  
                $$('.item-content').removeClass('item-input-with-error-message');
                $$('.item-content').removeClass('item-input-invalid');
  
              });
              if(resdata.paymentlink !=='')
              {
                window.open(resdata.paymentlink,"_blank");
                this.$f7.views.main.router.navigate(`payments/`+resdata.paymentid+`/`);
              }
              else
              {
                this.$f7.views.main.router.navigate(`thanks/addleadcreditsuccess/`);
              }
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){

      const{packageid, smscredits, smspackages, packagecost, ismount, bankdetails, packagetype, ispackageloaded, haspackage, effect, showpackagedetail, packagename, paymenttype} = this.state;

      return(
        <Page className="page" name="AddLeadCredit" pageContent={false}>
          <AppNavbar title="Request Lead Credits"/>
          <PageContent>
            <Block>
              {/*
                showpackagedetail ? null:(
                  <Row noGap className="listtype">
                      <Col width="20">
                          <p style={{marginTop:'1.2em',marginBottom:'1.2em'}}><b>Package</b></p>
                      </Col>
                      <Col width="40">
                          <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                              <ListItem radio value="1" name="packagetype" checked={Number(packagetype) === 1} title="Transactional"
                              onChange={this.handleUserInput}
                              ></ListItem>
                          </List>
                      </Col>
                      <Col width="40">
                          <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                              <ListItem radio value="0" name="packagetype" checked={Number(packagetype) === 0} title="Promotional"
                              onChange={this.handleUserInput}
                              ></ListItem>
                          </List>
                      </Col>
                  </Row>
                )
              */}
              {
                showpackagedetail ? (<Fragment>
                  {
                    packageid ? (
                      <div>
                          {/*<Row noGap className="listtype">
                              <Col width="30">
                                  <p style={{marginTop:'1.2em',marginBottom:'1.2em'}}><b>Payment Type</b></p>
                              </Col>
                              <Col width="20">
                                  <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                      <ListItem radio value="neft" name="paymenttype" checked={paymenttype === 'neft'} title="Neft"
                                      onChange={this.handleUserInput}
                                      ></ListItem>
                                  </List>
                              </Col>
                              <Col width="20">
                                  <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                      <ListItem radio value="cash" name="paymenttype" checked={paymenttype === 'cash'} title="Cash"
                                      onChange={this.handleUserInput}
                                      ></ListItem>
                                  </List>
                              </Col>
                              <Col width="30">
                                  <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                      <ListItem radio value="online" name="paymenttype" checked={paymenttype === 'online'} title="Online"
                                      onChange={this.handleUserInput}
                                      ></ListItem>
                                  </List>
                              </Col>
                          </Row>*/}
                          <BlockTitle medium style={{textAlign:'center'}}>Lead Credit : {smscredits}</BlockTitle>
                          <BlockTitle medium style={{textAlign:'center'}}>Package Cost : {packagecost}</BlockTitle>
                          <div className="customtext" style={{textAlign:'center'}}>Package:<br/>{packagename}</div>
                          <div>
                          Please make a payment us and lead credit will be added to your account with in 24 hours.
                          </div>
                          {(()=>{
                            if(paymenttype === "neft")
                            {
                              return(
                                <div>
                                    -----------------------------------------------<br/>
                                    Bank Name : HDFC <br/>
                                    Account Name : Woodpecker Technologies Private Limited <br/>
                                    Acount Number : 09352560001324 <br/>
                                    IFSC Code : HDFC0000935 <br/>
                                    -----------------------------------------------
                                </div>
                              );
                            }
                          })()}
                          <br/>
                          Thanks
                          <br /><br /><br />
                      </div>
                    ):null
                  }
                </Fragment>):(<Fragment>
                  {
                    ispackageloaded ? (
                      <Fragment>
                      {
                        haspackage ? (
                          <List mediaList>
                          {
                            smspackages.map((list)=>{
                              return(
                                <ListItem
                                  key={list.id}
                                  link="#"
                                  title={`${list.credits} Credits`}
                                  after={`Rs. ${list.price}`}
                                  subtitle={`${list.packagetext}`}
                                  text={list.name}
                                  onClick={()=>{
                                    this.setState({
                                      packageid:Number(list.id),
                                      packagename:list.name,
                                      packagecost:list.price,
                                      smscredits:list.credits,
                                      showpackagedetail:true,
                                    });
                                  }}
                                >
                                <div slot="media" className={`noitemimage`} width="80">{list.credittext}</div>
                                </ListItem>
                              );
                            })
                          }
                          </List>
                        ):(
                          <Block>
                            <br />
                            <Row>
                              <Col>
                                  <img src={logo404} className="norecimg" />
                                  <BlockTitle className="errmsg">No Lead Package found.</BlockTitle>
                              </Col>
                            </Row>
                          </Block>
                        )
                      }
                      </Fragment>
                    ):(
                      <List mediaList className={`skeleton-text skeleton-effect-${effect}`}>
                        <ListItem
                          title="Title Title Title"
                          subtitle="Subtitle Subtitle Subtitle"
                          after="00.00"
                          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum."
                        >
                          <SkeletonBlock style={{ width: '90px', height: '90px'}} slot="media" />
                        </ListItem>
                        <ListItem
                          title="Title Title Title"
                          subtitle="Subtitle Subtitle Subtitle"
                          after="00.00"
                          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum."
                        >
                          <SkeletonBlock style={{ width: '90px', height: '90px'}} slot="media" />
                        </ListItem>
                        <ListItem
                          title="Title Title Title"
                          subtitle="Subtitle Subtitle Subtitle"
                          after="00.00"
                          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum."
                        >
                          <SkeletonBlock style={{ width: '90px', height: '90px'}} slot="media" />
                        </ListItem>

                        <ListItem
                          title="Title Title Title"
                          subtitle="Subtitle Subtitle Subtitle"
                          after="00.00"
                          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum."
                        >
                          <SkeletonBlock style={{ width: '90px', height: '90px'}} slot="media" />
                        </ListItem>
                      </List>
                    )
                  }
                </Fragment>)
              }
            </Block>
            {/*</PageContent>*/}
          </PageContent>
          {
            showpackagedetail ? (
              <Toolbar position="bottom">
                <Row style={{width:'100%'}}>
                    <Col width="40">
                    <Button fill color="blue" style={{marginRight:'.4rem'}}
                    onClick={()=>{
                      if(this.state.internetstatus === "online")
                      {
                        this.setState({
                          showpackagedetail:false
                        })
                      }
                    }}
                    ><Icon f7="line_horizontal_3_decrease" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Modify</Button>
                    </Col>
                    <Col width="60"><Button fill color="blue" style={{marginRight:'.4rem'}}
                    onClick={()=>{
                      if(this.state.internetstatus === "online")
                      {
                        this.saveDetail();
                      }
                    }}
                    ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Recharge Now</Button></Col>
                </Row>
              </Toolbar>
            ):null
          }
        </Page>
      );
    }
}