import React,{Component,Fragment} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import { CampaignConsumer } from '../context/CampaignContext';
import scanlogo from '../assets/images/scanlogo.png';
import logo404 from '../assets/images/orlo404.png';

import {
    Page,
    Fab,
    Icon,
    Block,
    BlockTitle,
    Chip,
    Row,
    Col,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
    List,
    ListInput,
    Toolbar,
  } from 'framework7-react';

  export default class Campaigns extends Component {

    constructor(props) {
      super(props);

      this.state = {
        iscampaignloaded:false,
      }

    }

    render(){

      return(
        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <CampaignConsumer>{(value)=>{

                const{handleUserInput, deleteRecordAction, deleteFilterActionCampaign, openFilterPopup, Paging, manageCampaignAction, init, clearCampaignFilter} = value;

                const{isavailable, campaigns, paginglist, showpages, totalrecord, page, totalpages, loaded,hasfilter, hasallrecord, effect, toolbarPosition, statusname, campname, groupname} = value;

                  setTimeout(() => {
                    if(this.state.iscampaignloaded === false)
                    {
                      this.setState(()=>{
                        return{
                          iscampaignloaded:true
                        }
                      },()=>{

                        init(this.props.f7route.params.statusid, this.props.f7route.params.statusname);

                      })
                      
                    }
                  }, 100);

                  return(
                      <Page className="page" name="Campaigns">
                          <AppNavbar title="Campaigns" showrefresh={true} init={init} statusid={this.props.f7route.params.statusid} statusname={this.props.f7route.params.statusname} iscampaign={true}/>
                          {/*<Navbar sliding={true}>
                            <NavLeft>
                              <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="right" className="smallbox"/>
                              <Link href="/dashboard/" className="clearmenupadding"><img className="navbarlogo" src={scanlogo} alt="logo" /></Link>
                            </NavLeft>
                            <NavTitle>Campaigns</NavTitle>
                            <NavRight className="right-with-menu">
                              <Link onClick={()=>{init()}} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                            </NavRight>
                          </Navbar>*/}
                          {
                            showpages ? (
                              <Toolbar position={toolbarPosition}>
                              {
                                (()=>{
                                  if(page > 1)
                                  {
                                    return(
                                      <Link className="paging" onClick={()=>{Paging('previous')}}>
                                        <Icon f7="arrow_left_square_fill"></Icon>
                                      </Link>
                                    );
                                  }
                                  else
                                  {
                                    return(
                                      <Link href="#"></Link>
                                    );
                                  }
                                })()}
                                <List className="droppage" noHairlines>
                                    <ListInput
                                        name="page"
                                        type="select"
                                        value={page}
                                        onChange={handleUserInput}
                                        placeholder="Please choose..."
                                        >
                                        {
                                          paginglist.map((pages)=>{
                                            return(<option key={pages.page} value={pages.page}>{pages.name}</option>);
                                          })
                                        }
                                    </ListInput>
                                </List>
                                {
                                  (()=>{
                                    if(page < totalpages)
                                    {
                                      return(
                                        <Link className="paging" onClick={()=>{Paging('next')}}>
                                        <Icon f7="arrow_right_square_fill" ></Icon>
                                        </Link>
                                      );
                                    }
                                    else
                                    {
                                      return(
                                        <Link href="#"></Link>
                                      );
                                    }
                                  })()
                                }
                              </Toolbar>
                            ):null
                          }
                          {
                            loaded ? (<Fragment>
                                {
                                  isavailable ? (<BlockTitle className="totalrec">Total campaigns : {totalrecord}</BlockTitle>):null
                                }
                                {
                                  hasfilter ? (
                                    <Block strong className="selectedfilter" style={{marginTop:0}}>
                                      {hasallrecord ? (<Chip text={`Campaign : All`} mediaBgColor="blue" media="A"/>):null}

                                      {campname ? (<Chip text={`Campaign : ${campname}`} mediaBgColor="orange" media={campname.toUpperCase().substring(0, 1)} deleteable 
                                      onClick={()=>{
                                              if(internetstatus === "online")
                                              {
                                                deleteFilterActionCampaign('campaign');
                                              }
                                            }}
                                      />):null}
                                      {statusname ? (<Chip text={`Status : ${statusname}`} mediaBgColor="pink" media={statusname.toUpperCase().substring(0, 1)} deleteable 
                                      onClick={()=>{
                                              if(internetstatus === "online")
                                              {
                                                deleteFilterActionCampaign('status');
                                              }
                                            }}
                                      />):null}
                                      {groupname ? (<Chip text={`Campaign Group : ${groupname}`} mediaBgColor="green" media={groupname.toUpperCase().substring(0, 1)} deleteable 
                                      onClick={()=>{
                                              if(internetstatus === "online")
                                              {
                                                deleteFilterActionCampaign('group');
                                              }
                                            }}
                                      />):null}
                                      &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                                        openFilterPopup();
                                      }}/>
                                      &nbsp;
                                      <Chip text="Clear Filter" color="blue" onClick={()=>{
                                        clearCampaignFilter();
                                      }}/>
                                    </Block>
                                  ):null
                                }
                                {
                                  isavailable ? (
                                    <div className="search-list searchbar-found">
                                    {
                                        campaigns.map((campaign)=>{
                                            return(
                                            <div key={campaign.id}>
                                            <Card outline className="recordset">
                                                <CardHeader
                                                className="date"><div className="item-title">Campaign # {campaign.id}</div><div className="item-title"></div></CardHeader>
                                                <CardContent>
                                                {campaign.campaigndate ? (<p><b>Campaign Added : </b>{campaign.campaigndate}</p>):null}
                                                {campaign.campaignname ? (<p><b>Campaign Name : </b>{campaign.campaignname}</p>):null}
                                                {campaign.trackablestatus ? (<p><b>Is Trackable? : </b>{campaign.trackablestatus}</p>):null}
                                                {campaign.pagename ? (<Fragment><p><b>Page : </b>{campaign.pagename}</p></Fragment>):null}
                                                {campaign.scheduleddate ? (<p><b>Sending Date : </b>{campaign.scheduleddate}</p>):null}
                                                {campaign.completeddate ? (<p><b>Completed Date : </b>{campaign.completeddate}</p>):null}
                                                 {campaign.totalmessages? (<p><b>Total Messages : </b>{campaign.totalmessages} {Number(campaign.isdataprocessed) < 1 ? ( `(tentative)` ) : null }</p>):null}
                                                {(campaign.totalsent && Number(campaign.isdataprocessed) > 0) ? (<p><b>Total Sent : </b>{campaign.totalsent}</p>):null}
                                                {(campaign.totaldelivered && Number(campaign.isdataprocessed) > 0)  ? (<p><b>Total Delivered : </b>{campaign.totaldelivered}</p>):null}
                                                {campaign.completed ? (<p><b>Status : </b>{campaign.completed}</p>):null}
                                                {campaign.campaignlisttext ? (<p><b>List : </b>{campaign.campaignlisttext}</p>):null}
                                                {
                                                  campaign.ispartial ? (<p><b>Range : </b>{campaign.fromposition} - {campaign.toposition}</p>):null
                                                }
                                                {campaign.languagetxt ? (<p><b>Language : </b>{campaign.languagetxt}</p>):null}
                                                {campaign.messagetext ? (<p><b>Message : </b>{campaign.messagetext}</p>):null}
                                                </CardContent>
                                                <CardFooter>
                                                  <Link onClick={()=>{
                                                    manageCampaignAction(Number(campaign.id), Number(campaign.status), campaign.completed,campaign.totalsent);
                                                  }}
                                                    ignoreCache={true}>Manage</Link>
                                                    <Link onClick={() =>{
                                                      if(Number(campaign.status) < 1 || Number(campaign.status) === 3)
                                                      { 
                                                        if(internetstatus === "online")
                                                        {
                                                          deleteRecordAction(campaign.id);
                                                        }
                                                      }
                                                      else
                                                      {
                                                        const self      = this;
                                                        const app       = self.$f7;
                                                      
                                                          const toastWithButton = app.toast.create({
                                                              text: "This campaign is alaready "+campaign.completed+" state, hence cannot be deleted.",
                                                              closeButton: true,
                                                              closeTimeout: 3000,
                                                          });
                                                            
                                                          toastWithButton.open();
                                                        }
                                                    }}>Delete</Link>
                                                        
                                                  </CardFooter>
                                            </Card>
                                            </div>
                                            );
                                        })
                                    }
                                    <br /><br /><br />
                                </div>
                                ):(<Block>
                                    <br />
                                    <Row>
                                    <Col>
                                        <img src={logo404} className="norecimg" />
                                        <BlockTitle className="errmsg">No campaign found.</BlockTitle>
                                    </Col>
                                    </Row>
                                    </Block>)
                                }
                                </Fragment>):(<Fragment>
                                  <BlockTitle className={`totalrec recordset skeleton-text skeleton-effect-${effect}`} style={{width:'50%',margin:'12px auto'}}>xxxxxxxxxxxxxxxxxxxx</BlockTitle>
                                  <Card className={`waiting recordset skeleton-text skeleton-effect-${effect}`}>
                                    <CardHeader
                                    className="date"><div className="item-title">Customer Name</div><div className="item-title">xxxxx</div></CardHeader>
                                    <CardContent>
                                      <p><b>Phone : </b>xxx xxx xxxx</p>
                                      <p><b>Line : </b>Line Text</p>
                                      <p><b>Lineman : </b>Lineman Text</p>
                                      <p><b>Hawker : </b>Hawker Name</p>
                                      <p><b>Discount : </b>Discount Text</p>
                                      <p><b>Address : </b>Address will be here</p>
                                    </CardContent>
                                    <CardFooter>
                                      <Link >Edit</Link>
                                      <Link >Subscription</Link>
                                      <Link >Delete</Link>
                                    </CardFooter>
                                  </Card>
                                  <Card className={`waiting recordset skeleton-text skeleton-effect-${effect}`}>
                                    <CardHeader
                                    className="date"><div className="item-title">Customer Name</div><div className="item-title">xxxxx</div></CardHeader>
                                    <CardContent>
                                      <p><b>Phone : </b>xxx xxx xxxx</p>
                                      <p><b>Line : </b>Line Text</p>
                                      <p><b>Lineman : </b>Lineman Text</p>
                                      <p><b>Hawker : </b>Hawker Name</p>
                                      <p><b>Discount : </b>Discount Text</p>
                                      <p><b>Address : </b>Address will be here</p>
                                    </CardContent>
                                    <CardFooter>
                                      <Link >Edit</Link>
                                      <Link >Subscription</Link>
                                      <Link >Delete</Link>
                                    </CardFooter>
                                  </Card>
                                </Fragment>)
                              }
                          {
                            loaded ? (
                              <Fab position="center-bottom" slot="fixed" text="Add Campaign" onClick={()=>{navigateToLink('dataselection')}}>
                                <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
                              </Fab>
                            ):null
                          }
                      </Page>
                  );
              }}
              </CampaignConsumer>            
          );
        }}
        </CommonConsumer>
      )
    }
}