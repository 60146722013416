import React,{Component} from 'react';
import { CommonConsumer } from '../context/CommonContext';
import { CampaignConsumer } from '../context/CampaignContext';

import {
    Page,
    Popup,
    Navbar,
    NavRight,
    NavTitle,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    ListInput,
    Link,
    Toggle,
    Row,
    Col,
  } from 'framework7-react';

  export default class AssignStaff extends Component {
    
    constructor(props) {
      super(props);

      this.state = {

        clientdetail:[],
        leadstafflist:[],
        staffid:'',
        staffname:'Select',
        issuccess:false,
        staffid_org:'',
      };

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      
      if(clientdetail !== undefined && clientdetail !== null)
      {
          this.setState(()=>{
            return{

              clientdetail:clientdetail,
              recordid:this.props.f7route.params.id

            }

          },()=>{
            this.onPageInit();
          });
      }

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteStaffPopup.destroy();

    }

    onPageInit(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }

      self.autocompleteStaffPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-leadstaff-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectleadstaff/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/staff.php?t=${Date.now()}`,
            data: {
              Mode:'GetStaff',
              clientid:self.state.clientdetail.id,
            },
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    leadstafflist:res.recordlist,
                  }
                },()=>{

                  const searchstatuslist  = self.state.leadstafflist;

                  for (let i = 0; i < searchstatuslist.length; i += 1) {
                    if (searchstatuslist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchstatuslist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*$('#autocomplete-leadstatus-popup').find('.item-after').text(value[0].name);*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            self.setState({
                staffid:id,
                staffname:name
            });

          },
        },
      });
    }

    saveDetail(campaignleads){
      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }
      let errmsg    = "";
      
      app.input.validateInputs('.adddata');

      if((this.state.staffid === "" || this.state.staffid === undefined) && errmsg === "" || this.state.staffid < 1)
      {
        errmsg  = "Please select a staff";
      }

      if(this.state.staffid === "" || this.state.staffid === undefined || this.state.staffid < 1)
      {
          this.$$('.autocomplete-leadstaff-popup').addClass('error');
      }
      else
      {
          this.$$('.autocomplete-leadstaff-popup').removeClass('error');
      }
     
      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

        let bodyFormData  = "";

        bodyFormData = {
            Mode:'AssignStaff',
            clientid:self.state.clientdetail.id,
            staffid:self.state.staffid,
            campaignleads:campaignleads
        };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/staff.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();
  
            if(resdata.success === true)
            {
              this.setState({issuccess:true});
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){

      const {issuccess, staffname, staffid, staffid_org} = this.state;
 
      return(

        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <CampaignConsumer>{(value)=>{

                const{getAllLead}    = value;

                const{campaignleads} = value;

                if(issuccess)
                {
                  Promise.all([
                    setTimeout(()=> {

                      this.setState(()=>{

                        return{
                          issuccess:false
                        }
                        
                      },()=>{

                        getAllLead();

                      })

                    }, 500)
                    
                  ])
                  .then(() => {
      
                    setTimeout(()=> {

                      this.$f7.popup.close();
          
                    }, 1200)
                    
                  })
                }

                  return(
                    <Popup className="demo-popup">
                      <Navbar sliding={true}>
                          <NavTitle>Assign Staff</NavTitle>
                          <NavRight>
                            <Link className="popup-close-custom" onClick={()=>{
                              if((staffid !== staffid_org))
                              {
                                if(internetstatus === 'online')
                                {
                                  this.$f7.actions.create({
                                    buttons: [
                                    // First group
                                    [
                                      {
                                        text: 'Select an action to proceed',
                                        label: true
                                      },
                                      {
                                        text: 'Save',
                                        bold: true,
                                        color:'blue',
                                        onClick:()=>{
                                          this.saveDetail(campaignleads);
                                        }
                                      },
                                      {
                                        text: `Don't Save`,
                                        bold: true,
                                        onClick:()=>{
                                          this.$f7.popup.close();
                                        }
                                      }
                                    ],
                                    // Second group
                                    [
                                      {
                                        text: 'Cancel',
                                        color: 'red'
                                      }
                                    ]
                                    ]
                                  }).open();

                                };
                              }
                              else
                              {
                                this.$f7.popup.close();
                              }
                            }}>Close</Link>
                          </NavRight>
                      </Navbar>
                        <Page className="page" name="AssignStaff">
                            <Block>
                                <List noHairlines style={{marginTop:'5px',marginBottom:'5px'}}>
                                    <ListItem link="#" id="autocomplete-leadstaff-popup" className="autocomplete-leadstaff-popup" title="Staff" after={staffname}></ListItem>
                                </List>
                            </Block>
                            <br />
                            <Fab position="center-bottom" slot="fixed" text="Save Now" onClick={()=>{
                                if(internetstatus === 'online')
                                {
                                    this.saveDetail(campaignleads);
                                };
                            }}>
                            <Icon f7="floppy_disk"></Icon>
                            </Fab>
                        </Page>
                    </Popup>
                  );
              }}
              </CampaignConsumer>            
          );
        }}
        </CommonConsumer>        

      );
    }
}