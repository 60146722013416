import React,{Component, Fragment} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import { CampaignConsumer } from '../context/CampaignContext';

import {
    Page,
    PageContent,
    List,
    Row,
    Col,
    Icon,
    Link,
    Block,
    ListItem,
    Fab,
    SkeletonBlock,
  } from 'framework7-react';

  export default class LeadDashboard extends Component {

    constructor(props) {
        super(props);
    
        this.state = {

          issummaryloading:false

        };
    }

    componentDidMount(){
      setTimeout(()=> {
    
        this.Dom7('.campaigndashboardlink').addClass('animate-bottom');
  
      }, 100);
    }

    render(){

      return(
        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <CampaignConsumer>{(value)=>{

                const{getLeadSummary} = value;

                const{isavailablesummary_lead, issummaryloaded_lead, newinterestedlead, campaignlistbystatus, alllead, allinterestedlead, allopeninterests} = value;

                  setTimeout(() => {
                    if(this.state.issummaryloading === false)
                    {
                      this.setState(()=>{
                        return{
                          issummaryloading:true
                        }
                      },()=>{

                        getLeadSummary();

                      })
                      
                    }
                  }, 100);

                  return(
                      <Page name="LeadDashboard" className="ContactDashboard page dashboardpage" pageContent={false}>
                        <AppNavbar title="Lead Dashboard" showrefresh={true} init={getLeadSummary}/>
                        <PageContent>
                          {
                            issummaryloaded_lead ? (
                              <Fragment>
                                <br /><br />
                                <Row>
                                    <Col>
                                        <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5 leaddashboardelev" onClick={()=>{
                                            if(internetstatus === "online")
                                            {
                                                navigateToLink(`leads/1/New Interested`);
                                            }
                                        }} style={{color:'#2196f3'}}>
                                            <div style={{fontSize:'3rem',fontWeight:'bold',paddingTop:'8px'}}>{newinterestedlead}</div>
                                            <div style={{fontSize:'1.2rem',marginTop:'8px'}}>New Interested</div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5 leaddashboardelev" onClick={()=>{
                                            if(internetstatus === "online")
                                            {
                                                navigateToLink(`leads/2/All Lead`);
                                            }
                                        }} style={{color:'#2196f3'}}>
                                            <div style={{fontSize:'3rem',fontWeight:'bold',paddingTop:'8px'}}>{alllead}</div>
                                            <div style={{fontSize:'1.2rem',marginTop:'8px'}}>All Lead</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5 leaddashboardelev" onClick={()=>{
                                            if(internetstatus === "online")
                                            {
                                                navigateToLink(`leads/3/All Interested`);
                                            }
                                        }} style={{color:'#2196f3'}}>
                                            <div style={{fontSize:'3rem',fontWeight:'bold',paddingTop:'8px'}}>{allinterestedlead}</div>
                                            <div style={{fontSize:'1.2rem',marginTop:'8px'}}>All Interested</div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5 leaddashboardelev" onClick={()=>{
                                            if(internetstatus === "online")
                                            {
                                                navigateToLink(`leads/4/All Open Interests`);
                                            }
                                        }} style={{color:'#2196f3'}}>
                                            <div style={{fontSize:'3rem',fontWeight:'bold',paddingTop:'8px'}}>{allopeninterests}</div>
                                            <div style={{fontSize:'1.2rem',marginTop:'8px'}}>All Open Interests</div>
                                        </div>
                                    </Col>
                                </Row>
                              </Fragment>
                            ):(
                              <Fragment>
                                <List noHairlinesMd className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                                    <ListItem title="Title Title Title Title Title Title Title Title Title"></ListItem>
                                </List>
                                <Row>
                                    <Col style={{width:'100%'}}>
                                      <div className={`elevation-demo elevation-hover-5 elevation-pressed-5`} style={{padding:'0'}}>
                                        <SkeletonBlock style={{ width: '100%', height: '95px', margin:'auto', borderRadius:'10px'}} slot="media" className={`skeleton-effect-${this.state.effect}`}/>
                                        </div>
                                    </Col>
                                </Row>
                                <List mediaList className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                </List>
                              </Fragment>
                            )
                          }
                      <br /><br />
                      </PageContent>
                      </Page>
                  );

              }}
              </CampaignConsumer>            
          );
        }}
        </CommonConsumer>
      );
    }
}