import React,{Component} from 'react';
import { CustomerConsumer, CustomerContext } from '../context/CustomerContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    LoginScreen,
    LoginScreenTitle,
    ListInput,
    BlockFooter,
    List,
    ListItem,
    ListButton,
    Button,
    Popup,
    Navbar,
    NavRight,
    NavLeft,
    NavTitle,
    Link,
    Block,
    Row,
    Col,
    View
  } from 'framework7-react';

  export default class ForgetPassword extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        phone: '',
        password: '',
        forgetusername:'',
        isloaded:false,
        logintype:1,
        passwordrecoverytype:0,
        internetstatus:navigator.onLine ? "online" : "offline",
      };

      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.handleUserInput    = this.handleUserInput.bind(this);

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.hideSplashScreen();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    sendPasswordRecoverMessage(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(this.state.internetstatus === "offline")
        {
             const toastWithButton = app.toast.create({
                 text: "No internet connection",
             });
               
             toastWithButton.open();
             
             return;
        }

        if(self.state.forgetusername === '')
        {
            const toastWithButton = app.toast.create({
                text: `Please enter your user name to get password`,
                closeButton: true,
                closeTimeout: 3000,
            });
              
            toastWithButton.open();

            app.input.validateInputs('.forgetpassuser');
            
            return;
        }
        else
        {
           const bodyFormData = {
              Mode:'RecoverPassword',
              forgetusername:self.state.forgetusername,
              recoverytype:self.state.logintype,
           };

            let serverurl = "/";

            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

           app.request.promise.post(`${serverurl}api/recoverpassword.php?t=${Date.now()}`,bodyFormData)
            .then(function (res) {

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                // Create notification with close button
                let notificationWithButton = app.notification.create({
                    icon: '',
                    title: 'OrloPay',
                    subtitle: 'Success',
                    text: resdata.msg,
                    closeButton: true,
                });

                notificationWithButton.open();

                router.back();
              }
              else
              {
                // Create notification with close button
                let notificationWithButton = app.notification.create({
                    icon: '',
                    title: 'OrloPay',
                    subtitle: 'Error',
                    text: resdata.msg,
                    closeButton: true,
                });

                notificationWithButton.open();
              }

            }).catch(e => {
              console.log(e);
          });
        }
    }

    render(){

        return(
            <Popup className="demo-popup">
                <Navbar sliding={true}>
                <NavLeft>
                    <Link popupClose>
                    <i className="icon icon-back"></i>                      
                    </Link>
                    <img className="navbarlogo" src={scanlogo} alt="logo" />
                </NavLeft>
                <NavTitle>Forget Password</NavTitle>
                </Navbar>
                <Page loginScreen>
                    <Row>
                        <Col>
                            <img className="loginlogo" src={scanlogo} alt="logo" />
                        </Col>
                    </Row>
                    <LoginScreenTitle>Password Recovery</LoginScreenTitle>
                    <Row noGap>
                        <Col width="10"></Col>
                        <Col width="80">
                            <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                <ListInput
                                    name="logintype"
                                    label="Login Type"
                                    type="select"
                                    onChange={this.handleUserInput}
                                    value={this.state.logintype}
                                    placeholder="Please choose..."
                                    required validate
                                    >
                                    <option value="1">Owner</option>
                                    <option value="0">Staff</option>
                                </ListInput>
                            </List>
                        </Col>
                        <Col width="10"></Col>
                    </Row>
                    <Row noGap>
                        <Col width="10"></Col>
                        <Col width="80">
                            <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                                <ListInput
                                className="forgetpassuser"
                                label="User Name"
                                floatingLabel
                                type="text"
                                placeholder="User Name"
                                value={this.state.forgetusername}
                                onInput={(e) => {
                                    this.setState({ forgetusername: e.target.value});
                                }}
                                required validate
                                />
                            </List>
                            <List noHairlinesMd>
                                <Row>
                                    <Col width="20"></Col>
                                    <Col width="60"><Button fill color="blue" onClick={this.sendPasswordRecoverMessage.bind(this)}>Recover Password</Button></Col>
                                    <Col width="20"></Col>
                                </Row>
                            </List>
                        </Col>
                        <Col width="10"></Col>
                    </Row>
                </Page>
            </Popup>
        );
    }
}