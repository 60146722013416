import React,{Component, Fragment} from 'react';
import AppNavbar from './appnavbar';

import {
    Page,
    Fab,
    Icon,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    List,
    ListItem,
    Block,
    Row,
    Col,
    ListInput,
    Button,
    Toolbar,
    Toggle,
    Link
  } from 'framework7-react';

  export default class Settings extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        profiledetail:[],
        isloaded:false,
        internetstatus:navigator.onLine ? "online" : "offline",
        clientname:'',
        setbirthdaywish:false,
        message:'',
        messagecharcount:0,
        smscredit:0,
      };

      this.getConfigurationDetail = this.getConfigurationDetail.bind(this);
      this.handleUserInput        = this.handleUserInput.bind(this);
      this.updateOnlineStatus     = this.updateOnlineStatus.bind(this);
      this.countMessageChars      = this.countMessageChars.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.getConfigurationDetail();
        this.hideSplashScreen();

      })

      const app = this.$f7;
      const $$  = this.Dom7;
      const ptrContent = $$('.ptr-content');

      // Add 'refresh' listener on it
      ptrContent.on('ptr:refresh', (e)=> {
        // Emulate 2s loading

        setTimeout(()=> {
      
          this.getConfigurationDetail();
      
          app.ptr.done(); // or e.detail();
        }, 2000);

      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'setbirthdaywish')
      {
        this.setState({setbirthdaywish:!this.state.setbirthdaywish});
      }
      else
      {
        this.setState(()=>{

            return{
              [name]: value
            }

          },()=>{
            this.countMessageChars();
          });
      }
    }

    countMessageChars(){

        let messagecharcount = this.state.message.length;
        let smscredit = 0;
        if(this.state.istrackable < 1)
        {
            smscredit        = Math.ceil((messagecharcount / 160));
        }
        else
        {
            smscredit        = Math.ceil((messagecharcount / 140));
        }
         this.setState(()=>{
             return{
              messagecharcount : messagecharcount,  
              smscredit : smscredit  
             }
         });
    }

    check_email(elementValue){
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(elementValue);
    }

    getConfigurationDetail(){

      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      const bodyFormData = {
          Mode:'GetConfigurationDetail',
          recordid:self.state.clientdetail.id,
      };

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
        serverurl = "http://orloprachar/app/";
      }

      const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${serverurl}api/configuration.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
            this.setState({
              setbirthdaywish:resdata.detail.setbirthdaywish,
              message:resdata.detail.message,
              isloaded:true,
            },()=>{
              this.countMessageChars();
            });
          }

      }).catch(e => {
          console.log(e);
      });
    }

    saveSettingDetail(){
      
      const self      = this;
      const app       = self.$f7;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      const bodyFormData = {
          Mode:'SaveConfigurationDetail',
          recordid:self.state.clientdetail.id,
          setbirthdaywish:Number(self.state.setbirthdaywish),
          message:self.state.message,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/configuration.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
            const resdata = JSON.parse(res.data);

            const toastWithButton2 = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton2.open();

            if(resdata.success === true)
            {
              /*app.dialog.alert(resdata.msg);*/
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){

        const{setbirthdaywish, message, messagecharcount, smscredit} = this.state;
       
      return(
            <Page className="page" name="Settings" pageContent={false}>
              <AppNavbar title="Configuration"/>
                <PageContent>
                  <Block>
                  <List noHairlinesMd>
                      <ListItem title="Send Birthday Wishes">
                        <Toggle slot="after" value="1" name="setbirthdaywish" onChange={this.handleUserInput} checked={Number(setbirthdaywish) === 1}></Toggle>
                      </ListItem>
                  </List>
                  {
                    Number(setbirthdaywish) ? (
                      <Fragment>
                        <List noHairlines style={{marginTop:'5px',marginBottom:'5px'}}>
                            <ListInput
                                name="message"
                                inputId="messagearea"
                                className="adddata resizable"
                                label="Message"
                                type="textarea"
                                resizable
                                placeholder="Message"
                                onInput={this.handleUserInput}
                                value={message}
                                multiple={true}
                                clearButton required validate
                            >
                            </ListInput>
                        </List>
                        <Row>
                            <Col style={{textAlign:'right',fontWeight:'bold'}}>
                                Char Count : <span style={{color:'red'}}>{messagecharcount.toString()}</span> , Sms Credit : <span style={{color:'red'}}>{smscredit.toString()}</span>
                            </Col>
                        </Row>
                      </Fragment>
                    ):null
                  }
                  <br />
                  </Block>
              </PageContent>
              <Toolbar position="bottom">
                  <Row style={{width:'100%'}}>
                      <Col width="60"></Col>
                      <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                      onClick={()=>{
                      if(this.state.internetstatus === "online")
                      {
                          this.saveSettingDetail();
                      }
                      }}
                      ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                  </Row>
              </Toolbar>
            </Page>
      );
    }
}