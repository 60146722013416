import React,{Component} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import { CampaignConsumer } from '../context/CampaignContext';

import {
    Page,
    Block,
    Row,
    Col,
    Button,
  } from 'framework7-react';

  export default class AddCampaign extends Component {
    constructor(props) {
      super(props);

      this.state = {
        datatype:'list',
        internetstatus:navigator.onLine ? "online" : "offline",
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      this.setState(()=>{
        return{
          clientdetail:clientdetail,
        }
      },()=>{

          this.hideSplashScreen();
          
      });
  }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      
        this.setState(()=>{
            return{
                [name]: value
            }
        });

    }


    render(){

        const{datatype} = this.state;
        
        return(
            <CommonConsumer>{(value)=>{

                const{internetstatus} = value;
                const{navigateToLink} = value;

                return(
                    <CampaignConsumer>{(value)=>{

                        return(
                            <Page className="page" name="AddCampaign">
                                <AppNavbar title="Add Campaign"/>
                                <Block>
                                <br /><br /><br /><br />
                                <p style={{textAlign:'center',fontSize:'16px'}}><b>Data Selection</b></p>
                                    <Row noGap className="listtype">
                                        <Col width="25"></Col>
                                        <Col width="50">
                                        <Button fill color="blue" style={{marginRight:'.4rem'}}
                                        onClick={()=>{
                                            if(internetstatus === "online")
                                            {
                                                navigateToLink(`addcampaign/list`);
                                            }
                                        }}
                                        >List Selection</Button>
                                        </Col>
                                        <Col width="25">
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row noGap className="listtype">
                                        <Col width="25">
                                        </Col>
                                        <Col width="50">
                                        <Button fill color="blue" style={{marginRight:'.4rem'}}
                                        onClick={()=>{
                                            if(internetstatus === "online")
                                            {
                                                navigateToLink(`addcampaign/input`);
                                            }
                                        }}
                                        >Text Input</Button>
                                        </Col>
                                        <Col width="25">
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row noGap className="listtype">
                                        <Col width="25">
                                        </Col>
                                        <Col width="50">
                                        <Button fill color="blue" style={{marginRight:'.4rem'}}
                                        onClick={()=>{
                                            if(internetstatus === "online")
                                            {
                                                navigateToLink(`addcampaign/excel`);
                                            }
                                        }}
                                        >Excel Upload</Button>
                                        </Col>
                                        <Col width="25">
                                        </Col>
                                    </Row>
                                </Block>
                            </Page>
                        );
                    }}
                    </CampaignConsumer>            
                );
            }}
            </CommonConsumer>
        )
    }
}