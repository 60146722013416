import React,{Component} from 'react';
import Chart from 'chart.js';

const CampaignContext = React.createContext();
//Provider
//Consumer

class CampaignProvider extends Component{

	constructor(props){
		super(props);

		  this.state = {
			clientdetail:[],
			loaded:false,
			isavailable:true,
			campaigns:[],
			deleterecordid:'',
			toolbarPosition:'bottom',
			page:1,
			perpage:'',
			totalpages:'',
			showpages:false,
			paginglist:[],
			isloaded:false,
			totalrecord:'',
			effect:'blink',
			hasfilter:true,
			hasallrecord:true,
			applyfilter:false,
			statusid:'',
			statusname:'',
			iscampaignloading:false,
			internetstatus:navigator.onLine ? "online" : "offline",
			campid:"",
			campname:"",
			phonenumber:"",
			filterstaffid:"",
			staff:"",
			leadcategoryid:"",
			leadcategory:"",
			statename:"",
			city:"",
			pincode:"",
			isinterested:-1,
			filterisinteresedtext:'',
			filterisinteresedabb:'',
			isreferred:0,
			loadedlead:false,
			isavailablelead:true,
			hasfilterlead:true,
			hasallrecordlead:true,
			campaignleads:[],
			totalleads:0,
			totalinterested:0,
			totalreferred:0,
			graphlabels:[],
			graphdata:[],
			bgcolordata:[],
			leadstatusids:"",
			leadstatusnames:"Call Pending, Call Back Requested, Cold Lead, Hot Lead, Moved to CRM",
			leadtagids:"",
			leadtagnames:"",        
			issummaryloaded:false,
			isavailablesummary:false,
			totalschedulecampaign:0,
			campaignlistbystatus:[],
			hasinprocesscampaign:false,
			totalinprocesscampaign:0,
			isinsufficientcredit:0,
			inprocessmsg:"",
			hascredit:false,
			credits:0,
			clientname:'',
			clientphone:'',
			selectedleadcommonfilter:[],
			page_lead:1,
			perpage_lead:'',
			totalpages_lead:'',
			showpages_lead:false,
			paginglist_lead:[],
			recordrange_lead:'',
			isallselected:false,
			allselectedtext:'Select All',
			fourcelogout:false,
			issummaryloaded_lead:false,
			isavailablesummary_lead:false,
			loaded_lead:false,
			newinterestedlead:0,
			alllead:0,
			allinterestedlead:0,
			allopeninterests:0,
			groupid_lead:'',
			groupname_lead:'',
			groupid:'',
			groupname:'',
			leadtype:'',
			leadtypename:'',
			adminid:'',
		  };

		this.handleUserInput			  = this.handleUserInput.bind(this);
		this.getAllCampaign			      = this.getAllCampaign.bind(this);
		this.updateOnlineStatus		      = this.updateOnlineStatus.bind(this);
		this.deleteRecordAction		      = this.deleteRecordAction.bind(this);
		this.manageCampaignAction		  = this.manageCampaignAction.bind(this);
		this.Paging						  = this.Paging.bind(this);
		this.openFilterPopup			  = this.openFilterPopup.bind(this);
		this.deleteSingleRecord		      = this.deleteSingleRecord.bind(this);
		this.pauseResumeCampaign		  = this.pauseResumeCampaign.bind(this);
		this.init		                  = this.init.bind(this);
		this.getAllLead                 = this.getAllLead.bind(this);
		this.setCampaignLeadFilterData  = this.setCampaignLeadFilterData.bind(this);
		this.deleteFilterActionLead		  = this.deleteFilterActionLead.bind(this);
		this.getCampaignSummary		      = this.getCampaignSummary.bind(this);
		this.setCampaignFilterData		  = this.setCampaignFilterData.bind(this);
		this.deleteFilterActionCampaign = this.deleteFilterActionCampaign.bind(this);
		this.archiveCampaign		        = this.archiveCampaign.bind(this);
		this.getSMSCredit               = this.getSMSCredit.bind(this);
		this.handleUserInputLead        = this.handleUserInputLead.bind(this);
		this.PagingLead					        = this.PagingLead.bind(this);
		this.handleLeadInput					  = this.handleLeadInput.bind(this);
		this.openStaffAssignPopup			  = this.openStaffAssignPopup.bind(this);
		this.selectDeselectAllLead			= this.selectDeselectAllLead.bind(this);
		this.manageLeadRemark			      = this.manageLeadRemark.bind(this);
		this.manageLeadCall			        = this.manageLeadCall.bind(this);
		this.clearLeadFilter			      = this.clearLeadFilter.bind(this);
		this.clearCampaignFilter			  = this.clearCampaignFilter.bind(this);
		this.getLeadSummary		          = this.getLeadSummary.bind(this);
		this.getLeadByDashboard         = this.getLeadByDashboard.bind(this);
	}

  componentDidMount(){

      this.hideSplashScreen();
  }

  hideSplashScreen(){

    document.getElementById("splash-screen").style.visibility = "hidden";
    
  }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    deleteSingleRecord(){

      const self	= this;
      const app	= self.$f7;

        const bodyFormData = {
            Mode:'DeleteCampaign',
            clientid:this.state.clientdetail.id,
            recordid:this.state.deleterecordid,
        };

        app.preloader.show();

        // Simulate Ajax Request
        setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();

              const resdata = JSON.parse(res.data);

              const toastWithButton = app.toast.create({
                text: resdata.msg,
                closeButton: true,
                closeTimeout: 3000,
              });
    
              toastWithButton.open();

              if(resdata.success === true)
              {
                this.setState(
                  ()=>{
                    return {
                      deleterecordid:'',
                    }
                  },()=>{
                    this.getAllCampaign();
                  }
                );
                
              }
    
          }).catch(e => {
              console.log(e);
          });

        }, 500);	
    }

    pauseResumeCampaign(status){

      const self	= this;
      const app	= self.$f7;

      let bodyFormData  = "";

      if(status === 3)
      {
        bodyFormData = {
            Mode:'ResumeCampaign',
            clientid:this.state.clientdetail.id,
            recordid:this.state.deleterecordid,
        };
      }
      else
      {
        bodyFormData = {
            Mode:'PauseCampaign',
            clientid:this.state.clientdetail.id,
            recordid:this.state.deleterecordid,
        };

      }

        app.preloader.show();

        // Simulate Ajax Request
        setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();

              const resdata = JSON.parse(res.data);

              const toastWithButton = app.toast.create({
                text: resdata.msg,
                closeButton: true,
                closeTimeout: 3000,
              });
    
              toastWithButton.open();

              if(resdata.success === true)
              {
                this.setState(
                  ()=>{
                    return {
                      deleterecordid:'',
                    }
                  },()=>{
                    this.getAllCampaign();
                  }
                );
              }
    
          }).catch(e => {
              console.log(e);
          });

        }, 500);	
    }

    deleteRecordAction(recordid){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                this.setState(()=>{
                  return{
                    deleterecordid:recordid
                  }
                },()=>{

                  this.deleteSingleRecord();

                })
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    archiveCampaign(status){

      const self	= this;
      const app	= self.$f7;

      const bodyFormData = {
          Mode:'ArchiveCampaign',
          clientid:this.state.clientdetail.id,
          recordid:this.state.deleterecordid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                  }
                },()=>{
                  this.getAllCampaign();
                }
              );
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);
    }

    manageCampaignAction(recordid, status, completed,totalsent){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

      let pauseresumetext = "Pause";
      let pauseresumeicon = '<i class="icon f7-icons greenecolor">pause_circle_fill</i>';

      if(status === 3)
      {
        pauseresumetext = "Resume";
        pauseresumeicon = '<i class="icon f7-icons redcolor">play_rectangle_fill</i>';
      }

      const appButton     = [{
        text: 'Select an action to proceed',
        label: true,
      }];
      
      if(status !== 2 && Number(totalsent) < 1)
      {
        appButton.push({
            icon: '<i class="icon f7-icons pinkcolor">pencil_circle_fill</i>',
            text: 'Edit',
            bold: true,
            color:'blue',
            onClick:()=>{
              if(this.state.internetstatus === "online")
              {
                if(status !== 3)
                {
                  app.dialog.confirm(`Are you sure? you are going to edit your campaign, hence your campaign will pause and after edit this will resume`,'Edit Campaign Confirmation',() => {
      
                    this.setState(()=>{
                      return{
                        deleterecordid:recordid
                      }
                    },()=>{
                      
                      Promise.all([

                        this.pauseResumeCampaign(1)

                      ]).then(()=>{

                        this.$f7.views.main.router.navigate(`/editcampaign/${recordid}/`);

                      })

                    })
    
                  });
                }
                else
                {
                  this.$f7.views.main.router.navigate(`/editcampaign/${recordid}/`);
                }
              }
            }
        },
        {
          icon: pauseresumeicon,
          text: pauseresumetext,
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                this.setState(()=>{
                  return{
                    deleterecordid:recordid
                  }
                },()=>{

                  this.pauseResumeCampaign(status);

                })
              }
            }
        });
      }

      appButton.push({
          icon: '<i class="icon f7-icons orangecolor">paperplane_fill</i>',
          text: 'Resend',
          bold: true,
          color:'blue',
          onClick:()=>{

            if(this.state.internetstatus === "online")
            {
              /*this.$f7.views.main.router.navigate(`/resendcampaign/${recordid}/`);*/
              this.$f7.views.main.router.navigate(`/editcampaign/${recordid}/resend/`);
            }
          }
        },
        {
          icon: '<i class="icon f7-icons">clock_fill</i>',
          text: 'View History',
          bold: true,
          color:'blue',
          onClick:()=>{

            if(this.state.internetstatus === "online")
            {
              this.$f7.views.main.router.navigate(`/viewlog/${recordid}/`);
            }
          }
      });

      if(status === 2)
      {
        appButton.push({
            icon: '<i class="icon f7-icons archivecolor">archivebox_fill</i>',
            text: 'Archive',
            bold: true,
            color:'blue',
            onClick:()=>{
              if(this.state.internetstatus === "online")
              {
                this.setState(()=>{
                  return{
                    deleterecordid:recordid
                  }
                },()=>{

                  this.archiveCampaign(status);

                })
              }
            }
          });
      }

      app.actions.create({
        grid:true,
        buttons: [
        // First group
        appButton,
          // Second group
          /*[
            {
              text: 'Cancel',
              color: 'red'
            }
          ]*/
        ]
      }).open();
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        this.Paging('select');

      });

    }

    Paging(mode){

      let pageerror = "";

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = this.$f7.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      let temppage    = '';

      const currpage  = this.state.page;

      if(mode === 'previous')
      {
        temppage  = Number(this.state.page) - 1;
      }
      if(mode === 'next')
      {
        temppage  = Number(this.state.page) + 1;
      }
      if(mode === 'select')
      {
        temppage  = Number(this.state.page);
      }

      if(temppage < 1)
      {
        pageerror = "You are on first page";
      }

      if(temppage > Number(this.state.totalpages))
      {
        pageerror = "You are on last page";
      }

      if(pageerror !== "")
      {
           const toastWithButton = this.$f7.toast.create({
               text: pageerror,
               closeButton: true,
               closeTimeout: 3000,
           });
             
           toastWithButton.open();
           
           return;
      }

      if(temppage <= 0)
      {
        temppage  = 1;
      }

      if(temppage >= Number(this.state.totalpages))
      {
        temppage  = Number(this.state.totalpages);
      }

      this.setState(()=>{

        return{
          page:temppage,
          loaded:false
        }

      },()=>{

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          if(mode === 'select')
          {
            this.getAllCampaign();
          }
          else
          {
            if(Number(this.state.page) !== Number(currpage))
            {
              this.getAllCampaign();
            }
          }

        })

      })

    }

    init(statusid, statusname){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      
      if(clientdetail !== undefined && clientdetail !== null)
      {
          this.setState(()=>{
            return{
  
              clientdetail:clientdetail,
              loaded:false,
              isavailable:false,
              applyfilter:false,
              page:1,
              statusid:statusid,
              statusname:statusname,
  
            }
          },()=>{
  
            this.getAllCampaign();
  
          });
      }
    }

    getAllCampaign(){

        if(this.state.internetstatus === "offline")
        {
            const toastWithButton = this.$f7.toast.create({
                text: "No internet connection",
            });
              
            toastWithButton.open();
            
            return;
        }

        if(Number(this.state.clientdetail.id) < 1 || this.state.clientdetail.id === undefined || this.state.iscampaignloading === true)
        {
          return;
        }

        this.setState(()=>{
          return{
            iscampaignloading:true,
            loaded:false
          }
        },()=>{

          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');
    
          }, 50);

          const self      = this;
          const app       = self.$f7;
    
          let bodyFormData = {};

          if(Number(self.state.clientdetail.isstaff) > 0)
          {
            bodyFormData = {
                Mode:'GetAllCampaign',
                clientid:this.state.clientdetail.id,
                page:this.state.page,
                perpage:this.state.perpage,
                searchkeyword:this.state.searchkeyword,
                statusids:this.state.statusid,
                campids:this.state.campid,
                staffid:this.state.clientdetail.staffid,
                groupid:this.state.clientdetail.groupid,
            };
          }
          else
          {
            bodyFormData = {
                Mode:'GetAllCampaign',
                clientid:this.state.clientdetail.id,
                page:this.state.page,
                perpage:this.state.perpage,
                searchkeyword:this.state.searchkeyword,
                statusids:this.state.statusid,
                campids:this.state.campid,
                groupid:this.state.groupid,
            };
          }
    
          // Simulate Ajax Request
          setTimeout(()=> {

            let serverurl = "/";

            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

            const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request
        
                // Hide Preloader
                app.preloader.hide();
                
                // Hide Pull To Refresh
                app.ptr.done();

                const resdata = JSON.parse(res.data);

                if(resdata.success === true)
                {
                  this.setState({
                    loaded:true,
                    isavailable:true,
                    campaigns:resdata.recordlist,
                    perpage:Number(resdata.perpage),
                    totalpages:Number(resdata.totalpages),
                    paginglist:resdata.paginglist,
                    showpages:resdata.showpages,
                    totalrecord:resdata.totalrecord,
                    isptr:false,
                    iscampaignloading:false,
                  });
                }
                else{
                  this.setState({
                    loaded:true,
                    isavailable:false,
                    isptr:false,
                    iscampaignloading:false,
                  });
                }
      
            }).catch(e => {
                console.log(e);
            });
    
          }, 500);

        })

    }

    setCampaignFilterData(campid, campname, statusid, statusname, groupid, groupname){

      if(this.state.internetstatus === "offline")
      {
        const toastWithButton = this.$f7.toast.create({
          text: "No internet connection",
        });
    
        toastWithButton.open();
        
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            campid:campid,
            campname:campname,
            statusid:statusid,
            statusname:statusname,
            groupid:groupid,
            groupname:groupname,
            loaded:false,
            isavailable:false,
            applyfilter:false,
            page:1,
          }
  
        },()=>{

          if((this.state.campname !== "" && this.state.campname !== undefined) || (this.state.groupname !== "" && this.state.groupname !== undefined) || (this.state.statusname !== "" && this.state.statusname !== undefined) > 0)
          {
            this.setState({hasfilter:true,hasallrecord:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallrecord:true});
          }
  
          this.getAllCampaign();
  
        });
      })
    }

    deleteFilterActionCampaign(filtername){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                if(filtername === "campaign")
                {
                  this.setState(()=>{
                    return{
                      campid:'',
                      campname:'',
                      hasfilter:true,
                      hasallrecord:true,
                      isavailable:false,
                      loaded:false,
                    }
                  },()=>{
  
                    this.getAllCampaign();
  
                  })
                }
                else if(filtername === "status")
                {
                  this.setState(()=>{
                    return{
                      statusid:'',
                      statusname:'',
                      hasfilter:true,
                      hasallrecord:true,
                      isavailable:false,
                      loaded:false,
                    }
                  },()=>{
  
                    this.getAllCampaign();
  
                  })
                }
                else if(filtername === "group")
                {
                  this.setState(()=>{
                    return{
                      groupid:'',
                      groupname:'',
                      hasfilter:true,
                      hasallrecord:true,
                      isavailable:false,
                      loaded:false,
                    }
                  },()=>{
  
                    this.getAllCampaign();
  
                  })
                }
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    getAllLead(){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

      let tempcampid          = this.state.campid;
      let tempcampname        = this.state.campname;
      let tempstatusids       = this.state.statusids;
      let templeadstatusnames = this.state.leadstatusnames;
      let temptagids          = this.state.tagids;
      let templeadtagnames    = this.state.leadtagnames;
      let tempselectedleadcommonfilter    = this.state.selectedleadcommonfilter;

      if(JSON.parse(localStorage.getItem('selectedleadcampaign')) !== undefined && JSON.parse(localStorage.getItem('selectedleadcampaign')) !== null)
      {
        tempcampid = JSON.parse(localStorage.getItem('selectedleadcampaign')).join(', ');
      }

      if(JSON.parse(localStorage.getItem('selectedleadcampaignname')) !== undefined && JSON.parse(localStorage.getItem('selectedleadcampaignname')) !== null)
      {
        tempcampname = JSON.parse(localStorage.getItem('selectedleadcampaignname')).join(', ');
      }
      
      if(JSON.parse(localStorage.getItem('selectedleadstatus')) !== undefined && JSON.parse(localStorage.getItem('selectedleadstatus')) !== null)
      {
        tempstatusids = JSON.parse(localStorage.getItem('selectedleadstatus')).join(', ');
      }

      if(JSON.parse(localStorage.getItem('selectedleadstatusname')) !== undefined && JSON.parse(localStorage.getItem('selectedleadstatusname')) !== null)
      {
        templeadstatusnames = JSON.parse(localStorage.getItem('selectedleadstatusname')).join(', ');
      }

      if(JSON.parse(localStorage.getItem('selectedleadtag')) !== undefined && JSON.parse(localStorage.getItem('selectedleadtag')) !== null)
      {
        temptagids = JSON.parse(localStorage.getItem('selectedleadtag')).join(', ');
      }

      if(JSON.parse(localStorage.getItem('selectedleadtagname')) !== undefined && JSON.parse(localStorage.getItem('selectedleadtagname')) !== null)
      {
        templeadtagnames = JSON.parse(localStorage.getItem('selectedleadtagname')).join(', ');
      }

      if(JSON.parse(localStorage.getItem('selectedleadcommonfilter')) !== undefined && JSON.parse(localStorage.getItem('selectedleadcommonfilter')) !== null)
      {
        tempselectedleadcommonfilter = JSON.parse(localStorage.getItem('selectedleadcommonfilter'));
      }

      this.setState(()=>{
        return{
          loadedlead:false,
          isavailablelead:false,
          isallselected:false,
          leadstatusids:tempstatusids,
          campids:tempcampid,
          campname:tempcampname,
          leadstatusnames:templeadstatusnames,
          leadtagids:temptagids,
          leadtagnames:templeadtagnames,
          selectedleadcommonfilter:tempselectedleadcommonfilter,
        }
      },()=>{

        Promise.all([
        
          /*localStorage.getItem('selectedleadcampaign') && this.setState(()=>{
            return{
              campid:JSON.parse(localStorage.getItem('selectedleadcampaign')).join(', ')
            }
          }),
          localStorage.getItem('selectedleadcampaignname') && this.setState(()=>{
            return{
              campname:JSON.parse(localStorage.getItem('selectedleadcampaignname')).join(', ')
            }
          }),
          localStorage.getItem('selectedleadstatus') && this.setState(()=>{
            return{
              statusids:JSON.parse(localStorage.getItem('selectedleadstatus'))
            }
          }),
          localStorage.getItem('selectedleadstatusname') && this.setState(()=>{
            return{
              leadstatusnames:JSON.parse(localStorage.getItem('selectedleadstatusname')).join(', ')
            }
          }),
          localStorage.getItem('selectedleadtag') && this.setState(()=>{
            return{
              tagids:JSON.parse(localStorage.getItem('selectedleadtag'))
            }
          }),
          localStorage.getItem('selectedleadtagname') && this.setState(()=>{
            return{
              leadtagnames:JSON.parse(localStorage.getItem('selectedleadtagname')).join(', ')
            }
          }),
          localStorage.getItem('selectedleadcommonfilter') && this.setState(()=>{
            return{
              selectedleadcommonfilter:JSON.parse(localStorage.getItem('selectedleadcommonfilter'))
            }
          },()=>{
  
            this.setState({
              phonenumber:this.state.selectedleadcommonfilter.phonenumber,
              filterstaffid:this.state.selectedleadcommonfilter.filterstaffid,
              leadcategoryid:this.state.selectedleadcommonfilter.leadcategoryid,
              statename:this.state.selectedleadcommonfilter.statename,
              city:this.state.selectedleadcommonfilter.city,
              pincode:this.state.selectedleadcommonfilter.pincode,
              isinterested:this.state.selectedleadcommonfilter.isinterested,
              isreferred:Number(this.state.selectedleadcommonfilter.isreferred),
              filterisinteresedtext:this.state.selectedleadcommonfilter.isintrestedname,
            });
            
          })*/

          this.setState({
            phonenumber:this.state.selectedleadcommonfilter.phonenumber,
            filterstaffid:this.state.selectedleadcommonfilter.filterstaffid,
            leadcategoryid:this.state.selectedleadcommonfilter.leadcategoryid,
            statename:this.state.selectedleadcommonfilter.statename,
            city:this.state.selectedleadcommonfilter.city,
            pincode:this.state.selectedleadcommonfilter.pincode,
            isinterested:this.state.selectedleadcommonfilter.isinterested,
            isreferred:Number(this.state.selectedleadcommonfilter.isreferred),
            filterisinteresedtext:this.state.selectedleadcommonfilter.isintrestedname
          })
  
        ]).then(()=>{
  
          setTimeout(() => {
  
            const{campname, leadstatusnames, phonenumber, isinterested, isreferred,filterstaffid, leadcategoryid, statename, city, pincode, leadtype}  = this.state;
  
            if((campname !== "" && campname !== undefined) || (leadstatusnames !== "" && leadstatusnames !== undefined) || (phonenumber !== "" && phonenumber !== undefined) || (isinterested !== '' && isinterested !== -1) || Number(isreferred) > 0 || (leadcategoryid !== "" && leadcategoryid !== undefined) || (filterstaffid !== "" && filterstaffid !== undefined) || (statename !== "" && statename !== undefined) || (city !== "" && city !== undefined) || (pincode !== "" && pincode !== undefined) || (leadtype !== "" && leadtype !== undefined))
            {
              this.setState({hasfilterlead:true,hasallrecordlead:false});
            }
            else
            {
              this.setState({hasfilterlead:true,hasallrecordlead:true});
            }
    
            let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
          
            if(clientdetail !== undefined && clientdetail !== null)
            {
                this.setState(()=>{
                  return{
        
                    clientdetail:clientdetail,
        
                  }
                },()=>{
      
                  if(this.state.internetstatus === "offline")
                  {
                      const toastWithButton = this.$f7.toast.create({
                          text: "No internet connection",
                      });
                        
                      toastWithButton.open();
                      
                      return;
                  }
          
                  if(Number(this.state.clientdetail.id) < 1 || this.state.clientdetail.id === undefined)
                  {
                    return;
                  }
              
                  const self      = this;
                  const app       = self.$f7;

                  let bodyFormData  = "";

                  if(Number(self.state.clientdetail.staffid) > 0)
                  {
                    bodyFormData = {
                        Mode:'GetLeads',
                        clientid:this.state.clientdetail.id,
                        campids:this.state.campids,
                        phonenumber:this.state.phonenumber,
                        leadcategoryid:Number(this.state.leadcategoryid),
                        statename:this.state.statename,
                        city:this.state.city,
                        pincode:this.state.pincode,
                        isinterested:this.state.isinterested,
                        isreferred:Number(this.state.isreferred),
                        statusids:this.state.leadstatusids,
                        tagids:this.state.leadtagids,
                        groupid:this.state.groupid_lead,
                        page:this.state.page_lead,
                        perpage:this.state.perpage_lead,
                        staffid:Number(self.state.clientdetail.staffid),
                        leadtype:Number(self.state.leadtype)
                    };
                  }
                  else
                  {
                    bodyFormData = {
                        Mode:'GetLeads',
                        clientid:this.state.clientdetail.id,
                        campids:this.state.campids,
                        phonenumber:this.state.phonenumber,
                        leadcategoryid:Number(this.state.leadcategoryid),
                        statename:this.state.statename,
                        city:this.state.city,
                        pincode:this.state.pincode,
                        filterstaffid:Number(this.state.filterstaffid),
                        isinterested:this.state.isinterested,
                        isreferred:Number(this.state.isreferred),
                        statusids:this.state.leadstatusids,
                        tagids:this.state.leadtagids,
                        groupid:this.state.groupid_lead,
                        page:this.state.page_lead,
                        perpage:this.state.perpage_lead,
                        leadtype:Number(this.state.leadtype)
                    };
                  }
                  
                  // Simulate Ajax Request
                  setTimeout(()=> {
          
                    let serverurl = "/";
          
                    if(process.env.NODE_ENV === "development")
                    {
                        serverurl = "http://orloprachar/app/";
                    }

                    const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
                    app.request.setup({
                      headers:{
                        Authorization: `${accesstoken}`
                      }
                    });
          
                    app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
                    .then((res)=> {
          
                        // We got user data from request
                
                        // Hide Preloader
                        app.preloader.hide();
                        
                        // Hide Pull To Refresh
                        app.ptr.done();
          
                        const resdata = JSON.parse(res.data);
          
                        if(resdata.success === true)
                        {
                          this.setState(()=>{
                            return{
                              loadedlead:true,
                              isavailablelead:true,
                              campaignleads:resdata.recordlist,
                              totalleads:resdata.totalleads,
                              totalinterested:resdata.totalinterested,
                              totalreferred:resdata.totalreferred,
                              graphlabels:resdata.graphlabels,
                              graphdata:resdata.graphdata,
                              bgcolordata:resdata.bgcolordata,
                              perpage_lead:Number(resdata.perpage),
                              totalpages_lead:Number(resdata.totalpages),
                              paginglist_lead:resdata.paginglist,
                              showpages_lead:resdata.showpages,
                              recordrange_lead:resdata.recordrange,
                            }
                          },()=>{
                            
                            const ctx   = $$('#leadChart');
      
                            new Chart(ctx, {
                              type: 'horizontalBar',
                              data: {
                                  labels: this.state.graphlabels,
                                  datasets: [{
                                      label: 'Phone Model',
                                      data: this.state.graphdata,
                                      backgroundColor: this.state.bgcolordata,
                                  }]
                              },
                              options: {
                                "hover": {
                                  "animationDuration": 0
                                },
                                "animation": {
                                  "duration": 1,
                                  "onComplete": function() {
                                    var chartInstance = this.chart,
                                      ctx = chartInstance.ctx;
      
                                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                                    ctx.textAlign = 'center';
                                    ctx.textBaseline = 'bottom';
      
                                    this.data.datasets.forEach(function(dataset, i) {
                                      var meta = chartInstance.controller.getDatasetMeta(i);
                                      meta.data.forEach(function(bar, index) {
                                        var data = dataset.data[index];
                                        ctx.fillText(data, bar._model.x + 3, bar._model.y + 5);
                                      });
                                    });
                                  }
                                },
                                legend: {
                                  "display": false
                                },
                                tooltips: {
                                  "enabled": false
                                },
                                scales: {
                                  xAxes: [{
                                      gridLines: {
                                        display: false
                                      },
                                      ticks: {
                                          beginAtZero:true,
                                      }
                                  }]
                                }
                              }
                            });
      
                          });
                        }
                        else{
                          this.setState({
                            loadedlead:true,
                            isavailablelead:false,
                            campaignleads:[],
                            totalleads:0,
                            totalinterested:0,
                            totalreferred:0,
                            graphlabels:[],
                            graphdata:[],
                            bgcolordata:[],
                          });
                        }
              
                    }).catch(e => {
                        console.log(e);
                    });
            
                  }, 100);            
        
                });
            }
  
          }, 500);
        })

      })

    }

    setCampaignLeadFilterData(campid, campname, phonenumber, isinterested, isreferred, filterisinteresedtext, filterisinteresedabb, leadstatusids, leadstatusnames,leadcategoryid,leadcategory,filterstaffid,staff,statename,city,pincode, leadtagids, leadtagnames, groupid, groupname){

      if(this.state.internetstatus === "offline")
      {
        const toastWithButton = this.$f7.toast.create({
          text: "No internet connection",
        });
    
        toastWithButton.open();
        
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            campid:campid,
            campname:campname,
            phonenumber:phonenumber,
            filterstaffid:filterstaffid,
            staff:staff,
            leadcategoryid:leadcategoryid,
            leadcategory:leadcategory,
            statename:statename,
            city:city,
            pincode:pincode,
            isreferred:Number(isreferred),
            filterisinteresedtext:filterisinteresedtext,
            filterisinteresedabb:filterisinteresedabb,
            leadstatusids:leadstatusids,
            leadstatusnames:leadstatusnames,
            leadtagids:leadtagids,
            leadtagnames:leadtagnames,
            groupid_lead:groupid,
            groupname_lead:groupname,
            loadedlead:false,
            isavailablelead:false,
          }
  
        },()=>{
  
          if((this.state.campname !== "" && this.state.campname !== undefined) || (this.state.leadstatusnames !== "" && this.state.leadstatusnames !== undefined) || (this.state.phonenumber !== "" && this.state.phonenumber !== undefined) || (this.state.filterstaffid !== "" && this.state.filterstaffid !== undefined)  || (this.state.leadcategory !== "" && this.state.leadcategory !== undefined) || (this.state.statename !== "" && this.state.statename !== undefined) || (this.state.city !== "" && this.state.city !== undefined) || (this.state.pincdoe !== "" && this.state.pincode !== undefined) || (this.state.leadstatusnames !== "" && this.state.leadstatusnames !== undefined) || (this.state.groupid_lead !== "" && this.state.groupid_lead !== undefined) || this.state.isinterested !== '' || Number(this.state.isreferred) > 0 )
          {
            this.setState({hasfilterlead:true,hasallrecordlead:false});
          }
          else
          {
            this.setState({hasfilterlead:true,hasallrecordlead:true});
          }
  
          this.getAllLead();
  
        });
      })
    }

    deleteFilterActionLead(filtername){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                if(filtername === "campaign")
                {
                  localStorage.setItem("selectedleadcampaign",JSON.stringify([]));
                  localStorage.setItem("selectedleadcampaignname",JSON.stringify([]));
                  
                  this.setState(()=>{
                    return{
                      campid:'',
                      campname:'',
                      hasfilterlead:true,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
                }
                else if(filtername === "staff")
                {
                  let selectedleadcommonfilter = {staff:'',filterstaffid:'',isinterested:this.state.isinterested,isreferred:Number(this.state.isreferred),filterisinteresedtext:this.state.isintrestedname,phonenumber:this.state.phonenumber,leadcategoryid:this.state.leadcategoryid,leadcategory:this.state.leadcategory,statename:this.state.statename,city:this.state.city,pincode:this.state.pincode};

                  localStorage.setItem("selectedleadcommonfilter",JSON.stringify(selectedleadcommonfilter));
                  
                  this.setState(()=>{
                    return{
                      staff:'',
                      staffname:'',
                      hasfilterlead:true,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
                }
                else if(filtername === "leadcategory")
                {
                  let selectedleadcommonfilter = {staff:this.state.staff,filterstaffid:this.state.filterstaffid,isinterested:this.state.isinterested,isreferred:Number(this.state.isreferred),filterisinteresedtext:this.state.isintrestedname,phonenumber:this.state.phonenumber,leadcategoryid:'',leadcategory:'',statename:this.state.statename,city:this.state.city,pincode:this.state.pincode};

                  localStorage.setItem("selectedleadcommonfilter",JSON.stringify(selectedleadcommonfilter));
                  
                  this.setState(()=>{
                    return{
                      leadcategoryid:'',
                      leadcategory:'',
                      hasfilterlead:true,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
                }
                else if(filtername === "statename")
                {
                  let selectedleadcommonfilter = {staff:this.state.staff,filterstaffid:this.state.filterstaffid,isinterested:this.state.isinterested,isreferred:Number(this.state.isreferred),filterisinteresedtext:this.state.isintrestedname,phonenumber:this.state.phonenumber,leadcategoryid:this.state.leadcategoryid,leadcategory:this.state.leadcategory,statename:'',city:this.state.city,pincode:this.state.pincode};

                  localStorage.setItem("selectedleadcommonfilter",JSON.stringify(selectedleadcommonfilter));
                  
                  this.setState(()=>{
                    return{
                      statename:'',
                      hasfilterlead:true,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
                }
                else if(filtername === "city")
                {
                  let selectedleadcommonfilter = {staff:this.state.staff,filterstaffid:this.state.filterstaffid,isinterested:this.state.isinterested,isreferred:Number(this.state.isreferred),filterisinteresedtext:this.state.isintrestedname,phonenumber:this.state.phonenumber,leadcategoryid:this.state.leadcategoryid,leadcategory:this.state.leadcategory,statename:this.state.statename,city:'',pincode:this.state.pincode};

                  localStorage.setItem("selectedleadcommonfilter",JSON.stringify(selectedleadcommonfilter));
                  
                  this.setState(()=>{
                    return{
                      city:'',
                      hasfilterlead:true,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
                }
                else if(filtername === "pincode")
                {
                  let selectedleadcommonfilter = {staff:this.state.staff,filterstaffid:this.state.filterstaffid,isinterested:this.state.isinterested,isreferred:Number(this.state.isreferred),filterisinteresedtext:this.state.isintrestedname,phonenumber:this.state.phonenumber,leadcategoryid:this.state.leadcategoryid,leadcategory:this.state.leadcategory,statename:this.state.statename,city:this.state.city,pincode:''};

                  localStorage.setItem("selectedleadcommonfilter",JSON.stringify(selectedleadcommonfilter));
                  
                  this.setState(()=>{
                    return{
                      pincode:'',
                      hasfilterlead:true,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
                }
                else if(filtername === "phone")
                {
                  let selectedleadcommonfilter = {phonenumber:'',isinterested:this.state.isinterested,isreferred:Number(this.state.isreferred),filterisinteresedtext:this.state.isintrestedname};

                  localStorage.setItem("selectedleadcommonfilter",JSON.stringify(selectedleadcommonfilter));
                  
                  this.setState(()=>{
                    return{
                      phonenumber:'',
                      hasfilterlead:true,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
                }
                else if(filtername === "interested")
                {
                  let selectedleadcommonfilter = {phonenumber:this.state.phonenumber,isinterested:-1,isreferred:Number(this.state.isreferred),filterisinteresedtext:''};

                  localStorage.setItem("selectedleadcommonfilter",JSON.stringify(selectedleadcommonfilter));

                  this.setState(()=>{
                    return{
                      isinterested:-1,
                      filterisinteresedtext:'',
                      filterisinteresedabb:'',
                      hasfilterlead:true,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
                }
                else if(filtername === "referred")
                {
                  let selectedleadcommonfilter = {phonenumber:this.state.phonenumber,isinterested:this.state.isinterested,isreferred:0,filterisinteresedtext:this.state.filterisinteresedtext};

                  localStorage.setItem("selectedleadcommonfilter",JSON.stringify(selectedleadcommonfilter));

                  this.setState(()=>{
                    return{
                      isreferred:0,
                      hasfilterlead:true,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
                }
                else if(filtername === "leadstatus")
                {
                  localStorage.setItem("selectedleadstatus",JSON.stringify([]));
                  localStorage.setItem("selectedleadstatusname",JSON.stringify([]));

                  this.setState(()=>{
                    return{
                      leadstatusids:'',
                      leadstatusnames:'',
                      hasfilterlead:true,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
                }
                else if(filtername === "leadtag")
                {
                  localStorage.setItem("selectedleadtag",JSON.stringify([]));
                  localStorage.setItem("selectedleadtagname",JSON.stringify([]));

                  this.setState(()=>{
                    return{
                      leadtagids:'',
                      leadtagnames:'',
                      hasfilterlead:true,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
                }
                else if(filtername === "leadgroup")
                {
                  this.setState(()=>{
                    return{
                      groupid_lead:'',
                      groupname_lead:'',
                      hasfilterlead:true,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
                }
                else if(filtername === "dashboardtype")
                {
                  this.setState(()=>{
                    return{
                      leadtype:'',
                      leadtypename:'',
                      hasfilterlead:true,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
                }
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    openFilterPopup(){

      if(this.state.internetstatus === "online")
      {
        this.$f7.views.main.router.navigate('filterpopup/');
      }
    }

    getCampaignSummary(){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      
      if(clientdetail !== undefined && clientdetail !== null)
      {
          this.setState(()=>{
            return{
  
              clientdetail:clientdetail,
              loadedlead:false,
              isavailablelead:false,
  
            }
          },()=>{

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = this.$f7.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
                
                return;
            }
    
            if(Number(this.state.clientdetail.id) < 1 || this.state.clientdetail.id === undefined)
            {
              return;
            }
        
            const self      = this;
            const app       = self.$f7;

            let bodyFormData = {};

            if(Number(self.state.clientdetail.isstaff) > 0)
            {
              bodyFormData = {
                  Mode:'GetCampaignSummary',
                  clientid:self.state.clientdetail.id,
                  staffid:self.state.clientdetail.staffid,
              };
            }
            else
            {
              bodyFormData = {
                  Mode:'GetCampaignSummary',
                  clientid:self.state.clientdetail.id,
              };
            }

            this.setState(()=>{
              return{
                issummaryloaded:false
              }
            },()=>{

                // Simulate Ajax Request
                setTimeout(()=> {
        
                  let serverurl = "/";
        
                  if(process.env.NODE_ENV === "development")
                  {
                      serverurl = "http://orloprachar/app/";
                  }

                  const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
                  app.request.setup({
                    headers:{
                      Authorization: `${accesstoken}`
                    }
                  });
        
                  app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
                  .then((res)=> {
        
                      // We got user data from request
              
                      // Hide Preloader
                      app.preloader.hide();
                      
                      // Hide Pull To Refresh
                      app.ptr.done();
        
                      const resdata = JSON.parse(res.data);
        
                      if(resdata.success === true)
                      {
                        this.setState(()=>{
                          return{
                            issummaryloaded:true,
                            isavailablesummary:true,
                            loaded:true,
                            totalschedulecampaign:resdata.totalcampaign,
                            campaignlistbystatus:resdata.recordlist,
                            campaignlistbystatus:resdata.recordlist,
                            hasinprocesscampaign:resdata.hasinprocesscampaign,
                            totalinprocesscampaign:resdata.totalinprocesscampaign,
                            isinsufficientcredit:resdata.isinsufficientcredit,
                            inprocessmsg:resdata.inprocessmsg,
                          }
                        },()=>{
                          setTimeout(()=> {
        
                            this.Dom7('.campaigndashboardelev').addClass('animate-bottom');
                            this.Dom7('.campaigndashboardlink').addClass('animate-bottom');
                    
                          }, 100);
                        });
                      }
                      else{
                        this.setState({
                          issummaryloaded:true,
                          isavailablesummary:false,
                          totalschedulecampaign:0,
                          campaignlistbystatus:[],
                          hasinprocesscampaign:false,
                          totalinprocesscampaign:0,
                          isinsufficientcredit:0,
                          inprocessmsg:"",
                        });
                      }
            
                  }).catch(e => {
                      console.log(e);
                  });
          
                }, 100);

            })

          });
      }
    }

    getLeadSummary(){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      
      if(clientdetail !== undefined && clientdetail !== null)
      {
          this.setState(()=>{
            return{
  
              clientdetail:clientdetail,
              loadedlead:false,
              isavailablelead:false,
  
            }
          },()=>{

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = this.$f7.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
                
                return;
            }
    
            if(Number(this.state.clientdetail.id) < 1 || this.state.clientdetail.id === undefined)
            {
              return;
            }
        
            const self      = this;
            const app       = self.$f7;

            let bodyFormData = {};

            if(Number(self.state.clientdetail.isstaff) > 0)
            {
              bodyFormData = {
                  Mode:'GetLeadSummary',
                  clientid:self.state.clientdetail.id,
                  staffid:self.state.clientdetail.staffid,
              };
            }
            else
            {
              bodyFormData = {
                  Mode:'GetLeadSummary',
                  clientid:self.state.clientdetail.id,
              };
            }

            this.setState(()=>{
              return{
                issummaryloaded_lead:false
              }
            },()=>{

                // Simulate Ajax Request
                setTimeout(()=> {
        
                  let serverurl = "/";
        
                  if(process.env.NODE_ENV === "development")
                  {
                      serverurl = "http://orloprachar/app/";
                  }

                  const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
                  app.request.setup({
                    headers:{
                      Authorization: `${accesstoken}`
                    }
                  });
        
                  app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
                  .then((res)=> {
        
                      // We got user data from request
              
                      // Hide Preloader
                      app.preloader.hide();
                      
                      // Hide Pull To Refresh
                      app.ptr.done();
        
                      const resdata = JSON.parse(res.data);
        
                      if(resdata.success === true)
                      {
                        this.setState(()=>{
                          return{
                            issummaryloaded_lead:true,
                            isavailablesummary_lead:true,
                            loaded_lead:true,
                            /*newinterestedlead:resdata.newinterestedlead,*/
                            newinterestedlead:resdata.newinterestedlead,
                            alllead:resdata.alllead,
                            allinterestedlead:resdata.allinterestedlead,
                            allopeninterests:resdata.allopeninterests,
                          }
                        },()=>{
                          setTimeout(()=> {
        
                            this.Dom7('.leaddashboardelev').addClass('animate-bottom');
                            this.Dom7('.leaddashboardlink').addClass('animate-bottom');
                    
                          }, 100);
                        });
                      }
                      else{
                        this.setState({
                          issummaryloaded_lead:true,
                          isavailablesummary_lead:false,
                          newinterestedlead:0,
                          alllead:0,
                          allinterestedlead:0,
                          allopeninterests:0,
                          loaded_lead:true
                        });
                      }
            
                  }).catch(e => {
                      console.log(e);
                  });
          
                }, 100);

            })

          });
      }
    }

    getSMSCredit(){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      const accesstoken  = localStorage.getItem('prachar_accesstoken');
	  let adminid       = Number(localStorage.getItem('prachar_adminid'));
      
      if((clientdetail !== undefined && clientdetail !== null) && (accesstoken !== undefined && accesstoken !== null))
      {
          this.setState(()=>{
            return{
  
              clientdetail:clientdetail,
			  adminid:adminid,
            }
          },()=>{

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = this.$f7.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
                
                return;
            }
    
            if(Number(this.state.clientdetail.id) < 1 || this.state.clientdetail.id === undefined)
            {
              return;
            }
        
            const self      = this;
            const app       = self.$f7;

            const bodyFormData = {
                Mode:'GetSMSCredits',
                clientid:this.state.clientdetail.id,
            };
      
            // Simulate Ajax Request
            setTimeout(()=> {
    
              let serverurl = "/";
    
              if(process.env.NODE_ENV === "development")
              {
                  serverurl = "http://orloprachar/app/";
              }

              app.request.setup({
                headers:{
                  Authorization: `${accesstoken}`
                }
              });
    
              app.request.promise.post(`${serverurl}api/smscredit.php?t=${Date.now()}`,bodyFormData)
              .then((res)=> {
    
                  const resdata = JSON.parse(res.data);
    
                  if(resdata.success === true)
                  {
                    this.setState(()=>{
                      return{
                        hascredit:true,
                        credits:resdata.credits,
                        clientname:resdata.clientname,
                        clientphone:resdata.clientphone,
                      }
                    });
                  }
                  else{
                    this.setState({
                      hascredit:true,
                      credits:0,
                    });
                  }
        
              }).catch(e => {
                  console.log(e);
              });
      
            }, 1500);            
  
          });
      }
      else
      {
        this.setState({
          fourcelogout:true
        });
      }
    }

    handleUserInputLead(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        this.PagingLead('select');

      });

    }

    PagingLead(mode){

      let pageerror = "";

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = this.$f7.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      let temppage    = '';

      const currpage  = this.state.page_lead;

      if(mode === 'previous')
      {
        temppage  = Number(this.state.page_lead) - 1;
      }
      if(mode === 'next')
      {
        temppage  = Number(this.state.page_lead) + 1;
      }
      if(mode === 'select')
      {
        temppage  = Number(this.state.page_lead);
      }

      if(temppage < 1)
      {
        pageerror = "You are on first page";
      }

      if(temppage > Number(this.state.totalpages_lead))
      {
        pageerror = "You are on last page";
      }

      if(pageerror !== "")
      {
           const toastWithButton = this.$f7.toast.create({
               text: pageerror,
               closeButton: true,
               closeTimeout: 3000,
           });
             
           toastWithButton.open();
           
           return;
      }

      if(temppage <= 0)
      {
        temppage  = 1;
      }

      if(temppage >= Number(this.state.totalpages_lead))
      {
        temppage  = Number(this.state.totalpages_lead);
      }

      this.setState(()=>{

        return{
          page_lead:temppage,
          loadedlead:false
        }

      },()=>{

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          if(mode === 'select')
          {
            this.getAllLead();
          }
          else
          {
            if(Number(this.state.page_lead) !== Number(currpage))
            {
              this.getAllLead();
            }
          }

        })

      })

    }

    handleLeadInput(leadid){

        let tempcampaignleads       = [...this.state.campaignleads];
        const tempInventoryList2    = tempcampaignleads.find(lead => Number(lead.id) === Number(leadid));

        tempInventoryList2.isassigned  = !tempInventoryList2.isassigned;

        this.setState(()=>{
          
          return{

            campaignleads:tempcampaignleads

          }

        });
    }

    openStaffAssignPopup(){

      const app   = this.$f7;

      let haslead = false;

      this.state.campaignleads.forEach(lead => {

          if(Number(lead.isassigned) === 1)
          {
            haslead  = true;
          }
      });

      if(haslead !== true)
      {
        const toastWithButton = app.toast.create({
          text: 'Please select a lead to assign staff.',
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();

        return false;
      }

      if(this.state.internetstatus === "online")
      {
        this.$f7.views.main.router.navigate('assignstaffpopup/');
      }
    }

    selectDeselectAllLead(){

      this.setState(()=>{
        return{
          isallselected:!this.state.isallselected
        }
      },()=>{

        let tempLeads = [];

        this.state.campaignleads.forEach(lead => {
            
          const singleLead = {...lead};

          singleLead.isassigned  = this.state.isallselected;

          tempLeads = [...tempLeads, singleLead];

        });

        let temptext  = "";

        if(this.state.isallselected === true)
        {
          temptext  = "De-Select All";
        }
        else
        {
          temptext  = "Select All";
        }

        this.setState({
          campaignleads:tempLeads,
          allselectedtext:temptext
        });

      })

    }

    manageLeadRemark(recordid, leadcredit){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

      if(Number(leadcredit) < 1)
      {
        app.actions.create({
          buttons: [
          // First group
          [
            {
              text: 'Are you sure? you want to use your lead credit',
              label: true
            },
            {
            text: 'Yes',
            bold: true,
            color:'blue',
            onClick:()=>{
                if(this.state.internetstatus === "online")
                {
                    const bodyFormData = {
                        Mode:'MarkUseLeadCredit',
                        clientid:this.state.clientdetail.id,
                        recordid:recordid,
                    };

                    app.preloader.show();

                    // Simulate Ajax Request
                    setTimeout(()=> {

                      let serverurl = "/";

                      if(process.env.NODE_ENV === "development")
                      {
                          serverurl = "http://orloprachar/app/";
                      }

                      const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
                      app.request.setup({
                        headers:{
                          Authorization: `${accesstoken}`
                        }
                      });

                      app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
                      .then((res)=> {

                          // We got user data from request
                  
                          // Hide Preloader
                          app.preloader.hide();

                          const resdata = JSON.parse(res.data);

                          if(resdata.success === true)
                          {
                            this.getAllLead();

                            this.$f7.views.main.router.navigate(`remark/${recordid}/`);
                          }
                          else
                          {
                            const toastWithButton = app.toast.create({
                              text: resdata.msg,
                              closeButton: true,
                              closeTimeout: 3000,
                            });
                  
                            toastWithButton.open();
                          }
                
                      }).catch(e => {
                          console.log(e);
                      });

                    }, 500);
                }
              }
            }
          ],
          // Second group
          [
            {
              text: 'No',
              color: 'red'
            }
          ]
          ]
        }).open();
      }
      else
      {
        this.$f7.views.main.router.navigate(`remark/${recordid}/`);
      }
    }

    manageLeadCall(recordid, leadcredit){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

      if(Number(leadcredit) < 1)
      {
        app.actions.create({
          buttons: [
          // First group
          [
            {
              text: 'Are you sure? you want to use your lead credit',
              label: true
            },
            {
            text: 'Yes',
            bold: true,
            color:'blue',
            onClick:()=>{
                if(this.state.internetstatus === "online")
                {
                    const bodyFormData = {
                        Mode:'MarkUseLeadCredit',
                        clientid:this.state.clientdetail.id,
                        recordid:recordid,
                    };

                    app.preloader.show();

                    // Simulate Ajax Request
                    setTimeout(()=> {

                      let serverurl = "/";

                      if(process.env.NODE_ENV === "development")
                      {
                          serverurl = "http://orloprachar/app/";
                      }

                      const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
                      app.request.setup({
                        headers:{
                          Authorization: `${accesstoken}`
                        }
                      });

                      app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
                      .then((res)=> {

                          // We got user data from request
                  
                          // Hide Preloader
                          app.preloader.hide();

                          const resdata = JSON.parse(res.data);

                          if(resdata.success === true)
                          {
                            this.getAllLead();

                            window.open(`tel:${resdata.phonenumber}`,'_system');
                          }
                          else
                          {
                            const toastWithButton = app.toast.create({
                              text: resdata.msg,
                              closeButton: true,
                              closeTimeout: 3000,
                            });
                  
                            toastWithButton.open();
                          }
                
                      }).catch(e => {
                          console.log(e);
                      });

                    }, 500);
                }
              }
            }
          ],
          // Second group
          [
            {
              text: 'No',
              color: 'red'
            }
          ]
          ]
        }).open();
      }
    }

    clearLeadFilter(){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                  localStorage.setItem("selectedleadcampaign",JSON.stringify([]));
                  localStorage.setItem("selectedleadcampaignname",JSON.stringify([]));

                  localStorage.setItem("selectedleadstatus",JSON.stringify([]));
                  localStorage.setItem("selectedleadstatusname",JSON.stringify([]));

                  localStorage.setItem("selectedleadtag",JSON.stringify([]));
                  localStorage.setItem("selectedleadtagname",JSON.stringify([]));

                  let selectedleadcommonfilter = {staff:'',filterstaffid:'',isinterested:-1,isreferred:0,filterisinteresedtext:'',phonenumber:'',leadcategoryid:'',leadcategory:'',statename:'',city:'',pincode:''};

                  localStorage.setItem("selectedleadcommonfilter",JSON.stringify(selectedleadcommonfilter));
                  
                  this.setState(()=>{
                    return{
                      campid:'',
                      campname:'',
                      staff:'',
                      staffname:'',
                      leadcategoryid:'',
                      leadcategory:'',
                      statename:'',
                      city:'',
                      pincode:'',
                      phonenumber:'',
                      isinterested:-1,
                      filterisinteresedtext:'',
                      filterisinteresedabb:'',
                      isreferred:0,
                      leadstatusids:'',
                      leadstatusnames:'',
                      leadtagids:'',
                      leadtagnames:'',
                      groupid_lead:'',
                      groupname_lead:'',
                      /*leadtype:'',
                      leadtypename:'',*/
                      hasfilterlead:false,
                      hasallrecordlead:true,
                      isavailablelead:false,
                      loadedlead:false,
                    }
                  },()=>{
  
                    this.getAllLead();
  
                  })
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    clearCampaignFilter(){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                this.setState(()=>{
                  return{
                    statusid:'',
                    statusname:'',
                    campid:'',
                    campname:'',
                    groupid:'',
                    groupname:'',
                    hasfilter:true,
                    hasallrecord:true,
                    isavailable:false,
                    loaded:false,
                  }
                },()=>{

                  this.getAllCampaign();

                })
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    getLeadByDashboard(leadtype, typename){

      localStorage.setItem("selectedleadcampaign",JSON.stringify([]));
      localStorage.setItem("selectedleadcampaignname",JSON.stringify([]));

      localStorage.setItem("selectedleadstatus",JSON.stringify([]));
      localStorage.setItem("selectedleadstatusname",JSON.stringify([]));

      localStorage.setItem("selectedleadtag",JSON.stringify([]));
      localStorage.setItem("selectedleadtagname",JSON.stringify([]));

      let selectedleadcommonfilter = {staff:'',filterstaffid:'',isinterested:-1,isreferred:0,filterisinteresedtext:'',phonenumber:'',leadcategoryid:'',leadcategory:'',statename:'',city:'',pincode:''};

      localStorage.setItem("selectedleadcommonfilter",JSON.stringify(selectedleadcommonfilter));

      this.setState(()=>{
        return{
          campid:'',
          campname:'',
          staff:'',
          staffname:'',
          leadcategoryid:'',
          leadcategory:'',
          statename:'',
          city:'',
          pincode:'',
          phonenumber:'',
          isinterested:-1,
          filterisinteresedtext:'',
          filterisinteresedabb:'',
          isreferred:0,
          leadstatusids:'',
          leadstatusnames:'',
          leadtagids:'',
          leadtagnames:'',
          groupid_lead:'',
          groupname_lead:'',
          leadtype:leadtype,
          leadtypename:typename,
        }
      },()=>{

        this.getAllLead();

      })
    }

	render(){
		return (
			<CampaignContext.Provider value={{
			  ...this.state,
				init:this.init,
				setCampaignFilterData:this.setCampaignFilterData,
				deleteRecordAction:this.deleteRecordAction,
				deleteFilterActionCampaign:this.deleteFilterActionCampaign,
				manageCampaignAction:this.manageCampaignAction,
				handleUserInput:this.handleUserInput,
				Paging:this.Paging,
				openFilterPopup:this.openFilterPopup,
				getAllCampaign:this.getAllCampaign,
        getAllLead:this.getAllLead,
				setCampaignLeadFilterData:this.setCampaignLeadFilterData,
				deleteFilterActionLead:this.deleteFilterActionLead,
				getCampaignSummary:this.getCampaignSummary,
        getSMSCredit:this.getSMSCredit,
				handleUserInputLead:this.handleUserInputLead,
				PagingLead:this.PagingLead,
				handleLeadInput:this.handleLeadInput,
				openStaffAssignPopup:this.openStaffAssignPopup,
				selectDeselectAllLead:this.selectDeselectAllLead,
				manageLeadRemark:this.manageLeadRemark,
				manageLeadCall:this.manageLeadCall,
				clearLeadFilter:this.clearLeadFilter,
        clearCampaignFilter:this.clearCampaignFilter,
				getLeadSummary:this.getLeadSummary,
				getLeadByDashboard:this.getLeadByDashboard,
			}}
			>
			{this.props.children}
			</CampaignContext.Provider>
		);
	}
}

const CampaignConsumer = CampaignContext.Consumer;

export {CampaignProvider, CampaignConsumer, CampaignContext};