import React,{Component,Fragment} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import logo404 from '../assets/images/orlo404.png';

import {
    Page,
    PageContent,
    BlockTitle,
    Link,
    Block,
    List,
    Row,
    Col,
    ListItem,
    Fab,
    Icon,
    Chip,
    Button,
    Toolbar,
    Tabs,
    Tab,
  } from 'framework7-react';

  export default class BulkAddRequest extends Component {
    constructor(props) {
      super(props);

      this.state = {
        bulkqueuelog:[],
        clientdetail:'',
        isptr:false,
        isloaded:false,
        internetstatus:navigator.onLine ? "online" : "offline",
        ismount:false,
        isdatafetched:false,
        effect:'blink',
        hasdataavailable:false,
        totalpending:0,
        totalcompleted:0,
        haspending:false,
        hascompleted:false,
      };

      this.handleUserInput    = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.getBulkQueueLog    = this.getBulkQueueLog.bind(this);

    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getBulkQueueLog();
        this.hideSplashScreen();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus(){

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getBulkQueueLog(){

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
            Mode:'GetBulkQueueLog',
            clientid:self.state.clientdetail.id,
        };

        this.setState(()=>{

          return{
            isdatafetched:false
          }

        },()=>{

          setTimeout(()=> {
  
            let serverurl = "/";
  
            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

            const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
    
            app.request.promise.post(`${serverurl}api/bulkadd.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {
  
                // Hide Pull To Refresh
                app.ptr.done();
  
                const resdata = JSON.parse(res.data);
      
                if(resdata.success === true)
                {
                    this.setState(()=>{
                        return{
                            bulkqueuelog:resdata.recordlist,
                            totalpending:resdata.totalpending,
                            totalcompleted:resdata.totalcompleted,
                            isptr:false,
                            isdatafetched:true,
                            hasdataavailable:true,
                            haspending:resdata.haspending,
                        }
                    })
                }
                else
                {
                    this.setState(()=>{
                        return{
                            isptr:false,
                            isdatafetched:true,
                            hasdataavailable:false,
                            totalpending:0,
                            totalcompleted:0,
                        }
                    });
                }
      
            }).catch(e => {
                console.log(e);
            });
  
          },500);

        });
    }

    render(){
        
      const{smsloglist, isdatafetched, hasdataavailable, totalpending, totalcompleted} = this.state;

      return(
        <CommonConsumer>{(value)=>{
          
          const{navigateToLink, internetstatus} = value;

          return(
            <Page className="page" name="BulkRequest" pageContent={false}>
              <AppNavbar title="Bulk Add Request" showrefresh={false} init={this.getBulkQueueLog}/>
              <Toolbar tabbar bottom>
                <Link tabLink href="./" routeTabId="pendingrequest">Pending</Link>
                <Link tabLink href="completedrequest/" routeTabId="completedrequest">Completed</Link>
              </Toolbar>
              <Tabs routable>
                <Tab id="pendingrequest" className="page-content" tabActive></Tab>
                <Tab id="completedrequest" className="page-content"></Tab>
              </Tabs>
            </Page>
          );
        }}</CommonConsumer>
      );
    }
}