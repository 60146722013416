import React,{Component,Fragment} from 'react';
import { CampaignConsumer } from '../context/CampaignContext';
import scanlogo from '../assets/images/scanlogo.png';
import {
  Page,
  LoginScreenTitle,
  ListInput,
  BlockFooter,
  List,
  ListButton,
  Button,
  Row,
  Col
} from 'framework7-react';

export default class extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        username: '',
        password: '',
        isloaded:false,
        logintype:0,
        passwordrecoverytype:0,
        internetstatus:navigator.onLine ? "online" : "offline",
        issuccess:false,
        resdata:[],
      };

      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.signIn             = this.signIn.bind(this);
      this.handleUserInput    = this.handleUserInput.bind(this);
    }

    componentDidMount(){

        this.hideSplashScreen();

        this.updateOnlineStatus();
        
        window.addEventListener('online',  this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);

    }
    
	updateOnlineStatus() {

        if(this.state.isloaded  === true)
        {
            let intstatus	= "offline";

            intstatus		= navigator.onLine ? "online" : "offline";
    
            this.setState(()=>{
                return{
                    internetstatus: intstatus,
                };
            },()=>{
    
                const self      = this;
                const app       = self.$f7;
    
                if(this.state.internetstatus === "offline")
                {
                    const toastWithButton = app.toast.create({
                        text: "No internet connection",
                    });
                      
                    toastWithButton.open();
                }
                else
                {
                    const toastWithButton = app.toast.create({
                        text: "Internet connected",
                        closeButton: true,
                        closeTimeout: 3000,
                    });
                      
                    toastWithButton.open();
                }
            });
        }
        else
        {
            this.setState({
                isloaded:true
            });
        }
	}

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

        this.setState(()=>{
            return{
                [name]: value
            }
        });
    }

    signIn(){
        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(this.state.internetstatus === "offline")
        {
             const toastWithButton = app.toast.create({
                 text: "No internet connection",
             });
               
             toastWithButton.open();
             
             return;
        }

        let errmsg  = "";

        if(self.state.username === '' && self.state.password === '')
        {
            errmsg  = "User name and password can not be blank";
        }

        if(self.state.username === '' && errmsg == "")
        {
            errmsg  = "User name can't be blank";
        }

        if(self.state.password === ''  && errmsg == "")
        {
            errmsg  = "Password can't be blank";
        }

        if(errmsg !== "")
        {
            const toastWithButton = app.toast.create({
                text: errmsg,
                closeButton: true,
                closeTimeout: 3000,
            });
              
            toastWithButton.open();

            app.input.validateInputs('.logindata');
        }
        else
        {
            app.preloader.show();

           const bodyFormData = {
              Mode:'AppLogin',
              username:self.state.username,
              logintype:self.state.logintype,
              password:self.state.password
           };

            // Simulate Ajax Request
            setTimeout(()=> {
                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                let serverurl = "/";

                if(process.env.NODE_ENV === "development")
                {
                    serverurl = "http://orloprachar/app/";
                }

                app.request.promise.post(`${serverurl}api/applogin.php?t=${Date.now()}`,bodyFormData)
                .then((res)=> {

                    const resdata = JSON.parse(res.data);

                    if(resdata.success === true)
                    {
                        this.setState({
                            issuccess:true,
                            resdata:resdata
                        },()=>{

                            localStorage.setItem("prachar_accesstoken",resdata.accesstoken);

                            if(resdata.isadminlogin === true)
                            {
                                localStorage.setItem("prachar_isadminlogin",resdata.isadminlogin);
                                localStorage.setItem("prachar_adminid",resdata.adminid);
                                localStorage.setItem("prachar_isadminverified",false);
                            }
                            else
                            {
                                localStorage.setItem("prachar_isadminlogin",false);
                                localStorage.setItem("prachar_adminid","");
                                localStorage.setItem("prachar_isadminverified",false);
                            }

                            localStorage.setItem("prachar_clientdetail",JSON.stringify(resdata.clientdetail));

                        });
                    }
                    else
                    {
                        const toastWithButton = app.toast.create({
                            text: resdata.msg,
                            closeButton: true,
                            closeTimeout: 3000,
                        });
                          
                        toastWithButton.open();            
                    }
                    }).catch(e => {
                    console.log(e);
                });                

            }, 1000);

        }
    }
  
    render() {
        
        const{issuccess, resdata} = this.state;

        return(
            <CampaignConsumer>{(value)=>{

                const{getSMSCredit} = value;
                
                if(issuccess)
                {
                    setTimeout(() => {
                        this.setState(()=>{
                            return{
                                issuccess:false
                            }
                        },()=>{

                            getSMSCredit();

                            const router = this.$f7router;

                            if(resdata.isadminlogin === true)
                            {
                                router.navigate('verifyadmin/');
                            }
                            else
                            {
                                if(resdata.ispasswordupdate === 1)
                                {
                                    router.navigate('/dashboard/');
                                }
                                else
                                {
                                    router.navigate('/changepassword/');
                                }
                            }
                        })
                    }, 500);
                }
                
                return (
                    <Page noToolbar noNavbar noSwipeback loginScreen>
                        <Row>
                            <Col>
                                <img className="loginlogo" src={scanlogo} alt="logo" />
                            </Col>
                        </Row>
                        <LoginScreenTitle>Prachar Login</LoginScreenTitle>
                        <Row noGap>
                            <Col width="10"></Col>
                            <Col width="80">
                                <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListInput
                                        name="logintype"
                                        label="Login Type"
                                        type="select"
                                        onChange={this.handleUserInput}
                                        value={this.state.logintype}
                                        placeholder="Please choose..."
                                        required validate
                                        >
                                        <option value="0">Owner</option>
                                        <option value="1">Staff</option>
                                    </ListInput>
                                </List>
                            </Col>
                            <Col width="10"></Col>
                        </Row>
                        <Row noGap>
                            <Col width="10"></Col>
                            <Col width="80">
                                <List form style={{marginBottom:'5px',marginTop:'5px'}}>
                                <ListInput
                                    className="logindata"
                                    label="User Name"
                                    floatingLabel
                                    type="text"
                                    placeholder="User Name"
                                    value={this.state.username}
                                    onInput={(e) => {
                                        this.setState({ username: e.target.value});
                                    }}
                                    required validate
                                />
                                <ListInput
                                    className="logindata"
                                    label="Password"
                                    floatingLabel
                                    type="password"
                                    placeholder="Your password"
                                    value={this.state.password}
                                    onInput={(e) => {
                                        this.setState({ password: e.target.value});
                                    }}
                                    required validate
                                />
                                </List>
                            </Col>
                            <Col width="10"></Col>
                        </Row>
                        <Row>
                            <Col width="20"></Col>
                            <Col width="60"><Button fill color="blue" onClick={()=>{
                                this.signIn();
                            }}>Sign In</Button></Col>
                            <Col width="20"></Col>
                        </Row>
                        <br />
                        <Row>
                            <Col width="20"></Col>
                            <Col width="60">
                                <List style={{marginTop:'0px',marginBottom:'0px'}}>
                                    <ListButton
                                        onClick={()=>{
                                            if(this.state.internetstatus === "online")
                                            {
                                                this.$f7router.navigate('forgetpassword/');
                                            }
                                        }}
                                    >Forgot Password?</ListButton>
                                </List>
                            </Col>
                            <Col width="20"></Col>
                        </Row>
                        <Row>
                            <Col width="20"></Col>
                            <Col width="60">
                                <List style={{marginTop:'0px',marginBottom:'0px'}}>
                                    <ListButton
                                        onClick={()=>{
                                            if(this.state.internetstatus === "online")
                                            {
                                                this.$f7router.navigate('/signup/');
                                            }
                                        }}
                                    >Sign Up</ListButton>
                                </List>
                            </Col>
                            <Col width="20"></Col>
                        </Row>
                        <BlockFooter>&copy; 2020 OrloPay.com</BlockFooter>
                    </Page>
                )
            }}</CampaignConsumer>
        )
    }
}