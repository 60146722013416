import React,{Component} from 'react';
import { CommonConsumer } from '../context/CommonContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Icon,
    Block,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Button,
    Toggle,
    Link,
    Toolbar,
  } from 'framework7-react';

  export default class QuickAddCampaignGroup extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        name:'',
        description:'',
        resid:'',
        resname:'',
        status:1,
        canstaffuse:1,
        clientdetail:[],
        isloaded:false,
        issuccess:false,
        frmsuccess:false,
        name_org:'',
        status_org:1,
        description_org:'',
        internetstatus:navigator.onLine ? "online" : "offline",
      };
      this.handleUserInput = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.hideSplashScreen();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else if(name === 'canstaffuse')
      {
        this.setState({canstaffuse:!this.state.canstaffuse});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    saveDetail(){
        const self  = this;
        const app   = self.$f7;
        const $$    = self.Dom7;
  
        if(this.state.internetstatus === "offline")
        {
            const toastWithButton = app.toast.create({
                text: "No internet connection",
            });
            
            toastWithButton.open();
            
            return;
        }
  
        let errmsg    = "";
        
        app.input.validateInputs('.adddata');
  
        if(this.state.name === "")
        {
          errmsg = "Please enter list name";
        }
       
        if(errmsg !== "")
        {
          const toastWithButton = app.toast.create({
            text: errmsg,
            closeButton: true,
            closeTimeout: 3000,
          });
  
          toastWithButton.open();
    
          return false;
        }
  
        let bodyFormData = {};
  
        if(Number(this.state.clientdetail.isstaff) > 0)
        {
          bodyFormData = {
              Mode:'AddCampaignGroup',
              clientid:self.state.clientdetail.id,
              name:self.state.name,
              status:Number(self.state.status),
              canstaffuse:1,
              staffid:this.state.clientdetail.staffid,
              description:self.state.description,
          };
        }
        else
        {
          bodyFormData = {
              Mode:'AddCampaignGroup',
              clientid:self.state.clientdetail.id,
              name:self.state.name,
              status:Number(self.state.status),
              canstaffuse:Number(self.state.canstaffuse),
              staffid:0,
              description:self.state.description,
          };
        }
  
        app.preloader.show();
  
        // Simulate Ajax Request
        setTimeout(()=> {
  
            let serverurl = "/";
  
            if(process.env.NODE_ENV === "development")
            {
              serverurl = "http://orloprachar/app/";
            }
  
            const accesstoken  = localStorage.getItem('prachar_accesstoken');
            
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
  
          app.request.promise.post(`${serverurl}api/campaigngroup.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {
  
              // We got user data from request
  
              // Hide Preloader
              app.preloader.hide();
    
              const resdata = JSON.parse(res.data);
  
              if(resdata.success === true)
              {
                Promise.all([
                  this.setState(()=>{
                    return{
                      name:'',
                      description:'',
                      status:1,
                      canstaffuse:1,
                      issuccess:true,
                      frmsuccess:true,
                      resid:resdata.recordid,
                      resname:resdata.name,
                    }
                  })
                ])
                .then(() => {
    
                  $$('.item-content').removeClass('item-input-with-error-message');
                  $$('.item-content').removeClass('item-input-invalid');
    
                });

              }
              else
              {
                const toastWithButton = app.toast.create({
                  text: resdata.toastmsg,
                  closeButton: true,
                  closeTimeout: 3000,
                });
  
                toastWithButton.open();
              }
    
          }).catch(e => {
              console.log(e);
          });
  
        }, 500);
  
      }

    render(){

      const{resid, resname, frmsuccess, internetstatus, name, status, description, name_org, status_org, description_org} = this.state;

      return(
        <CommonConsumer>{(value)=>{

          const{setCampaignGroupData}  = value;

          if(frmsuccess === true)
          {
            Promise.all([
              setTimeout(()=> {

                setCampaignGroupData(Number(resid), resname, true);
    
              }, 500)
              
            ])
            .then(() => {

              setTimeout(()=> {

                this.$f7.popup.close();

                /*this.$$('.popup-close').trigger('click');*/
    
              }, 1200)
              
            })
          }

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Add Campaign Group</NavTitle>
                      <NavRight>
                        <Link className="popup-close-custom" onClick={()=>{
                          if((name !== name_org) || (status !== status_org) || (description !== description_org))
                          {
                            if(internetstatus === 'online')
                            {
                              this.$f7.actions.create({
                                buttons: [
                                  // First group
                                  [
                                    {
                                      text: 'Select an action to proceed',
                                      label: true
                                    },
                                    {
                                      text: 'Save',
                                      bold: true,
                                      color:'blue',
                                      onClick:()=>{

                                        this.saveDetail();

                                      }
                                    },
                                    {
                                      text: `Don't Save`,
                                      bold: true,
                                      onClick:()=>{

                                        this.$f7.popup.close();

                                      }
                                    }
                                  ],
                                  // Second group
                                  [
                                    {
                                      text: 'Cancel',
                                      color: 'red'
                                    }
                                  ]
                                ]
                              }).open();

                            };
                          }
                          else
                          {
                            this.$f7.popup.close();
                          }
                        }}>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="QuickAddCampaignGroup">
                    <Block>
                        <List noHairlinesMd>
        
                            <ListInput
                                name="name"
                                inputId="name"
                                className="adddata"
                                label="Name"
                                floatingLabel
                                type="text"
                                onInput={this.handleUserInput}
                                placeholder="Name"
                                value={this.state.name}
                                maxlength="200"
                                clearButton required validate
                            >
                            </ListInput>
                        
                            <ListInput
                                name="description"
                                label="Description"
                                floatingLabel
                                type="textarea"
                                onInput={this.handleUserInput}
                                value={this.state.description}
                                resizable
                                placeholder="Description"
                            >
                            </ListInput>
                            <ListItem title="Status">
                                <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                            </ListItem>
                        </List>
                    </Block>
                    <br />
                    <Toolbar position="bottom">
                      <Row style={{width:'100%'}}>
                          <Col width="60"></Col>
                          <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                          onClick={()=>{
                            if(this.state.internetstatus === "online")
                            {
                              this.saveDetail();
                            }
                          }}
                          ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                      </Row>
                    </Toolbar>
                  </Page>
              </Popup>
            );
        }}
        </CommonConsumer>
      );

    }
}