import React,{Component} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import { ListConsumer } from '../context/ListContext';

import {
    Page,
    PageContent,
    Icon,
    Block,
    List,
    ListInput,
    ListItem,
    Toggle,
    Toolbar,
    Row,
    Col,
    Button,
    Link
  } from 'framework7-react';

  export default class AddList extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        name:'',
        description:'',
        status:1,
        deletefailednumbers:1,
        canstaffuse:0,
        clientdetail:[],
        isloaded:false,
        issuccess:false,
        internetstatus:navigator.onLine ? "online" : "offline",
      };
      this.handleUserInput = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.hideSplashScreen();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else if(name === 'canstaffuse')
      {
        this.setState({canstaffuse:!this.state.canstaffuse});
      }
      else if(name === 'deletefailednumbers')
      {
        this.setState({deletefailednumbers:!this.state.deletefailednumbers});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    saveDetail(){
      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      let errmsg    = "";
      
      app.input.validateInputs('.adddata');

      if(this.state.name === "")
      {
        errmsg = "Please enter list name";
      }
     
      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      let bodyFormData = {};

      if(Number(this.state.clientdetail.isstaff) > 0)
      {
        bodyFormData = {
            Mode:'AddList',
            clientid:self.state.clientdetail.id,
            name:self.state.name,
            status:Number(self.state.status),
            canstaffuse:0,
            staffid:this.state.clientdetail.staffid,
            description:self.state.description,
        };
      }
      else
      {
        bodyFormData = {
            Mode:'AddList',
            clientid:self.state.clientdetail.id,
            name:self.state.name,
            status:Number(self.state.status),
            deletefailednumbers:Number(self.state.deletefailednumbers),
            canstaffuse:Number(self.state.canstaffuse),
            staffid:0,
            description:self.state.description,
        };
      }

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
            serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

        app.request.promise.post(`${serverurl}api/list.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            if(resdata.success === true)
            {
              Promise.all([
                this.setState(()=>{
                  return{
                    name:'',
                    description:'',
                    status:1,
                    deletefailednumbers:1,
                    canstaffuse:0,
                    issuccess:true,
                  }
                })
              ])
              .then(() => {
  
                $$('.item-content').removeClass('item-input-with-error-message');
                $$('.item-content').removeClass('item-input-invalid');
  
              });

              this.$f7.views.main.router.navigate(`thanks/addlistsuccess/`);
            }
            else
            {
              const toastWithButton = app.toast.create({
                text: resdata.toastmsg,
                closeButton: true,
                closeTimeout: 3000,
              });

              toastWithButton.open();
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    render(){

      const{issuccess}  = this.state;
       
      return(
        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <ListConsumer>{(value)=>{

                const{init} = value;

                  if(issuccess)
                  {
                      setTimeout(() => {

                          init();

                          this.setState(()=>{
                              return{
                                  issuccess:false
                              }
                          })
                          
                      }, 500);
                  }

                  return(
                      <Page className="page" name="AddList" pageContent={false}>
                        <AppNavbar title="Add List"/>
                        <PageContent>
                          <Block>
                            <List noHairlinesMd>
            
                                <ListInput
                                    name="name"
                                    className="adddata"
                                    label="Name"
                                    floatingLabel
                                    type="text"
                                    onInput={this.handleUserInput}
                                    placeholder="Name"
                                    value={this.state.name}
                                    maxlength="200"
                                    clearButton required validate
                                >
                                </ListInput>
                            
                                <ListInput
                                    name="description"
                                    label="Description"
                                    floatingLabel
                                    type="textarea"
                                    onInput={this.handleUserInput}
                                    value={this.state.description}
                                    resizable
                                    placeholder="Description"
                                >
                                </ListInput>
                                <ListItem title="Status">
                                  <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                                </ListItem>
                                <ListItem title="Delete Failed Numbers?">
                                  <Toggle slot="after" name="deletefailednumbers" checked={Number(this.state.deletefailednumbers) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                                </ListItem>
                                {
                                  this.state.clientdetail && Number(this.state.clientdetail.isstaff) ? null:(
                                    <ListItem title="Available For Staff">
                                      <Toggle slot="after" name="canstaffuse" checked={Number(this.state.canstaffuse) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                                    </ListItem>
                                  )
                                }
                            </List>
                        </Block>
                        </PageContent>
                        <Toolbar position="bottom">
                          <Row style={{width:'100%'}}>
                              <Col width="60"></Col>
                              <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                              onClick={()=>{
                                if(internetstatus === "online")
                                {
                                  this.saveDetail();
                                }
                              }}
                              ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                          </Row>
                        </Toolbar>
                      </Page>
                  );
              }}
              </ListConsumer>            
          );
        }}
        </CommonConsumer>
      );
    }
}