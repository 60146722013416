import React,{Component} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import { StaffConsumer } from '../context/StaffContext';

import {
    Page,
    PageContent,
    Icon,
    Block,
    List,
    ListInput,
    ListItem,
    Toggle,
    Toolbar,
    Row,
    Col,
    Button,
    Link
  } from 'framework7-react';

  export default class AddStaff extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        name:'',
        phone:'',
        password:'',
        permissions:[
                    {"id":"cansmscredits","name":'SMS Credits',"ischecked":false},
                    {"id":"canlist","name":'Lists',"ischecked":false},
                    {"id":"cancontacts","name":'Contacts',"ischecked":false},
                    {"id":"canpages","name":'Pages',"ischecked":false},
                    {"id":"cancampaign","name":'Campaigns',"ischecked":false},
                    {"id":"canbulkadd","name":'Bulk Add',"ischecked":false},
                    {"id":"canleads","name":'Leads',"ischecked":false},
                    {"id":"canleadcategory","name":'Lead Area',"ischecked":false}
                  ],
        status:1,
        isloaded:false,
        issuccess:false,
        internetstatus:navigator.onLine ? "online" : "offline",
      };
      this.handleUserInput = this.handleUserInput.bind(this);
      this.handlePermissionInput = this.handlePermissionInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.hideSplashScreen();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }
    handlePermissionInput(permissionid){

      let tempPermList       = [...this.state.permissions];
      const tempPerm         = tempPermList.find(perm => perm.id === permissionid);

      tempPerm.ischecked   = !tempPerm.ischecked;

      this.setState(()=>{
        
        return{

          permissions: tempPermList

        }

      });
  }
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    saveDetail(){
      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      let errmsg    = "";
      
      app.input.validateInputs('.adddata');
      let ispermissioned = 0;
  
      if(this.state.name === "" && errmsg === "")
      {
        errmsg = "Please enter staff name";
      }
      else if(this.state.phone === "" && errmsg === "")
      {
        errmsg = "Please enter phone";
      }
      else if(this.state.password === "" && errmsg === "")
      {
        errmsg = "Please enter password";
      }
     else 
      { 
        let searchperm =this.state.permissions;
        for (let i = 0; i < searchperm.length; i += 1) {
          if (searchperm[i].ischecked === true)
          {
            ispermissioned = 1;
            break;
          }
        }
      }
      if(ispermissioned < 1)
      {
        errmsg = "Please select atleast one permission";
      }
      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }
      
      const bodyFormData = {
          Mode:'AddStaff',
          clientid:self.state.clientdetail.id,
          name:self.state.name,
          phone:self.state.phone,
          password:self.state.password,
          permissions:self.state.permissions,
          status:Number(self.state.status),
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
          serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/staff.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            if(resdata.success === true)
            {
              Promise.all([
                this.setState(()=>{
                  return{
                    name:'',
                    phone:'',
                    password:'',
                    status:1,
                    issuccess:true,
                  }
                })
              ])
              .then(() => {
  
                $$('.item-content').removeClass('item-input-with-error-message');
                $$('.item-content').removeClass('item-input-invalid');
  
              });

              this.$f7.views.main.router.navigate(`thanks/addstaffsuccess/`);
            }
            else
            {
                const toastWithButton = app.toast.create({
                text: resdata.toastmsg,
                closeButton: true,
                closeTimeout: 3000,
                });

                toastWithButton.open();
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    render(){

      const{issuccess,permissions}  = this.state;
      return(
        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <StaffConsumer>{(value)=>{

                const{init} = value;

                  if(issuccess)
                  {
                      setTimeout(() => {

                          init();

                          this.setState(()=>{
                              return{
                                  issuccess:false
                              }
                          })
                          
                      }, 500);
                  }

                  return(
                      <Page className="page" name="AddStaff" pageContent={false}>
                        <AppNavbar title="Add Staff"/>
                        <PageContent>
                          <Block>
                            <List noHairlinesMd>
                                <ListInput
                                    name="name"
                                    className="adddata"
                                    label="Name"
                                    floatingLabel
                                    type="text"
                                    onInput={this.handleUserInput}
                                    placeholder="Name"
                                    value={this.state.name}
                                    maxlength="200"
                                    clearButton required validate
                                >
                                </ListInput>

                                <ListInput
                                    name="phone"
                                    className="adddata"
                                    label="Phone"
                                    floatingLabel
                                    type="tel"
                                    onInput={this.handleUserInput}
                                    placeholder="Mobile Number"
                                    value={this.state.phone}
                                    maxlength="10"
                                    clearButton required validate pattern="[0-9]*"
                                >
                                </ListInput>

                                <ListInput
                                    name="password"
                                    className="adddata"
                                    label="Password"
                                    floatingLabel
                                    type="password"
                                    onInput={this.handleUserInput}
                                    placeholder="Password"
                                    value={this.state.password}
                                    maxlength="10"
                                    clearButton required validate
                                >
                                </ListInput>
                                </List>
                                <List noHairlines style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>
                                
                                  <Block><b>Permissions</b></Block>
                                  {
                                    permissions.map((perm)=>{
                                        return ( 
                                          <ListItem key={perm.id} checkbox title={perm.name} name="permissionsarr[]" checked={perm.ischecked} onChange={()=>{this.handlePermissionInput(perm.id)}}  id={`perm`+ perm.id}></ListItem>
                                          );
                                      }) 
                                  }
                                <ListItem title="Status">
                                  <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                                </ListItem>
                            </List>
                            <br/>
                            <br/>
                        </Block>
                        </PageContent>
                        <Toolbar position="bottom">
                          <Row style={{width:'100%'}}>
                              <Col width="60"></Col>
                              <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                              onClick={()=>{
                                if(internetstatus === "online")
                                {
                                  this.saveDetail();
                                }
                              }}
                              ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                          </Row>
                        </Toolbar>
                      </Page>
                  );
              }}
              </StaffConsumer>            
          );
        }}
        </CommonConsumer>
      );
    }
}