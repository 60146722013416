import React,{Component,Fragment} from 'react';
import Agentdashboard from './Agentdashboard';
import Staffdashboard from './Staffdashboard';
import { CampaignConsumer } from '../context/CampaignContext';
import AppNavbar from './appnavbar';
import banner from '../assets/images/banner.jpg';

import {
    Page,
    PageContent,
    Block,
    Swiper,
    SwiperSlide,
    SkeletonBlock,
    BlockTitle,
    List,
    ListItem,
    Link,
    Icon,
  } from 'framework7-react';

  export default class Dashboard extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          clientdetail:'',
          showprogress:true,
          effect: 'blink',
          isbannerloaded:false,
          isbetaaccount:0,
          isloaded:false,
          iscampaignloaded:false,
          iscreditloaded:false,
          adminid:'',
        };

        this.bannerLoaded = this.bannerLoaded.bind(this);
        this.init = this.init.bind(this);
    }

    componentDidMount(){

      let clientdetail  = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      let adminid       = Number(localStorage.getItem('prachar_adminid'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          isbetaaccount:clientdetail.isbetaaccount,
          adminid:adminid
        }
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    bannerLoaded(){

      this.setState(()=>{

        return{

          isbannerloaded:true

        }
      },()=>{

        if(this.state.isbannerloaded === true)
        {
          Promise.all([
            this.hideSplashScreen()
          ])
          .then(() => {

            setTimeout(()=> {
              this.Dom7('.dashboardlink').addClass('animate-bottom');
            }, 50);

          })
        }

      });
      
    }

    init(){

      let clientdetail  = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      let adminid       = Number(localStorage.getItem('prachar_adminid'));
      
      this.Dom7('.dashboardlink').removeClass('animate-bottom');
      
      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          isbetaaccount:clientdetail.isbetaaccount,
          adminid:adminid,
          isbannerloaded:false
        }
      },()=>{

        setTimeout(()=> {
          this.bannerLoaded();
        }, 1500);
      });
    }

    render(){

      const{iscreditloaded, adminid} = this.state;
      const{clientname, clienttype, isstaff} = this.state.clientdetail;
        return(

          <CampaignConsumer>{(value)=>{

            const{getSMSCredit} = value;

            setTimeout(() => {
              if(iscreditloaded === false)
              {
                this.setState(()=>{
                  return{
                    iscreditloaded:true,
                  }
                },()=>{

                  getSMSCredit();
                  
                })
                
              }
            }, 100);

            return(
                <Page name="Dashboard" className="dashboardpage" pageContent={false}>
                  <AppNavbar title="Dashboard" showrefresh={true} init={this.init}/>
                  <PageContent>
                  <Block style={{marginBottom:0}}>
                    {
                      this.state.isbannerloaded ? (
                        <Swiper params={{autoplay:{delay:5000}}}>
                          <SwiperSlide>
                            <img src={banner} onLoad={() =>{this.bannerLoaded()}}/>
                          </SwiperSlide>
                          <SwiperSlide>
                            <img src={banner} onLoad={() =>{this.bannerLoaded()}}/>
                          </SwiperSlide>
                        </Swiper>
                      ) : (
                        <List className={`skeleton-banner skeleton-effect-${this.state.effect}`}>
                          <ListItem>
                            <SkeletonBlock style={{ width: '100%', height: '175px' }} slot="media" />
                            <img src={banner} onLoad={() => this.setState({isbannerloaded: true})} style={{ display: 'none'}}/>
                          </ListItem>
                        </List>
                      )
                    }                  
                  </Block>
                  {(()=>{
                    if(clienttype === 2)
                    {
                        return(
                          <Fragment>
                            {
                              clientname ? (<BlockTitle medium className="agencyname">{clientname}
                              &nbsp;
                              {
                                adminid ? (<Link href="/admindashboard/"><Icon f7="lock_rotation"></Icon></Link>):null
                              }</BlockTitle>):null
                            }
                            {
                              isstaff ?
                                (
                                <Staffdashboard isbetaaccount={this.state.isbetaaccount} clientdetail={this.state.clientdetail}/> ):(<Agentdashboard isbetaaccount={this.state.isbetaaccount} />)
                              }
                          </Fragment>
                        );
                    }
                  })()}
                  </PageContent>
                </Page>
              );
          }}</CampaignConsumer>

        )
    }
}