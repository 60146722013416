import React,{Component,Fragment} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import { CampaignConsumer } from '../context/CampaignContext';

import {
    Page,
    PageContent,
    Toolbar,
    Block,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Button,
    Icon,
    Input,
    Link,
  } from 'framework7-react';

  export default class AddCampaign extends Component {
    constructor(props) {
      super(props);
      let today = new Date();
      let currmonth = today.getMonth()+1;
      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let date = today.getFullYear()+'-'+currmonth+'-'+today.getDate();
      let currtimehours = today.getHours();   
      let currtimeminutes = today.getMinutes();   
      if(currtimehours < 10)
      {
        currtimehours = '0'+currtimehours;
      }
      if(currtimeminutes < 10)
      {
        currtimeminutes = '0'+currtimeminutes;
      }
      let tempscheduletime = currtimehours+':'+currtimeminutes;
      let pagetitle = 'Edit Campaign';
      if(this.props.f7route.params.actiontype === 'resend')
      {
          pagetitle = 'Resend Campaign';
      }
      this.state = {
        listrecords:[],
        defaultlist:[],
        clientdetail:'',
        isloaded:false,
        isscheduled:"",
        pagetitle:pagetitle,
        message:'',
        messagecharcount:0,
        smscredit:0,
        scheduleddate:date,
        prescheduleddate:'',
        scheduledtime:tempscheduletime,
        listtype:true,
        istrackable:true,
        pageid:'',
        pagename:'',
        fromposition:'',
        toposition:'',
        listid:'',
        listname:'',
        campaignname:'',
        issuccess:false,
        sendertype:1,
        language:1,
        senderlist:[],
        senderrecordid:'',
        senderid:'Select',
        datatype:'',
        phonelist:'',
        hasphonelist:false,
        totaltextboxphone:'',
        grouplist:[],
        groupid:'',
        groupname:'Select',
        newgroupname:'',
        isnewgroup:false,
        actiontype:this.props.f7route.params.actiontype,
        effect:'blink',
      };

      this.handleUserInput          = this.handleUserInput.bind(this);
      this.countMessageChars        = this.countMessageChars.bind(this);
      this.listAutocompletePopup    = this.listAutocompletePopup.bind(this);
      this.pagesAutocompletePopup   = this.pagesAutocompletePopup.bind(this);
      this.senderAutocompletePopup  = this.senderAutocompletePopup.bind(this);
      this.groupAutocompletePopup   = this.groupAutocompletePopup.bind(this);
    }
    
    componentDidMount(){
        
      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

          this.getDetail();
          this.hideSplashScreen();

          //this.pagesAutocompletePopup();
      });

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      if(name === 'isscheduled')
      {
        this.setState({isscheduled:!this.state.isscheduled});
      }
      else
      {
        let totalnumber = this.state.totaltextboxphone;
        let hasphone    = this.state.hasphonelist;

        if(this.state.datatype === 'input')
        {
            let lines = value.split(/\r\n|\r|\n/);

            let filtered = lines.filter(value => Object.keys(value).length !== 0);

            if(filtered.length > 0)
            {
                totalnumber = filtered.length;
                hasphone    = true;
            }
            else
            {
                totalnumber = '';
                hasphone    = false;
            }
        }

        this.setState(()=>{
            return{
                [name]: value,
                totaltextboxphone: totalnumber,
                hasphonelist: hasphone,
            }
        },()=>{

            this.countMessageChars();

            if(name === 'listtype')
            {
                if(value === "0")
                {
                    this.listAutocompletePopup();
                }
                else
                {
                    this.listAutocomplete.destroy();
                }
            }
            else if(name === 'istrackable')
            {
                if(value === "1")
                {
                    this.pagesAutocompletePopup();
                }
                else
                {
                    this.pagesAutocomplete.destroy();
                }
            }

         });
     }
    }

    countMessageChars(){

        let messagecharcount = this.state.message.length;
        let language = Number(this.state.language);
        let charcountlimitpermessage = 160;
        let smscredit = 0;
        let trackablechar = 0;
        if(messagecharcount > 160)
        {
            charcountlimitpermessage = 153;
        }
       
        if (language === 2)
        {
            charcountlimitpermessage = 70;
            if(messagecharcount > 70)
            {
                charcountlimitpermessage = 67;
            }
        }
        if(this.state.istrackable > 0)
        {
            trackablechar = 25;
        } 
        smscredit        = Math.ceil(((messagecharcount+trackablechar) / charcountlimitpermessage));
        
        this.setState(()=>{
             return{
              messagecharcount : messagecharcount,  
              smscredit : smscredit  
             }
         });
    }

    getMessageingDetail(){

        const self      = this;
        const app       = self.$f7;

        let bodyFormData = {};

        if(Number(self.state.clientdetail.isstaff) > 0)
        {
          bodyFormData = {
              Mode:'GetCampaignDetail',
              clientid:self.state.clientdetail.id,
              staffid:self.state.clientdetail.staffid,
              stateid:self.state.clientdetail.stateid,
              cityid:self.state.clientdetail.cityid,
          };
        }
        else
        {
            bodyFormData = {
                Mode:'GetCampaignDetail',
                clientid:self.state.clientdetail.id,
                stateid:self.state.clientdetail.stateid,
                cityid:self.state.clientdetail.cityid,
            };
        }
        
          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                  this.setState(()=>{
                      return{
                          listrecords:resdata.recordlist.recordlist,
                          isrequestdone:true,
                      }
                  },()=>{
                    setTimeout(() => {

                        this.groupAutocompletePopup();
                        this.senderAutocompletePopup();
                        this.pagesAutocompletePopup();
                        this.listAutocompletePopup();
            
                    }, 1500);
                  });
              }
              else
              {                 
                  this.setState(()=>{
                      return{
                          isrequestdone:false
                      }
                  },()=>{
                    setTimeout(() => {

                        this.groupAutocompletePopup();
                        this.senderAutocompletePopup();
                        this.pagesAutocompletePopup();
                        this.listAutocompletePopup();

                    }, 1500);
                  });
              }
    
          }).catch(e => {
              console.log(e);
          });
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        const bodyFormData = {
            Mode:'GetEditCampaignDetail',
            clientid:self.state.clientdetail.id,
            recordid:self.props.f7route.params.id,
            actiontype:self.state.actiontype,
        };

        setTimeout(()=> {
            let serverurl = "/";

            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

            const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // Hide Pull To Refresh
                app.ptr.done();

                const resdata = JSON.parse(res.data);
                
                if(resdata.success === true)
                {
                    this.setState({
                        defaultlist:resdata.campaigndetail.listids,
                        message:resdata.campaigndetail.message,
                        listtype:Number(resdata.campaigndetail.listtype),
                        fromposition:resdata.campaigndetail.fromposition,
                        toposition:resdata.campaigndetail.toposition,
                        listid:resdata.campaigndetail.listid,
                        listname:resdata.campaigndetail.listname,
                        istrackable:resdata.campaigndetail.istrackable,
                        pageid:resdata.campaigndetail.pageid,
                        pagename:resdata.campaigndetail.pagename,
                        campaignname:resdata.campaigndetail.campaignname,
                        sendertype:Number(resdata.campaigndetail.sendertype),
                        language:Number(resdata.campaigndetail.language),
                        senderrecordid:resdata.campaigndetail.senderrecordid,
                        senderid:resdata.campaigndetail.senderid,
                        datatype:resdata.campaigndetail.datatype,
                        groupid:resdata.campaigndetail.groupid,
                        groupname:resdata.campaigndetail.groupname,

                    },()=>{

                        if(this.props.f7route.params.actiontype !== 'resend')
                        {
                            this.setState({
                                isscheduled:true,
                                scheduleddate:resdata.campaigndetail.scheduleddate,
                                prescheduleddate:resdata.campaigndetail.scheduleddate,
                                scheduledtime:resdata.campaigndetail.scheduledtime,
                            });
                        }

                        this.countMessageChars();
                        this.getMessageingDetail();

                        /*if(Number(this.state.listtype) === 0)
                        {
                            setTimeout(() => {
                                this.listAutocompletePopup();
                            }, 2000);
                        }
                        if(Number(this.state.istrackable) === 1)
                        {
                            setTimeout(() => {
                                this.pagesAutocompletePopup();
                            }, 2000);
                        }*/                        
                    });
                }
                else
                {
                    if(resdata.dialogmsg !=='')
                    {
                        app.dialog.alert(`${resdata.dialogmsg}`,'Edit Campaign Warning',() => {
                            this.$f7.views.main.router.navigate(`/campaign/`);
                      });
                    }
                    else
                    {
                        this.setState({
            
                            isptr:false,

                        },()=>{

                            setTimeout(() => {

                                /*this.senderAutocompletePopup();*/
                    
                            }, 1500);

                        });
                    }
                }
    
            }).catch(e => {
                console.log(e);
            });
        },200);
    }

    listAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }

      self.listAutocomplete = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-list-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectlist/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          let bodyFormData = {};

          if(Number(self.state.clientdetail.isstaff) > 0)
          {
            bodyFormData = {
                Mode:'GetCampaignDetail',
                clientid:self.state.clientdetail.id,
                staffid:self.state.clientdetail.staffid,
            };
          }
          else
          {
              bodyFormData = {
                  Mode:'GetCampaignDetail',
                  clientid:self.state.clientdetail.id,
              };
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/campaign.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    listrecords2:res.campaignlist,
                  }
                },()=>{

                  const searchlist  = self.state.listrecords2;

                  for (let i = 0; i < searchlist.length; i += 1) {
                    if (searchlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-list-name2').text(value[0].name);
            $('.autocomplete-list-popup').removeClass('error');
            
            self.setState({
              listid:value[0].id,
              listname:value[0].name,
            });

          },
        },
      });
    }

    senderAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }

      self.senderAutocomplete = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-sender-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectsender/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/campaign.php?t=${Date.now()}`,
            data: {
              Mode:'GetSenderDetail',
              clientid:self.state.clientdetail.id,
              sendertype:Number(self.state.sendertype),
            },
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    senderlist:res.recordlist,
                  }
                },()=>{

                  const searchlist  = self.state.senderlist;

                  console.log(searchlist);

                  for (let i = 0; i < searchlist.length; i += 1) {
                    if (searchlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-sender-name2').text(value[0].name);
            $('.autocomplete-sender-popup').removeClass('error');
            
            self.setState({
              senderrecordid:value[0].id,
              senderid:value[0].name,
            });

          },
        },
      });
    }

    pagesAutocompletePopup(){

        const self = this;
        const app = self.$f7;
        const $ = self.$$;
  
        let serverurl = "/";
  
        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }
  
        self.pagesAutocomplete = app.autocomplete.create({
  
          openIn: 'popup',
          valueProperty: 'name',
          textProperty: 'name',
          typeahead: true,
          openerEl: '#autocomplete-page-popup a',
          closeOnSelect: true,
          routableModals:true,
          url:'selectpage/',
          requestSourceOnOpen:true,
  
          source(query, render) {
            const autocomplete = this;
            const results = [];
  
            /*autocomplete.preloaderShow();*/
            app.preloader.show();

            const accesstoken  = localStorage.getItem('prachar_accesstoken');
  
            app.request({
              
              method: 'POST',
              dataType: 'json',
              url: `${serverurl}api/page.php?t=${Date.now()}`,
              data: {
                Mode:'GetAllPageList',
                clientid:self.state.clientdetail.id,
              },
              headers: {
                Authorization: `${accesstoken}`
              },
              success(res) {
              // Find matched items
  
                if(res.success === true)
                {
                  self.setState(()=>{
                    return{
                     pagerecords:res.recordlist,
                    }
                  },()=>{
  
                    const searchlist  = self.state.pagerecords;
  
                    for (let i = 0; i < searchlist.length; i += 1) {
                      if (searchlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlist[i]);
                    }
                    
                  })
                }
  
                // Hide Preoloader
                /*autocomplete.preloaderHide();*/
                app.preloader.hide();
                // Render items by passing array with result items
                render(results);
              },
            });
          },
          on: {
            change(value) {
  
              self.Dom7('#autocomplete-page-name2').text(value[0].name);
              $('.autocomplete-page-popup').removeClass('error');
              
              self.setState({
                pageid:value[0].id,
                pagename:value[0].name,
              });
  
            },
          },
        });
    }

    groupAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
          serverurl = "http://orloprachar/app/";
      }

      self.senderAutocomplete = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-group-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectgroup/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          /*autocomplete.preloaderShow();*/
          app.preloader.show();

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/campaigngroup.php?t=${Date.now()}`,
            data: {
              Mode:'GetCampaignGroup',
              clientid:self.state.clientdetail.id,
            },
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    grouplist:res.recordlist,
                  }
                },()=>{

                  const searchlist  = self.state.grouplist;

                  for (let i = 0; i < searchlist.length; i += 1) {
                    if (searchlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              /*autocomplete.preloaderHide();*/
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-group-name2').text(value[0].name);
            $('.autocomplete-group-popup').removeClass('error');
            
            self.setState({
                groupid:value[0].id,
                groupname:value[0].name,
            });

          },
        },
      });
    }

    saveDetail(){

        const self    = this;
        const app     = self.$f7;
        const $$      = self.Dom7;
        const router  = self.$f7router;

        let errmsg    = "";

        let selectedlist = "";
        let haslist = false;

        let filedate    = "";

        app.input.validateInputs('.adddata');

        /*if((this.state.groupid === "" || this.state.groupid === undefined) && errmsg === "")
        {
            errmsg  = "Please select a group";
        }

        if(this.state.groupid === "" || this.state.groupid === undefined)
        {
            this.$$('.autocomplete-sender-popup').addClass('error');
        }
        else
        {
            this.$$('.autocomplete-sender-popup').removeClass('error');
        }*/

        if(this.state.campaignname === "" && errmsg === "")
        {
            errmsg  = "Please enter campaign name.";
        }
        if(Number(this.state.istrackable) === 1)
        {
            if((this.state.pageid === "" || this.state.pageid === undefined) && errmsg === "")
            {
              errmsg  = "Please select a page";
            }

            if(this.state.pageid === "" || this.state.pageid === undefined)
            {
                this.$$('.autocomplete-page-popup').addClass('error');
            }
            else
            {
                this.$$('.autocomplete-page-popup').removeClass('error');
            }
        }

        if(this.state.datatype === 'list')
        {
            if(Number(this.state.listtype) === 1)
            {
                selectedlist = app.smartSelect.get('.smart-select').$selectEl.val();

                if(Number(selectedlist.length) > 0)
                {
                    haslist  = true;
                    this.$$('.smartselectlist').removeClass('error');
                }
                else
                {
                    this.$$('.smartselectlist').addClass('error');
                }
            }

            if((haslist !== true && Number(this.state.listtype) === 1) && errmsg === "")
            {
                errmsg  = "Please select atleast one list to send message.";
            }

            if(Number(this.state.listtype) === 0 && errmsg === "")
            {
                if((this.state.listid === "" || this.state.listid === undefined) && errmsg === "")
                {
                errmsg  = "Please select a list";
                }

                if(this.state.listid === "" || this.state.listid === undefined)
                {
                    this.$$('.autocomplete-list-popup').addClass('error');
                }
                else
                {
                    this.$$('.autocomplete-list-popup').removeClass('error');
                }
            }

            if(Number(this.state.listtype) === 0 && errmsg === "")
            {
                if(this.state.fromposition === "" || this.state.toposition === "")
                {
                    errmsg  = "From and To position can not be blank.";
                }
            }
        }
        else if(this.state.datatype === 'input' && this.state.actiontype ==='resend')
        {
            if(this.state.phonelist === "" && errmsg === "")
            {
                errmsg  = "You must enter atleast one phone number.";
            }
        }
        else if(this.state.datatype === 'excel' && this.state.actiontype ==='resend')
        {
            filedate = document.getElementById('csvfile').files[0];

            if((filedate === "" || filedate === undefined) && errmsg === "")
            {
                errmsg  = "You must upload a file with .txt or .csv extension.";
            }
        }

        if((this.state.senderrecordid === "" || this.state.senderrecordid === undefined) && errmsg === "")
        {
            errmsg  = "Please select a sender id";
        }

        if(this.state.senderrecordid === "" || this.state.senderrecordid === undefined)
        {
            this.$$('.autocomplete-sender-popup').addClass('error');
        }
        else
        {
            this.$$('.autocomplete-sender-popup').removeClass('error');
        }

        if(this.state.isscheduled === "" && errmsg === "")
        {
            errmsg  = "Please select campaign schedule type.";
        }

        if(this.state.isscheduled == true)
        {
            if(this.state.scheduleddate === "" && errmsg === "")
            {
                errmsg  = "Please select a scheduled date.";
            }
            else if(this.state.scheduledtime ==='')
            {
                errmsg  = "Please select a scheduled time.";
            }
        }
        if(this.state.message === "" && errmsg === "")
        {
            errmsg  = "Please enter message";
        }

        if(errmsg !== "")
        {
            const toastWithButton = app.toast.create({
                text: errmsg,
                closeButton: true,
                closeTimeout: 3000,
            });

            toastWithButton.open();
    
            return false;
        }

        let savetype = 'EditCampaign';
        if(self.props.f7route.params.actiontype ==='resend')
        {
            savetype ='AddCampaign';
        }

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const formData = new FormData();

        formData.append('Mode',savetype);
        formData.append('clientid',Number(self.state.clientdetail.id));
        formData.append('listrecords',self.state.listrecords);
        formData.append('selectedlist',selectedlist);
        formData.append('isscheduled',self.state.isscheduled);
        formData.append('scheduleddate',self.state.scheduleddate);
        formData.append('scheduledtime',self.state.scheduledtime);
        formData.append('smscredit',self.state.smscredit);
        formData.append('message',self.state.message);
        formData.append('listtype',Number(self.state.listtype));
        formData.append('istrackable',Number(self.state.istrackable));
        formData.append('pageid',Number(self.state.pageid));
        formData.append('fromposition',self.state.fromposition);
        formData.append('toposition',self.state.toposition);
        formData.append('listid',self.state.listid);
        formData.append('campaignname',self.state.campaignname);
        formData.append('senderrecordid',self.state.senderrecordid);
        formData.append('senderid',self.state.senderid);
        formData.append('sendertype',self.state.sendertype);
        formData.append('language',self.state.language);
        formData.append('contactlistfile',filedate);
        formData.append('phonelist',self.state.phonelist);
        formData.append('datatype',self.state.datatype);
        formData.append('prescheduleddate',self.state.prescheduleddate);
        formData.append('groupid',Number(self.state.groupid));
        formData.append('isnewgroup',Number(self.state.isnewgroup));
        formData.append('groupname',self.state.newgroupname);
        formData.append('recordid',self.props.f7route.params.id);

        if(Number(self.state.clientdetail.isstaff) > 0)
        {
            formData.append('staffid',Number(self.state.clientdetail.staffid));
        }
        else
        {
            formData.append('staffid',0);
        }

        app.preloader.show();

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('prachar_accesstoken');

            app.request({
                url: `${serverurl}api/campaign.php?t=${Date.now()}`,
                method: 'POST', 
                data: formData,
                crossDomain: true,
                cache: false, 
                dataType: 'application/json',
                contentType: 'multipart/form-data',
                processData: true,
                headers: {
                    Authorization: `${accesstoken}`
                },
                success: (data)=>{

                  const resdata = JSON.parse(data);

                  app.preloader.hide();

                  if(resdata.success === true)
                  {
                    if(this.state.actiontype ==='resend')
                    {
                        if(this.state.datatype === 'excel')
                        {
                          document.getElementById('csvfile').value='';    
                        }

                        let today = new Date();
                        let currmonth = today.getMonth()+1;
                        if(currmonth < 10)
                        {
                            currmonth = "0"+currmonth;
                        }
                        let date = today.getFullYear()+'-'+currmonth+'-'+today.getDate();
                        let currtimehours = today.getHours();   
                        let currtimeminutes = today.getMinutes();   
                        if(currtimehours < 10)
                        {
                            currtimehours = '0'+currtimehours;
                        }
                        if(currtimeminutes < 10)
                        {
                            currtimeminutes = '0'+currtimeminutes;
                        }
                        let tempscheduletime = currtimehours+':'+currtimeminutes;

                        Promise.all([
                            this.setState(()=>{
                                return{
                                    isscheduled:'',
                                    scheduleddate:date,
                                    scheduledtime:tempscheduletime,
                                    message:'',
                                    messagecharcount:0,
                                    fromposition:'',
                                    toposition:'',
                                    listid:'',
                                    listname:'',
                                    campaignname:'',
                                    pageid:'',
                                    pagename:'',
                                    issuccess:true,
                                    senderrecordid:'',
                                    senderid:'Select',
                                    groupid:'',
                                    groupname:'Select',
                                    newgroupname:'',
                                    isnewgroup:false,
                                    phonelist:'',
                                }
                            },()=>{
                                this.$f7.views.main.router.navigate(`thanks/addcampaignsuccess/`);
                            })
                        ])
                        .then(() => {
                            this.$$('#autocomplete-list-name2').text('Select');
                            this.$$('#autocomplete-sender-name2').text('Select');
                            this.$$('#autocomplete-group-name2').text('Select');
                            $$('.item-content').removeClass('item-input-with-error-message');
                            $$('.item-content').removeClass('item-input-invalid');
                        })
                    }
                    else
                    {
                        this.setState({
                            issuccess:true
                        });

                      const toastWithButton2 = app.toast.create({
                          text: resdata.msg,
                          closeButton: true,
                          closeTimeout: 3000,
                      });
  
                      toastWithButton2.open();
                    }
                  }
                  else
                  {
                    if(resdata.dialogmsg !=='' && resdata.dialogmsg !==undefined)
                    {
                        app.dialog.alert(`${resdata.dialogmsg}`,'Edit Campaign Warning',() => {
                            this.$f7.views.main.router.navigate(`/campaign/`);
                      });
                    }
                    else
                    {
                        const toastWithButton2 = app.toast.create({
                            text: resdata.msg,
                            closeButton: true,
                            closeTimeout: 3000,
                        });

                        toastWithButton2.open();
                    }
                  }

                }
            });

        }, 200);

    }

    fetchNewGroup(newgroupid, newgroupname){

      this.setState(()=>{

        return{

          groupid:newgroupid,
          groupname:newgroupname

        }

      },()=>{

        /*this.Dom7('#autocomplete-list-name2').text(newgroupname);*/

      })

    }

    render(){
       
        const{listrecords, isrequestdone, message, scheduleddate, scheduledtime, isscheduled, messagecharcount, smscredit, listtype, fromposition, toposition, issuccess, defaultlist, listname, campaignname,istrackable, pagetitle, sendertype,language, senderid, pagename, datatype, actiontype, phonelist, hasphonelist, totaltextboxphone, effect, groupid, groupname, newgroupname, isnewgroup} = this.state;

        return(
            <CommonConsumer>{(value)=>{

                const{navigateToLink} = value;

                const{internetstatus, singlecampaigngroup} = value;

                if(isrequestdone === true)
                {
                  setTimeout(()=> {
      
                    if((singlecampaigngroup.id !== groupid) && singlecampaigngroup.id > 0)
                    {
                      this.fetchNewGroup(singlecampaigngroup.id, singlecampaigngroup.name);
                    }
        
                  }, 500)
                  
                }

                return(
                    <CampaignConsumer>{(value)=>{

                        const{init} = value;

                        if(issuccess)
                        {
                            setTimeout(() => {

                                init();

                                this.setState(()=>{
                                    return{
                                        issuccess:false
                                    }
                                },()=>{
                                    if(actiontype !=='resend')
                                    {
                                        navigateToLink('campaign');
                                    }
                                })

                            }, 500);
                        }

                        return(
                            <Page className="page" name="EditCampaign" pageContent={false}>
                                <AppNavbar title={pagetitle}/>
                                <PageContent>
                                <Block>
                                {
                                    isrequestdone ? (<Fragment>
                                        <Row noGap>
                                            <Col width="80">
                                            <List noHairlinesMd className="zeromargin">
                                                <ListItem link="#" id="autocomplete-group-popup" className="autocomplete-group-popup" title="Campaign Group">
                                                    <div slot="after" id="autocomplete-group-name2">{groupname}</div>
                                                </ListItem>
                                            </List>
                                            </Col>
                                            <Col width="15" style={{marginTop:'8px',marginRight:'15px'}}>
                                                <Link onClick={()=>{
                                                if(internetstatus === "online")
                                                {
                                                    this.$f7router.navigate('addcampaigngrouppopup/');
                                                    /*this.setState({
                                                        isnewgroup:true,
                                                        newgroupname:''
                                                    });*/
                                                }
                                                }}>
                                                <Icon ios="f7:plus" aurora="f7:plus" md="material:add_circle" style={{fontSize:'30px'}}></Icon>
                                                </Link>
                                            </Col>
                                        </Row>
                                        <List noHairlines style={{margin:0,padding:0,listStyle:'none',marginBottom:'0px'}}>
                                            {
                                                isnewgroup ? (
                                                    <ListInput
                                                        name="newgroupname"
                                                        className="adddata resizable"
                                                        label="Group Name"
                                                        type="text"
                                                        resizable
                                                        placeholder="Group Name"
                                                        onInput={this.handleUserInput}
                                                        value={newgroupname}
                                                        multiple={true}
                                                        clearButton required validate
                                                    >
                                                    </ListInput>
                                                ):null
                                            }
                                            <ListInput
                                                name="campaignname"
                                                className="adddata resizable"
                                                label="Campaign Name"
                                                type="text"
                                                resizable
                                                placeholder="Campaign Name"
                                                onInput={this.handleUserInput}
                                                value={campaignname}
                                                multiple={true}
                                                clearButton required validate
                                            >
                                            </ListInput>
                                        </List>
                                        <Row noGap className="listtype">
                                            <Col width="20">
                                                <p style={{marginLeft:'10px',marginTop:'1.2em',marginBottom:'1.2em'}}><b>Type</b></p>
                                            </Col>
                                            <Col width="40">
                                                <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                    <ListItem radio value="0" name="language" checked={Number(language) === 0} title="English"
                                                    onChange={this.handleUserInput}
                                                    ></ListItem>
                                                </List>
                                            </Col>
                                            <Col width="40">
                                                <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                    <ListItem radio value="2" name="language" checked={Number(language) === 2} title="Multilingual"
                                                    onChange={this.handleUserInput}
                                                    ></ListItem>
                                                </List>
                                            </Col>
                                        </Row>
                                        <List noHairlines style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <ListInput
                                                name="message"
                                                inputId="messagearea"
                                                className="adddata resizable"
                                                label="Message"
                                                type="textarea"
                                                resizable
                                                placeholder="Message"
                                                onInput={this.handleUserInput}
                                                value={message}
                                                multiple={true}
                                                clearButton required validate
                                            >
                                            </ListInput>
                                        </List>
                                        <Row>
                                            <Col style={{textAlign:'right',fontWeight:'bold'}}>
                                                Char Count : <span style={{color:'red'}}>{messagecharcount.toString()}</span> , Sms Credit : <span style={{color:'red'}}>{smscredit.toString()}</span>
                                            </Col>
                                        </Row>
                                        {(()=>{
                                            if(datatype === 'list')
                                            {
                                                return(<Fragment>
                                                    <Row noGap className="listtype">
                                                        <Col width="30">
                                                            <p style={{marginLeft:'10px',marginTop:'1.2em',marginBottom:'1.2em'}}><b>List Type</b></p>
                                                        </Col>
                                                        <Col width="35">
                                                            <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                                <ListItem radio value="1" name="listtype" checked={Number(listtype) === 1} title="Full List"
                                                                onChange={this.handleUserInput}
                                                                ></ListItem>
                                                            </List>
                                                        </Col>
                                                        <Col width="35">
                                                            <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                                <ListItem radio value="0" name="listtype" checked={Number(listtype) === 0} title="Partial List"
                                                                onChange={this.handleUserInput}
                                                                ></ListItem>
                                                            </List>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        Number(listtype) ? (
                                                            <Row noGap>
                                                                <Col>
                                                                    <List noHairlines style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>
                                                                    <ListItem
                                                                        title="Choose List(s)"
                                                                        smartSelect
                                                                        smartSelectParams={{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Search List'}}
                                                                        name='ListIds'
                                                                        id='ListIds'
                                                                        className="smartselectlist"
                                                                        after = 'Select'
                                                                        PlaceHolder='Select'
                                                                        >
                                                                        <select name="List" multiple onChange={this.handleUserInput}
                                                                        defaultValue={defaultlist}>
                                                                        {
                                                                            listrecords.map((list)=>{
                                                                            return( 
                                                                                    <option value={list.id} key={list.id}>{list.name}</option>
                                                                                );
                                                                            })
                                                                        }
                                                                        </select>
                                                                    </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>                                
                                                        ):(<Fragment><List noHairlines style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>
                                                            <ListItem link="#" id="autocomplete-list-popup" className="autocomplete-list-popup" title="Choose List(s)">
                                                                <div slot="after" id="autocomplete-list-name2">{listname}</div>
                                                            </ListItem>
                                                        </List>
                                                        <Row noGap>
                                                            <Col width="50">
                                                                <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                                    <ListInput
                                                                        name="fromposition"
                                                                        className="adddata resizable"
                                                                        label="From Position"
                                                                        type="number"
                                                                        resizable
                                                                        placeholder="From Position"
                                                                        onInput={this.handleUserInput}
                                                                        value={fromposition}
                                                                        multiple={true}
                                                                        clearButton required validate
                                                                    >
                                                                    </ListInput>
                                                                </List>
                                                            </Col>
                                                            <Col width="50">
                                                                <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                                    <ListInput
                                                                        name="toposition"
                                                                        className="adddata resizable"
                                                                        label="To Position"
                                                                        type="number"
                                                                        resizable
                                                                        placeholder="To Position"
                                                                        onInput={this.handleUserInput}
                                                                        value={toposition}
                                                                        multiple={true}
                                                                        clearButton required validate
                                                                    >
                                                                    </ListInput>
                                                                </List>
                                                            </Col>
                                                        </Row>
                                                        </Fragment>)
                                                    }
                                                </Fragment>)
                                            }
                                            else if(datatype === 'input')
                                            {
                                                return(
                                                    <Fragment>
                                                    {(()=>{
                                                        if(actiontype ==='resend')
                                                        {
                                                            return(
                                                                <Fragment>
                                                                    <p style={{marginLeft:'10px',textAlign:'left',fontSize:'16px'}}><b>Manual Upload</b></p>
                                                                    <br />
                                                                    <List noHairlines style={{marginTop:'5px'}}>
                                                                        <ListInput
                                                                            name="phonelist"
                                                                            className="adddata resizable"
                                                                            label="Phone List"
                                                                            type="textarea"
                                                                            resizable
                                                                            placeholder="Phone List"
                                                                            onInput={this.handleUserInput}
                                                                            value={phonelist}
                                                                            multiple={true}
                                                                            clearButton required validate
                                                                        >
                                                                        </ListInput>
                                                                    </List>
                                                                    {
                                                                        hasphonelist ? (
                                                                            <p style={{marginLeft:'15px',marginTop:'0'}}><i style={{color:'#ff0000'}}>{totaltextboxphone} Number(s)</i></p>
                                                                        ):null
                                                                    }
                                                                    <p style={{marginLeft:'15px',marginTop:'5px'}}><i style={{color:'#ff0000'}}>Example : 2222222222</i><br />
                                                                    <i><b>One Phone Number per line</b></i>
                                                                    </p>
                                                                </Fragment>
                                                            );
                                                        }
                                                        else
                                                        {
                                                            return(
                                                                <Fragment>
                                                                    <p style={{marginLeft:'10px',textAlign:'left',fontSize:'16px'}}><b>Data Selection : </b>Manual Phone List</p>                            
                                                                </Fragment>
                                                            );
                                                        }
                                                    })()}
                                                    </Fragment>
                                                )
                                            }
                                            else if(datatype === 'excel')
                                            {
                                                return(
                                                    <Fragment>
                                                    {(()=>{
                                                        if(actiontype ==='resend')
                                                        {
                                                            return(
                                                                <Fragment>
                                                                    <p style={{marginLeft:'10px',textAlign:'left',fontSize:'16px'}}><b>Excel Upload</b></p>
                                                                    <br />
                                                                    <List noHairlines style={{marginTop:'5px'}}>
                                                                        <Input type="file" name="csvfile" inputId="csvfile" />
                                                                    </List>
                                                                </Fragment>
                                                            );
                                                        }
                                                        else
                                                        {
                                                            return(
                                                                <Fragment>
                                                                    <p style={{marginLeft:'10px',textAlign:'left',fontSize:'16px'}}><b>Data Selection : </b>Excel Uploaded</p>                            
                                                                </Fragment>
                                                            );
                                                        }
                                                    })()}
                                                    </Fragment>
                                                )
                                            }
                                        })()}
                                        <Fragment>
                                            <Row noGap className="listtype">
                                                <Col width="60">
                                                    <p style={{marginLeft:'10px',marginTop:'1.2em',marginBottom:'1.2em'}}><b>Is Trackable Campaign?</b></p>
                                                </Col>
                                                <Col width="20">
                                                    <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                        <ListItem radio value="1" name="istrackable" checked={Number(istrackable) === 1} title="Yes"
                                                        onChange={this.handleUserInput}
                                                        ></ListItem>
                                                    </List>
                                                </Col>
                                                <Col width="20">
                                                    <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                        <ListItem radio value="0" name="istrackable" checked={Number(istrackable) === 0} title="No"
                                                        onChange={this.handleUserInput}
                                                        ></ListItem>
                                                    </List>
                                                </Col>
                                            </Row>
                                            {
                                                Number(istrackable) ? (
                                                    <Fragment><List noHairlines style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>
                                                    <ListItem link="#" id="autocomplete-page-popup" className="autocomplete-page-popup" title="Choose Page">
                                                        <div slot="after" id="autocomplete-page-name2">{pagename}</div>
                                                    </ListItem>
                                                </List>
                                            </Fragment>):null
                                            }
                                            <Row noGap className="listtype">
                                                <Col width="20">
                                                    <p style={{marginLeft:'10px',marginTop:'1.2em',marginBottom:'1.2em'}}><b>Type</b></p>
                                                </Col>
                                                <Col width="40">
                                                    <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                        <ListItem radio value="0" name="sendertype" checked={Number(sendertype) === 0} title="Promotional"
                                                        onChange={this.handleUserInput}
                                                        ></ListItem>
                                                    </List>
                                                </Col>
                                                <Col width="40">
                                                    <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                        <ListItem radio value="1" name="sendertype" checked={Number(sendertype) === 1} title="Transactional"
                                                        onChange={this.handleUserInput}
                                                        ></ListItem>
                                                    </List>
                                                </Col>
                                            </Row>
                                            <List noHairlines style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>
                                                <ListItem link="#" id="autocomplete-sender-popup" className="autocomplete-sender-popup" title="Sender ID">
                                                    <div slot="after" id="autocomplete-sender-name2">{senderid}</div>
                                                </ListItem>
                                            </List>
                                            <Block className='campaignradiolableblock'>
                                                <Row noGap>
                                                    <Col width="50">
                                                        <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                            <ListItem radio value="0" name="isscheduled" checked={isscheduled === false} title="Send Now"
                                                            onChange={(e) => {
                                                                this.setState({ isscheduled: false});
                                                            }}
                                                            ></ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col width="50">
                                                        <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                                            <ListItem radio value="1" name="isscheduled" checked={isscheduled === true} title="Schedule Date"
                                                            onChange={(e) => {
                                                                this.setState({ isscheduled: true});
                                                            }}
                                                            ></ListItem>
                                                        </List>
                                                    </Col>
                                                </Row>
                                            </Block>
                                        </Fragment>                            
                                            {(()=>{
                                            if(isscheduled > 0)
                                            {
                                                return(
                                                    <List noHairlines style={{marginTop:'5px',marginBottom:'5px'}}>
                                                    <ListInput
                                                    name="scheduleddate"
                                                    id="scheduleddate"
                                                    label="Select Date"
                                                    placeholder="Select Date"
                                                    type="date"
                                                    value={scheduleddate}
                                                    onChange={this.handleUserInput}
                                                    calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                                                    >
                                                    </ListInput>
                                                    <Row noGap>
                                                    
                                                        <Col style={{marginLeft:'15px',marginRight:'15px'}}>
                                                            <div className="item-title item-label">Time</div>
                                                            <input type="time" id="scheduledtime" name="scheduledtime" min="09:00" max="18:00" onChange={this.handleUserInput} placeholder='select a time' value={scheduledtime} required style={{borderBottom:'1px solid #f1f1f1'}}/>
                                                        </Col>
                                                    </Row>
                                                </List>
                                                )
                                            }
                                            })()}
                                    </Fragment>):(
                                        <List mediaList className={`skeleton-text skeleton-effect-${effect}`}>
                                            <ListItem
                                                title="Title Title Title Title Title Title Title Title Title"
                                            >
                                            </ListItem>
                                            <ListItem
                                                title="Title Title Title Title Title Title Title Title Title"
                                            >
                                            </ListItem>
                                            <ListItem
                                                title="Title Title Title Title Title Title Title Title Title"
                                            >
                                            </ListItem>                              
                                            <ListItem
                                                title="Title Title Title Title Title Title Title Title Title"
                                            >
                                            </ListItem>
                                            <ListItem
                                                title="Title Title Title Title Title Title Title Title Title"
                                            >
                                            </ListItem>
                                            <ListItem
                                                title="Title Title Title Title Title Title Title Title Title"
                                            >
                                            </ListItem>
                                            <ListItem
                                                title="Title Title Title Title Title Title Title Title Title"
                                            >
                                            </ListItem>
                                            <ListItem
                                                title="Title Title Title Title Title Title Title Title Title"
                                            >
                                            </ListItem>
                                            <ListItem
                                                title="Title Title Title Title Title Title Title Title Title"
                                            >
                                            </ListItem>
                                        </List>
                                    )
                                }
                                    <br /><br />
                                </Block>
                                </PageContent>
                                {
                                    isrequestdone ? (
                                        <Toolbar position="bottom">
                                            <Row style={{width:'100%'}}>
                                                <Col width="60"></Col>
                                                <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                                                onClick={()=>{
                                                    if(internetstatus === "online")
                                                    {
                                                        this.saveDetail();
                                                    }
                                                }}
                                                ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                                            </Row>
                                        </Toolbar>
                                    ):null
                                }
                            </Page>
                        );
                    }}
                    </CampaignConsumer>            
                );
            }}
            </CommonConsumer>
        )
    }
}