import React,{Component} from 'react';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Link,
    Icon,
    Searchbar
} from 'framework7-react';

export default class AppNavbar extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          adminid:'',
          dashboardlink:'',
        };
    }

    componentDidMount(){

      let adminid   = Number(localStorage.getItem('prachar_adminid'));

      let dashboardlink = "dashboard";

      if(adminid > 0)
      {
        dashboardlink   = "admindashboard";
      }

      this.setState(()=>{
        return{
          adminid:adminid,
          dashboardlink:dashboardlink,
        }
      });

    }

    render(){
        return(
            <Navbar sliding={true}>
                <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding"><img className="navbarlogo" src={scanlogo} alt="logo" /></Link>
                </NavLeft>
                <NavTitle>{this.props.title}</NavTitle>
                <NavRight className="right-with-menu">
                {
                    this.props.showrefresh ? (<Link onClick={()=>{
                        if(this.props.iscampaign === true)
                        {
                            this.props.init(this.props.statusid, this.props.statusname);
                        }
                        else
                        {
                            this.props.init();                            
                        }
                        }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>):null
                }
                </NavRight>
            </Navbar>
        );
    }
}