import React,{Component,Fragment} from 'react';
import AppNavbar from './appnavbar';

import {
    Page,
    Link,
    Toolbar,
    Tabs,
    Tab,
  } from 'framework7-react';

  export default class SMSCredit extends Component {
    constructor(props) {
      super(props);

      this.state = {
      };
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
        }
      },()=>{

        this.hideSplashScreen();

      });
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    render(){
        return(
          <Page className="page" name="SMSCredit" pageContent={false}>
            <AppNavbar title="SMS / Lead Credit History" showrefresh={false}/>
            <Toolbar tabbar bottom>
              <Link tabLink href="./" routeTabId="smscredit">SMS Credit</Link>
              <Link tabLink href="leadcredit/" routeTabId="leadcredit">Lead Credit</Link>
            </Toolbar>
            <Tabs routable>
              <Tab id="smscredit" className="page-content" tabActive></Tab>
              <Tab id="leadcredit" className="page-content"></Tab>
            </Tabs>
          </Page>
        );
    }
}