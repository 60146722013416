import React,{Component, Fragment} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import { ContactConsumer } from '../context/ContactContext';
import logo404 from '../assets/images/orlo404.png';

import {
    Page,
    PageContent,
    List,
    ListInput,
    Button,
    Row,
    Col,
    Icon,
    Link,
    Block,
    BlockTitle,
    ListItem,
    Fab,
    SkeletonBlock,
  } from 'framework7-react';

  export default class ContactDashboard extends Component {

    constructor(props) {
        super(props);
    
        this.state = {

          isdataloading:false

        };
    }

    render(){

      return(
        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <ContactConsumer>{(value)=>{

                const{init, handleUserInput} = value;

                const{loaded, isavailable, searchkeyword, listcrecords, totalcontact, isdashboardloaded, haslistrecords} = value;

                  setTimeout(() => {
                    if(this.state.isdataloading === false)
                    {
                      this.setState(()=>{
                        return{
                          isdataloading:true
                        }
                      },()=>{

                        init();

                      })
                      
                    }
                  }, 100);

                  return(
                      <Page name="ContactDashboard" className="ContactDashboard page" pageContent={false}>
                        <AppNavbar title="Contact Dashboard" showrefresh={true} init={init}/>
                        <PageContent>
                          {
                            isdashboardloaded ? (
                              <Fragment>
                                <Block>
                                    <Row>
                                        <Col width="60">
                                          <List noHairlinesMd className="searchwrapper">
                                              <ListInput
                                                  name="searchkeyword"
                                                  className="searchbox"
                                                  outline
                                                  label="Keyword"
                                                  floatingLabel
                                                  type="text"
                                                  placeholder="Keyword"
                                                  onInput={handleUserInput}
                                                  value={searchkeyword}
                                                  clearButton
                                              >
                                              </ListInput>
                                          </List>
                                        </Col>
                                        <Col width="40">
                                            <Button fill color="blue" onClick={()=>{
                                                if(internetstatus === "online")
                                                {
                                                  navigateToLink(`managecontacts/${searchkeyword}`);
                                                }
                                            }}><Icon ios="f7:search" aurora="f7:search" md="material:search"></Icon> Search</Button>
                                            <div style={{marginTop:'5px',textAlign:'center'}}>
                                            <Link onClick={()=>{
                                                if(internetstatus === "online")
                                                {
                                                  navigateToLink(`managecontacts/advancesearch`);
                                                }
                                            }}>Advanced Search</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Block>
                                <Row>
                                    <Col>
                                      <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5 contactdashboardelev" onClick={()=>{
                                          if(internetstatus === "online")
                                          {
                                            navigateToLink(`managecontacts`);
                                          }
                                        }} style={{color:'#2196f3'}}>
                                          <div style={{fontSize:'3.5rem',fontWeight:'bold',paddingTop:'8px'}}>{totalcontact}</div>
                                          <div style={{fontSize:'1.8rem',marginTop:'12px'}}>Contacts</div>
                                      </div>
                                    </Col>
                                </Row>
                                {
                                    loaded ? (<Fragment>
                                      {
                                        isavailable ? (
                                            <Block>
                                            {
                                              haslistrecords ? (<Fragment>
                                                    <BlockTitle medium>Contacts by List</BlockTitle>
                                                    <List noHairlinesMd>
                                                    {
                                                        listcrecords.map((list)=>{
                                                          return(
                                                            <ListItem key={list.id} link="#" title={`${list.name} (${list.totalcontact})`} onClick={()=>{
                                                                if(internetstatus === "online")
                                                                {
                                                                  navigateToLink(`managecontacts/list/${list.id}/${list.name}`);
                                                                }
                                                            }}>
                                                                <div slot="after">View</div>
                                                            </ListItem>
                                                          );
                                                        })
                                                    }
                                                  </List>
                                                </Fragment>):null
                                            }
                                          </Block>
                                        ):(<Block>
                                          <br />
                                          <Row>
                                            <Col>
                                              <img src={logo404} className="norecimg" />
                                              <BlockTitle className="errmsg">No contact record found.</BlockTitle>
                                            </Col>
                                          </Row>
                                          </Block>)
                                      }
                                    </Fragment>):null
                                }
                              </Fragment>
                            ):(
                              <Fragment>
                                <List noHairlinesMd className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                                    <ListItem title="Title Title Title Title Title Title Title Title Title"></ListItem>
                                </List>
                                <Row>
                                    <Col>
                                      <div className={`elevation-demo elevation-hover-5 elevation-pressed-5`} style={{padding:'0'}}>
                                        <SkeletonBlock style={{ width: '100%', height: '95px', margin:'auto', borderRadius:'10px'}} slot="media" className={`skeleton-effect-${this.state.effect}`}/>
                                        </div>
                                    </Col>
                                </Row>
                                <List mediaList className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                </List>
                              </Fragment>
                            )
                          }
                      <br /><br />
                      </PageContent>
                      {
                        isdashboardloaded ? (
                        <Fab position="center-bottom" slot="fixed" text="Add Contact" onClick={()=>{
                              if(internetstatus === "online")
                              {
                                navigateToLink('addcontact');
                              }
                          }}>
                          <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
                        </Fab>
                        ):null
                      }
                      </Page>
                  );

              }}
              </ContactConsumer>            
          );
        }}
        </CommonConsumer>
      );
    }
}