import React,{Component,Fragment} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import { LeadCategoryConsumer } from '../context/LeadCategoryContext';

import logo404 from '../assets/images/orlo404.png';

import {
    Page,
    PageContent,
    Fab,
    Icon,
    Row,
    Col,
    Block,
    BlockTitle,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
    Badge,
  } from 'framework7-react';

  export default class ManageLeadCategory extends Component {
    constructor(props) {
      super(props);
  
      this.state = {

        islistloaded:false

      };
      
    }

    render(){

      return(
        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <LeadCategoryConsumer>{(value)=>{

                const{deleteRecordAction, init, manageLeadCategoryAction} = value;

                const{loaded, isavailable, contactlist, effect} = value;

                  setTimeout(() => {
                    if(this.state.islistloaded === false)
                    {
                      this.setState(()=>{
                        return{
                          islistloaded:true
                        }
                      },()=>{

                        init();

                      })
                      
                    }
                  }, 100);

                  return(
                    <Page className="page" name="ManageLeadCategory" pageContent={false}>
                      <AppNavbar title="Lead Category" showrefresh={true} init={init}/>
                      <PageContent>
                          {
                            loaded ? (<Fragment>
                              {
                                isavailable ? (
                                  <Fragment>
                                    {
                                        contactlist.map((list)=>{
                                          return(
                                            <Card outline key={list.id} className="recordset">
                                              <CardHeader
                                              className="date">{list.name}
                                              <div className="contactblock"><Badge color="pink" className="contactcount">{list.contactcount}</Badge>&nbsp;contacts</div>
                                              </CardHeader>
                                              <CardContent>
                                                <p>{list.addeddate}</p>
                                                {list.hascontact ? (<p>{list.contactcount} Contact(s)</p>):null}
                                              </CardContent>
                                              <CardFooter>
                                                <Link onClick={() =>{
                                                  if(internetstatus === "online")
                                                  {
                                                    manageLeadCategoryAction(list.id, list.name);
                                                  }
                                                }}>Manage</Link>
                                                <Link onClick={() =>{
                                                  if(internetstatus === "online")
                                                  {
                                                    deleteRecordAction(list.id, list.hascontact, list.contactcount);
                                                  }
                                                }}>Delete</Link>
                                              </CardFooter>
                                            </Card>
                                          );
                                        })
                                    }
                                  </Fragment>
                                ):(<Block>
                                  <br />
                                  <Row>
                                    <Col>
                                      <img src={logo404} className="norecimg" />
                                      <BlockTitle className="errmsg">No category found.</BlockTitle>
                                    </Col>
                                  </Row>
                                  </Block>)
                              }
                            </Fragment>):(<Fragment>
                              <Card className={`waiting recordset skeleton-text skeleton-effect-${effect}`}>
                                <CardHeader
                                className="date"><div className="item-title">xxxxxxxxx</div></CardHeader>
                                <CardContent>
                                  <p><b>Name : </b>xxxxxxx xxxxxxx</p>
                                  <p><b>Description : </b>xxx xxx xxxx</p>
                                </CardContent>
                                <CardFooter>
                                  <Link >Edit</Link>
                                  <Link >Delete</Link>
                                </CardFooter>
                              </Card>
                              <Card className={`waiting recordset skeleton-text skeleton-effect-${effect}`}>
                                <CardHeader
                                className="date"><div className="item-title">xxxxxxxxx</div></CardHeader>
                                <CardContent>
                                  <p><b>Name : </b>xxxxxxx xxxxxxx</p>
                                  <p><b>Description : </b>xxx xxx xxxx</p>
                                </CardContent>
                                <CardFooter>
                                  <Link >Edit</Link>
                                  <Link >Delete</Link>
                                </CardFooter>
                              </Card>
                              <Card className={`waiting recordset skeleton-text skeleton-effect-${effect}`}>
                                <CardHeader
                                className="date"><div className="item-title">xxxxxxxxx</div></CardHeader>
                                <CardContent>
                                  <p><b>Name : </b>xxxxxxx xxxxxxx</p>
                                  <p><b>Description : </b>xxx xxx xxxx</p>
                                </CardContent>
                                <CardFooter>
                                  <Link >Edit</Link>
                                  <Link >Delete</Link>
                                </CardFooter>
                              </Card>
                            </Fragment>)
                          }
                      <br /><br />
                      </PageContent>
                      <Fab position="center-bottom" slot="fixed" text="Add Category" onClick={()=>{
                        if(internetstatus === "online")
                        {
                          navigateToLink('addleadcategory');
                        }
                      }}>
                          <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
                      </Fab>
                    </Page>
                  );
              }}
              </LeadCategoryConsumer>            
          );
        }}
        </CommonConsumer>
      )
    }
}