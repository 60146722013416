import React,{Component,Fragment} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import { CampaignConsumer } from '../context/CampaignContext';
import logo404 from '../assets/images/orlo404.png';

import {
    Page,
    PageContent,
    Fab,
    Icon,
    Block,
    BlockTitle,
    Chip,
    Row,
    Col,
    Link,
    List,
    ListItem,
    Toolbar,
    ListInput,
    Checkbox,
    Button,
  } from 'framework7-react';

  export default class Leads extends Component {

    constructor(props) {
      super(props);

      this.state = {
        iscampaignloaded:false,
        clientdetail:[],
      }

    }

    componentDidMount(){

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

        this.setState(()=>{
            return{
              clientdetail:clientdetail,
            }
        })
    }

    downloadLog(campids, phone, isinterested, isreferred, leadstatusids){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'DownloadLog',
          Type:'LeadArea',
          clientid:self.state.clientdetail.id,
          campids:campids,
          phonenumber:phone,
          isinterested:isinterested,
          isreferred:Number(isreferred),
          statusids:leadstatusids,
          leadtype:this.props.f7route.params.leadtype,
          typename:this.props.f7route.params.typename,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);
              if(resdata.success === true)
              {
                  window.open(resdata.reportfilepath);
              }
              else
              {
                  const toastWithButton = app.toast.create({
                      text: resdata.msg,
                      closeButton: true,
                      closeTimeout: 3000,
                  });
                    
                  toastWithButton.open();    
              }
          }).catch(e => {
              console.log(e);
          });

      },500);
    }

    render(){

      const {staffid, isstaff} = this.state.clientdetail;
      const {leadtype, typename} = this.state;
      
      return(
        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <CampaignConsumer>{(value)=>{
                const{deleteFilterActionLead, openFilterPopup, getAllLead, PagingLead, handleUserInputLead, handleLeadInput, openStaffAssignPopup, selectDeselectAllLead, manageLeadRemark, manageLeadCall, clearLeadFilter, getLeadByDashboard} = value;

                const{isavailablelead, campaignleads, totalleads, loadedlead, hasfilterlead, hasallrecordlead, effect, campid, campname, phonenumber, isinterested, isreferred, totalinterested, totalreferred, filterisinteresedtext, leadstatusids, leadstatusnames, toolbarPosition, paginglist_lead, showpages_lead, page_lead, totalpages_lead, recordrange_lead, isallselected, allselectedtext,staff,leadcategory,statename,city,pincode, leadtagnames, groupname_lead, leadtype, leadtypename} = value;

                let extraspaceclass = "";

                if(showpages_lead)
                {
                  extraspaceclass = "withtoolbar";
                }

                  setTimeout(() => {
                    if(this.state.iscampaignloaded === false)
                    {
                      this.setState(()=>{
                        return{
                          iscampaignloaded:true,
                        }
                      },()=>{

                        getLeadByDashboard(this.props.f7route.params.leadtype, this.props.f7route.params.typename);
                        
                      })
                      
                    }
                  }, 100);

                  return(
                      <Page className="page" name="Leads" pageContent={false}>
                          <AppNavbar title="Leads" showrefresh={true} init={getAllLead}/>
                          <PageContent>
                          {
                            loadedlead ? (<Fragment>

                              {
                                isavailablelead ? (<Fragment>
                                  <Block style={{textAlign:'center',fontWeight:'bold'}}>
                                    <Chip text={`Total Leads : ${totalleads}`} mediaBgColor="pink" media="L"/>
                                    <br />
                                    <Chip text={`Total Interested : ${totalinterested}`} mediaBgColor="orange" media="I"/>
                                    <br />
                                    <Chip text={`Total Referred : ${totalreferred}`} mediaBgColor="green" media="R"/>
                                    <br />
                                    <BlockTitle className="totalrec">Showing : {recordrange_lead}</BlockTitle>
                                  </Block>
                                </Fragment>
                                ):null
                              }

                                {
                                    hasfilterlead ? (
                                    <Block strong className="selectedfilter" style={{marginTop:0}}>
                                      {hasallrecordlead ? (<Chip text={`Campaign : All`} mediaBgColor="blue" media="A"/>):null}

                                      {campname ? (<Chip text={`Campaign : ${campname}`} mediaBgColor="orange" media={campname.toUpperCase().substring(0, 1)} deleteable onClick={()=>{
                                          if(internetstatus === "online")
                                          {
                                            deleteFilterActionLead('campaign');
                                          }
                                        }}
                                      />):null}
                                      {leadtypename ? (<Chip text={`Type : ${leadtypename}`} mediaBgColor="blue" media={leadtypename.toUpperCase().substring(0, 1)} deleteable onClick={()=>{
                                          if(internetstatus === "online")
                                          {
                                            deleteFilterActionLead('dashboardtype');
                                          }
                                        }}
                                      />):null}
                                      {!staffid && staff ? (<Chip text={`Staff : ${staff}`} mediaBgColor="pink" media="S" deleteable onClick={()=>{
                                            if(internetstatus === "online")
                                            {
                                              deleteFilterActionLead('staff');
                                            }
                                          }}
                                        />):null }
                                      {leadcategory ? (<Chip text={`Lead Category : ${leadcategory}`} mediaBgColor="pink" media="L" deleteable 
                                      onClick={()=>{
                                              if(internetstatus === "online")
                                              {
                                                deleteFilterActionLead('leadcategory');
                                              }
                                            }}
                                      />):null}
                                      {statename ? (<Chip text={`State : ${statename}`} mediaBgColor="pink" media="S" deleteable 
                                      onClick={()=>{
                                              if(internetstatus === "online")
                                              {
                                                deleteFilterActionLead('statename');
                                              }
                                            }}
                                      />):null}
                                      {city ? (<Chip text={`City : ${city}`} mediaBgColor="pink" media="C" deleteable 
                                      onClick={()=>{
                                              if(internetstatus === "online")
                                              {
                                                deleteFilterActionLead('city');
                                              }
                                            }}
                                      />):null}
                                      {pincode ? (<Chip text={`Pincode : ${pincode}`} mediaBgColor="pink" media="P" deleteable 
                                      onClick={()=>{
                                              if(internetstatus === "online")
                                              {
                                                deleteFilterActionLead('pincode');
                                              }
                                            }}
                                      />):null}
                                      {phonenumber ? (<Chip text={`Phone : ${phonenumber}`} mediaBgColor="pink" media="P" deleteable 
                                      onClick={()=>{
                                          if(internetstatus === "online")
                                          {
                                            deleteFilterActionLead('phone');
                                          }
                                        }}
                                      />):null}
                                      {filterisinteresedtext ? (<Chip text={`Interested : ${filterisinteresedtext}`} mediaBgColor="green" media={filterisinteresedtext.toUpperCase().substring(0, 1)} deleteable 
                                      onClick={()=>{
                                          if(internetstatus === "online")
                                          {
                                            deleteFilterActionLead('interested');
                                          }
                                        }}
                                      />):null}
                                      {Number(isreferred) ? (<Chip text={`Referred : Yes`} mediaBgColor="yellow" media="Y" deleteable 
                                      onClick={()=>{
                                          if(internetstatus === "online")
                                          {
                                            deleteFilterActionLead('referred');
                                          }
                                        }}
                                      />):null}
                                      {leadstatusnames ? (<Chip text={`Status : ${leadstatusnames}`} mediaBgColor="pink" media={leadstatusnames.toUpperCase().substring(0, 1)} deleteable 
                                      onClick={()=>{
                                          if(internetstatus === "online")
                                          {
                                            deleteFilterActionLead('leadstatus');
                                          }
                                        }}
                                      />):null}
                                      {leadtagnames ? (<Chip text={`Tag : ${leadtagnames}`} mediaBgColor="blue" media={leadtagnames.toUpperCase().substring(0, 1)} deleteable 
                                      onClick={()=>{
                                          if(internetstatus === "online")
                                          {
                                            deleteFilterActionLead('leadtag');
                                          }
                                        }}
                                      />):null}
                                      {groupname_lead ? (<Chip text={`Group : ${groupname_lead}`} mediaBgColor="green" media={groupname_lead.toUpperCase().substring(0, 1)} deleteable 
                                      onClick={()=>{
                                          if(internetstatus === "online")
                                          {
                                            deleteFilterActionLead('leadgroup');
                                          }
                                        }}
                                      />):null}
                                      &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                                        openFilterPopup();
                                      }}/>
                                      &nbsp;
                                      <Chip text="Clear Filter" color="blue" onClick={()=>{
                                        clearLeadFilter();
                                      }}/>
                                    </Block>
                                  ):null
                                }
                                {
                                    isavailablelead ? (
                                    <div className="search-list searchbar-found" style={{width:'98%',margin:'auto'}}>
                                      <canvas id="leadChart" width="100%" height="75vh"></canvas>
                                        {
                                          staffid ? null:(
                                            <Row style={{width:'100%'}}>
                                                <Col width="50">
                                                    <Checkbox name={`assingall`} onChange={selectDeselectAllLead} checked={Number(isallselected) === 1}/>&nbsp;<span style={{verticalAlign:'middle',fontWeight:'bold'}}>{allselectedtext}</span>
                                                </Col>
                                                <Col width="50"><Button fill color="blue" style={{marginRight:'.4rem'}}
                                                onClick={()=>{
                                                  if(internetstatus === "online")
                                                  {
                                                    openStaffAssignPopup();
                                                  }
                                                }}
                                                ><Icon f7="person_alt_circle_fill" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Assign Staff</Button></Col>
                                            </Row>
                                          )
                                        }
                                        <Row noGap className='rowwithbottomborder'>
                                        {
                                          isstaff ? (
                                            <Col width='10' className="tableheader">
                                                S. No
                                            </Col>
                                          ):(
                                            <Col width='10' className="tableheader">
                                                &nbsp;
                                            </Col>
                                          )
                                        }
                                            <Col width='30' className="tableheader">
                                                Camp.
                                            </Col>
                                            <Col width='30' className="tableheader">
                                                Phone
                                            </Col>
                                            <Col width='15' className="tableheader">
                                                Int.?
                                            </Col>
                                            <Col width='15' className="tableheader">
                                                Rem.
                                            </Col>
                                        </Row>
                                    {
                                    campaignleads.map((smslog)=>{
                                          
                                          let referredclassname = '';

                                          if(Number(smslog.isreferred) > 0)
                                          {
                                            referredclassname = 'greencolortxt';
                                          }

                                          return(
                                            <Row key={smslog.index} noGap className='rowwithbottomborder'>
                                            {
                                              isstaff ? (
                                                <Col width='10' className="ledgercolumn textalignleft">
                                                    {smslog.index}
                                                </Col>
                                              ):(
                                                <Col width='10' className="ledgercolumn textalignleft">
                                                    <Checkbox name={`assing_${smslog.id}`} checked={Number(smslog.isassigned) === 1} onChange={()=>{
                                                      handleLeadInput(smslog.id);
                                                    }}></Checkbox>
                                                </Col>
                                              )
                                            }
                                                <Col width='30' className="ledgercolumn ">
                                                    {smslog.campaignname}
                                                </Col>
                                                <Col width='30' className={`ledgercolumn ${referredclassname}`} style={{textAlign:'center'}}>
                                                    {smslog.name ? (<div>{smslog.name}</div>):null}
                                                    <div style={{fontWeight:'bold',lineHeight:'20px',margin:'auto'}}>
                                                    {smslog.phonenumber}
                                                    &nbsp;
                                                    {
                                                      smslog.leadcredit ? (
                                                        <Link external href={`tel:${smslog.phonenumber}`} target="_system">
                                                        <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                                        </Link>
                                                      ):(
                                                        <Link onClick={()=>{
                                                          manageLeadCall(smslog.id, smslog.leadcredit);
                                                        }}>
                                                        <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                                        </Link>
                                                      )
                                                    }
                                                    </div>
                                                    <div>({smslog.mobile_device})</div>
                                                    {
                                                      smslog.leadgeneratedtime ? (<div>({smslog.leadgeneratedtime})</div>):null
                                                    }
                                                    {
                                                      smslog.staffname ? (<p>Staff : {smslog.staffname}</p>):null
                                                    }
                                                </Col>
                                                <Col width='15' className="ledgercolumn ">
                                                    {smslog.interestedtext}
                                                </Col>
                                                <Col width='15' className={`ledgercolumn`}>
                                                  <Link onClick={()=>{
                                                    manageLeadRemark(smslog.id, smslog.leadcredit);
                                                    /*if(internetstatus === "online")
                                                    {
                                                      this.$f7router.navigate(`remark/${smslog.id}/`);
                                                    }*/
                                                  }}>
                                                  {
                                                    smslog.hasremark ? (<Icon f7="square_pencil_fill"></Icon>):(<Icon f7="square_pencil"></Icon>)
                                                  }
                                                  </Link>
                                                </Col>
                                            </Row>
                                            );
                                        })
                                    }
                                    <br /><br /><br />
                                </div>
                                ):(<Block>
                                    <br />
                                    <Row>
                                    <Col>
                                        <img src={logo404} className="norecimg" />
                                        <BlockTitle className="errmsg">No lead found.</BlockTitle>
                                    </Col>
                                    </Row>
                                    </Block>)
                                }
                                </Fragment>):(<Fragment>
                            <List mediaList className={`skeleton-text skeleton-effect-${effect}`}>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>                              
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>                              
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                            </List>
                          </Fragment>)
                        }
                        <br /><br />
                        </PageContent>
                        {
                          isavailablelead  && showpages_lead ? (
                            <Toolbar position={toolbarPosition}>
                            {
                              (()=>{
                                if(page_lead > 1)
                                {
                                  return(
                                    <Link className="paging" onClick={()=>{PagingLead('previous')}}>
                                    <Icon f7="arrow_left_square_fill" ></Icon>
                                    </Link>
                                  );
                                }
                                else
                                {
                                  return(
                                    <Link href="#"></Link>
                                  );
                                }
                              })()
                            }
                              <List className="droppage" noHairlines>
                                  <ListInput
                                      name="page_lead"
                                      type="select"
                                      value={page_lead}
                                      onChange={handleUserInputLead}
                                      placeholder="Please choose..."
                                      >
                                      {
                                        paginglist_lead.map((pages)=>{
                                        return(<option key={pages.index} value={pages.page}>{pages.name}</option>);
                                        })
                                      }
                                  </ListInput>
                              </List>
                              {
                                (()=>{
                                  if(page_lead < totalpages_lead)
                                  {
                                    return(
                                      <Link className="paging" onClick={()=>{PagingLead('next')}}>
                                      <Icon f7="arrow_right_square_fill"></Icon>
                                      </Link>
                                    );
                                  }
                                  else
                                  {
                                    return(
                                      <Link href="#"></Link>
                                    );
                                  }
                                })()
                              }
                            </Toolbar>
                          ):null
                        }
                        {
                          isavailablelead ? (
                            <Fab position="center-bottom" className={`${extraspaceclass}`} slot="fixed" text="Download" onClick={()=>{
                                if(internetstatus === "online")
                                    {
                                        this.downloadLog(campid, phonenumber, isinterested, isreferred, leadstatusids);
                                    }
                                }}>
                                <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
                            </Fab>
                          ):null
                        }
                      </Page>
                  );
              }}
              </CampaignConsumer>            
          );
        }}
        </CommonConsumer>
      )
    }
}