import React,{Component} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import { LeadTagConsumer } from '../context/LeadTagContext';

import {
    Page,
    PageContent,
    Block,
    List,
    ListInput,
    ListItem,
    Icon,
    Toggle,
    Toolbar,
    Row,
    Col,
    Button,
  } from 'framework7-react';

  export default class EditLeadTag extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        name:'',
        description:'',
        status:1,
        clientdetail:'',
        isptr:false,
        isloaded:false,
        issuccess:false,
        internetstatus:navigator.onLine ? "online" : "offline",
      };

      this.handleUserInput = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.getDetail();
        this.hideSplashScreen();

      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(this.state.internetstatus === "offline")
        {
             const toastWithButton = app.toast.create({
                 text: "No internet connection",
             });
               
             toastWithButton.open();
             
             return;
        }

        const bodyFormData = {
            Mode:'GetLeadTagDetail',
            clientid:self.state.clientdetail.id,
            recordid:self.props.f7route.params.id,
        };

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
          serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/leadtag.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
  
                name:resdata.listdetail.name,
                status:Number(resdata.listdetail.status),
                description:resdata.listdetail.description,
                isptr:false,

              });
            }
            else
            {
              this.setState({
  
                isptr:false,

              });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    saveDetail(){
      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }
      let errmsg    = "";
      
      app.input.validateInputs('.adddata');

      if(this.state.name === "")
      {
        errmsg = "Please enter list name";
      }
     
      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const bodyFormData = {
          Mode:'EditLeadTag',
          clientid:self.state.clientdetail.id,
          recordid:self.props.f7route.params.id,
          name:self.state.name,
          status:Number(self.state.status),
          description:self.state.description,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/leadtag.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();
  
            if(resdata.success === true)
            {
              this.setState({
                issuccess:true
              })
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){

      const{issuccess}  = this.state;
       
      return(
        <CommonConsumer>{(value)=>{

          const{navigateToLink} = value;

          const{internetstatus} = value;

          return(
              <LeadTagConsumer>{(value)=>{

                const{init} = value;

                  if(issuccess)
                  {
                    setTimeout(() => {

                        init();

                        this.setState(()=>{
                            return{
                                issuccess:false
                            }
                        },()=>{
                            navigateToLink('leadtag');
                        })
                        
                    }, 500);
                  }

                  return(
                      <Page className="page" name="EditLeadTag" pageContent={false}>
                          <AppNavbar title="Edit Lead Tag"/>
                          <PageContent>
                          <Block>
                              <List noHairlinesMd>

                                <ListInput
                                    name="name"
                                    label="Name"
                                    floatingLabel
                                    type="text"
                                    onInput={this.handleUserInput}
                                    placeholder="Name"
                                    value={this.state.name}
                                    maxlength="200"
                                    clearButton required validate
                                >
                                </ListInput>

                                <ListInput
                                    name="description"
                                    label="Description"
                                    floatingLabel
                                    type="textarea"
                                    onInput={this.handleUserInput}
                                    value={this.state.description}
                                    resizable
                                    placeholder="Description"
                                >
                                </ListInput>
                                <ListItem title="Status">
                                  <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                                </ListItem>
                              </List>
                          </Block>
                          </PageContent>
                          <Toolbar position="bottom">
                            <Row style={{width:'100%'}}>
                                <Col width="60"></Col>
                                <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                                onClick={()=>{
                                  if(internetstatus === "online")
                                  {
                                    this.saveDetail();
                                  }
                                }}
                                ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                            </Row>
                          </Toolbar>
                      </Page>
                  );
              }}
              </LeadTagConsumer>            
          );
        }}
        </CommonConsumer>
      );
    }
}