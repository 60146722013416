import React,{Component} from 'react';

const CustomerContext = React.createContext();
//Provider
//Consumer

class CustomerProvider extends Component{

	constructor(props){
		super(props);
		this.state = {
		  teststate: 'I am test state',
		  singlelist: [],
		  singlepackage: [],
		  singleline: [],
		  singlelineman: [],
		  singlehawker: [],
		  filterdata: [],
		  circulationfilterdata: [],
		  customerlistfilterdata: [],
		  billprintfilterdata: [],
		  registerfilterdata: [],
		  ledgerfilterdata: [],
		  outstandingsummaryfilterdata: [],
		  billcollectionsummarydata: [],
		  invoicefilterdata:[],
		};

		this.DemoFunction						= this.DemoFunction.bind(this);
		this.setListData						= this.setListData.bind(this);
		this.setSMSPackageData					= this.setSMSPackageData.bind(this);
		this.setLineData						= this.setLineData.bind(this);
		this.setLinemanData						= this.setLinemanData.bind(this);
		this.setHawkerData						= this.setHawkerData.bind(this);
		this.applyFilter						= this.applyFilter.bind(this);
		this.applyCirculationFilter				= this.applyCirculationFilter.bind(this);
		this.applyCustomerListFilter			= this.applyCustomerListFilter.bind(this);
		this.applyBillPrintingFilter			= this.applyBillPrintingFilter.bind(this);
		this.applyPaymentRegisterFilter			= this.applyPaymentRegisterFilter.bind(this);
		this.applyCustomerLedgerFilter			= this.applyCustomerLedgerFilter.bind(this);
		this.applyOutStandingSummaryFilter		= this.applyOutStandingSummaryFilter.bind(this);
		this.applyBillCollectionSummaryFilter	= this.applyBillCollectionSummaryFilter.bind(this);
		this.applyInvoiceFilter					= this.applyInvoiceFilter.bind(this);
	}

	DemoFunction(areaid){

		console.log("I am areaid "+areaid);

	}

	setListData(id, name, isnewlist){

		let dataset	= [...this.state.singlelist];

		dataset.id			= id;
		dataset.name		= name;
		dataset.isnewlist	= isnewlist;

		this.setState({

			singlelist:dataset

		});

	}
	setSMSPackageData(id, name,price,smscredits){

		let dataset	= [...this.state.singlepackage];

		dataset.id		= id;
		dataset.name	= name;
		dataset.price	= price;
		dataset.smscredits	= smscredits;

		this.setState({

			singlepackage:dataset

		});

	}

	setLineData(id, name){

		let dataset	= [...this.state.singleline];

		dataset.id		= id;
		dataset.name	= name;

		this.setState({

			singleline:dataset

		});

	}

	setLinemanData(id, name){

		let dataset	= [...this.state.singlelineman];

		dataset.id		= id;
		dataset.name	= name;

		this.setState({

			singlelineman:dataset

		});

	}

	setHawkerData(id, name){

		let dataset	= [...this.state.singlehawker];

		dataset.id		= id;
		dataset.name	= name;

		this.setState({

			singlehawker:dataset

		});

	}

	applyFilter(listid, filterlist, isbulkcontact, bulkcontactname){

		let dataset	= [...this.state.filterdata];

		dataset.listid				= listid;
		dataset.filterlist			= filterlist;
		dataset.isbulkcontact		= isbulkcontact;
		dataset.bulkcontactname		= bulkcontactname;
	
		this.setState({

			filterdata:dataset

		});

	}

	applyCirculationFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, date){

		let dataset	= [...this.state.circulationfilterdata];

		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;
		dataset.date			= date;

		this.setState({

			circulationfilterdata:dataset

		});

	}

	applyCustomerListFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, inventoryid, inventoryname){

		let dataset	= [...this.state.customerlistfilterdata];

		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;
		dataset.inventoryid		= inventoryid;
		dataset.inventoryname	= inventoryname;

		this.setState({

			customerlistfilterdata:dataset

		});

	}

	applyBillPrintingFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, billnumberfrom,billnumberto,filterbillnumberfrom,filterbillnumberto,isbillnumber,isdatefilter,billstartdate,billenddate){

		let dataset	= [...this.state.filterdata];
		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;
		dataset.isbillnumber	= isbillnumber;
		dataset.billnumberfrom	= billnumberfrom;
		dataset.billnumberto	= billnumberto;
		dataset.isdatefilter		= isdatefilter;
		dataset.billstartdate	= billstartdate;
		dataset.billenddate		= billenddate;
		dataset.filterbillnumberfrom= filterbillnumberfrom;
		dataset.filterbillnumberto= filterbillnumberto;

		this.setState({

		billprintfilterdata:dataset

		});
	}

	applyPaymentRegisterFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate){

		let dataset	= [...this.state.registerfilterdata];

		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;
		dataset.startdate		= startdate;
		dataset.enddate			= enddate;

		this.setState({

			registerfilterdata:dataset

		});

	}

	applyCustomerLedgerFilter(customerid, customername, startdate, enddate){

		let dataset	= [...this.state.ledgerfilterdata];

		dataset.customerid		= customerid;
		dataset.customername	= customername;
		dataset.startdate		= startdate;
		dataset.enddate			= enddate;

		this.setState({

			ledgerfilterdata:dataset

		});

	}

	applyOutStandingSummaryFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea){

		let dataset	= [...this.state.outstandingsummaryfilterdata];

		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;

		this.setState({

			outstandingsummaryfilterdata:dataset

		});

	}

	applyBillCollectionSummaryFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate){

		let dataset	= [...this.state.billcollectionsummarydata];

		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;
		dataset.startdate		= startdate;
		dataset.enddate			= enddate;

		this.setState({

			billcollectionsummarydata:dataset

		});

	}

	applyInvoiceFilter(year, month, filteryear, filtermonth, lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea){

		let dataset	= [...this.state.invoicefilterdata];

		dataset.year			= year;
		dataset.month			= month;
		dataset.filteryear		= filteryear;
		dataset.filtermonth		= filtermonth;
		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;

		this.setState({

			invoicefilterdata:dataset

		});

	}

	render(){
		return (
			<CustomerContext.Provider value={{
			...this.state,
				DemoFunction:this.DemoFunction,
				setListData:this.setListData,
				setLineData:this.setLineData,
				setLinemanData:this.setLinemanData,
				setHawkerData:this.setHawkerData,
				applyFilter:this.applyFilter,
				applyCirculationFilter:this.applyCirculationFilter,
				applyCustomerListFilter:this.applyCustomerListFilter,
				applyBillPrintingFilter:this.applyBillPrintingFilter,
				applyPaymentRegisterFilter:this.applyPaymentRegisterFilter,
				applyCustomerLedgerFilter:this.applyCustomerLedgerFilter,
				applyOutStandingSummaryFilter:this.applyOutStandingSummaryFilter,
				applyBillCollectionSummaryFilter:this.applyBillCollectionSummaryFilter,
				applyInvoiceFilter:this.applyInvoiceFilter,
			}}
			>
			{this.props.children}
			</CustomerContext.Provider>
		);
	}
}

const CustomerConsumer = CustomerContext.Consumer;

export {CustomerProvider, CustomerConsumer, CustomerContext};