import React from 'react';
import AppMenu from '../pages/AppMenu';
import { CommonProvider } from '../context/CommonContext';
import { CampaignProvider } from '../context/CampaignContext';
import { CustomerProvider } from '../context/CustomerContext';
import { ListProvider } from '../context/ListContext';
import { LeadCategoryProvider } from '../context/LeadCategoryContext';
import { LeadTagProvider } from '../context/LeadTagContext';
import { ContactProvider } from '../context/ContactContext';
import { StaffProvider } from '../context/StaffContext';
import { CampaignGroupProvider } from '../context/CampaignGroupContext';
import { SupportProvider } from '../context/SupportContext';

import {
  App,
  Panel,
  Views,
  View,
  Popup,
  Page,
  Navbar,
  Toolbar,
  NavRight,
  Link,
  Block,
  BlockTitle,
  LoginScreen,
  LoginScreenTitle,
  List,
  ListItem,
  ListInput,
  ListButton,
  BlockFooter
} from 'framework7-react';

import routes from '../js/routes';

export default class extends React.Component {
  constructor() {
    super();

    this.state = {
      // Framework7 Parameters
      f7params: {
        name: 'orlopay', // App name
        theme: 'auto', // Automatic theme detection
        // App routes
        routes: routes,
      },
      // Login screen demo data
      username: '',
      password: '',
      isloggedin:false
    }
  }
  render() {
    return (
      <CommonProvider>
        <CampaignProvider>
          <CustomerProvider>
            <ListProvider>
              <LeadCategoryProvider>
              <LeadTagProvider>
                <ContactProvider>
                  <StaffProvider>
                    <CampaignGroupProvider>
                      <SupportProvider>
                        <App params={ this.state.f7params } >
                          <AppMenu />
                          {/* Your main view, should have "view-main" class 
                          <View main className="safe-areas" url="/" pushState={true} pushStateSeparator="" passRouteParamsToRequest={true} pushStateOnLoad={true} />*/}
                          <View main className="safe-areas" url="/" pushState={true} passRouteParamsToRequest={true} pushStateOnLoad={true} cache={false}/>
                          
                        </App>
                      </SupportProvider>
                    </CampaignGroupProvider>
                  </StaffProvider>
                </ContactProvider>
              </LeadTagProvider>
              </LeadCategoryProvider>
            </ListProvider>
          </CustomerProvider>
        </CampaignProvider>
      </CommonProvider>
    )
  }
  componentDidMount() {
    this.$f7ready((f7) => {

      //document.getElementById("splash-screen").style.visibility = "hidden";

      // Call F7 APIs here
    });
  }
}