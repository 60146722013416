import React,{Component,Fragment} from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import AppNavbar from './appnavbar';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
    Page,
    PageContent,
    Icon,
    Block,
    List,
    Link,
    ListInput,
    ListItem,
    Toggle,
    Toolbar,
    Row,
    Col,
    Button,
    Input,
    TextEditor,
    PhotoBrowser
  } from 'framework7-react';

  export default class EditPage extends Component {
    constructor(props) {
      super(props);
      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
        serverurl = "http://orloprachar/app/api/";
      }
      
      /*var contentState = stateFromHTML(props.input.value);
      editorState = EditorState.createWithContent(contentState);*/

      this.state = {
        name:'',
        description:'',
        imagefile:'',
        preimagefile:'',
        preimagefile2:'',
        isdestinationlink:false,
        destinationlinktype:'link',
        destinationlink:'',
        serverurl:serverurl,
        imagefilepreview:'',
        editorState: EditorState.createEmpty(),
        customButtons:[
            ['bold', 'italic', 'underline', 'strikeThrough'],
            ['orderedList', 'unorderedList','link'],
            ['paragraph', 'h1', 'h2', 'h3'],
            ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
            ['subscript', 'superscript'],
            ['indent', 'outdent'],
          ],
        status:1,
        isleadcategory:0,
        isloaded:false,
        isvideocode:false,
        videocode:'',
        isallcategory:true,
        allleadcategory:[],
        internetstatus:navigator.onLine ? "online" : "offline",
      };

      this.handleUserInput = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.handleLeadCatInput  = this.handleLeadCatInput.bind(this);
      this.getDetail = this.getDetail.bind(this);
      this.removepageheaderimage = this.removepageheaderimage.bind(this);
      this.removepagedestinationfile = this.removepagedestinationfile.bind(this);
    }
    
    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.hideSplashScreen();
        this.getDetail();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){
    
      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else if(name === 'isvideocode')
      {
        this.setState({isvideocode:!this.state.isvideocode});
      }
      else if(name === 'isdestinationlink')
      {
        this.setState({isdestinationlink:!this.state.isdestinationlink});
      }
      else if(name === 'isleadcategory')
      {
        this.setState({isleadcategory:!this.state.isleadcategory});
      }
      else if(name === 'imagefile')
      {
        //this.setState({previewimage:value});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    handleLeadCatInput(catid){

        let templeadcategory     = [...this.state.allleadcategory];
        const templeadcategory2    = templeadcategory.find(category => Number(category.id) === Number(catid));

        templeadcategory2.checked   = !templeadcategory2.checked;

        this.setState(()=>{
          
          return{

            allleadcategory: templeadcategory

          }

        });

    }

    getLeadCategory(){

      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      const bodyFormData = {
          Mode:'GetLeadCategory',
          clientid:self.state.clientdetail.id,
          pageid:self.props.f7route.params.id,
      };

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
        serverurl = "http://orloprachar/app/";
      }

      const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${serverurl}api/leadcategory.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          // Hide Pull To Refresh
          app.ptr.done();

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
            this.setState({
              allleadcategory:resdata.recordlist,
            });
          }

      }).catch(e => {
          console.log(e);
      });
    }    

    getDetail(){

      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;
      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      const bodyFormData = {
          Mode:'GetPageDetail',
          clientid:self.state.clientdetail.id,
          recordid:self.props.f7route.params.id,
      };

      let serverurl = "/";

      if(process.env.NODE_ENV === "development")
      {
        serverurl = "http://orloprachar/app/";
      }

      const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${serverurl}api/page.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          // Hide Pull To Refresh
          app.ptr.done();

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
            this.setState({

              name:resdata.pagedetail.name,
              status:Number(resdata.pagedetail.status),
              isleadcategory:Number(resdata.pagedetail.isleadcategory),
              preimagefile:resdata.pagedetail.imagefile,
              preimagefile2:resdata.pagedetail.destinationfile,
              description:resdata.pagedetail.description,
              isdestinationlink:Number(resdata.pagedetail.isdestinationlink),
              destinationlink:resdata.pagedetail.destinationlink,
              isvideocode:Number(resdata.pagedetail.isvideocode),
              videocode:resdata.pagedetail.videocode,
              destinationlinktype:resdata.pagedetail.destinationlinktype,
              isallcategory:resdata.pagedetail.isallcategory,
              isptr:false,
            },
            ()=>{

              const contentBlock = htmlToDraft(resdata.pagedetail.description);
              if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);

                this.setState({editorState});
              }

              this.getLeadCategory();

            });
          }
          else
          {
            this.setState({

              isptr:false,

            },()=>{
              this.getLeadCategory();
            });
          }

      }).catch(e => {
          console.log(e);
      });
    }

    saveDetail(){
      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;

      let destinationfile    = "";

      let description = convertToRaw(this.state.editorState.getCurrentContent());

      if(this.state.internetstatus === "offline")
      {
        const toastWithButton = app.toast.create({
            text: "No internet connection",
        });
          
        toastWithButton.open();
        
        return;
      }

      let errmsg    = "";
      
      app.input.validateInputs('.adddata');
      let filedata    = "";
      filedata = document.getElementById('imagefile').files[0];

      if(this.state.name === "")
      {
        errmsg = "Please enter page name";
      }
      if(filedata !== "" && filedata !==undefined  && errmsg==='')
      { 
        let fileext = filedata.name.split('.').pop().toLowerCase();
        if(fileext !=='jpg' && fileext !=='jpeg' && fileext !=='png' && fileext !=='svg' && fileext !=='bmp')
        {
            errmsg = "Please enter page name";
        }
      }

      if(description.blocks[0].text === "")
      {
        this.$$('.flddescription').addClass('error');
        if(errmsg ==='')
        {
            errmsg = "Please enter page description.";
        }
      }
      else
      {
          this.$$('.flddescription').removeClass('error');
      }
      if(Number(this.state.isdestinationlink) === 1)
      {
        if(this.state.destinationlinktype === "link")
        {
          if(this.state.destinationlink === "" && errmsg === "")
          {
            errmsg  = "Please enter a destination page link";
          }
        }
        else if(this.state.destinationlinktype === "fileupload")
        {
          destinationfile = document.getElementById('destinationfile').files[0];

          if((destinationfile === "" || destinationfile === undefined) && (this.state.preimagefile2 === "" || this.state.preimagefile2 === undefined) && errmsg === "")
          {
              errmsg  = "You must upload a file.";
          }
        }
      }
      if(Number(this.state.isvideocode) === 1)
      {
          if(this.state.videocode === "" && errmsg === "")
          {
            errmsg  = "Please enter video code";
          }
      }
      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const formData = new FormData();

      let selectedCat = [];

      this.state.allleadcategory.forEach(item => {	
        if(item.checked === true)
        {
          selectedCat.push(item.id);
        }
		  });

      formData.append('Mode','EditPage');
      formData.append('imagefile',filedata);
      formData.append('clientid',Number(self.state.clientdetail.id));
      formData.append('name',self.state.name);
      formData.append('status',Number(self.state.status));
      formData.append('isleadcategory',Number(self.state.isleadcategory));
      formData.append('preimagefile',Number(self.state.preimagefile));
      formData.append('preimagefile2',Number(self.state.preimagefile2));
      formData.append('description',draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
      formData.append('recordid',self.props.f7route.params.id);
      formData.append('isdestinationlink',Number(self.state.isdestinationlink));
      
      formData.append('isvideocode',Number(self.state.isvideocode));
      formData.append('videocode',self.state.videocode);
      formData.append('destinationlinktype',self.state.destinationlinktype);
      formData.append('isallcategory',Number(self.state.isallcategory));
      formData.append('selectedCat',selectedCat);

      if(this.state.destinationlinktype === "link")
      {
        formData.append('destinationlink',self.state.destinationlink);
      }
      else if(this.state.destinationlinktype === "fileupload")
      {
        formData.append('destinationfile',destinationfile);
      }
    
      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;
          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
            serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request({
            url: `${serverurl}api/page.php?t=${Date.now()}`,
            method: 'POST', 
            data: formData,
            crossDomain: true,
            cache: false, 
            dataType: 'application/json',
            contentType: 'multipart/form-data',
            processData: true,
            headers: {
                Authorization: `${accesstoken}`
            },
            success: (data)=>{

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();
    
                const resdata = JSON.parse(data);
                if(resdata.success === true)
                {
                    router.navigate('/pages/');             
                }
            }
        });

      }, 500);

    }
    removepageheaderimage(){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;
      const recordid = self.props.f7route.params.id;
		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                  const bodyFormData = {
                      Mode:'RemovePageHeaderImage',
                      clientid:this.state.clientdetail.id,
                      recordid:recordid,
                  };

                  app.preloader.show();

                  // Simulate Ajax Request
                  setTimeout(()=> {

                    let serverurl = "/";

                    if(process.env.NODE_ENV === "development")
                    {
                        serverurl = "http://orloprachar/app/";
                    }

                    const accesstoken  = localStorage.getItem('prachar_accesstoken');

                    app.request.setup({
                      headers:{
                        Authorization: `${accesstoken}`
                      }
                    });

                    app.request.promise.post(`${serverurl}api/page.php?t=${Date.now()}`,bodyFormData)
                    .then((res)=> {

                        // We got user data from request
                
                        // Hide Preloader
                        app.preloader.hide();

                        const resdata = JSON.parse(res.data);

                        const toastWithButton = app.toast.create({
                          text: resdata.msg,
                          closeButton: true,
                          closeTimeout: 3000,
                        });
              
                        toastWithButton.open();

                        if(resdata.success === true)
                        {
                          this.setState(
                            ()=>{
                              return {
                                preimagefile:'',
                              }
                            }
                          );
                          
                        }
              
                    }).catch(e => {
                        console.log(e);
                    });

                  }, 500);	
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }
    removepagedestinationfile(){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;
      const recordid = self.props.f7route.params.id;
		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                  const bodyFormData = {
                      Mode:'RemovePageDestinationFile',
                      clientid:this.state.clientdetail.id,
                      recordid:recordid,
                  };

                  app.preloader.show();

                  // Simulate Ajax Request
                  setTimeout(()=> {

                    let serverurl = "/";

                    if(process.env.NODE_ENV === "development")
                    {
                        serverurl = "http://orloprachar/app/";
                    }

                    const accesstoken  = localStorage.getItem('prachar_accesstoken');

                    app.request.setup({
                      headers:{
                        Authorization: `${accesstoken}`
                      }
                    });

                    app.request.promise.post(`${serverurl}api/page.php?t=${Date.now()}`,bodyFormData)
                    .then((res)=> {

                        // We got user data from request
                
                        // Hide Preloader
                        app.preloader.hide();

                        const resdata = JSON.parse(res.data);

                        const toastWithButton = app.toast.create({
                          text: resdata.msg,
                          closeButton: true,
                          closeTimeout: 3000,
                        });
              
                        toastWithButton.open();

                        if(resdata.success === true)
                        {
                          this.setState(
                            ()=>{
                              return {
                                preimagefile2:'',
                              }
                            }
                          );
                          
                        }
              
                    }).catch(e => {
                        console.log(e);
                    });

                  }, 500);	
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }
    render(){
      const{description, name, previewimage, preimagefile, preimagefile2, serverurl, isdestinationlink, destinationlink, editorState, isvideocode, videocode, destinationlinktype, isleadcategory, isallcategory, allleadcategory} = this.state;
      
      let tempphotos = [
        {
          url: serverurl+preimagefile,
          caption: '',
        },
      ];

      return(
            <Page className="page" name="EditPage" pageContent={false}>
              <AppNavbar title="Edit Page"/>
              <PageContent>
                  <Block>
                  <List noHairlinesMd style={{marginBottom:'5px'}}>
  
                      <ListInput
                          name="name"
                          className="adddata"
                          label="Name"
                          floatingLabel
                          type="text"
                          onInput={this.handleUserInput}
                          placeholder="Name"
                          value={name}
                          maxlength="200"
                          clearButton required validate
                      >
                      </ListInput>
                      <ListItem>
                            <span style={{marginRight:'5px'}}>Image</span><br/>
                            <Input type="file" name="imagefile" inputId="imagefile" onChange={this.handleUserInput}/>
                            {
                                previewimage ? ( <img src={previewimage} width='50px'/>) :null
                            }
                      </ListItem>  
                     </List>
                     {
                       preimagefile ? ( 
                        <Row noGap style={{marginTop:'5px',marginBottom:'5px',marginRight:'15px'}}>
                         
                          <Col style={{textAlign:'left',marginLeft: '15px'}}>
                            Preview: (<Link onClick={() => this.removepageheaderimage()} className='textredcolor'>Remove</Link>)<br/>
                            <PhotoBrowser
                              type="popup"
                            ref={(el) => {this.popup = el}}
                              photos={tempphotos}
                            />
                            <img src={serverurl+preimagefile} style={{maxWidth:'100%'}} onClick={() => this.popup.open()}/>
                          </Col>
                        </Row>
                        ) :null
                     }
                      <Row noGap style={{marginLeft:'15px'}}><Col className='flddescription'>Description</Col>
                       </Row>
                       <Row noGap><Col className='txtEditor'>
                          <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            textAlignment='left'
                            onEditorStateChange={(value)=>{
                              this.setState(()=>{
                                return{
                                  editorState:value
                                }
                              });
                            }}
                            toolbar={{
                              options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link','colorPicker'],
                              inline: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                              },
                              list: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['unordered', 'ordered'],
                              },
                              textAlign: { inDropdown: false },
                            }}
                          />
                        </Col>
                       </Row>
                      <List noHairlines style={{marginBottom:'5px',marginTop:'5px'}}> 
                          <ListItem title="Add Video Code?">
                            <Toggle slot="after" name="isvideocode" checked={Number(isvideocode) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                          </ListItem>
                      </List>
                      {
                        Number(isvideocode) ? (
                          <List noHairlines style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>
                            <ListInput
                                name="videocode"
                                inputId="messagearea"
                                className="adddata resizable"
                                label="Video Code"
                                type="textarea"
                                resizable
                                placeholder="Video Code"
                                onInput={this.handleUserInput}
                                value={videocode}
                                multiple={true}
                                clearButton required validate
                            >
                            </ListInput>
                        </List>):null
                      }
                      <List noHairlines style={{marginBottom:'5px',marginTop:'5px'}}> 
                          <ListItem title="Add Destination Page Link?">
                            <Toggle slot="after" name="isdestinationlink" checked={Number(isdestinationlink) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                          </ListItem>
                      </List>
                      {
                        Number(isdestinationlink) ? (<Fragment>
                          <Row noGap className="listtype">
                              <Col width="30">
                                  <p style={{marginLeft:'18px',marginTop:'1.2em',marginBottom:'1.2em'}}><b>Link Type</b></p>
                              </Col>
                              <Col width="35">
                                  <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                      <ListItem radio value="link" name="destinationlinktype" checked={destinationlinktype === 'link'} title="Link"
                                      onChange={this.handleUserInput}
                                      ></ListItem>
                                  </List>
                              </Col>
                              <Col width="35">
                                  <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                      <ListItem radio value="fileupload" name="destinationlinktype" checked={destinationlinktype === 'fileupload'} title="File Upload"
                                      onChange={this.handleUserInput}
                                      ></ListItem>
                                  </List>
                              </Col>
                          </Row>
                          {(()=>{
                            if(destinationlinktype === "link")
                            {
                              return(
                                <List noHairlines style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>
                                  <ListInput
                                      name="destinationlink"
                                      className="adddata"
                                      label="Destination Link"
                                      floatingLabel
                                      type="text"
                                      onInput={this.handleUserInput}
                                      placeholder="Destination Link"
                                      value={destinationlink}
                                      maxlength="250"
                                      clearButton required validate
                                  >
                                  </ListInput>
                                </List>
                              );
                            }
                            else if(destinationlinktype === "fileupload")
                            {
                              return(
                                <Block style={{paddingLeft:'5px'}}>
                                    <p style={{marginLeft:'10px',textAlign:'left',fontSize:'16px'}}><b>Destination File</b></p>
                                    <br />
                                    <List noHairlines style={{marginBottom:'5px',marginTop:'5px',marginLeft:'10px'}}>
                                        <Input type="file" name="destinationfile" inputId="destinationfile" />
                                    </List>
                                    {
                                      preimagefile2 ? ( 
                                        <Row noGap style={{marginTop:'5px',marginBottom:'5px',marginRight:'15px'}}>
                                          <Col style={{textAlign:'left'}}>
                                          </Col>
                                          <Col style={{textAlign:'right'}}>
                                          (<Link onClick={() => this.removepagedestinationfile()} className='textredcolor'>Remove</Link>) <Button small fill onClick={() => {
                                              window.open(serverurl+preimagefile2,'_blank');
                                            }}>View Current File</Button>
                                          </Col>
                                        </Row>
                                        ) :null
                                    }
                                </Block>
                              );
                            }
                          })()}
                        </Fragment>):null
                    }
                  <List noHairlines style={{marginBottom:'5px',marginTop:'5px'}}> 
                      <ListItem title="Show Lead Category Input?">
                        <Toggle slot="after" name="isleadcategory" checked={Number(this.state.isleadcategory) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                      </ListItem>
                  </List>
                  {
                    Number(isleadcategory) ? (<Fragment>
                      <Row noGap className="listtype">
                          <Col width="40">
                              <p style={{marginLeft:'10px',marginTop:'1.2em',marginBottom:'1.2em'}}><b>Category Type?</b></p>
                          </Col>
                          <Col width="30">
                              <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                  <ListItem radio value="1" name="isallcategory" checked={Number(isallcategory) === 1} title="All"
                                  onChange={this.handleUserInput}
                                  ></ListItem>
                              </List>
                          </Col>
                          <Col width="30">
                              <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                  <ListItem radio value="0" name="isallcategory" checked={Number(isallcategory) === 0} title="Selected"
                                  onChange={this.handleUserInput}
                                  ></ListItem>
                              </List>
                          </Col>
                      </Row>
                      {
                        Number(isallcategory) ? null:(
                          <List style={{marginBottom:'5px',marginTop:'5px'}}>
                            {
                              allleadcategory.map((category)=>{
                                return(
                                      <ListItem key={category.id} checkbox value={category.id} title={category.name} name={`leadcategory_${category.id}`} checked={category.checked} onChange={()=>{this.handleLeadCatInput(category.id)}}></ListItem> 
                                    );
                                })
                            }
                          </List>
                        )
                      }
                    </Fragment>):null
                }
                  <List noHairlines style={{marginBottom:'5px',marginTop:'5px'}}> 
                      <ListItem title="Status">
                        <Toggle slot="after" name="
                        " checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                      </ListItem>
                  </List>
              </Block>
              <br /><br />
              </PageContent>
              <Toolbar position="bottom">
                <Row style={{width:'100%'}}>
                    <Col width="60"></Col>
                    <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                    onClick={()=>{
                      if(this.state.internetstatus === "online")
                      {
                        this.saveDetail();
                      }
                    }}
                    ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                </Row>
              </Toolbar>
            </Page>
      );
    }
}