import React,{Component,Fragment} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import logo404 from '../assets/images/orlo404.png';

import {
    Page,
    PageContent,
    BlockTitle,
    Block,
    List,
    Row,
    Col,
    ListItem,
  } from 'framework7-react';

  export default class PreviewPage extends Component {
    constructor(props) {
      super(props);
     
      this.state = {
        pagedescriptiontext:'',
        pagebanner:'',
        isptr:false,
        isloaded:false,
        internetstatus:navigator.onLine ? "online" : "offline",
        ismount:false,
        isdatafetched:false,
        effect:'blink',
        hasdataavailable:false,
      };
      this.updateOnlineStatus         = this.updateOnlineStatus.bind(this);
      this.getPreviewDetails          = this.getPreviewDetails.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getPreviewDetails();
        this.hideSplashScreen();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus(){

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getPreviewDetails(){

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
            Mode:'GetPageDetail',
            clientid:self.state.clientdetail.id,
            recordid:this.props.f7route.params.id,
            recordtype:'previewpage',
        };

        this.setState(()=>{

          return{
            isdatafetched:false
          }

        },()=>{

          setTimeout(()=> {
  
            let serverurl = "/";
  
            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

            const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
    
            app.request.promise.post(`${serverurl}api/page.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {
  
                // Hide Pull To Refresh
                app.ptr.done();
  
                const resdata = JSON.parse(res.data);
               
                if(resdata.success === true)
                {
                    this.setState(()=>{
                        return{
                            pagedescriptiontext:resdata.pagedetail.description,
                            bannerimage:resdata.pagedetail.bannerimage,
                            isptr:false,
                            isdatafetched:true,
                            hasdataavailable:true
                        }
                    })
                }
                else
                {
                    this.setState(()=>{
                        return{
                            pagedescriptiontext:'',
                            bannerimage:'',
                            isptr:false,
                            isdatafetched:true,
                            hasdataavailable:false
                        }
                    });
                }
      
            }).catch(e => {
                console.log(e);
            });
  
          },500);

        });
    }

    render(){
        
      const{pagedescriptiontext,bannerimage, isdatafetched, hasdataavailable} = this.state;
      return(
        <Page className="page" name="PreviewPage" pageContent={false}>
          <AppNavbar title="Not found"/>
          <PageContent>
          <CommonConsumer>{(value)=>{

            return(
                <Block>
                        {
                          isdatafetched ? (
                            <Fragment>
                            {
                              hasdataavailable ? (
                                <Block>
                                    {bannerimage ? (<Fragment><img src={bannerimage} /><br/></Fragment>):null} 
                                        {pagedescriptiontext}
                                </Block>  
                              ):(
                                  <Block>
                                      <br />
                                      <Row>
                                      <Col>
                                          <img src={logo404} className="norecimg" />
                                          <BlockTitle className="errmsg">No record found.</BlockTitle>
                                      </Col>
                                      </Row>
                                  </Block>                                
                              )
                            }
                            </Fragment>
                          ):(
                            <Fragment>
                            <List mediaList className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>                              
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>                              
                              <ListItem
                                title="Title Title Title Title Title Title Title Title Title"
                              >
                              </ListItem>
                            </List>
                          </Fragment>
                          )
                        }
                    <br /><br /><br />
                </Block>
            );

          }}
          </CommonConsumer>
          <br />
          </PageContent>
        </Page>
      );
    }
}