import React,{Component} from 'react';
import { CustomerConsumer } from '../context/CustomerContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Icon,
    Block,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Button,
    Toggle,
    Link,
    Toolbar,
  } from 'framework7-react';

  export default class QuickAddList extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        name:'',
        status:1,
        deletefailednumbers:1,
        canstaffuse:0,
        description:'',
        clientdetail:[],
        isloaded:false,
        frmsuccess:false,
        resid:'',
        resname:'',
        name_org:'',
        status_org:1,
        description_org:'',
        internetstatus:navigator.onLine ? "online" : "offline",
      };
      this.handleUserInput = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.hideSplashScreen();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else if(name === 'canstaffuse')
      {
        this.setState({canstaffuse:!this.state.canstaffuse});
      }
      else if(name === 'deletefailednumbers')
      {
        this.setState({deletefailednumbers:!this.state.deletefailednumbers});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    saveListDetail(){
      const self      = this;
      const app       = self.$f7;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

     /* const toastWithButton = app.toast.create({
        text: 'Please enter all required field.',
        closeButton: true,
        closeTimeout: 3000,
      });*/
      let errmsg    = "";

      app.input.validateInputs('.quickadddata');

      if(this.state.name === "")
      {
        errmsg = "Please enter list name";
      }
     
      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      let bodyFormData = {};

      if(Number(this.state.clientdetail.isstaff) > 0)
      {
        bodyFormData = {
            Mode:'AddList',
            clientid:self.state.clientdetail.id,
            name:self.state.name,
            status:Number(self.state.status),
            deletefailednumbers:Number(self.state.deletefailednumbers),
            canstaffuse:0,
            staffid:this.state.clientdetail.staffid,
            description:self.state.description,
        };
      }
      else
      {
        bodyFormData = {
            Mode:'AddList',
            clientid:self.state.clientdetail.id,
            name:self.state.name,
            status:Number(self.state.status),
            deletefailednumbers:Number(self.state.deletefailednumbers),
            canstaffuse:Number(self.state.canstaffuse),
            staffid:0,
            description:self.state.description,
        };
      }

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

        app.request.promise.post(`${serverurl}api/list.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();

            if(resdata.success === true)
            {
              Promise.all([
                this.setState(()=>{
                  return{
                    name:'',
                    status:1,
                    deletefailednumbers:1,
                    canstaffuse:0,
                    description:'',
                    frmsuccess:true,
                    resid:resdata.recordid,
                    resname:resdata.name,
                  }
                })
              ])
              .then(() => {

                this.Dom7('.item-content').removeClass('item-input-with-error-message');
                this.Dom7('.item-content').removeClass('item-input-invalid');
                /*this.props.fetchNewArea(resdata.recordid, resdata.name);
                this.props.closePopup();*/
  
              });

            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    render(){

      const{resid, resname, frmsuccess, internetstatus, name, status, description, name_org, status_org, description_org} = this.state;

      return(
        <CustomerConsumer>{(value)=>{

          const{setListData}  = value;

          if(frmsuccess === true)
          {
            Promise.all([
              setTimeout(()=> {

                setListData(Number(resid), resname, true);
    
              }, 500)
              
            ])
            .then(() => {

              setTimeout(()=> {

                this.$f7.popup.close();

                /*this.$$('.popup-close').trigger('click');*/
    
              }, 1200)
              
            })
          }

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Add New List</NavTitle>
                      <NavRight>
                        <Link className="popup-close-custom" onClick={()=>{
                          if((name !== name_org) || (status !== status_org) || (description !== description_org))
                          {
                            if(internetstatus === 'online')
                            {
                              this.$f7.actions.create({
                                buttons: [
                                  // First group
                                  [
                                    {
                                      text: 'Select an action to proceed',
                                      label: true
                                    },
                                    {
                                      text: 'Save',
                                      bold: true,
                                      color:'blue',
                                      onClick:()=>{

                                        this.saveListDetail();

                                      }
                                    },
                                    {
                                      text: `Don't Save`,
                                      bold: true,
                                      onClick:()=>{

                                        this.$f7.popup.close();

                                      }
                                    }
                                  ],
                                  // Second group
                                  [
                                    {
                                      text: 'Cancel',
                                      color: 'red'
                                    }
                                  ]
                                ]
                              }).open();

                            };
                          }
                          else
                          {
                            this.$f7.popup.close();
                          }
                        }}>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="QuickAddList">
                    <Block>
                        <List noHairlinesMd>
        
                            <ListInput
                                name="name"
                                className="quickadddata"
                                label="Name"
                                floatingLabel
                                type="text"
                                onInput={this.handleUserInput}
                                placeholder="Name"
                                value={name}
                                maxlength="200"
                                clearButton required validate
                            >
                            </ListInput>

                            <ListInput
                                name="description"
                                label="Description"
                                floatingLabel
                                type="textarea"
                                onInput={this.handleUserInput}
                                value={description}
                                resizable
                                placeholder="Description"
                            >
                            </ListInput>
                            <ListItem title="Status">
                              <Toggle slot="after" name="status" checked={Number(status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                            </ListItem>
                            <ListItem title="Delete Failed Numbers?">
                                  <Toggle slot="after" name="deletefailednumbers" checked={Number(this.state.deletefailednumbers) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                                </ListItem>
                            {
                              this.state.clientdetail && Number(this.state.clientdetail.isstaff) ? null:(
                                <ListItem title="Available For Staff">
                                  <Toggle slot="after" name="canstaffuse" checked={Number(this.state.canstaffuse) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                                </ListItem>
                              )
                            }
                        </List>
                    </Block>
                    {/*</PageContent>*/}
                    <br />
                    {/*<Fab position="center-bottom" slot="fixed" text="Save" onClick={()=>{
                      if(this.state.internetstatus === "online")
                      {
                        this.saveListDetail();
                      }
                    }}>
                      <Icon ios="f7:floppy_disk" aurora="f7:plus" md="material:save"></Icon>
                    </Fab>*/}
                    <Toolbar position="bottom">
                      <Row style={{width:'100%'}}>
                          <Col width="60"></Col>
                          <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                          onClick={()=>{
                            if(this.state.internetstatus === "online")
                            {
                              this.saveListDetail();
                            }
                          }}
                          ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                      </Row>
                    </Toolbar>
                  </Page>
              </Popup>
            );
        }}
        </CustomerConsumer>
      );

    }
}