import React,{Component} from 'react';
import AppNavbar from './appnavbar';
import { CustomerConsumer } from '../context/CustomerContext';

import {
    Page,
    PageContent,
    Block,
    List,
    ListInput,
    ListItem,
    Icon,
    Toggle,
    Row,
    Col,
    Link,
    Toolbar,
    Button
  } from 'framework7-react';

  export default class EditContact extends Component {
    constructor(props) {
      super(props);

      let today = new Date();

      let currmonth = today.getMonth()+1;
      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }

      let currdate = today.getDate();
      if(currdate < 10)
      {
          currdate = "0"+currdate;
      }

      let date = today.getFullYear()+'-'+currmonth+'-'+currdate;
  
      this.state = {
        name:'',
        phone:'',
        status:1,
        remarks:'',
        clientdetail:'',
        isptr:false,
        isloaded:false,
        listid:'',
        listrecords:[],
        colwidth:80,
        popupOpenedList:false,
        internetstatus:navigator.onLine ? "online" : "offline",
        ismount:false,
        listname:false,
        issmartarea:false,
        hasbirthday:false,
        dob:date,
        hassmartarea:false
      };

      this.handleUserInput    = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.fetchNewList       = this.fetchNewList.bind(this);
      this.openListPopup      = this.openListPopup.bind(this);

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        if(this.state.clientdetail.islineman === 1)
        {
          this.setState({colwidth:100});
        }
        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getList();

        this.getDetail();
        this.hideSplashScreen();

      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);
      
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else if(name === 'hasbirthday')
      {
        this.setState({hasbirthday:!this.state.hasbirthday});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(this.state.internetstatus === "offline")
        {
             const toastWithButton = app.toast.create({
                 text: "No internet connection",
             });
               
             toastWithButton.open();
             
             return;
        }

        const bodyFormData = {
            Mode:'GetContactDetail',
            clientid:self.state.clientdetail.id,
            recordid:self.props.f7route.params.id,
        };

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/contact.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
            
            if(resdata.success === true)
            {
              this.setState({
  
                name:resdata.contactdetail.name,
                phone:resdata.contactdetail.phone,
                status:resdata.contactdetail.status,
                listid:resdata.contactdetail.listid,
                hasbirthday:resdata.contactdetail.hasbirthday,
                dob:resdata.contactdetail.dob,
                isptr:false,

              },()=>{
                this.Dom7('#autocomplete-list-name2').text(resdata.contactdetail.listname);
              });
            }
            else
            {
              this.setState({
  
                isptr:false,

              });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    listAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      const searchlinelist  = this.state.listrecords;

      self.autocompleteStandalonePopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-list-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectlist/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchlinelist.length; i += 1) {
            if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-list-name2').text(value[0].name);
            $('.autocomplete-list-popup').removeClass('error');
            
            self.setState({
              listid:value[0].id
            });

          },
        },
      });
    }

    fetchNewList(newlistid, newlist){

      this.setState(()=>{

        return{

          listid:newlistid

        }

      },()=>{

        this.Dom7('#autocomplete-list-name2').text(newlist);
        this.getList();

      })

    }

    getList(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      const bodyFormData = {
          Mode:'GetList',
          Type:'Assign',
          clientid:clientdetail.id,
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/list.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                listrecords:resdata.recordlist,
              },()=>{
                if(this.state.hassmartarea === false)
                {
                  this.setState({hassmartarea:true});
                  this.listAutocompletePopup();
                }
              });
            }
            else
            {
              this.setState({
                listrecords:[],
              },()=>{
                if(this.state.hassmartarea === false)
                {
                  this.setState({hassmartarea:true});
                  this.listAutocompletePopup();
                }
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    openListPopup(){

      this.setState(()=>{

        return{
          popupOpenedList:!this.state.popupOpenedList
        }

      });

    }

    saveDetail(){
      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      let errmsg    = "";

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      app.input.validateInputs('.adddata');

      if(this.state.listid === "" && errmsg === "")
      {
        errmsg  = "Please select a List";
      }

      if(this.state.listid === "")
      {
        this.$$('.autocomplete-list-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-list-popup').removeClass('error');
      }

      if(this.state.name === "" && errmsg === "")
      {
        //errmsg  = "Please enter name";
      }
      if(this.state.phone === "" && errmsg === "")
      {
        errmsg  = "Please enter phone number";
      }
      else if(Number(this.state.phone.length) < 10 && errmsg === "")
      {
        errmsg  = "Please enter a valid phone number";
      }

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      let bodyFormData  = {};

      if(Number(self.state.clientdetail.isstaff) > 0)
      {
        bodyFormData = {
            Mode:'EditContact',
            clientid:self.state.clientdetail.id,
            recordid:self.props.f7route.params.id,
            name:self.state.name,
            phone:self.state.phone,
            status:Number(self.state.status),
            remarks:self.state.remarks,
            listid:self.state.listid,
            staffid:self.state.clientdetail.staffid,
            hasbirthday:Number(self.state.hasbirthday),
            dob:self.state.dob,
        };
      }
      else
      {
        bodyFormData = {
            Mode:'EditContact',
            clientid:self.state.clientdetail.id,
            recordid:self.props.f7route.params.id,
            name:self.state.name,
            phone:self.state.phone,
            status:Number(self.state.status),
            remarks:self.state.remarks,
            listid:self.state.listid,
            staffid:0,
            hasbirthday:Number(self.state.hasbirthday),
            dob:self.state.dob,
        };
      }

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/contact.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();
  
            if(resdata.success === true)
            {
                router.navigate('/managecontacts/');             
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){
      
      const{colwidth, clientdetail, listid, ismount, hasbirthday, dob} = this.state;

      return(
        <CustomerConsumer>{(value)=>{

          const{singlelist} = value;

          if(ismount === true)
          {
            setTimeout(()=> {

              if((singlelist.id !== this.state.listid) && singlelist.id > 0)
              {
                this.fetchNewList(singlelist.id, singlelist.name);
              }
  
            }, 500) 
          }

      return(
            <Page className="page" name="EditContact" pageContent={false}>
              <AppNavbar title="Edit Contact"/>
              <PageContent>
                <Block>
                    <Row>
                        <Col width={colwidth}>
                        <List noHairlinesMd className="zeromargin">
                          <ListItem link="#" id="autocomplete-list-popup" className="autocomplete-list-popup" title="List">
                            <div slot="after" id="autocomplete-list-name2">Select</div>
                          </ListItem>
                        </List>
                        </Col>
                        <Col width="15" style={{marginTop:'8px',marginRight:'15px'}}>
                          <Link onClick={()=>{
                            if(this.state.internetstatus === "online")
                            {
                              this.$f7router.navigate('addlistpopup/')
                            }
                          }}>
                            <Icon ios="f7:plus" aurora="f7:plus" md="material:add_circle" style={{fontSize:'30px'}}></Icon>
                          </Link>
                        </Col>
                    </Row>
                    <List noHairlinesMd style={{marginBottom:'5px'}}>

                      <ListInput
                          name="name"
                          label="Name"
                          floatingLabel
                          type="text"
                          onInput={this.handleUserInput}
                          placeholder="Name"
                          value={this.state.name}
                          maxlength="200"
                          clearButton
                      >
                      </ListInput>

                      <ListInput
                          name="phone"
                          className='adddata'
                          label="Mobile"
                          floatingLabel
                          type="tel"
                          onInput={this.handleUserInput}
                          placeholder="Mobile"
                          value={this.state.phone}
                          maxlength="10"
                          clearButton required validate pattern="[0-9]{10}"
                      >
                      </ListInput>

                      <ListItem title="Status">
                        <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                      </ListItem>

                      <ListItem title="Has Birthday">
                        <Toggle slot="after" name="hasbirthday" checked={Number(hasbirthday) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                      </ListItem>
                      {
                        Number(hasbirthday) ? (
                          <ListInput
                              name="dob"
                              id="calendar-dob-input"
                              label="Date Of Birth"
                              placeholder="Date Of Birth"
                              type="date"
                              value={dob}
                              onChange={this.handleUserInput}
                              calendarParams={{dateFormat: { day: 'numeric', month: 'numeric', year: 'numeric'},closeOnSelect:true}}
                          >
                          </ListInput>
                        ):null
                      }

                    </List>
                </Block>
                {/*</PageContent>*/}
                <br />
                </PageContent>
                <Toolbar position="bottom">
                  <Row style={{width:'100%'}}>
                      <Col width="60"></Col>
                      <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                      onClick={()=>{
                        if(this.state.internetstatus === "online")
                        {
                          this.saveDetail();
                        }
                      }}
                      ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                  </Row>
                </Toolbar>
            </Page>
          );          
          
        }}</CustomerConsumer>
      );
    }
}