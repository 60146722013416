import React,{Component,Fragment} from 'react';
import AppNavbar from './appnavbar';
import logo404 from '../assets/images/orlo404.png';

import {
    Page,
    PageContent,
    Fab,
    Icon,
    Row,
    Col,
    Block,
    BlockTitle,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton    
  } from 'framework7-react';

  export default class managePage extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isavailable:false,
        loaded:false,
        clientdetail:'',
        recordlist:[],
        actionGridOpened: false,
        oneGroupOpened: false,
        deleterecordid:'',
        isptr:false,
        isloaded:false,
        effect:'blink',
        internetstatus:navigator.onLine ? "online" : "offline",
      };

      this.getAllPage         = this.getAllPage.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.navigateToLink     = this.navigateToLink.bind(this);
      this.confirmDelete      = this.confirmDelete.bind(this);
      
    }

    componentDidMount(){
  
      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.hideSplashScreen();

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          setTimeout(()=> {

            this.getAllPage();
            
          }, 200)

        })
        
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    confirmDelete(){

      const self      = this;
      const app       = self.$f7;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      const bodyFormData = {
          Mode:'DeletePage',
          clientid:this.state.clientdetail.id,
          recordid:this.state.deleterecordid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/page.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              let temprecordlist	= [...this.state.recordlist];
              temprecordlist	= temprecordlist.filter(page => page.id !== this.state.deleterecordid);
        
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                    recordlist:temprecordlist
                  }
                },()=>{
                  if(this.state.recordlist.length < 1)
                  {
                    this.setState(()=>{
                      return {
                        isavailable:false
                      }
                    });
                  }
                }
              );
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);
    }

    deleteRecord(){

      const self      = this;
      const app       = self.$f7;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      if(this.state.hascontact)
      {
        app.dialog.confirm(`Are you sure, you want to delete? There are ${this.state.contactcount} contact(s) in page.`,'Delete List Confirmation',() => {

          this.confirmDelete();

        },()=>{

          this.setState({
            deleterecordid:'',
            hascontact:false,
            contactcount:''
          })

        });
      }
      else
      {
        this.confirmDelete();
      }
    }

    getAllPage(){

        const self      = this;
        const app       = self.$f7;

        if(this.state.internetstatus === "offline")
        {
             const toastWithButton = app.toast.create({
                 text: "No internet connection",
             });
               
             toastWithButton.open();
             
             return;
        }
  
        const bodyFormData = {
            Mode:'GetAllPage',
            clientid:this.state.clientdetail.id,
        };

        /*if(this.state.isptr !== true)
        {
          app.preloader.show();
        }*/

        this.setState(()=>{
          return{
            loaded:false
          }
        },()=>{

          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');
  
          }, 50);

          // Simulate Ajax Request
          setTimeout(()=> {

            let serverurl = "/";

            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

            const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${serverurl}api/page.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request
        
                // Hide Preloader
                app.preloader.hide();
                
                // Hide Pull To Refresh
                app.ptr.done();
      
                const resdata = JSON.parse(res.data);

                if(resdata.success === true)
                {
                  this.setState({
                    loaded:true,
                    isavailable:true,
                    recordlist:resdata.recordlist,
                    isptr:false,
                  });
                }
                else{
                  this.setState({
                    loaded:true,
                    isavailable:false,
                    isptr:false,
                  });
                }
      
            }).catch(e => {
                console.log(e);
            });
    
          }, 500);

        })
    }

    navigateToLink(link){
        
        if(this.state.internetstatus === "online")
        {
          this.$f7router.navigate(`/${link}/`);
          /*this.$f7.views.main.router.navigate(`/${link}/`);*/
        }

    }

    render(){

       const{isavailable, loaded, recordlist} = this.state;

        return(
            <Page className="page" name="managePage" pageContent={false}>
              <AppNavbar title="Pages" showrefresh={true} init={this.getAllPage}/>
              <PageContent>
                  {
                    loaded ? (<Fragment>
                      {
                        isavailable ? (
                          <Fragment>
                            {
                                recordlist.map((page)=>{
                                  let temppagelink = page.pagelink;
                                  return(
                                    <Card outline key={page.id} className="recordset">
                                      <CardHeader
                                      className="date">{page.addeddate}</CardHeader>
                                      <CardContent>
                                      <p>{page.name}</p>
                                      </CardContent>
                                      <CardFooter>
                                        <Link onClick={()=>{this.navigateToLink(`editpage/${page.id}`)}} ignoreCache={true}>Edit</Link>
                                        <Link onClick={() =>{
                                          if(this.state.internetstatus === "online")
                                          {
                                            window.open(`${temppagelink}?preview=true&${page.extraarg}` , '_blank');
                                          }
                                        }}
                                         >Preview</Link>
                                        <Link onClick={() =>{
                                          if(this.state.internetstatus === "online")
                                          {
                                            this.setState(()=>{
                                              return {
                                                deleterecordid:page.id
                                              }
                                            },()=>{
                                              this.refs.actionsOneGroup.open()
                                            })
                                          }
                                        }}>Delete</Link>
                                      </CardFooter>
                                    </Card>
                                  );
                                })
                            }
                          </Fragment>
                        ):(<Block>
                          <br />
                          <Row>
                            <Col>
                              <img src={logo404} className="norecimg" />
                              <BlockTitle className="errmsg">No page record found.</BlockTitle>
                            </Col>
                          </Row>
                          </Block>)
                      }
                    </Fragment>):(<Fragment>
                      <Card className={`waiting recordset skeleton-text skeleton-effect-${this.state.effect}`}>
                        <CardHeader
                        className="date"><div className="item-title">xxxxxxxxx</div></CardHeader>
                        <CardContent>
                          <p><b>Name : </b>xxxxxxx xxxxxxx</p>
                          <p><b>Description : </b>xxx xxx xxxx</p>
                        </CardContent>
                        <CardFooter>
                          <Link >Edit</Link>
                          <Link >Delete</Link>
                        </CardFooter>
                      </Card>
                      <Card className={`waiting recordset skeleton-text skeleton-effect-${this.state.effect}`}>
                        <CardHeader
                        className="date"><div className="item-title">xxxxxxxxx</div></CardHeader>
                        <CardContent>
                          <p><b>Name : </b>xxxxxxx xxxxxxx</p>
                          <p><b>Description : </b>xxx xxx xxxx</p>
                        </CardContent>
                        <CardFooter>
                          <Link >Edit</Link>
                          <Link >Delete</Link>
                        </CardFooter>
                      </Card>
                      <Card className={`waiting recordset skeleton-text skeleton-effect-${this.state.effect}`}>
                        <CardHeader
                        className="date"><div className="item-title">xxxxxxxxx</div></CardHeader>
                        <CardContent>
                          <p><b>Name : </b>xxxxxxx xxxxxxx</p>
                          <p><b>Description : </b>xxx xxx xxxx</p>
                        </CardContent>
                        <CardFooter>
                          <Link >Edit</Link>
                          <Link >Delete</Link>
                        </CardFooter>
                      </Card>
                    </Fragment>)
                  }
              <br /><br />
              </PageContent>
              <Actions ref="actionsOneGroup">
                <ActionsGroup>
                  <ActionsLabel bold>Are you sure?</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.deleteRecord()}}>Yes</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">No</ActionsButton>
                </ActionsGroup>
              </Actions>
              <br /><br />
              <Fab position="center-bottom" slot="fixed" text="Add page" onClick={()=>{
                if(this.state.internetstatus === "online")
                {
                  this.$f7router.navigate('/addpage/');
                }
              }}>
                  <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
              </Fab>
            </Page>
      );
    }
}