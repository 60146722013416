import React,{Component,Fragment} from 'react';
import AppNavbar from './appnavbar';
import { CommonConsumer } from '../context/CommonContext';
import logo404 from '../assets/images/orlo404.png';

import {
    Page,
    PageContent,
    BlockTitle,
    Block,
    List,
    Link,
    Row,
    Col,
    ListItem,
    Fab,
    Icon,
    Chip
  } from 'framework7-react';

  export default class BulkAddCompletedRequest extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;
      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let date = today.getFullYear()+'-'+currmonth+'-'+today.getDate();
     
      this.state = {
        bulkqueuelog:[],
        clientdetail:'',
        /*subscriptiondate:date,*/
        isptr:false,
        isloaded:false,
        internetstatus:navigator.onLine ? "online" : "offline",
        ismount:false,
        isdatafetched:false,
        effect:'blink',
        hasdataavailable:false,
        totalcompleted:0,
        totalinprogress:0,
        deleterecordid:'',
      };

      this.handleUserInput    = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.getBulkCompletedRequestLog    = this.getBulkCompletedRequestLog.bind(this);
      this.deleteRecordAction = this.deleteRecordAction.bind(this);

    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getBulkCompletedRequestLog();
        this.hideSplashScreen();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus(){

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }
    
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getBulkCompletedRequestLog(){

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
            Mode:'GetCompletedBulkAddRequest',
            clientid:self.state.clientdetail.id,
        };

        this.setState(()=>{

          return{
            isdatafetched:false
          }

        },()=>{

          setTimeout(()=> {
  
            let serverurl = "/";
  
            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

            const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
    
            app.request.promise.post(`${serverurl}api/bulkadd.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {
  
                // Hide Pull To Refresh
                app.ptr.done();
  
                const resdata = JSON.parse(res.data);
      
                if(resdata.success === true)
                {
                    this.setState(()=>{
                        return{
                            bulkqueuelog:resdata.recordlist,
                            totalcompleted:resdata.totalcompleted,
                            isptr:false,
                            isdatafetched:true,
                            hasdataavailable:true
                        }
                    })
                }
                else
                {
                    this.setState(()=>{
                        return{
                            isptr:false,
                            isdatafetched:true,
                            hasdataavailable:false,
                            totalcompleted:0,
                        }
                    });
                }
      
            }).catch(e => {
                console.log(e);
            });
  
          },500);

        });
    }

    deleteRecordAction(recordid){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                this.setState(()=>{
                  return{
                    deleterecordid:recordid
                  }
                },()=>{

                  this.deleteSingleRecord();

                })
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    deleteSingleRecord(){

      const self	= this;
      const app	= self.$f7;

        const bodyFormData = {
            Mode:'DeleteBulkAddRequest',
            clientid:this.state.clientdetail.id,
            recordid:this.state.deleterecordid,
        };

        app.preloader.show();

        // Simulate Ajax Request
        setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');

          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${serverurl}api/smscredit.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();

              const resdata = JSON.parse(res.data);

              const toastWithButton = app.toast.create({
                text: resdata.msg,
                closeButton: true,
                closeTimeout: 3000,
              });
    
              toastWithButton.open();

              if(resdata.success === true)
              {
                this.setState(
                  ()=>{
                    return {
                      deleterecordid:'',
                    }
                  },()=>{
                    this.getBulkCompletedRequestLog();
                  }
                );
                
              }
    
          }).catch(e => {
              console.log(e);
          });

        }, 500);	
    }    

    render(){
        
      const{bulkqueuelog, isdatafetched, hasdataavailable, totalcompleted, totalinprogress} = this.state;

      return(
        <Page className="page" name="BulkAddPCompletedRequest" pageContent={false}>
        <AppNavbar title="Completed Bulk Request" showrefresh={true} init={this.getBulkCompletedRequestLog}/>
        <PageContent>
          <CommonConsumer>{(value)=>{

            const{navigateToLink} = value;

            const{internetstatus} = value;

            return(
                    <Block>
                            {
                              isdatafetched ? (
                                <Fragment>
                                {
                                  hasdataavailable ? (
                                              <Fragment>
                                               <Block style={{textAlign:'center',fontWeight:'bold'}}>
                                                <Chip text={`Total Completed : ${totalcompleted}`} mediaBgColor="pink" media="C"/>
                                                </Block>
                                                <Row noGap className='rowwithbottomborder'>
                                                    <Col width='15' className="tableheader">
                                                        Date
                                                    </Col>
                                                    <Col width='25' className="tableheader">
                                                        List
                                                    </Col>
                                                    <Col width='20' className="tableheader">
                                                        Total
                                                    </Col>
                                                    <Col width='20' className="tableheader">
                                                        Result
                                                    </Col>
                                                    <Col width='20' className="tableheader">
                                                        Options
                                                    </Col>
                                                </Row>
                                                {
                                                bulkqueuelog.map((bulkqueuelog)=>{
                                                      
                                                      let statusclassname = '';
                                                      if(Number(bulkqueuelog.isinprogress) > 0)
                                                      {
                                                        statusclassname = 'redcolortxt';
                                                      }
                                                      return(
                                                        <Row key={bulkqueuelog.id} noGap className='rowwithbottomborder'>
                                                            <Col width='15' className="ledgercolumn">
                                                                {bulkqueuelog.completedon}
                                                            </Col>
                                                            <Col width='25' className="ledgercolumn">
                                                                {bulkqueuelog.listname}
                                                            </Col>
                                                            <Col width='20' className="ledgercolumn">
                                                                {bulkqueuelog.totalrecords}
                                                                {
                                                                  bulkqueuelog.ispartialupload ? (
                                                                    <Fragment><br/><span style={{color:'red'}}>Partial Upload <br /> ( {bulkqueuelog.fromposition} - {bulkqueuelog.toposition} )</span></Fragment>
                                                                  ):null
                                                                }
                                                            </Col>
                                                            <Col width='20' className="ledgercolumn" >
                                                                {bulkqueuelog.added ? (<span style={{color:'green'}}>Added: {bulkqueuelog.added}</span>):null}
                                                                {bulkqueuelog.alreadyexists ? (<Fragment><br/><span style={{color:'red'}}>Duplicate: {bulkqueuelog.alreadyexists}</span></Fragment>):null}
                                                                {bulkqueuelog.invalid ? (<Fragment><br/><span style={{color:'red'}}>Invalid: {bulkqueuelog.invalid}</span></Fragment>):null}
                                                            </Col>
                                                            <Col width='15' className={`ledgercolumn`}>
                                                                <Fragment>{bulkqueuelog.filelink ? (<Fragment>
                                                                  <Link onClick={()=>{window.open(`${bulkqueuelog.filelink}`)}} style={{marginRight:'8px'}}><Icon f7="folder_circle_fill" color='blue' /></Link>
                                                                    </Fragment>):null}</Fragment>
                                                            </Col>
                                                        </Row>
                                                        );
                                                    })
                                                }
                                    </Fragment>                                
                                  ):(
                                      <Block>
                                          <br />
                                          <Row>
                                          <Col>
                                              <img src={logo404} className="norecimg" />
                                              <BlockTitle className="errmsg">No record found.</BlockTitle>
                                          </Col>
                                          </Row>
                                      </Block>                                
                                  )
                                }
                                </Fragment>
                              ):(
                                <Fragment>
                                <List mediaList className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>                              
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>                              
                                  <ListItem
                                    title="Title Title Title Title Title Title Title Title Title"
                                  >
                                  </ListItem>
                                </List>
                              </Fragment>
                              )
                            }
                        <br /><br /><br />
                    </Block>
            );

            }}
          </CommonConsumer>
        <br /><br />
        </PageContent>
         
        <Fab position="center-bottom" slot="fixed" text="Bulk Add" onClick={()=>{
                if(this.state.internetstatus === "online")
                {
                  this.$f7router.navigate('/bulkadd/');
                }
              }}>
                  <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
              </Fab>
        </Page>

      );
    }
}