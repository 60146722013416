import React,{Component} from 'react';

const ContactContext = React.createContext();
//Provider
//Consumer

class ContactProvider extends Component{

	constructor(props){
		super(props);

        this.state = {
          clientdetail:[],
          effect:'blink',
          isloaded:false,
          searchkeyword:'',
          isavailable:false,
          loaded:false,
          listcrecords:[],
          totalcontact:'',
          isdashboardloaded:false,
          haslistrecords:true,
          loaded_contact:false,
          isavailable_contact:true,
          allcontacts:[],
          contacts:[],
          actionGridOpened: false,
          oneGroupOpened: false,
          deleterecordid:'',
          isptr:false,
          toolbarPosition:'bottom',
          page:1,
          perpage:'',
          totalpages:'',
          popupOpened:false,
          applyfilter:false,
          showpages:false,
          listid:'',
          paginglist:[],
          totalrecord:'',
          hasfilter:true,
          filterlist:'',
          removefiltername:'',
          applyremovefilter:false,
          hasallcontact:true,
          ismount:false,
          isallcontact:false,
          isbulkcontact:'',
          bulkcontactname:'',
          recordrange:'',
          internetstatus:navigator.onLine ? "online" : "offline",
        };

        this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
        this.handleUserInput    = this.handleUserInput.bind(this);
        this.getContactList     = this.getContactList.bind(this);
        this.init               = this.init.bind(this);

        this.getAllContacts      = this.getAllContacts.bind(this);
        this.searchContact       = this.searchContact.bind(this);
        this.setNewFilterData     = this.setNewFilterData.bind(this);
        this.openFilterPopup      = this.openFilterPopup.bind(this);
        this.deleteFromPopUp      = this.deleteFromPopUp.bind(this);
        this.applySearchContact   = this.applySearchContact.bind(this);
        this.showAllContact       = this.showAllContact.bind(this);
        this.handleUserInput_contact = this.handleUserInput_contact.bind(this);
        this.deleteFilterAction		  = this.deleteFilterAction.bind(this);
        this.deleteRecordAction		  = this.deleteRecordAction.bind(this);
        this.Paging		              = this.Paging.bind(this);
        this.initContact		        = this.initContact.bind(this);
        this.clearContactFilter		  = this.clearContactFilter.bind(this);
	}

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));


      this.hideSplashScreen();
      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);
      
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

        this.setState(()=>{
          return{
            [name]: value
          }
        });

    }

    handleUserInput_contact(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === "searchkeyword")
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        },()=>{
  
          this.applySearchContact();
  
        });
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        },()=>{
  
          this.Paging('select');
  
        });
      }

    }
    
    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    getContactList(){

        const self      = this;
        const app       = self.$f7;

        if(this.state.internetstatus === "offline")
        {
             const toastWithButton = app.toast.create({
                 text: "No internet connection",
             });
               
             toastWithButton.open();
             
             return;
        }

        let bodyFormData = {};

        if(Number(self.state.clientdetail.isstaff) > 0)
        {
          bodyFormData = {
              Mode:'GetContactList',
              clientid:self.state.clientdetail.id,
              staffid:self.state.clientdetail.staffid,
          };
        }
        else
        {
          bodyFormData = {
              Mode:'GetContactList',
              clientid:self.state.clientdetail.id,
          };
        }
          // Simulate Ajax Request
        setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${serverurl}api/contact.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();
    
              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  loaded:true,
                  isavailable:true,
                  listcrecords:resdata.recordlist,
                  totalcontact:resdata.totalcontact,
                  haslistrecords:resdata.haslistrecords,
                  isloaded:true,
                  isdashboardloaded:true,
                },()=>{
                  setTimeout(()=> {

                    this.Dom7('.contactdashboardelev').addClass('animate-bottom');
            
                  }, 100);
                });
              }
              else{
                this.setState({
                  loaded:true,
                  isavailable:false,
                  totalcontact:0,
                  isloaded:true,
                  isdashboardloaded:true
                });
              }
    
          }).catch(e => {

              console.log(e);

          });
  
        }, 500);
    }

    init(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      
      if(clientdetail !== undefined && clientdetail !== null)
      {
          this.setState(()=>{
            return{
  
              clientdetail:clientdetail,
              loaded:false,
              isdashboardloaded:false
  
            }
          },()=>{
  
            this.getContactList();
  
          });
      }
    }

    openFilterPopup(){

      if(this.state.internetstatus === "online")
      {
        this.$f7.views.main.router.navigate('filterpopup/');
      }
    }

    setFilterData(name, value, filtername, filtervalue){

      this.setState(()=>{
        
        return{
          [name]: value,
          [filtername]: filtervalue,
        }

      },()=>{

        if(name === 'applyfilter' && value === true)
        {

          this.setState(()=>{

            return{
              loaded_contact:false,
              applyfilter:false,
              popupOpened:false,
              page:1,
            }

          },()=>{

            if(Number(this.state.listid) > 0 || (this.state.searchkeyword !== "" && this.state.searchkeyword !== undefined))
            {
              this.setState({hasfilter:true,hasallcontact:false});
            }
            else
            {
              this.setState({hasfilter:true,hasallcontact:true});
            }

            if(this.state.internetstatus === "offline")
            {
              const toastWithButton = this.$f7.toast.create({
                text: "No internet connection",
              });
          
              toastWithButton.open();
              
              return;
            }

            this.getAllContacts();

          });

        }

      });

    }

    setNewFilterData(listid, filterlist, isbulkcontact, bulkcontactname){

      if(this.state.internetstatus === "offline")
      {
        const toastWithButton = this.$f7.toast.create({
          text: "No internet connection",
        });
    
        toastWithButton.open();
        
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            listid:listid,
            filterlist:filterlist,
            isbulkcontact:isbulkcontact,
            bulkcontactname:bulkcontactname,
            loaded_contact:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
          }
  
        },()=>{
  
          if(Number(this.state.listid) > 0 || this.state.isbulkcontact !== "" || (this.state.searchkeyword !== "" && this.state.searchkeyword !== undefined))
          {
            this.setState({hasfilter:true,hasallcontact:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcontact:true});
          }
  
          this.getAllContacts();
  
        });

      })
      
    }

    applySearchContact(){

      if(this.state.internetstatus === "offline")
      {
        const toastWithButton = this.$f7.toast.create({
          text: "No internet connection",
        });
    
        toastWithButton.open();
        
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        if(Number(this.state.listid) > 0 || this.state.isbulkcontact !== "" || (this.state.searchkeyword !== "" && this.state.searchkeyword !== undefined))
        {
          this.setState({hasfilter:true,hasallcontact:false});
        }
        else
        {
          this.setState({hasfilter:true,hasallcontact:true});
        }

        this.getAllContacts();

      })

    }

    deleteFromPopUp(recordid){
      this.setState(()=>{
        return{
          deleterecordid:recordid
        }
      },()=>{
        this.deleteRecord();
      })
    }

    deleteRecord(){

      const self      = this;
      const app       = self.$f7;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      const bodyFormData = {
          Mode:'DeleteContact',
          clientid:this.state.clientdetail.id,
          recordid:this.state.deleterecordid,
      };

      app.preloader.show();

      setTimeout(()=> {

        let serverurl = "/";

        if(process.env.NODE_ENV === "development")
        {
            serverurl = "http://orloprachar/app/";
        }

        const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${serverurl}api/contact.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              let tempContacts		= [...this.state.contacts];
              tempContacts	= tempContacts.filter(contact => contact.id !== this.state.deleterecordid);
        
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                    contacts:tempContacts
                  }
                },()=>{
                  if(this.state.contacts.length < 1)
                  {
                    this.setState(()=>{
                      return {
                        isavailable_contact:false
                      }
                    });
                  }
                }
              );
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })

    }

    Paging(mode){

      let pageerror = "";

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = this.$f7.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

      let temppage    = '';

      const currpage  = this.state.page;

      if(mode === 'previous')
      {
        temppage  = Number(this.state.page) - 1;
      }
      if(mode === 'next')
      {
        temppage  = Number(this.state.page) + 1;
      }
      if(mode === 'select')
      {
        temppage  = Number(this.state.page);
      }

      if(temppage < 1)
      {
        pageerror = "You are on first page";
      }

      if(temppage > Number(this.state.totalpages))
      {
        pageerror = "You are on last page";
      }

      if(pageerror !== "")
      {
           const toastWithButton = this.$f7.toast.create({
               text: pageerror,
               closeButton: true,
               closeTimeout: 3000,
           });
             
           toastWithButton.open();
           
           return;
      }

      if(temppage <= 0)
      {
        temppage  = 1;
      }

      if(temppage >= Number(this.state.totalpages))
      {
        temppage  = Number(this.state.totalpages);
      }

      this.setState(()=>{

        return{
          page:temppage,
          loaded_contact:false
        }

      },()=>{

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          if(mode === 'select')
          {
            this.getAllContacts();
          }
          else
          {
            if(Number(this.state.page) !== Number(currpage))
            {
              this.getAllContacts();
            }
          }

        })

      })

    }

    getAllContacts(){

      if(this.state.internetstatus === "offline")
      {
          const toastWithButton = this.$f7.toast.create({
              text: "No internet connection",
          });
            
          toastWithButton.open();
          
          return;
      }

      const self      = this;
      const app       = self.$f7;

      let bodyFormData  = {};

      if(Number(self.state.clientdetail.isstaff) > 0)
      {
        bodyFormData = {
            Mode:'GetAllContacts',
            clientid:Number(this.state.clientdetail.id),
            page:this.state.page,
            perpage:this.state.perpage,
            searchkeyword:this.state.searchkeyword,
            listid:this.state.listid,
            isbulkcontact:this.state.isbulkcontact,
            staffid:self.state.clientdetail.staffid,
        };
      }
      else
      {
        bodyFormData = {
          Mode:'GetAllContacts',
          clientid:Number(this.state.clientdetail.id),
          page:this.state.page,
          perpage:this.state.perpage,
          searchkeyword:this.state.searchkeyword,
          listid:this.state.listid,
          isbulkcontact:this.state.isbulkcontact,
        };
      }

      this.setState(()=>{
        return{
          loaded_contact:false
        }
      },()=>{

        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50);

        setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
        
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${serverurl}api/contact.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              app.preloader.hide();
              
              app.ptr.done();

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                this.setState({paginglist:[]});

                this.setState(()=>{
                  return{
                    loaded_contact:true,
                    isavailable_contact:true,
                    contacts:resdata.recordlist,
                    allcontacts:resdata.recordlist,
                    perpage:Number(resdata.perpage),
                    totalpages:Number(resdata.totalpages),
                    paginglist:resdata.paginglist,
                    showpages:resdata.showpages,
                    totalrecord:resdata.totalrecord,
                    recordrange:resdata.recordrange,
                    isptr:false,
                  }
                });
              }
              else{
                this.setState({
                  loaded_contact:true,
                  isavailable_contact:false,
                  isptr:false,
                  paginglist:[],
                  recordrange:'',
                });
              }

              if(Number(this.state.listid) > 0 || this.state.isbulkcontact !== "" || (this.state.searchkeyword !== "" && this.state.searchkeyword !== undefined))
              {
                this.setState({hasallcontact:false});
              }
              else
              {
                this.setState({hasallcontact:true});
              }
    
          }).catch(e => {
              console.log(e);
          });
  
        }, 500);

      });
    }

    searchContact(){

      const{allcontacts} = this.state;

      let tempContacts  = [];

      if(this.state.searchkeyword !== "" && this.state.searchkeyword !== undefined)
      {
				allcontacts.forEach(contact => {

          if((contact.name.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1) || (contact.phone.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1))
          {
            tempContacts = [...tempContacts, contact];
          }

        });
        
        if(tempContacts.length > 0)
        {
          this.setState(()=>{
            return{
              contacts:tempContacts,
              isavailable_contact:true
            }
          });
        }
        else
        {
          this.setState(()=>{
            return{
              contacts:[],
              isavailable_contact:false
            }
          });
        }
      }
      else
      {
        this.setState(()=>{
          return{
            contacts:allcontacts,
            isavailable_contact:true
          }
        });
      }

    }

    showAllContact(){

      this.setState(()=>{

        return{
          page:1,
          loaded_contact:false,
          isallcontact:true
        }

      },()=>{

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          this.getAllContacts();
          
        })

      })

    }

    deleteFilterAction(filtername){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                if(filtername === "searchkeyword")
                {                  
                  this.setState(()=>{
                    return{
                      searchkeyword:'',
                      loaded_contact:false,
                      applyfilter:false,
                      popupOpened:false,
                      page:1,
                    }
                  },()=>{
  
                    this.getAllContacts();
  
                  })
                }
                else if(filtername === "list")
                {
                  this.setState(()=>{
                    return{
                      listid:'',
                      filterlist:'',
                      loaded_contact:false,
                      applyfilter:false,
                      popupOpened:false,
                      page:1,
                    }
                  },()=>{
  
                    this.getAllContacts();
  
                  })
                }
                else if(filtername === "datatype")
                {

                  this.setState(()=>{
                    return{
                      isbulkcontact:'',
                      bulkcontactname:'',
                      loaded_contact:false,
                      applyfilter:false,
                      popupOpened:false,
                      page:1,
                    }
                  },()=>{
  
                    this.getAllContacts();
  
                  })
                }
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    deleteRecordAction(recordid){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                this.setState(()=>{
                  return{
                    deleterecordid:recordid
                  }
                },()=>{

                  this.deleteRecord();

                })
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    initContact(listid, filterlist, searchkeyword){
      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
      
      if(clientdetail !== undefined && clientdetail !== null)
      {
          this.setState(()=>{
            return{
  
              clientdetail:clientdetail,
              loaded_contact:false,
              isavailable:false,
              applyfilter:false,
              page:1,  
            }
          },()=>{

            this.hideSplashScreen();

            Promise.all([
                
              setTimeout(()=> {
    
                this.Dom7('.waiting').addClass('animate-bottom');
    
              }, 50)
    
            ])
            .then(() => {
    
              setTimeout(()=> {
    
                if(Number(listid) > 0)
                {
                  this.setState(()=>{
    
                    return{
    
                      listid:listid,
                      filterlist:filterlist,
                      hasfilter:true,
                      hasallcontact:false
                      
                    }
    
                  },()=>{
    
                    this.getAllContacts();
    
                  });
                }
                else if(searchkeyword !== "" && searchkeyword !== undefined && searchkeyword !== "advancesearch")
                {
                  this.setState(()=>{
    
                    return{
    
                      searchkeyword:searchkeyword,
                      hasfilter:true,
                      hasallcontact:false
                      
                    }
    
                  },()=>{
    
                    this.getAllContacts();
    
                  });
                }
                else if(searchkeyword !== "" && searchkeyword === "advancesearch")
                {
    
                  this.setState(()=>{
    
                    return{
    
                      popupOpened:true,
                      
                    }
    
                  },()=>{
    
                    this.getAllContacts();
    
                  });
    
                }
                else
                {
                  this.setState(()=>{
    
                    return{
    
                      hasallcontact:true
                      
                    }
    
                  },()=>{
    
                    this.getAllContacts();
    
                  });
                  
                }
                
              }, 200)
    
            })

          });
      }
    }

    clearContactFilter(){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.state.internetstatus === "online")
              {
                this.setState(()=>{
                  return{
                    searchkeyword:'',
                    listid:'',
                    filterlist:'',
                    isbulkcontact:'',
                    bulkcontactname:'',
                    loaded_contact:false,
                    applyfilter:false,
                    popupOpened:false,
                    page:1,
                  }
                },()=>{

                  this.getAllContacts();

                })
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }


	render(){
		return (
			<ContactContext.Provider value={{
              ...this.state,
              init:this.init,
              handleUserInput:this.handleUserInput,
              getContactList:this.getContactList,
              handleUserInput_contact:this.handleUserInput_contact,
              getAllContacts:this.getAllContacts,
              setNewFilterData:this.setNewFilterData,
              openFilterPopup:this.openFilterPopup,
              deleteFromPopUp:this.deleteFromPopUp,
              applySearchContact:this.applySearchContact,
              showAllContact:this.showAllContact,
              deleteFilter:this.deleteFilter,
              Paging:this.Paging,
              deleteFilterAction:this.deleteFilterAction,
              deleteRecordAction:this.deleteRecordAction,
              initContact:this.initContact,
              clearContactFilter:this.clearContactFilter,
			}}
			>
			{this.props.children}
			</ContactContext.Provider>
		);
	}
}

const ContactConsumer = ContactContext.Consumer;

export {ContactProvider, ContactConsumer, ContactContext};