
import LoginPage from '../pages/login.jsx';
import SignUpPage from '../pages/signup.jsx';
import VerifyRegistrationPage from '../pages/verifyregistration.jsx';
import CompleteRegistration from '../pages/completeregistration.jsx';
import ForgetPassword from '../pages/forgetpassword.jsx';
import VerifyAdmin from '../pages/verifyadmin.jsx';
import DashboardPage from '../pages/dashboard.jsx';
import ChangePasswordPage from '../pages/ChangePassword.jsx';
import ProfilePage from '../pages/profile.jsx';

import AddList from '../pages/addlist.jsx';
import ManageList from '../pages/managelist.jsx';
import EditList from '../pages/editlist.jsx';

import AddContact from '../pages/addcontact.jsx';
import ManageContact from '../pages/managecontact.jsx';
import EditContact from '../pages/editcontact.jsx';

import QuickAddList from '../pages/quickaddlist.jsx';
import ContactFilter from '../pages/contactfilter.jsx';

import NotFoundPage from '../pages/404.jsx';

import AddCampaign from '../pages/addcampaign.jsx';
import EditCampaign from '../pages/editcampaign.jsx';
import Campaign from '../pages/managecampaign.jsx';
import ViewLog from '../pages/viewlog.jsx';
import CampaignFilter from '../pages/campaignfilter.jsx';
import DataSelection from '../pages/dataselection.jsx';

import SMSCredit from '../pages/smscredit.jsx';
import SMSCreditPending from '../pages/smscreditpending.jsx';
import AddSMSCredit from '../pages/addsmscredit.jsx';
import AddLeadCredit from '../pages/addleadcredit.jsx';

import ContactDashboard from '../pages/contactdashboard.jsx';

import BulkAddRequest from '../pages/bulkaddrequest.jsx';
import BulkAdd from '../pages/bulkadd.jsx';
import BulkAddPendingTab from '../pages/bulkaddpendingtab.jsx';
import BulkAddCompletedTab from '../pages/bulkaddcompletedtab.jsx';
import PaymentProcessing from '../pages/paymentprocessing.jsx';

/*import AddSenderID from '../pages/addsenderids.jsx';
import SenderIDs from '../pages/senderids.jsx';
import PendingSenderIDsTab from '../pages/pendingsenderids.jsx';
import ApprovedSenderIDsTab from '../pages/approvedsenderids.jsx';*/

import AddPage from '../pages/addpage.jsx';
import ManagePage from '../pages/managepage.jsx';
import EditPage from '../pages/editpage.jsx';
import PreviewPage from '../pages/previewpage.jsx';

import BulkAddThanks from '../pages/bulkaddthanks.jsx';

import Leads from '../pages/manageleads.jsx';
import LeadFilter from '../pages/leadfilter.jsx';
import LeadRemark from '../pages/leadremark.jsx';
import AssignStaff from '../pages/assignstaff.jsx';

import CampaignDashboard from '../pages/campaigndashboard.jsx';
import LeadDashboard from '../pages/leaddashboard.jsx';

import AddLeadCatogory from '../pages/addleadcategory.jsx';
import ManageLeadCategory from '../pages/manageleadcategory.jsx';
import EditLeadCatogory from '../pages/editleadcategory.jsx';

import AdminDashboardPage from '../pages/admindashboard.jsx';

import AddStaff from '../pages/addstaff.jsx';
import ManageStaff from '../pages/managestaff.jsx';
import EditStaff from '../pages/editstaff.jsx';
import SMSCreditTab from '../pages/smscredittab.jsx';
import LeadCreditTab from '../pages/leadcredittab.jsx';

import AddLeadTag from '../pages/addleadtag.jsx';
import ManageLeadTag from '../pages/manageleadtag.jsx';
import EditLeadTag from '../pages/editleadtag.jsx';

import AddCampaignGroup from '../pages/addcampaigngroup.jsx';
import QuickAddCampaignGroup from '../pages/quickaddcampaigngroup.jsx';
import ManageCampaignGroup from '../pages/managecampaigngroup.jsx';
import EditCampaignGroup from '../pages/editcampaigngroup.jsx';

import Configuration from '../pages/configuration.jsx';

import AddSupport from '../pages/addsupport.jsx';
import ManageSupport from '../pages/managesupport.jsx';
import EditSupport from '../pages/editsupport.jsx';

var routes = [
  {
    path: '/',
    /*options:{
      reloadAll:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      var app = this.app;
      /*app.preloader.show();*/
      setTimeout(()=> {

        app.preloader.hide();

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: DashboardPage
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }, 500);
    },
    routes: [
      {
        path: 'forgetpassword/',
        popup: {
          component: ForgetPassword,
        },
      },
      {
        path: 'verifyadmin/',
        popup: {
          component: VerifyAdmin,
        },
      }
    ],

  },
  {
    path: '/login/',
    options:{
      pushState:false,
      history:false
    },
    async: function (routeTo, routeFrom, resolve, reject) {

      var app = this.app;
      /*app.preloader.show();*/
      setTimeout(()=> {

        app.preloader.hide();

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));
        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: DashboardPage
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }, 500);
    },
    routes: [
      {
        path: 'forgetpassword/',
        popup: {
          component: ForgetPassword,
        },
      },
      {
        path: 'verifyadmin/',
        popup: {
          component: VerifyAdmin,
        },
      }
    ],

  },
  {
    path: '/signup/',
    component: SignUpPage,
    routes: [
      {
        path: 'verifyregistration/',
        popup: {
          component: VerifyRegistrationPage,
        },
      },
    ],
  },
  {
    path: '/completeregistration/',
    component: CompleteRegistration,
  },
  {
    path: '/profile/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ProfilePage
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }

    },
  },
  {
    path: '/configuration/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Configuration
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/changepassword/',
    async: function (routeTo, routeFrom, resolve, reject) {
      
      var app = this.app;
      /*app.preloader.show();*/

      setTimeout(()=> {

        app.preloader.hide();

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
              resolve({
                  component: ChangePasswordPage
              });
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }, 500);
    },
  },
  {
    path: '/dashboard/',
    /*options:{
      reloadAll:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {

        if(clientdetail.id > 0)
        {

          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: DashboardPage
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }

    },

  },
  {
    path: '/addlist/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddList
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/lists/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageList
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  { 
    path: '/editlist/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditList
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/smscredits/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: SMSCredit
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    tabs: [
      {
        path: '/',
        id: 'smscredit',
        component: SMSCreditTab
      },
      {
        path: '/leadcredit/',
        id: 'leadcredit',
        component: LeadCreditTab
      }
    ],
  },
  {
    path: '/smscreditpending/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: SMSCreditPending
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'payments/:paymentid/:frompage/',
        popup: {
          component: PaymentProcessing,
        },
      },
    ],
  },
  {
    path: '/addsmscredit/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddSMSCredit
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
      {
        path: 'payments/:paymentid/',
        popup: {
          component: PaymentProcessing,
        },
      },
    ],
  },
  {
    path: '/addleadcredit/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddLeadCredit
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
      {
        path: 'payments/:paymentid/',
        popup: {
          component: PaymentProcessing,
        },
      },
    ],
  },
  {
    path: '/addcontact/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddContact
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'addlistpopup/',
        popup: {
          component: QuickAddList,
        },
      },
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
        options:{
          pushState:false,
        },
      },
    ],
  },
  {
    path: '/addcontact/:listid/:listname/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddContact
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'addlistpopup/',
        popup: {
          component: QuickAddList,
        },
      },
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
    ],
  },
  {
    path: '/managecontacts/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageContact
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
	  routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: ContactFilter,
        },
      },
    ],
  },
  {
    path: '/managecontacts/:searchkeyword/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: ManageContact
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: ContactFilter,
        },
      },
    ],
  },
  {
    path: '/managecontacts/list/:listid/:listname/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: ManageContact
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: ContactFilter,
        },
      },
    ],
  },
  {
    path: '/editcontact/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditContact
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'addlistpopup/',
        popup: {
          component: QuickAddList,
        },
      },
    ],
  },
  {
    path: '/campaign/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: Campaign
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
	  routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: CampaignFilter,
        },
      },
    ],
  },
  {
    path: '/campaign/:statusid/:statusname/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: Campaign
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
	  routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: CampaignFilter,
        },
      },
    ],
  },
  {
    path: '/addcampaign/:datatype/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: AddCampaign
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
      {
        path: 'addcampaigngrouppopup/',
        popup: {
          component: QuickAddCampaignGroup,
        },
      },
    ],
  },
  {
    path: '/dataselection/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: DataSelection
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
  },
  {
    path: '/editcampaign/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditCampaign
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
      {
        path: 'addcampaigngrouppopup/',
        popup: {
          component: QuickAddCampaignGroup,
        },
      },
    ],
  },
  {
    path: '/editcampaign/:id/:actiontype/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditCampaign
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
      {
        path: 'addcampaigngrouppopup/',
        popup: {
          component: QuickAddCampaignGroup,
        },
      },
    ],
  },
  {
    path: '/viewlog/:id/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ViewLog
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'remark/:id/',
        popup: {
          component: LeadRemark,
        },
      },
    ],
  },
  {
    path: '/bulkaddrequest/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: BulkAddRequest
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    tabs: [
      {
        path: '/',
        id: 'pendingrequest',
        component: BulkAddPendingTab
      },
      {
        path: '/completedrequest/',
        id: 'completedrequest',
        component: BulkAddCompletedTab
      }
    ],
  },
  {
    path: '/bulkadd/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: BulkAdd
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'addlistpopup/',
        popup: {
          component: QuickAddList,
        },
      },
      {
        path: 'bulkthanksqueue/:type/',
        popup: {
          component: BulkAddThanks,
        },
      },
      {
        path: 'bulkthanks/:type/:addedcontact/:oldcontact/:totalcontact/',
        popup: {
          component: BulkAddThanks,
        },
      },
    ],
  },
  {
    path: '/ContactDashboard/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ContactDashboard
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }

    },
  },
  {
    path: '/campaigndashboard/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: CampaignDashboard
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/leaddashboard/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: LeadDashboard
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/addpage/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddPage
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
    ],
  },
  {
    path: '/pages/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManagePage
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  { 
    path: '/editpage/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditPage
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/previewpage/:id/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: PreviewPage
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/leads/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: Leads
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
	  routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: LeadFilter,
        },
      },
      {
        path: 'remark/:id/',
        popup: {
          component: LeadRemark,
        },
      },
      {
        path: 'assignstaffpopup/',
        popup: {
          component: AssignStaff,
        },
      },
    ],
  },
  {
    path: '/leads/:leadtype/:typename/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: Leads
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
	  routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: LeadFilter,
        },
      },
      {
        path: 'remark/:id/',
        popup: {
          component: LeadRemark,
        },
      },
      {
        path: 'assignstaffpopup/',
        popup: {
          component: AssignStaff,
        },
      },
    ],
  },
  {
    path: '/addleadcategory/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddLeadCatogory
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
    ],
  },
  {
    path: '/leadcategory/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageLeadCategory
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  { 
    path: '/editleadcategory/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditLeadCatogory
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/admindashboard/',
    /*options:{
      reloadAll:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let adminid        = Number(localStorage.getItem('prachar_adminid'));
      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {

        if(adminid > 0)
        {
            resolve({
                component: AdminDashboardPage
            });
        }
        else if(clientdetail.id > 0)
        {

          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: DashboardPage
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }

    },

  },
  {
    path: '/addstaff/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddStaff
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
    ],
  },
  {
    path: '/staff/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageStaff
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  { 
    path: '/editstaff/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditStaff
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/addleadtag/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddLeadTag
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
    ],
  },
  {
    path: '/leadtag/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageLeadTag
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  { 
    path: '/editleadtag/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditLeadTag
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/addcampaigngroup/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddCampaignGroup
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/campaigngroup/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageCampaignGroup
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  { 
    path: '/editcampaigngroup/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditCampaignGroup
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/addsupport/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddSupport
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
    ],
  },
  {
    path: '/support/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageSupport
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  { 
    path: '/editsupport/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditSupport
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  /*{
    path: '/senderids/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: SenderIDs
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    tabs: [
      {
        path: '/',
        id: 'pendingsenderids',
        component: PendingSenderIDsTab
      },
      {
        path: '/approvedsenderids/',
        id: 'approvedsenderids',
        component: ApprovedSenderIDsTab
      }
    ],
  },
  {
    path: '/addsenderid/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: BulkAdd
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'addlistpopup/',
        popup: {
          component: QuickAddList,
        },
      },
      {
        path: 'bulkthanksqueue/:type/',
        popup: {
          component: BulkAddThanks,
        },
      },
      {
        path: 'bulkthanks/:type/:addedcontact/:oldcontact/:totalcontact/',
        popup: {
          component: BulkAddThanks,
        },
      },
    ],
  },
  */
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
