import React,{Component} from 'react';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    LoginScreenTitle,
    ListInput,
    List,
    Button,
    Popup,
    Navbar,
    NavRight,
    NavLeft,
    NavTitle,
    Link,
    Block,
    Row,
    Col
  } from 'framework7-react';

  export default class VerifyAdmin extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        otp: '',
        isloaded:false,
        logintype:0,
        internetstatus:navigator.onLine ? "online" : "offline",
      };

      this.updateOnlineStatus   = this.updateOnlineStatus.bind(this);
      this.VerifyOTP            = this.VerifyOTP.bind(this);
      this.resendOTP            = this.resendOTP.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.hideSplashScreen();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus() {

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    VerifyOTP(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(this.state.internetstatus === "offline")
        {
             const toastWithButton = app.toast.create({
                 text: "No internet connection",
             });
               
             toastWithButton.open();
             
             return;
        }

        if(self.state.otp === '')
        {
            const toastWithButton = app.toast.create({
                text: `Please enter otp to verify`,
                closeButton: true,
                closeTimeout: 3000,
            });
              
            toastWithButton.open();

            app.input.validateInputs('.forgetpassuser');
            
            return;
        }
        else
        {
           const bodyFormData = {
              Mode:'VerifyAdmin',
              otp:self.state.otp,
              adminid:Number(localStorage.getItem('prachar_adminid')),
           };

            let serverurl = "/";

            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

            const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

           app.request.promise.post(`${serverurl}api/applogin.php?t=${Date.now()}`,bodyFormData)
            .then(function (res) {

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                localStorage.setItem("prachar_isadminverified",true);
                self.$f7.views.main.router.navigate('/admindashboard/');
              }
              else
              {
                const toastWithButton = app.toast.create({
                    text: resdata.msg,
                    closeButton: true,
                    closeTimeout: 3000,
                });
                
                toastWithButton.open();
              }

            }).catch(e => {
              console.log(e);
          });
        }
    }

    resendOTP(){

      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      if(this.state.internetstatus === "offline")
      {
           const toastWithButton = app.toast.create({
               text: "No internet connection",
           });
             
           toastWithButton.open();
           
           return;
      }

         const bodyFormData = {
            Mode:'ResendAdminOTP',
            otp:self.state.otp,
            adminid:Number(localStorage.getItem('prachar_adminid')),
         };

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

         app.request.promise.post(`${serverurl}api/applogin.php?t=${Date.now()}`,bodyFormData)
          .then(function (res) {

            const resdata = JSON.parse(res.data);

            if(resdata.success === true)
            {
              const toastWithButton = app.toast.create({
                text: resdata.msg,
                closeButton: true,
                closeTimeout: 3000,
            });
            
            toastWithButton.open();
            }
          }).catch(e => {
            console.log(e);
        });
    }
    
    render(){

        return(
            <Popup className="demo-popup">
                {/*<Navbar sliding={true}>
                <NavLeft>
                    <Link popupClose>
                    <i className="icon icon-back"></i>                      
                    </Link>
                    <img className="navbarlogo" src={scanlogo} alt="logo" />
                </NavLeft>
                <NavTitle>Verify Login</NavTitle>
                </Navbar>*/}
                <Page loginScreen>
                    <Row>
                        <Col>
                            <img className="loginlogo" src={scanlogo} alt="logo" />
                        </Col>
                    </Row>
                    <LoginScreenTitle>Admin Verification</LoginScreenTitle>
                    <Row noGap>
                        <Col width="10"></Col>
                        <Col width="80">
                            <List noHairlinesMd>
                                <ListInput
                                className="forgetpassuser"
                                label="OTP"
                                floatingLabel
                                type="number"
                                placeholder="OTP"
                                value={this.state.otp}
                                onInput={(e) => {
                                    this.setState({ otp: e.target.value});
                                }}
                                required validate
                                />
                            </List>
                            <List noHairlinesMd>
                                <Row>
                                    <Col width="20"></Col>
                                    <Col width="60"><Button fill color="blue" onClick={()=>{
                                        this.VerifyOTP();
                                    }}>Verify</Button></Col>
                                    <Col width="20"></Col>
                                </Row>
                                <Row>
                                    <Col style={{textAlign:"center",marginTop:'15px'}}><Link  onClick={()=>{
                                        this.resendOTP();
                                    }}>Resend OTP</Link></Col>
                                </Row>
                            </List>
                        </Col>
                        <Col width="10"></Col>
                    </Row>
                </Page>
            </Popup>
        );
    }
}