import React,{Component,Fragment} from 'react';
import AppNavbar from './appnavbar';
import Chart from 'chart.js';
import { CommonConsumer } from '../context/CommonContext';
import logo404 from '../assets/images/orlo404.png';

import {
    Page,
    PageContent,
    BlockTitle,
    Block,
    List,
    Row,
    Col,
    ListItem,
    Fab,
    Icon,
    Toggle,
    Link,
  } from 'framework7-react';

  export default class ViewLog extends Component {
    constructor(props) {
      super(props);
     
      this.state = {
        smsloglist:[],
        totalavailable:0,
        totalopened:0,
        totalinterested:0,
        totalsent:0,
        totalreferred:0,
        clientdetail:[],
        isptr:false,
        isloaded:false,
        internetstatus:navigator.onLine ? "online" : "offline",
        ismount:false,
        isdatafetched:false,
        effect:'blink',
        hasdataavailable:false,
        graphlabels:[],
        graphdata:[],
        bgcolordata:[],
        interestedonly:false,
      };

      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.handleUserInput    = this.handleUserInput.bind(this);
      this.getSMSLog          = this.getSMSLog.bind(this);
      this.manageLeadRemark	  = this.manageLeadRemark.bind(this);
      this.manageLeadCall			= this.manageLeadCall.bind(this);

    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('prachar_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getSMSLog();
        this.hideSplashScreen();

      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus(){

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    
    handleUserInput(e){
    
      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'interestedonly')
      {
        this.setState(()=>{
          return{
            interestedonly:!this.state.interestedonly
          }
        },()=>{

          this.getSMSLog();

        });
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    getSMSLog(){

        const self  = this;
        const app   = self.$f7;
        const $$    = self.Dom7;

        const bodyFormData = {
            Mode:'GetSmsLogHistory',
            clientid:self.state.clientdetail.id,
            interestedonly:Number(self.state.interestedonly),
            recordid:this.props.f7route.params.id,
        };

        this.setState(()=>{

          return{
            isdatafetched:false
          }

        },()=>{

          setTimeout(()=> {
  
            let serverurl = "/";
  
            if(process.env.NODE_ENV === "development")
            {
                serverurl = "http://orloprachar/app/";
            }

            const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
    
            app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {
  
                // Hide Pull To Refresh
                app.ptr.done();
  
                const resdata = JSON.parse(res.data);
      
                if(resdata.success === true)
                {
                    this.setState(()=>{
                      return{
                        smsloglist:resdata.recordlist,
                        totalavailable:resdata.totalavailable,
                        totalopened:resdata.totalopened,
                        totalsent:resdata.totalsent,
                        totalreferred:resdata.totalreferred,
                        totalinterested:resdata.totalinterested,
                        graphlabels:resdata.graphlabels,
                        graphdata:resdata.graphdata,
                        bgcolordata:resdata.bgcolordata,
                        isptr:false,
                        isdatafetched:true,
                        hasdataavailable:true
                      }
                    },()=>{

                      const ctx   = $$('#logChart');

                      new Chart(ctx, {
                        type: 'horizontalBar',
                        data: {
                            labels: this.state.graphlabels,
                            datasets: [{
                                label: 'Phone Model',
                                data: this.state.graphdata,
                                backgroundColor: this.state.bgcolordata,
                            }]
                        },
                        options: {
                          "hover": {
                            "animationDuration": 0
                          },
                          "animation": {
                            "duration": 1,
                            "onComplete": function() {
                              var chartInstance = this.chart,
                                ctx = chartInstance.ctx;

                              ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                              ctx.textAlign = 'center';
                              ctx.textBaseline = 'bottom';

                              this.data.datasets.forEach(function(dataset, i) {
                                var meta = chartInstance.controller.getDatasetMeta(i);
                                meta.data.forEach(function(bar, index) {
                                  var data = dataset.data[index];
                                  ctx.fillText(data, bar._model.x + 3, bar._model.y + 5);
                                });
                              });
                            }
                          },
                          legend: {
                            "display": false
                          },
                          tooltips: {
                            "enabled": false
                          },
                          scales: {
                            xAxes: [{
                                gridLines: {
                                  display: false
                                },
                                ticks: {
                                    beginAtZero:true
                                }
                            }]
                          }
                        }
                      });

                    })
                }
                else
                {
                  this.setState(()=>{
                    return{
                      smsloglist:[],
                      totalavailable:0,
                      totalopened:0,
                      totalsent:0,
                      totalreferred:0,
                      totalinterested:0,
                      isptr:false,
                      isdatafetched:true,
                      hasdataavailable:false
                    }
                  });
                }
      
            }).catch(e => {
                console.log(e);
            });
  
          },500);

        });
    }

    downloadLog(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'DownloadLog',
          clientid:self.state.clientdetail.id,
          interestedonly:Number(self.state.interestedonly),
          recordid:this.props.f7route.params.id,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          let serverurl = "/";

          if(process.env.NODE_ENV === "development")
          {
              serverurl = "http://orloprachar/app/";
          }

          const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);
              if(resdata.success === true)
              {
                  window.open(resdata.reportfilepath);
              }
              else
              {
                  const toastWithButton = app.toast.create({
                      text: resdata.msg,
                      closeButton: true,
                      closeTimeout: 3000,
                  });
                    
                  toastWithButton.open();    
              }
          }).catch(e => {
              console.log(e);
          });

      },500);
    }

    manageLeadRemark(recordid, leadcredit){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

      if(Number(leadcredit) < 1)
      {
        app.actions.create({
          buttons: [
          // First group
          [
            {
              text: 'Are you sure? you want to use your lead credit',
              label: true
            },
            {
            text: 'Yes',
            bold: true,
            color:'blue',
            onClick:()=>{
                if(this.state.internetstatus === "online")
                {
                    const bodyFormData = {
                        Mode:'MarkUseLeadCredit',
                        clientid:this.state.clientdetail.id,
                        recordid:recordid,
                    };

                    app.preloader.show();

                    // Simulate Ajax Request
                    setTimeout(()=> {

                      let serverurl = "/";

                      if(process.env.NODE_ENV === "development")
                      {
                          serverurl = "http://orloprachar/app/";
                      }

                      const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
                      app.request.setup({
                        headers:{
                          Authorization: `${accesstoken}`
                        }
                      });

                      app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
                      .then((res)=> {

                          // We got user data from request
                  
                          // Hide Preloader
                          app.preloader.hide();

                          const resdata = JSON.parse(res.data);

                          if(resdata.success === true)
                          {
                            this.getSMSLog();

                            this.$f7.views.main.router.navigate(`remark/${recordid}/`);                            
                          }
                          else
                          {
                            const toastWithButton = app.toast.create({
                              text: resdata.msg,
                              closeButton: true,
                              closeTimeout: 3000,
                            });
                  
                            toastWithButton.open();
                          }
                
                      }).catch(e => {
                          console.log(e);
                      });

                    }, 500);
                }
              }
            }
          ],
          // Second group
          [
            {
              text: 'No',
              color: 'red'
            }
          ]
          ]
        }).open();
      }
      else
      {
        this.$f7.views.main.router.navigate(`remark/${recordid}/`);
      }
    }

    manageLeadCall(recordid, leadcredit){

      const self	= this;
      const app	= self.$f7;
      const $$	= self.Dom7;

      if(Number(leadcredit) < 1)
      {
        app.actions.create({
          buttons: [
          // First group
          [
            {
              text: 'Are you sure? you want to use your lead credit',
              label: true
            },
            {
            text: 'Yes',
            bold: true,
            color:'blue',
            onClick:()=>{
                if(this.state.internetstatus === "online")
                {
                    const bodyFormData = {
                        Mode:'MarkUseLeadCredit',
                        clientid:this.state.clientdetail.id,
                        recordid:recordid,
                    };

                    app.preloader.show();

                    // Simulate Ajax Request
                    setTimeout(()=> {

                      let serverurl = "/";

                      if(process.env.NODE_ENV === "development")
                      {
                          serverurl = "http://orloprachar/app/";
                      }

                      const accesstoken  = localStorage.getItem('prachar_accesstoken');
          
                      app.request.setup({
                        headers:{
                          Authorization: `${accesstoken}`
                        }
                      });

                      app.request.promise.post(`${serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
                      .then((res)=> {

                          // We got user data from request
                  
                          // Hide Preloader
                          app.preloader.hide();

                          const resdata = JSON.parse(res.data);

                          if(resdata.success === true)
                          {
                            this.getSMSLog();

                            window.open(`tel:${resdata.phonenumber}`,'_system');
                          }
                          else
                          {
                            const toastWithButton = app.toast.create({
                              text: resdata.msg,
                              closeButton: true,
                              closeTimeout: 3000,
                            });
                  
                            toastWithButton.open();
                          }
                
                      }).catch(e => {
                          console.log(e);
                      });

                    }, 500);
                }
              }
            }
          ],
          // Second group
          [
            {
              text: 'No',
              color: 'red'
            }
          ]
          ]
        }).open();
      }
    }

    render(){
        
      const{totalavailable, totalopened, totalsent, smsloglist, isdatafetched, hasdataavailable,totalinterested, totalreferred, interestedonly} = this.state;

      return(
        <Page className="page" name="ViewLog" pageContent={false}>
        <AppNavbar title="View History" showrefresh={true} init={this.getSMSLog}/>
        <PageContent>

        <CommonConsumer>{(value)=>{

          return(
            <Block>
                {
                  isdatafetched ? (
                    <Fragment>
                      <List style={{marginBottom:'5px',marginTop:'5px'}}> 
                          <ListItem title="Show Interested Only?">
                            <Toggle slot="after" name="interestedonly" checked={Number(interestedonly) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                          </ListItem>
                      </List>
                    {
                      hasdataavailable ? (
                          <Fragment>
                            <Block style={{textAlign:'center',fontWeight:'bold'}}>
                                <p>Total Available : {Number(totalavailable)}</p>
                                <p>Total Opened : {Number(totalopened)}</p>
                                <p>Total Interested : {Number(totalinterested)}</p>
                                <p>Total Sent : {Number(totalsent)}</p>
                                <p>Total Referred : {Number(totalreferred)}</p>
                            </Block>
                            <canvas id="logChart" width="100%" height="75vh"></canvas>
                            <Row noGap className='rowwithbottomborder'>
                                <Col width='30' className="tableheader">
                                    Camp.
                                </Col>
                                <Col width='25' className="tableheader">
                                    Phone
                                </Col>
                                <Col width='15' className="tableheader">
                                    Int.?
                                </Col>
                                <Col width='20' className="tableheader">
                                    Opn. Cnt.
                                </Col>
                                <Col width='10' className="tableheader">
                                    R.
                                </Col>
                            </Row>
                            {
                            Number(totalopened) ? (  
                              smsloglist.map((smslog)=>{
                                    
                                    let statusclassname = 'redcolortxt';
                                    if(Number(smslog.status) > 0)
                                    {
                                      statusclassname = 'greencolortxt';
                                    }

                                    let referredclassname = '';

                                    if(Number(smslog.isreferred) > 0)
                                    {
                                      referredclassname = 'greencolortxt';
                                    }

                                    return(
                                      <Row key={smslog.id} noGap className='rowwithbottomborder'>
                                          <Col width='30' className="ledgercolumn textalignleft">
                                             {smslog.sentdate}<br />
                                              {smslog.senttime}<br />
                                              {smslog.campaignname}
                                          </Col>
                                          <Col width='25' className={`ledgercolumn ${referredclassname}`}>
                                            {smslog.name ? (<div>{smslog.name}</div>):null}
                                            <div style={{fontWeight:'bold',lineHeight:'20px',margin:'auto'}}>
                                            {smslog.phonenumber}
                                            &nbsp;
                                            {
                                              smslog.leadcredit ? (
                                                <Link external href={`tel:${smslog.phonenumber}`} target="_system">
                                                <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                                </Link>
                                              ):(
                                                <Link onClick={()=>{
                                                  this.manageLeadCall(smslog.id, smslog.leadcredit);
                                                }}>
                                                <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                                </Link>
                                              )
                                            }
                                            </div>
                                            <div>({smslog.mobile_device})</div>
                                          </Col>
                                          <Col width='15' className="ledgercolumn ">
                                              <b>{smslog.interestedtext}</b>
                                          </Col>
                                          <Col width='20' className={`ledgercolumn ${statusclassname}`}>
                                              {smslog.statustext}
                                              {
                                                smslog.timeago ? (<p style={{textAlign:'center',color:'#ff0000'}}>{smslog.timeago} ago</p>):null
                                              }
                                          </Col>
                                          <Col width='10' className={`ledgercolumn`}>
                                            <Link onClick={()=>{
                                              this.manageLeadRemark(smslog.id, smslog.leadcredit);
                                            }}>
                                            {
                                              smslog.hasremark ? (<Icon f7="square_pencil_fill"></Icon>):(<Icon f7="square_pencil"></Icon>)
                                            }
                                            </Link>
                                          </Col>
                                      </Row>
                                      );
                                  })
                            ):(
                              <Block>
                              <br />
                              <Row>
                              <Col>
                                  <img src={logo404} className="norecimg" />
                                  <BlockTitle className="errmsg">No sms opened.</BlockTitle>
                              </Col>
                              </Row>
                          </Block>
                            )
                            }
                        </Fragment>
                      ):(
                          <Block>
                              <br />
                              <Row>
                              <Col>
                                  <img src={logo404} className="norecimg" />
                                  <BlockTitle className="errmsg">No record found.</BlockTitle>
                              </Col>
                              </Row>
                          </Block>                                
                      )
                    }
                    </Fragment>
                  ):(
                    <List mediaList className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                      <ListItem
                        title="Title Title Title Title Title Title Title Title Title"
                      >
                      </ListItem>
                      <ListItem
                        title="Title Title Title Title Title Title Title Title Title"
                      >
                      </ListItem>
                      <ListItem
                        title="Title Title Title Title Title Title Title Title Title"
                      >
                      </ListItem>
                      <ListItem
                        title="Title Title Title Title Title Title Title Title Title"
                      >
                      </ListItem>
                      <ListItem
                        title="Title Title Title Title Title Title Title Title Title"
                      >
                      </ListItem>
                      <ListItem
                        title="Title Title Title Title Title Title Title Title Title"
                      >
                      </ListItem>                              
                      <ListItem
                        title="Title Title Title Title Title Title Title Title Title"
                      >
                      </ListItem>
                      <ListItem
                        title="Title Title Title Title Title Title Title Title Title"
                      >
                      </ListItem>                              
                      <ListItem
                        title="Title Title Title Title Title Title Title Title Title"
                      >
                      </ListItem>
                    </List>
                  )
                }
                <br /><br /><br />
            </Block>
          );
        }}
        </CommonConsumer>
        </PageContent>
          {
             Number(totalopened) ? (
              <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                if(this.state.internetstatus === "online")
                    {
                      this.downloadLog();
                    }
                }}>
                <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
              </Fab>
            ):null
          }
        </Page>
      );
    }
}